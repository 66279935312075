import React, { useEffect, useState } from 'react'
const MasterDetails = (props) => {
  const { data } = props;
  const [state, setState] = useState({
    FULL_NAME: { name: 'FULL_NAME', value: data?.FULL_NAME ? data.FULL_NAME : '', error: '', isRequired: true },
    EMAIL: { name: 'EMAIL', value: data?.EMAIL ? data.EMAIL : '', error: '', isRequired: true },
    PHONE: { name: 'PHONE', value: data?.PHONE ? data.PHONE : '', error: '', isRequired: true },
    ROLE_NAME: { name: 'ROLE_NAME', value: data?.ROLE_NAME ? data.ROLE_NAME : '', options: [], error: '', isRequired: true },
    USER_NAME: { name: 'USER_NAME', value: data?.USER_NAME ? data.USER_NAME : '', error: '', isRequired: true },
  })

  return (
    <React.Fragment>
      <div className="tab-content pt-4 text-muted">
        <div
          className="tab-pane active"
          id="overview-tab"
          role="tabpanel"
        >
          <div className="row">
            <div className="col-xxl-12">
              <div className="card p-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className={state.FULL_NAME.error && !state.FULL_NAME.value ? "form-control is-invalid" : "form-control"}
                          name={state.FULL_NAME.name}
                          value={state.FULL_NAME.value}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label htmlFor="lastnameInput" className="form-label">
                          User Name
                        </label>
                        <input
                          type="text"
                          className={state.USER_NAME.error && !state.USER_NAME.value ? "form-control is-invalid" : "form-control"}
                          name={state.USER_NAME.name}
                          value={state.USER_NAME.value}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label htmlFor="lastnameInput" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className={state.EMAIL.error && !state.EMAIL.value ? "form-control is-invalid" : "form-control"}
                          name={state.EMAIL.name}
                          value={state.EMAIL.value}
                        />
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label htmlFor="phonenumberInput" className="form-label">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className={state.PHONE.error && !state.PHONE.value ? "form-control is-invalid" : "form-control"}
                          name={state.PHONE.name}
                          value={state.PHONE.value}

                        />
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Role
                        </label>
                        <input
                          className={state.ROLE_NAME.error && !state.ROLE_NAME.value ? "form-control is-invalid" : "form-control"}
                          name={state.ROLE_NAME.name}
                          value={state.ROLE_NAME.value}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default MasterDetails