import React, { useEffect, useState } from 'react'
import DocumentTypeList from '../../../components/CompanyAdmin/settings/documentTypeList'
import Modal from '../../../components/common/htmlModal';
import DesignationForm from '../../../components/CompanyAdmin/settings/designationForm';
import { v2_add_designations, v2_add_documentTypes, v2_designations, v2_documentTypes, v2_edit_designations, v2_edit_document_types, v2_visibility_change_designations, v2_visibility_change_document_types } from '../../../actions/v2/setting';

const Designation = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState(null);
    const [count, setCount] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const onAdd = (model) => {
        v2_add_documentTypes(model).then((res) => {
            if (res.status) {
                setCurrentPage(1)
                setRefresh(!refresh)
                setShowAddModal(false)
            }
        })
    }


    const onEdit = (model) => {
        model.DOCUMENT_TYPE_ID = showEditModal.DOCUMENT_TYPE_ID
        v2_edit_document_types(model).then((res) => {
            if (res.status) {
                setRefresh(!refresh)
                setShowEditModal(null)

            }
        })
    }

    const onChangeVisibility = (id, status) => {
        v2_visibility_change_document_types({ DOCUMENT_TYPE_ID: id, STATUS: status }).then((res) => {
            if (res.status) {
                setRefresh(!refresh)
            }

        })
    }

    useEffect(() => {

        v2_documentTypes(currentPage).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.TOTAL)

            }

        })
    }, [currentPage, refresh])
    return (
        <React.Fragment>
            <Modal isOpen={showAddModal} onClose={() => setShowAddModal(false)} header='Add Designation'>
                <DesignationForm
                    onSubmit={onAdd}
                    buttonName={'Add New'}
                />
            </Modal>
            <Modal isOpen={showEditModal} onClose={() => setShowEditModal(false)} header='Edit Designation'>
                <DesignationForm
                    onSubmit={onEdit}
                    buttonName={'Save'}
                    data={showEditModal}
                />
            </Modal>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Document Type</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Settings</a>
                                            </li>
                                            <li className="breadcrumb-item active">Document Type</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Document Type List</h4>
                                        <div className="flex-shrink-0">
                                            <div className="form-check form-switch form-switch-right form-switch-md">
                                                <button type="button" onClick={() => { setShowAddModal(true) }} className="btn btn-primary waves-effect waves-light"><i className="ri-add-line" /> Add New</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end card header */}
                                    <div className="card-body">
                                        <div className="live-preview">
                                            <DocumentTypeList
                                                list={list}
                                                count={count}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                onChangeVisibility={onChangeVisibility}
                                                setShowEditModal={setShowEditModal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>

    )
}

export default Designation