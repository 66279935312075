import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Pagination from '../../common/Pagination';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import DrawerModal from '../../common/htmlDrawerModal'
import DetailModal from './demoRequestedDetailDrawerModal'
import Modal from '../../common/htmlModal';

const DemoRequestList = (props) => {
    const { list, count, currentPage, openChangeStatusModal, setOpenChangeStatusModal } = props;
    const [state, setState] = useState({
        KEYWORD: { name: 'KEYWORD', value: '' },
        FROM_DATE: { name: 'FROM_DATE', value: moment().format('YYYY-MM-DD') },
        TO_DATE: { name: 'TO_DATE', value: moment().format('YYYY-MM-DD') }
    })

    const [statusState, setStatusState] = useState('')


    const [showDetail, setShowDetail] = useState(null);
    useEffect(() => {
        if (openChangeStatusModal) {
            setStatusState(openChangeStatusModal.CURRENT_STATUS)
        }
        else {
            setStatusState('')
        }
    }, [openChangeStatusModal])
    const onReset = () => {
        setState({
            KEYWORD: { name: 'KEYWORD', value: '' },
            FROM_DATE: { name: 'FROM_DATE', value: moment().format('YYYY-MM-DD') },
            TO_DATE: { name: 'TO_DATE', value: moment().format('YYYY-MM-DD') }
        })
        const model = {
            KEYWORD: '',
            FROM_DATE: moment().format('YYYY-MM-DD'),
            TO_DATE: moment().format('YYYY-MM-DD'),
        }

        props.onSearch(model)
    }

    const onSearch = () => {
        const model = {
            KEYWORD: state.KEYWORD.value,
            FROM_DATE: state.FROM_DATE.value,
            TO_DATE: state.TO_DATE.value,

        }

        props.onSearch(model)
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }
    const onUpdateStatus = () => {
        if (statusState) {
            props.onUpdateStatus({ CURRENT_STATUS: statusState, ID: openChangeStatusModal.ID })
        }
    }
    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(list.map((item) => {
            const model = {
                'Request ID ': item.REQUEST_ID,
                'Company Name': item.COMPANY_NAME,
                'Requester Name': item.NAME,
                'Current Status': item.CURRENT_STATUS,
                'Recruiter Size': item.RECRUITERS_SIZE,
                'Business Email': item.BUSINESS_EMAIL,
                'Phone': item.PHONE,
                'Location': item.LOCATION,
                'Requirement items': item.REQUIREMENT_DETAILS,
                'Requested Date': moment(item.CREATED_ON).format('DD MMM YYYY'),
            }
            return model
        }));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(data, 'data.xlsx');
    };
    return (
        <React.Fragment>
            <DrawerModal isOpen={showDetail} onClose={() => { setShowDetail(null) }}>
                {showDetail && <DetailModal
                    detail={showDetail}
                />}
            </DrawerModal>
            <Modal
                header={'Change Status'}
                isOpen={openChangeStatusModal} onClose={() => { setOpenChangeStatusModal(null) }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="">
                            <label> Select Status</label>
                            <select
                                className="form-select"
                                value={statusState}
                                onChange={(e) => {
                                    setStatusState(e.target.value)
                                }}
                            >
                                <option value='Requested'> Requested</option>
                                <option value='Demo Scheduled'> Demo Scheduled</option>
                                <option value='Demo Completed'> Demo Completed</option>
                                <option value='Lead Converted'> Lead Converted</option>
                                <option value='Failed to Convert Lead'> Failed to Convert Lead</option>

                            </select>
                        </div>
                    </div>
                    <div className="col-lg-auto mt-3">
                        <button type="button" onClick={() => {
                            onUpdateStatus()
                        }} className="btn btn-success add-btn ms-1">
                            Save
                        </button>
                    </div>
                </div>

            </Modal>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Demo Requests</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Dashboard</a>
                                            </li>
                                            <li className="breadcrumb-item active">Demo Requests</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-sm-5">
                                                <div className="search-box">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Company Name, SPOC Name"
                                                        name={state.KEYWORD.name}
                                                        value={state.KEYWORD.value}
                                                        onChange={onChange}
                                                    />
                                                    <i className="ri-search-line search-icon" />
                                                </div>
                                            </div>


                                            <div className="col-sm-2">
                                                <input
                                                    type="date"
                                                    min={5}
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    name={state.FROM_DATE.name}
                                                    value={state.FROM_DATE.value}
                                                    onChange={onChange}
                                                />
                                            </div>

                                            <div className="col-sm-2">
                                                <input
                                                    type="date"
                                                    min={5}
                                                    className="form-control"
                                                    name={state.TO_DATE.name}
                                                    value={state.TO_DATE.value}
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="hstack gap">
                                                    <button type="button" onClick={() => { onReset() }} className="btn btn-danger add-btn">
                                                        Reset
                                                    </button>
                                                    <button type="button" onClick={() => {
                                                        onSearch()
                                                    }} className="btn btn-success add-btn ms-1">
                                                        <i className="ri-search-line search-icon align-bottom me-1" />
                                                        Search
                                                    </button>
                                                    <button type="button" onClick={() => {
                                                        downloadExcel()
                                                    }} className="btn btn-primary add-btn ms-1">
                                                        <i className="ri-file-excel-2-line align-bottom me-1" />
                                                        Download
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>

                                                            <th className="sort">Company Name</th>
                                                            <th className="sort">Contact Details</th>
                                                            <th className="sort">Requested On</th>
                                                            <th className="sort">Current Status</th>
                                                            {/* <th className="sort">Client Add Date</th> */}
                                                            <th className="sort">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {list && list.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td className="id">
                                                                        {item.COMPANY_NAME}
                                                                    </td>
                                                                    <td>
                                                                        <tr className="flex-grow-1 name"> <i className="ri-mail-line text-primary me-1 align-bottom" />&nbsp;&nbsp;{item.BUSINESS_EMAIL}</tr>
                                                                        <tr className="flex-grow-1 name"> <i className="ri-phone-line text-primary me-1 align-bottom" />&nbsp;&nbsp;{item.PHONE}</tr>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <div className="flex-grow-1 name">{moment(item.CREATED_ON).format('DD MMM YYYY on hh:mm A')}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="company_name">
                                                                        {item.CURRENT_STATUS}
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                                            <li
                                                                                className="list-inline-item"

                                                                            >
                                                                                <a href="javascript:void(0);" onClick={() => { setShowDetail(item) }}>
                                                                                    <i className="ri-eye-fill align-bottom text-muted" />
                                                                                </a>
                                                                            </li>
                                                                            <li
                                                                                className="list-inline-item edit"
                                                                            >
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    onClick={() => { setOpenChangeStatusModal(item) }}

                                                                                    className="text-muted d-inline-block"
                                                                                >
                                                                                    <i className="ri-edit-2-line fs-16" />
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })

                                                        }
                                                    </tbody>
                                                </table>
                                                {count === 0 && <div className="noresult" >
                                                    <div className="text-center">
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                                            trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a"
                                                            style={{ width: 75, height: 75 }}
                                                        />
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">
                                                            We've searched more than 150+ leads We did not find any
                                                            leads for you search.
                                                        </p>
                                                    </div>
                                                </div>}
                                            </div>
                                            {count > 0 && < Pagination
                                                limit={20}
                                                totalCount={count}
                                                range={5}
                                                onPageChange={(page) => {
                                                    props.setCurrentPage(page)
                                                }}
                                                currentPage={currentPage}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default DemoRequestList