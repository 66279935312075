import axios from 'axios'
import { getCompanyUserAuthHeader } from '../../utils';

export const v3_team_table_insight = async (model) => {

       let url = `${process.env.REACT_APP_BASE_URL}/v3/team/table-insight/${model.FROM_DATE}/${model.TO_DATE}`;
       if (model.USER_ID) {
           url += `?USER_ID=${model.USER_ID}`;
       }
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v3_team_dashboard_count_stats = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/team-dashboard/count-stats`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_team_lead_owners = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/team-lead-owners`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_team_dashboard_billing_cycle_candidates = async (PAGE) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/team-dashboard/billing-cycle/candidates/${PAGE}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_team_dashboard_job_status_wise_candidates = async (STATUS_ID, PAGE, QUERY) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/team-dashboard/job-status-wise/candidates/${STATUS_ID}/${PAGE}/${QUERY}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v3_team_dashbaord_today_candidate_reminder = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/team-dashboard/today-candidate-reminder`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_team_dashboard_application_statistics = async (DATE_RANGE) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/team-dashboard/application-statistics/${DATE_RANGE}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}