import React, { useEffect, useState } from 'react'
import { v3_live_jobs, v3_update_job_visibility } from '../../../actions/v3/jobs'
import Posted from '../../../components/companyUser/jobs/jobList/posted'
import { getStorage } from '../../../utils';
import constant from '../../../constant';

const JobList = (props) => {
  const havingTeam = getStorage(constant.keys.companyUserHavingTeam);
  const isAdministrator = getStorage(constant.keys.companyUserHavingAdminRights);

  const [jobList, setJobList] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')


  useEffect(() => {
    v3_live_jobs({ keyword:searchKeyword}).then((res) => {
      if (res.status) {
        setJobList(res.result)
      }
    })
  }, [refresh, searchKeyword])

  const updateJobVisibility = (model) => {
    v3_update_job_visibility({ JOB_ID: model.JOB_ID, IS_LIVE: false }).then((res) => {
      if (res.status) {
        setRefresh(!refresh)
      }
    })
  }
  return (
    <React.Fragment>
      <Posted
        Title={'Live Jobs'}
        jobList={jobList}
        CLOSE_JOB_BUTTON={
          (havingTeam || isAdministrator) ? true : false
        }
        updateJobVisibility={updateJobVisibility}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </React.Fragment>
  )
}

export default JobList