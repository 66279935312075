import React, { useEffect, useState } from 'react'
import { v3_designation_options, v3_work_location_options } from '../../../actions/v3/administrator';

const MasterDetails = (props) => {
    const { data } = props;
    const [state, setState] = useState({
        EMPLOYEE_CODE: { name: 'EMPLOYEE_CODE', value: data?.EMPLOYEE_CODE ? data.EMPLOYEE_CODE : '', error: '', isRequired: true },
        DESIGNATION_NAME: { name: 'DESIGNATION_NAME', value: data?.DESIGNATION_NAME ? data.DESIGNATION_NAME : '', options: [], error: '', isRequired: true },
        WORK_LOCATION_NAME: { name: 'WORK_LOCATION_NAME', value: data?.WORK_LOCATION_NAME ? data.WORK_LOCATION_NAME : '', options: [], error: '', isRequired: true },
        DATE_OF_JOINING: { name: 'DATE_OF_JOINING', value: data?.DATE_OF_JOINING ? data.DATE_OF_JOINING : '', options: [], error: '', isRequired: true },
        ID_CARD_NUMBER: { name: 'ID_CARD_NUMBER', value: data?.ID_CARD_NUMBER ? data.ID_CARD_NUMBER : '', error: '', isRequired: false },
    })

    return (
        <React.Fragment>
            <div className="tab-content pt-4 text-muted">
                <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                >
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="card p-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Employee Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.EMPLOYEE_CODE.error && !state.EMPLOYEE_CODE.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.EMPLOYEE_CODE.name}
                                                    value={state.EMPLOYEE_CODE.value}
                                                    placeholder='Employee Code'

                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label htmlFor="lastnameInput" className="form-label">
                                                    Designation
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.DESIGNATION_NAME.error && !state.DESIGNATION_NAME.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.DESIGNATION_NAME.name}
                                                    value={state.DESIGNATION_NAME.value}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label htmlFor="lastnameInput" className="form-label">
                                                    Work Location
                                                </label>
                                                <input
                                                    className={state.WORK_LOCATION_NAME.error && !state.WORK_LOCATION_NAME.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.WORK_LOCATION_NAME.name}
                                                    value={state.WORK_LOCATION_NAME.value}
                                                />

                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Date Of Joining
                                                </label>
                                                <input
                                                    type="date"
                                                    className={state.DATE_OF_JOINING.error && !state.DATE_OF_JOINING.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.DATE_OF_JOINING.name}
                                                    value={state.DATE_OF_JOINING.value}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    ID Card Number
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.ID_CARD_NUMBER.error && !state.ID_CARD_NUMBER.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.ID_CARD_NUMBER.name}
                                                    value={state.ID_CARD_NUMBER.value}
                                                    placeholder='ID Card Number'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default MasterDetails