import React from 'react'

const ForgetPassword = () => {
    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay" />
                {/* auth-page content */}
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card overflow-hidden">
                                    <div className="row justify-content-center g-0">
                                        <div className="col-lg-6">
                                            <div className="p-lg-5 p-4 auth-one-bg h-100">
                                                <div className="bg-overlay" />
                                                <div className="position-relative h-100 d-flex flex-column">
                                                    <div className="mb-4">
                                                        <a href="#" className="d-block">
                                                            <img
                                                                src="/assets/images/logo/recruit-logo-2.png"
                                                                alt=""
                                                                height={47}
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="mt-auto">
                                                        <div className="mb-3">
                                                            <i className="ri-double-quotes-l display-4 text-success" />
                                                        </div>
                                                        <div
                                                            id="qoutescarouselIndicators"
                                                            className="carousel slide"
                                                            data-bs-ride="carousel"
                                                        >
                                                            <div className="carousel-indicators">
                                                                <button
                                                                    type="button"
                                                                    data-bs-target="#qoutescarouselIndicators"
                                                                    data-bs-slide-to={0}
                                                                    className="active"
                                                                    aria-current="true"
                                                                    aria-label="Slide 1"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    data-bs-target="#qoutescarouselIndicators"
                                                                    data-bs-slide-to={1}
                                                                    aria-label="Slide 2"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    data-bs-target="#qoutescarouselIndicators"
                                                                    data-bs-slide-to={2}
                                                                    aria-label="Slide 3"
                                                                />
                                                            </div>
                                                            <div className="carousel-inner text-center text-white pb-5">
                                                                <div className="carousel-item active">
                                                                    <p className="fs-15 fst-italic">
                                                                        " Great! Clean code, clean design, easy for
                                                                        customization. Thanks very much! "
                                                                    </p>
                                                                </div>
                                                                <div className="carousel-item">
                                                                    <p className="fs-15 fst-italic">
                                                                        " The theme is really great with an amazing
                                                                        customer support."
                                                                    </p>
                                                                </div>
                                                                <div className="carousel-item">
                                                                    <p className="fs-15 fst-italic">
                                                                        " Great! Clean code, clean design, easy for
                                                                        customization. Thanks very much! "
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* end carousel */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end col */}
                                        <div className="col-lg-6">
                                            <div className="p-lg-5 p-4">
                                                <h5 className="text-primary">Forgot Password?</h5>
                                                <p className="text-muted">Reset password with Jobzshala Recruit</p>
                                                <div className="mt-2 text-center">
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/rhvddzym.json"
                                                        trigger="loop"
                                                        colors="primary:#0ab39c"
                                                        className="avatar-xl"
                                                    ></lord-icon>
                                                </div>
                                                <div
                                                    className="alert border-0 alert-warning text-center mb-2 mx-2"
                                                    role="alert"
                                                >
                                                    Enter your email and instructions will be sent to you!
                                                </div>
                                                <div className="p-2">
                                                    <form>
                                                        <div className="mb-4">
                                                            <label className="form-label">Email</label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                placeholder="Enter email address"
                                                            />
                                                        </div>
                                                        <div className="text-center mt-4">
                                                            <button className="btn btn-success w-100" type="submit">
                                                                Send Reset Link
                                                            </button>
                                                        </div>
                                                    </form>
                                                    {/* end form */}
                                                </div>
                                                <div className="mt-5 text-center">
                                                    <p className="mb-0">
                                                        Wait, I remember my password...{" "}
                                                        <a
                                                            href="index.php"
                                                            className="fw-semibold text-primary text-decoration-underline"
                                                        >
                                                            {" "}
                                                            Click here{" "}
                                                        </a>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end col */}
                                    </div>
                                    {/* end row */}
                                </div>
                                {/* end card */}
                            </div>
                            {/* end col */}
                        </div>
                        {/* end row */}
                    </div>
                    {/* end container */}
                </div>
                {/* end auth page content */}
                {/* footer */}
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0">
                                        © Jobzshala Recruit Crafted with <i className="mdi mdi-heart text-danger" />{" "}
                                        by AIPL.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* end Footer */}
            </div>

        </React.Fragment>
    )
}

export default ForgetPassword