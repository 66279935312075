import React from 'react'

const Footer = () => {
    return (
        <footer className="footer customFooterDashboard">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 text-center">2024 © Jobzshala. © Jobzshala Recruit Crafted with <i className="mdi mdi-heart text-danger" />{" "}
                            by AIPL.</div>
                    {/* <div className="col-sm-6">
                        <div className="text-sm-end d-none d-sm-block">
                            
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
    )
}

export default Footer