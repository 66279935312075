import React from 'react';

const Pagination = ({ limit, totalCount, range, currentPage, onPageChange }) => {
    const totalPages = Math.ceil(totalCount / limit);

    const handlePageChange = (page) => {
        onPageChange(page);
    };

    const renderPagination = () => {
        const pages = [];

        let startPage = 1;
        if (totalPages > range && currentPage > 1) {
            startPage = Math.min(currentPage, totalPages - range + 1);
        }

        const endPage = Math.min(startPage + range - 1, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i} className={i === currentPage ? 'active' : ''}>
                    <a className="page" href="#" onClick={() => handlePageChange(i)}>
                        {i}
                    </a>
                </li>
            );
        }

        return pages;
    };

    return (
        <div className="d-flex justify-content-end mt-3">
            <span className="page-item total-count ms-3">
                Total&nbsp;{totalCount}
            </span>
        <div className="pagination-wrap hstack gap-2">
            
            <a className={`page-item pagination-prev ${currentPage === 1 ? 'disabled' : ''}`} href="javascript:void(0)" onClick={() => { currentPage === 1 ? console.log('') : handlePageChange(currentPage - 1) }}>
                <i className="ri-arrow-left-s-line"></i>
            </a>
            <ul className="pagination listjs-pagination mb-0">
                {renderPagination()}
            </ul>
            <a className={`page-item pagination-next ${currentPage === totalPages ? 'disabled' : ''}`} href="javascript:void(0)" onClick={() => { currentPage === totalPages ? console.log('') : handlePageChange(currentPage + 1) }}>
                <i className="ri-arrow-right-s-line"></i>
            </a>
        </div>
    </div>
    
    );
};

export default Pagination;
