import React, { useEffect, useState } from 'react'
import { v1_application_status_options } from '../../../../actions/v1/master_data'
import { ToastContainer, toast } from 'react-toastify'
import { validateFunctionalForm } from '../../../../utils'
const UpdateHiringStatusModal = (props) => {
    const [state, setState] = useState({
        STATUS: { name: 'STATUS', value: '', options: [], isRequired: false },
        DOJ: { name: 'DOJ', value: '', isRequired: false },
        REVENUE_GENERATED: { name: 'REVENUE_GENERATED', value: '', isRequired: false },
        INTERVIEW_DATE: { name: 'INTERVIEW_DATE', value: '', isRequired: false },
        INTERVIEW_TIME: { name: 'INTERVIEW_TIME', value: '', isRequired: false },
        OFFER_RELEASE_DATE: { name: 'OFFER_RELEASE_DATE', value: '', isRequired: false },
        EMPLOYEE_ID: { name: 'EMPLOYEE_ID', value: '', isRequired: false },


    })

    useEffect(() => {
        if (state.STATUS.value === '10') {
            setState(prevState => ({
                ...prevState,
                ['DOJ']: {
                    ...prevState['DOJ'],
                    isRequired: true
                },
                ['OFFER_RELEASE_DATE']: {
                    ...prevState['OFFER_RELEASE_DATE'],
                    isRequired: true
                },
                ['REVENUE_GENERATED']: {
                    ...prevState['REVENUE_GENERATED'],
                    isRequired: false
                },

            }));
        }
        else if (state.STATUS.value === '11') {
            setState(prevState => ({
                ...prevState,
                ['DOJ']: {
                    ...prevState['DOJ'],
                    isRequired: true
                }, ['REVENUE_GENERATED']: {
                    ...prevState['REVENUE_GENERATED'],
                    isRequired: true
                },
                ['OFFER_RELEASE_DATE']: {
                    ...prevState['OFFER_RELEASE_DATE'],
                    isRequired: false
                },
            }));
        }
        else if (state.STATUS.value === '7' || state.STATUS.value === '8' || state.STATUS.value === '9') {
            setState(prevState => ({
                ...prevState,
                ['INTERVIEW_DATE']: {
                    ...prevState['INTERVIEW_DATE'],
                    isRequired: true
                }, ['INTERVIEW_TIME']: {
                    ...prevState['INTERVIEW_TIME'],
                    isRequired: true
                },
                ['DOJ']: {
                    ...prevState['DOJ'],
                    isRequired: false
                }, ['REVENUE_GENERATED']: {
                    ...prevState['REVENUE_GENERATED'],
                    isRequired: false
                },
                ['OFFER_RELEASE_DATE']: {
                    ...prevState['OFFER_RELEASE_DATE'],
                    isRequired: false
                },
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['INTERVIEW_DATE']: {
                    ...prevState['INTERVIEW_DATE'],
                    isRequired: false
                },
                ['INTERVIEW_TIME']: {
                    ...prevState['INTERVIEW_TIME'],
                    isRequired: false
                },
                ['DOJ']: {
                    ...prevState['DOJ'],
                    isRequired: false
                },
                ['REVENUE_GENERATED']: {
                    ...prevState['REVENUE_GENERATED'],
                    isRequired: false
                },
                ['OFFER_RELEASE_DATE']: {
                    ...prevState['OFFER_RELEASE_DATE'],
                    isRequired: false
                },
            }));
        }
    }, [state.STATUS.value])

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const onSave = () => {
        debugger
        if (validateFunctionalForm(state, setState)) {
            props.onSave({
                STATUS_ID: state.STATUS.value,
                DOJ: state.DOJ.value,
                INTERVIEW_DATE: state.INTERVIEW_DATE.value,
                INTERVIEW_TIME: state.INTERVIEW_TIME.value,
                OFFER_RELEASE_DATE: state.OFFER_RELEASE_DATE.value,
                REVENUE_GENERATED: state.REVENUE_GENERATED.value,
                EMPLOYEE_ID: state.EMPLOYEE_ID.value,
            })
        }
        else {
            toast.error('Please Choose Hiring Status')

        }
    }
    useEffect(() => {
        v1_application_status_options().then((res) => {
            if (res.status) {
                setOptions('STATUS', res.result)
            }
            else {

            }
        })
    }, [])
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="row ">
                <div className="col-lg-12 mb-3">
                    <div>
                        <label className="form-label">
                            Hiring Status
                        </label>
                        <select
                            className={state.STATUS.error && !state.STATUS.value ? 'form-select is-invalid' : 'form-select'}
                            onChange={onChange}
                            value={state.STATUS.value}
                            name={state.STATUS.name}

                        >
                            <option value={''}>
                                Select Hiring Status
                            </option>
                            {state.STATUS.options && state.STATUS.options.map((item) => {
                                return (<option value={item.STATUS_ID}>
                                    {item.STATUS_NAME}
                                </option>)
                            })}

                        </select>

                    </div>
                </div>
                {(state.STATUS.value === '10' || state.STATUS.value === '11') &&
                    <React.Fragment>
                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    {state.STATUS.value === '10' ? 'Date Of Joining' : 'Date of Joined'}
                                </label>
                                <input
                                    className={state.DOJ.error && !state.DOJ.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.DOJ.value}
                                    name={state.DOJ.name}
                                    type='date'
                                />

                            </div>
                        </div>
                        {state.STATUS.value === '10' && <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Offer Released Date
                                </label>
                                <input
                                    className={state.OFFER_RELEASE_DATE.error && !state.OFFER_RELEASE_DATE.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.OFFER_RELEASE_DATE.value}
                                    name={state.OFFER_RELEASE_DATE.name}
                                    type='date'
                                />
                            </div>
                        </div>}

                        {state.STATUS.value === '11' &&
                            <React.Fragment>


                                <div className="col-lg-6 mb-3">
                                    <div>
                                        <label className="form-label">
                                            Revenue Generated
                                        </label>
                                        <input
                                            className={state.REVENUE_GENERATED.error && !state.REVENUE_GENERATED.value ? 'form-control is-invalid' : 'form-control'}
                                            onChange={onChange}
                                            value={state.REVENUE_GENERATED.value}
                                            name={state.REVENUE_GENERATED.name}
                                            type='number'
                                            placeholder='Revenue Generated'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <div>
                                        <label className="form-label">
                                            Employee Id
                                        </label>
                                        <input
                                            className={state.EMPLOYEE_ID.error && !state.EMPLOYEE_ID.value ? 'form-control is-invalid' : 'form-control'}
                                            onChange={onChange}
                                            value={state.EMPLOYEE_ID.value}
                                            name={state.EMPLOYEE_ID.name}
                                            type='text'
                                            placeholder='Employee Id'
                                        />
                                    </div>
                                </div>
                            </React.Fragment>

                        }
                    </React.Fragment>

                }

                {(state.STATUS.value === '7' || state.STATUS.value === '8' || state.STATUS.value === '9') &&
                    <React.Fragment>

                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Interview Date
                                </label>
                                <input
                                    type='date'
                                    className={state.INTERVIEW_DATE.error && !state.INTERVIEW_DATE.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.INTERVIEW_DATE.value}
                                    name={state.INTERVIEW_DATE.name}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Interview Time
                                </label>
                                <input
                                    className={state.INTERVIEW_TIME.error && !state.INTERVIEW_TIME.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.INTERVIEW_TIME.value}
                                    name={state.INTERVIEW_TIME.name}
                                    type='time'
                                />
                            </div>
                        </div>
                    </React.Fragment>

                }
                <div className="col-sm-auto ms-auto">
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-success " onClick={() => { onSave() }}>
                            Save
                        </button>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default UpdateHiringStatusModal