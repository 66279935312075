import React from 'react'
import constant from '../../../../constant';

const TeamProgressTable = (props) => {
  const { data } = props;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">

          <div className="table-responsive customReportTableStyle">
            <table className="table align-middle mb-0" id="table-to-xls">
              <thead className="table-light">
                <tr>
                  <th scope="col" colSpan={2}></th>
                  <th scope="col" colSpan={8} style={{ textAlign: 'center' }}>Hiring Status</th>
                  {/* <th scope="col" colSpan={11} style={{ textAlign: 'center' }}>Candidate Status</th> */}


                </tr>
                <tr>

                  <th scope="col">#</th>
                  <th scope="col">Recruiter Name</th>
                  <th scope="col">HR Shortlisted</th>
                  <th scope="col">Client Shortlisted</th>
                  <th scope="col">Interview Scheduled</th>
                  <th scope="col">Rejected</th>
                  <th scope="col">Selected</th>
                  <th scope="col">Offered</th>
                  <th scope="col">Joined</th>
                  <th scope="col">Dropped</th>

                  {/* <th scope="col">Not Connected</th>
                  <th scope="col">RNR</th>
                  <th scope="col">Shortlisted</th>
                  <th scope="col">TL Scheduled</th>
                  <th scope="col">Walk in</th>
                  <th scope="col">Not Interested</th>
                  <th scope="col">Not Applicable</th>
                  <th scope="col">Future Prospect</th>
                  <th scope="col">Pending</th>
                  <th scope="col">No Show</th>
                  <th scope="col">Rejected</th> */}
                </tr>
              </thead>
              <tbody>
                {data && data.map((item, index) => {
                  debugger
                  return (
                    <tr>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        <a href={constant.companyUserComponent.teamInsights.url + `?id=${item.user_detail?.USER_ID}`} className="fw-semibold">
                          {item.user_detail?.FULL_NAME}
                        </a>
                      </td>
                      <td>{item.hiring_status.hr_shortlisted}</td>
                      <td >
                        {item.hiring_status.client_shortlisted}
                      </td>
                      <td>
                        {item.hiring_status.interview_scheduled}

                      </td>
                      <td>{item.hiring_status.rejected}</td>
                      <td>{item.hiring_status.selected}</td>
                      <td>{item.hiring_status.offered}</td>
                      <td>{item.hiring_status.joined}</td>
                      <td>{item.hiring_status.dropped}</td>

                      {/* <td>{item.candidate_status.not_connected}</td>
                      <td>{item.candidate_status.rnr}</td>
                      <td>{item.candidate_status.shortlisted}</td>
                      <td>{item.candidate_status.tl_shortlisted}</td>
                      <td>{item.candidate_status.walk_in}</td>
                      <td>{item.candidate_status.not_intersted}</td>
                      <td>{item.candidate_status.not_applicable}</td>
                      <td>{item.candidate_status.future_prospect}</td>
                      <td>{item.candidate_status.pending}</td>
                      <td>{item.candidate_status.no_show}</td>
                      <td>{item.candidate_status.rejected}</td> */}


                    </tr>
                  )
                })}

              </tbody>
            </table>
            {/* end table */}
          </div>

        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">

          <div className="table-responsive customReportTableStyle">
            <table className="table align-middle mb-0" id="table-to-xls">
              <thead className="table-light">
                <tr>
                  <th scope="col" colSpan={2}></th>
                  {/* <th scope="col" colSpan={8} style={{ textAlign: 'center' }}>Hiring Status</th> */}
                  <th scope="col" colSpan={13} style={{ textAlign: 'center' }}>Candidate Status</th>


                </tr>
                <tr>

                  <th scope="col">#</th>
                  <th scope="col">Recruiter Name</th>
                  {/* <th scope="col">HR Shortlisted</th>
                  <th scope="col">Client Shortlisted</th>
                  <th scope="col">Interview Scheduled</th>
                  <th scope="col">Rejected</th>
                  <th scope="col">Selected</th>
                  <th scope="col">Offered</th>
                  <th scope="col">Joined</th>
                  <th scope="col">Dropped</th> */}

                  <th scope="col">Not Connected</th>
                  <th scope="col">RNR</th>
                  <th scope="col">Shortlisted</th>
                  <th scope="col">TL Scheduled</th>
                  <th scope="col">Walk in</th>
                  <th scope="col">Not Interested</th>
                  <th scope="col">Not Applicable</th>
                  <th scope="col">Future Prospect</th>
                  <th scope="col">Pending</th>
                  <th scope="col">No Show</th>
                  <th scope="col">Rejected</th>
                  <th scope="col">Quick Add</th>
                  <th scope="col">Total</th>

                </tr>
              </thead>
              <tbody>
                {data && data.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        <a href="#" className="fw-semibold">
                          {item.user_detail?.FULL_NAME}
                        </a>
                      </td>
                      {/* <td>{item.hiring_status.hr_shortlisted}</td>
                      <td >
                        {item.hiring_status.client_shortlisted}
                      </td>
                      <td>
                        {item.hiring_status.interview_scheduled}

                      </td>
                      <td>{item.hiring_status.rejected}</td>
                      <td>{item.hiring_status.selected}</td>
                      <td>{item.hiring_status.offered}</td>
                      <td>{item.hiring_status.joined}</td>
                      <td>{item.hiring_status.dropped}</td> */}

                      <td>{item.candidate_status.not_connected}</td>
                      <td>{item.candidate_status.rnr}</td>
                      <td>{item.candidate_status.shortlisted}</td>
                      <td>{item.candidate_status.tl_shortlisted}</td>
                      <td>{item.candidate_status.walk_in}</td>
                      <td>{item.candidate_status.not_intersted}</td>
                      <td>{item.candidate_status.not_applicable}</td>
                      <td>{item.candidate_status.future_prospect}</td>
                      <td>{item.candidate_status.pending}</td>
                      <td>{item.candidate_status.no_show}</td>
                      <td>{item.candidate_status.rejected}</td>
                      <td>{item.candidate_status.quick_add_candidates}</td>
                      <td>{item.candidate_status.total_candidates}</td>



                    </tr>
                  )
                })}

              </tbody>
            </table>
            {/* end table */}
          </div>

        </div>
      </div>

    </React.Fragment>
  )
}

export default TeamProgressTable