import React from 'react'
import RequestDemoForm from '../../components/nonAuthed/requestDemoForm'
const RequestDemo = () => {
    return (
        <section id="features" className="section" style={{ marginTop: '40px' }}>
            <div className="container demoCustomContainer">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12" style={{ paddingRight: 60, }}>
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="features mb-sm-0 mb-md-5 mb-lg-5">
                                    <span className="customRoundStyleFeatues ti-layout icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left" style={{ paddingTop: '20px !important', }}>
                                        <i className="ri-smartphone-line"></i>
                                    </span>
                                    <div className="d-block overflow-hidden featuresBodySection">
                                        <h5 className="mb-2">24/7 Support</h5>
                                        <p>We're here when you need us. Our dedicated support team is available
                                            round-the-clock to assist you with any queries or concerns, ensuring uninterrupted service
                                            and peace of mind.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-12">
                                <div className="features mb-sm-0 mb-md-5 mb-lg-5">
                                    <span className="customRoundStyleFeatues ti-eye icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left">
                                        <i className="ri-thumb-up-fill"></i>
                                    </span>
                                    <div className="d-block overflow-hidden featuresBodySection">
                                        <h5 className="mb-2">Comprehensive Training</h5>
                                        <p>Empower your team with the knowledge and skills they need to
                                            make the most of Jobzshala Recruit. Our experts provide thorough training sessions to your
                                            users, ensuring seamless adoption and utilization of our platform.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-12">
                                <div className="features mb-sm-0 mb-md-5 mb-lg-0">
                                    <span className="customRoundStyleFeatues ti-mobile icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left">
                                        <i className="ri-building-4-line"></i>
                                    </span>
                                    <div className="d-block overflow-hidden featuresBodySection">
                                        <h5 className="mb-2">Customization at No Extra Cost:</h5>
                                        <p>Your satisfaction is our priority. We understand that every
                                            agency is unique, which is why we offer customization options tailored to your specific
                                            needs. Whether it's modifying features or adding new functionalities, we&#39;ll make it happen at
                                            no extra cost to you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                        <RequestDemoForm />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RequestDemo