import React, { useEffect, useState } from 'react'
import { v3_expired_jobs, v3_update_job_visibility } from '../../../actions/v3/jobs'
import Posted from '../../../components/companyUser/jobs/jobList/posted'
import constant from '../../../constant';
import { getStorage } from '../../../utils';

const JobList = (props) => {
    const havingTeam = getStorage(constant.keys.companyUserHavingTeam);
    const isAdministrator = getStorage(constant.keys.companyUserHavingAdminRights);
  
    const [jobList, setJobList] = useState(null)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        v3_expired_jobs().then((res) => {
            if (res.status) {
                setJobList(res.result)
            }
        })
    }, [refresh])


    const updateJobVisibility = (model) => {
        v3_update_job_visibility({ JOB_ID: model.JOB_ID, IS_LIVE: true }).then((res) => {
            if (res.status) {
                setRefresh(!refresh)
            }
        })
    }

    return (
        <React.Fragment>
            <Posted
                Title={'Expired Jobs'}
                jobList={jobList}
                LIVE_JOB_BUTTON={
                    (havingTeam || isAdministrator) ? true : false
                }
                updateJobVisibility={updateJobVisibility}
            />
        </React.Fragment>
    )
}

export default JobList