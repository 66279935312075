import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { v1_crm_invoice_generate } from '../../../actions/v1/subscription_management';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import constant from '../../../constant';
const ReviewSelectedPackage = (props) => {
    const { selectedPackage, billGenerationDetails } = props
    const { jobzshala_details, company_details } = billGenerationDetails;
    const selectedPackageDetail = selectedPackage.PACKAGE_DETAIL;
    const div = document.createElement("div");
    div.innerHTML = selectedPackageDetail;
    const text = div.textContent || div.innerText || "";

    // Replace multiple spaces with a single space
    const singleSpace = text.replace(/\s+/g, ' ');

    // Replace spaces with newline characters
    const newText = singleSpace.replace(/\s+/g, '\n');

    // console.log(neSwText);

    const [state, setState] = useState({
        PACKAGE_NAME: { name: 'PACKAGE_NAME', value: selectedPackage?.PACKAGE_NAME ? selectedPackage.PACKAGE_NAME : '', error: '', isRequired: true },
        NO_OF_LOGIN: { name: 'NO_OF_LOGIN', value: selectedPackage.NO_OF_LOGIN ? selectedPackage.NO_OF_LOGIN : '', error: '', isRequired: true },
        VALILDITY: { name: 'VALILDITY', value: '', error: '', isRequired: true },
        VALID_FROM: { name: 'VALID_FROM', value: '', error: '', isRequired: true },
        VALID_TO: { name: 'VALID_TO', value: '', error: '', isRequired: true },
        PAYMENT_STATUS: { name: 'PAYMENT_STATUS', value: '', error: '', isRequired: true },
        TAX: { name: 'TAX', value: '', error: '', isRequired: true },
        PLAN_AMOUNT: { name: 'PLAN_AMOUNT', value: selectedPackage?.PRICE ? selectedPackage.PRICE : '', error: '', isRequired: true },
        DISCOUNT: { name: 'DISCOUNT', value: '0', error: '', isRequired: true },
        INVOICE_DATE: { name: 'INVOICE_DATE', value: moment().format('YYYY-MM-DD'), error: '', isRequired: true },
        INVOICE_NUMBER: { name: 'INVOICE_NUMBER', value: '', error: '', isRequired: true },
        BILLER_NAME: { name: 'BILLER_NAME', value: company_details?.COMPANY_NAME ? company_details.COMPANY_NAME : '', error: '', isRequired: true },
        BILLER_ADDRESS: { name: 'BILLER_ADDRESS', value: company_details?.ADDRESS ? company_details.ADDRESS : '', error: '', isRequired: true },
        BILLER_PHONE: { name: 'BILLER_PHONE', value: company_details?.MOBILE ? company_details.MOBILE : '', error: '', isRequired: true },
        BILLER_GST: { name: 'BILLER_GST', value: company_details?.GST_NUMBER ? company_details.GST_NUMBER : '', error: '', isRequired: true },
        PAYMENT_MODE: { name: 'PAYMENT_MODE', value: company_details?.GST_NUMBER ? company_details.GST_NUMBER : '', error: '', isRequired: true },
        PAYMENT_TXN_ID: { name: 'PAYMENT_TXN_ID', value: company_details?.GST_NUMBER ? company_details.GST_NUMBER : '', error: '', isRequired: true },

    });
    const onSaveInvoice = () => {
        debugger
        const model = {
            PACKAGE_ID: selectedPackage.PACKAGE_ID,
            PACKAGE_NAME: state.PACKAGE_NAME.value,
            PACKAGE_DETAILS: selectedPackage.PACKAGE_DETAIL,
            NO_OF_LOGIN: state.NO_OF_LOGIN.value,
            VALIDITY_MONTHS: state.VALILDITY.value,
            VALID_FROM: state.VALID_FROM.value,
            VALID_TO: state.VALID_TO.value,
            PAYMENT_STATUS: state.PAYMENT_STATUS.value,
            PLAN_AMOUNT: state.PLAN_AMOUNT.value,
            TAX_AMOUNT: state.TAX.value,
            DISCOUNT: state.DISCOUNT.value,
            TOTAL_AMOUNT: (Number(state.PLAN_AMOUNT.value) + Number(state.TAX.value) - Number(state.DISCOUNT.value)) ? Number(state.PLAN_AMOUNT.value) + Number(state.TAX.value) - Number(state.DISCOUNT.value) : 0,
            INVOICE_DATE: state.INVOICE_DATE.value,
            INVOICE_NUMBER: state.INVOICE_NUMBER.value,
            BILLER_NAME: state.BILLER_NAME.value,
            BILLER_ADDRESS: state.BILLER_ADDRESS.value,
            BILLER_PHONE: state.BILLER_PHONE.value,
            BILLER_GST: state.BILLER_GST.value,
            PAYMENT_MODE: state.PAYMENT_MODE.value,
            PAYMENT_TXN_ID: state.PAYMENT_TXN_ID.value,


            JOBZSHALA_LEGAL_REGISTRATION_NUMBER: jobzshala_details.LEGAL_REGISTRATION_NUMBER,
            JOBZSHALA_ADDRESS: jobzshala_details.ADDRESS,
            JOBZSHALA_POSTAL_CODE: jobzshala_details.POSTAL_CODE,
            JOBZSHALA_EMAIL_ADDRESS: jobzshala_details.EMAIL_ADDRESS,
            JOBZSHALA_WEBSITE: jobzshala_details.WEBSITE,
            JOBZSHALA_CONTACT_NUMBER: jobzshala_details.CONTACT_NUMBER,
            JOBZSHALA_LEGAL_NAME: jobzshala_details.LEGAL_NAME,
            COMPANY_ID: company_details.USER_ID
        }
        v1_crm_invoice_generate(model).then((res) => {
            if (res.status) {
                toast.success('Invoice Saved');
                props.history.push(constant.superAdminComponent.clientSubscriptionInvoice.url.replace(":company_id", company_details.USER_ID).replace(":subscription_id", res.result))
            }
            else {
                toast.error(res.error);

            }
        })
    }

    // useEffect(() => {
    //     if (props.selectedPackage) {
    //         setState(prevState => ({
    //             ...prevState,
    //             PACKAGE_NAME: { ...prevState.PACKAGE_NAME, value: props.selectedPackage.PACKAGE_NAME },
    //             NO_OF_LOGIN: { ...prevState.NO_OF_LOGIN, value: props.selectedPackage.NO_OF_LOGIN },
    //             VALIDITY: { ...prevState.VALIDITY, value: props.selectedPackage.VALIDITY_PERIOD_MONTH },
    //             PLAN_AMOUNT: { ...prevState.PLAN_AMOUNT, value: props.selectedPackage.PRICE },
    //         }));
    //     }
    // }, [props.selectedPackage]);

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    };


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Review Choosen Package</h4>
                        </div>
                        <div className="card-body">
                            <div className="row justify-content-center">
                                <div className="col-xxl-9">
                                    <div className="card">
                                        <form className="needs-validation" noValidate="" id="invoice_form">
                                            <div className="card-body border-bottom border-bottom-dashed p-4">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="profile-user mx-auto  mb-3">
                                                            <input
                                                                id="profile-img-file-input"
                                                                type="file"
                                                                className="profile-img-file-input"
                                                            />
                                                            <label
                                                                htmlFor="profile-img-file-input"
                                                                className="d-block"
                                                                tabIndex={0}
                                                            >
                                                                <span
                                                                    className="overflow-hidden d-flex align-items-center justify-content-center rounded"
                                                                    style={{ height: 60, width: 256 }}
                                                                >
                                                                    <img
                                                                        style={{ height: '100%', width: 'auto' }}
                                                                        src="/images/logo/recruit-logo.png"
                                                                        className="card-logo card-logo-dark user-profile-image img-fluid"
                                                                        alt="logo dark"
                                                                    />

                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <label htmlFor="companyAddress">Address</label>
                                                            </div>
                                                            <div className="mb-2">
                                                                <textarea
                                                                    className="form-control bg-light border-0"
                                                                    id="companyAddress"
                                                                    rows={5}
                                                                    disabled
                                                                    placeholder="Company Address"
                                                                    required=""
                                                                    value={jobzshala_details.ADDRESS}
                                                                />

                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control bg-light border-0"
                                                                    id="companyaddpostalcode"
                                                                    minLength={5}
                                                                    disabled
                                                                    maxLength={6}
                                                                    placeholder="Enter Postal Code"
                                                                    value={jobzshala_details.POSTAL_CODE}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 ms-auto">
                                                        <div className="mb-2">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-light border-0"
                                                                id="registrationNumber"
                                                                maxLength={12}
                                                                disabled
                                                                placeholder="Legal Registration No"
                                                                value={jobzshala_details.LEGAL_REGISTRATION_NUMBER}
                                                            />

                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                type="email"
                                                                className="form-control bg-light border-0"
                                                                id="companyEmail"
                                                                disabled
                                                                placeholder="Email Address"
                                                                value={jobzshala_details.EMAIL_ADDRESS}
                                                            />

                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-light border-0"
                                                                id="companyWebsite"
                                                                disabled
                                                                placeholder="Website"
                                                                value={jobzshala_details.WEBSITE}

                                                            />

                                                        </div>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="form-control bg-light border-0"
                                                                data-plugin="cleave-phone"
                                                                id="compnayContactno"
                                                                disabled
                                                                placeholder="Contact No"
                                                                value={jobzshala_details.CONTACT_NUMBER}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {<div className="card-body p-4">
                                                <div className="row g-3">
                                                    <div className="col-lg-3 col-sm-6">
                                                        <label htmlFor="invoicenoInput">Invoice No</label>
                                                        <input
                                                            type="text"
                                                            className="form-control bg-light border-0"
                                                            placeholder="Invoice No"
                                                            name={state.INVOICE_NUMBER.name}
                                                            value={state.INVOICE_NUMBER.value}
                                                            onChange={onChange}

                                                        />
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6">
                                                        <div>
                                                            <label htmlFor="date-field">Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control bg-light border-0"
                                                                value={state.INVOICE_DATE.value}
                                                                name={state.INVOICE_DATE.name}
                                                                onChange={onChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6">
                                                        <label htmlFor="choices-payment-status">
                                                            Payment Status
                                                        </label>
                                                        <div className="input-light">
                                                            <select
                                                                className="form-control bg-light border-0"
                                                                name={state.PAYMENT_STATUS.name}
                                                                value={state.PAYMENT_STATUS.value}
                                                                onChange={onChange}
                                                            >
                                                                <option value="">Select Payment Status</option>
                                                                <option value="S">Paid</option>
                                                                <option value="P">Unpaid</option>
                                                                {/* <option value="Refund">Refund</option> */}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6">
                                                        <div>
                                                            <label htmlFor="totalamountInput">Total Amount</label>
                                                            <div className="app-search d-none d-md-block">
                                                                <div className="position-relative">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="0.00"
                                                                        value={Number(state.PLAN_AMOUNT.value) + Number(state.TAX.value) - Number(state.DISCOUNT.value)}
                                                                    // readOnly=""
                                                                    />
                                                                    <span className="la la-rupee-sign search-widget-icon" />
                                                                    <span
                                                                        className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                        id="search-close-options"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="app-search d-none d-md-block">
                                                            <label htmlFor="totalamountInput">Total Amount</label>

                                                            <div className="position-relative">

                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="0.00"
                                                                    value={Number(state.PLAN_AMOUNT.value) + Number(state.TAX.value) - Number(state.DISCOUNT.value)}
                                                                // readOnly=""
                                                                />
                                                                <span className="la la-rupee-sign search-widget-icon" />
                                                                <span
                                                                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                    id="search-close-options"
                                                                />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className="card-body p-4 border-top border-top-dashed">
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div>
                                                            <label
                                                                htmlFor="billingName"
                                                                className="text-muted text-uppercase fw-semibold"
                                                            >
                                                                Billing Address
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-light border-0"
                                                                placeholder="Full Name"
                                                                name={state.BILLER_NAME.name}
                                                                value={state.BILLER_NAME.value}
                                                                onChange={onChange}

                                                            />
                                                            <div className="invalid-feedback">
                                                                Please enter a full name
                                                            </div>
                                                        </div>
                                                        <div className="mb-2">
                                                            <textarea
                                                                className="form-control bg-light border-0"
                                                                id="billingAddress"
                                                                rows={3}
                                                                placeholder="Address"
                                                                name={state.BILLER_ADDRESS.name}
                                                                value={state.BILLER_ADDRESS.value}
                                                                onChange={onChange}
                                                            />
                                                            <div className="invalid-feedback">
                                                                Please enter a address
                                                            </div>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-light border-0"

                                                                placeholder="(123)456-7890"
                                                                name={state.BILLER_PHONE.name}
                                                                value={state.BILLER_PHONE.value}
                                                                onChange={onChange}
                                                            />
                                                            <div className="invalid-feedback">
                                                                Please enter a phone number
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control bg-light border-0"

                                                                placeholder="GST Number"
                                                                name={state.BILLER_GST.name}
                                                                value={state.BILLER_GST.value}
                                                                onChange={onChange}
                                                            />
                                                            <div className="invalid-feedback">
                                                                Please enter a gst number
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-4">
                                                <div className="table-responsive">
                                                    <table className="invoice-table table table-borderless table-nowrap mb-0">
                                                        <thead className="align-middle">
                                                            <tr className="table-active">
                                                                <th scope="col" style={{ width: 50 }}>
                                                                    #
                                                                </th>
                                                                <th scope="col" style={{ width: 240 }}>Product Details</th>
                                                                <th scope="col" style={{ width: 120 }}>
                                                                    <div className="d-flex currency-select input-light align-items-center">
                                                                        Rate

                                                                    </div>
                                                                </th>
                                                                <th scope="col" style={{ width: 120 }}>
                                                                    No of Users
                                                                </th>
                                                                <th scope="col" style={{ width: 200 }}>
                                                                    Validity
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    // className="text-end"
                                                                    style={{ width: 150 }}
                                                                >
                                                                    Amount
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    // className="text-end"
                                                                    style={{ width: 120 }}
                                                                />
                                                            </tr>
                                                        </thead>
                                                        <tbody id="newlink">
                                                            <tr id={1} className="product">
                                                                <th scope="row" className="product-id">
                                                                    1
                                                                </th>
                                                                <td className="text-start">
                                                                    <div className="mb-2">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control bg-light border-0"
                                                                            placeholder="Product Name"
                                                                            name={state.PACKAGE_NAME.name}
                                                                            value={state.PACKAGE_NAME.value}

                                                                        />
                                                                    </div>
                                                                    <textarea
                                                                        className="form-control bg-light border-0"
                                                                        rows={2}
                                                                        placeholder="Product Details"
                                                                        value={newText.trim()}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control product-price bg-light border-0"
                                                                        step="0.01"
                                                                        placeholder={0.0}
                                                                        value={state.PLAN_AMOUNT.value}
                                                                        name={state.PLAN_AMOUNT.name}
                                                                        onChange={onChange}
                                                                    />
                                                                    <div className="invalid-feedback">
                                                                        Please enter a rate
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="input-step">
                                                                        {/* <button type="button" className="minus">
                                                                            –
                                                                        </button> */}
                                                                        <input
                                                                            type="number"
                                                                            className="product-quantity"
                                                                            name={state.NO_OF_LOGIN.name}
                                                                            value={state.NO_OF_LOGIN.value}
                                                                            onChange={onChange}
                                                                        />
                                                                        {/* <button type="button" className="plus">
                                                                            +
                                                                        </button> */}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {selectedPackage?.VALIDITY_PERIOD_MONTH ? <div className="input-light">

                                                                        <select
                                                                            className="form-control bg-light border-0"
                                                                            name={state.VALILDITY.name}
                                                                            value={state.VALILDITY.value}
                                                                            onChange={onChange}
                                                                        >
                                                                            {selectedPackage?.VALIDITY_PERIOD_MONTH && <option value={selectedPackage?.VALIDITY_PERIOD_MONTH}>{selectedPackage?.VALIDITY_PERIOD_MONTH} Month's from date of payment</option>}
                                                                            <option value={''}>Choose Range</option>
                                                                        </select>

                                                                    </div> : ''}
                                                                    {!state.VALILDITY.value && <div className="row input-light">

                                                                        <div className="col-lg-6 col-sm-6">
                                                                            <div>
                                                                                <label >From</label>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control bg-light border-0"
                                                                                    value={state.VALID_FROM.value}
                                                                                    name={state.VALID_FROM.name}
                                                                                    onChange={onChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-sm-6">
                                                                            <div>
                                                                                <label >To</label>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control bg-light border-0"
                                                                                    value={state.VALID_TO.value}
                                                                                    name={state.VALID_TO.name}
                                                                                    onChange={onChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                </td>
                                                                <td className="text-end">
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control bg-light border-0 product-line-price"
                                                                            placeholder="₹0.00"
                                                                            value={'₹' + (Number(state.PLAN_AMOUNT.value) ? Number(state.PLAN_AMOUNT.value) : '0.0')}

                                                                        />
                                                                    </div>
                                                                </td>
                                                                {/* <td className="product-removal">
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="btn btn-success"
                                                                    >
                                                                        Delete
                                                                    </a>
                                                                </td> */}
                                                            </tr>

                                                        </tbody>
                                                        <tbody>
                                                            <tr className="border-top border-top-dashed mt-2">
                                                                <td colSpan={3} />
                                                                <td colSpan={2} className="p-0">
                                                                    <table className="table table-borderless table-sm table-nowrap align-middle mb-0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row">Sub Total</th>
                                                                                <td style={{ width: 150 }} className="app-search d-none d-md-block">

                                                                                    <div className="position-relative">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="0.00"
                                                                                            value={state.PLAN_AMOUNT.value}

                                                                                        />
                                                                                        <span className="la la-rupee-sign search-widget-icon" />
                                                                                        <span
                                                                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                                            id="search-close-options"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">Estimated Tax (12.5%)</th>
                                                                                <td className="app-search d-none d-md-block">

                                                                                    <div className="position-relative">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            placeholder="0.00"
                                                                                            // readOnly=""
                                                                                            name={state.TAX.name}
                                                                                            value={state.TAX.value}
                                                                                            onChange={onChange}

                                                                                        />
                                                                                        <span className="la la-rupee-sign search-widget-icon" />
                                                                                        <span
                                                                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                                            id="search-close-options"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">
                                                                                    Discount{" "}
                                                                                    {/* <small className="text-muted">
                                                                                        (VELZON15)
                                                                                    </small> */}
                                                                                </th>
                                                                                <td className="app-search d-none d-md-block">

                                                                                    <div className="position-relative">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            placeholder="0.00"
                                                                                            name={state.DISCOUNT.name}
                                                                                            value={state.DISCOUNT.value}
                                                                                            onChange={onChange}
                                                                                        // readOnly=""
                                                                                        />
                                                                                        <span className="la la-rupee-sign search-widget-icon" />
                                                                                        <span
                                                                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                                            id="search-close-options"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-top border-top-dashed">
                                                                                <th scope="row">Total Amount</th>
                                                                                <td className="app-search d-none d-md-block">

                                                                                    <div className="position-relative">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="0.00"
                                                                                            value={Number(state.PLAN_AMOUNT.value) + Number(state.TAX.value) - Number(state.DISCOUNT.value)}
                                                                                        // readOnly=""
                                                                                        />
                                                                                        <span className="la la-rupee-sign search-widget-icon" />
                                                                                        <span
                                                                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                                            id="search-close-options"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {state.PAYMENT_STATUS.value === 'S' && <div className="row mt-3">
                                                    <div className="col-lg-4">
                                                        <div className="mb-2">
                                                            <label
                                                                htmlFor="choices-payment-type"
                                                                className="form-label text-muted text-uppercase fw-semibold"
                                                            >
                                                                Payment Details
                                                            </label>
                                                            <div className="input-light">
                                                                <select
                                                                    className="form-control bg-light border-0"
                                                                    id="choices-payment-type"
                                                                    name={state.PAYMENT_MODE.name}
                                                                    value={state.PAYMENT_MODE.value}
                                                                    onChange={onChange}

                                                                >
                                                                    <option value="">Payment Method</option>
                                                                    <option value="Debit Card">Debit Card</option>
                                                                    <option value="Credit Card">Credit Card</option>
                                                                    <option value="UPI">UPI</option>
                                                                    <option value="NEFT">NEFT</option>
                                                                    <option value="Cheque">Cheque</option>



                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                className="form-control bg-light border-0"
                                                                type="text"
                                                                placeholder="Transaction Id"
                                                                name={state.PAYMENT_TXN_ID.name}
                                                                value={state.PAYMENT_TXN_ID.value}
                                                                onChange={onChange}
                                                            />
                                                        </div>
                                                        {/* <div className="mb-2">
                                                            <input
                                                                className="form-control bg-light border-0"
                                                                type="text"
                                                                id="cardholderName"
                                                                placeholder="Card Holder Name"
                                                            />
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                className="form-control bg-light border-0"
                                                                type="text"
                                                                id="cardNumber"
                                                                placeholder="xxxx xxxx xxxx xxxx"
                                                            />
                                                        </div>
                                                        <div>
                                                            <input
                                                                className="form-control  bg-light border-0"
                                                                type="text"
                                                                id="amountTotalPay"
                                                                placeholder="$0.00"
                                                                readOnly=""
                                                            />
                                                        </div> */}
                                                    </div>
                                                </div>}
                                                {/* <div className="mt-4">
                                                    <label
                                                        htmlFor="exampleFormControlTextarea1"
                                                        className="form-label text-muted text-uppercase fw-semibold"
                                                    >
                                                        NOTES
                                                    </label>
                                                    <textarea
                                                        className="form-control alert alert-info"
                                                        id="exampleFormControlTextarea1"
                                                        placeholder="Notes"
                                                        rows={2}
                                                        required=""
                                                        defaultValue={
                                                            "All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or credit card or direct payment online. If account is not paid within 7 days the credits details supplied as confirmation of work undertaken will be charged the agreed quoted fee noted above."
                                                        }
                                                    />
                                                </div> */}
                                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                                    <button type="button" className="btn btn-success"
                                                        onClick={() => {
                                                            onSaveInvoice()
                                                        }}
                                                    >
                                                        <i className="ri-printer-line align-bottom me-1" /> Save
                                                    </button>
                                                    {/* <a href="javascript:void(0);" className="btn btn-primary">
                                                        <i className="ri-download-2-line align-bottom me-1" />{" "}
                                                        Download Invoice
                                                    </a> */}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ReviewSelectedPackage);
