import React from 'react'
import Add from '../../../../components/companyUser/administrator/CRMUser/addCRMUser'
import { ToastContainer, toast } from 'react-toastify'
import constant from '../../../../constant'
import { v3_add_user } from '../../../../actions/v3/administrator'

const AddUser = (props) => {
    const onSubmit = (model) => {
        debugger
        v3_add_user(model).then((res => {
            if (res.status) {
                toast.success('User Added Successfully')
                props.history.push(constant.companyUserComponent.editUser.url.replace(':id', res.result))
                
                // if (model.HAVING_TEAM_MEMBERS == '1') {
                // }
                // else {
                //     props.history.push(constant.companyUserComponent.userworkDetail.url.replace(':id', res.result))

                // }

            }
            else {
                toast.error(res.error);
            }
        }))
    }
    return (
        <React.Fragment>
            <Add
                onSubmit={onSubmit}
            />
            <ToastContainer />

        </React.Fragment>
    )
}

export default AddUser