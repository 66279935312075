import React, { useEffect, useState } from 'react'
import { v1_course_specialization, v1_education_qualification, v1_qualification } from '../../../actions/v1/master_data'
import { validateFunctionalForm } from '../../../utils'
import { v3_candidate_preferred_role_options } from '../../../actions/v3/candidates'

const Add = (props) => {
    debugger
    const [state, setState] = useState({
        FULL_NAME: { name: 'FULL_NAME', value: props.candidateDetails ? props.candidateDetails.FULL_NAME : '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: props.candidateDetails ? props.candidateDetails.EMAIL : '', error: '', isRequired: true },
        PHONE: { name: 'PHONE', value: props.candidateDetails ? props.candidateDetails.PHONE : '', error: '', isRequired: true },
        WORKING_LOCATION: { name: 'WORKING_LOCATION', value: props.candidateDetails ? props.candidateDetails.WORKING_LOCATION : '', error: '', isRequired: true },
        RESIDENTIAL_LOCATION: { name: 'RESIDENTIAL_LOCATION', value: props.candidateDetails ? props.candidateDetails.RESIDENTIAL_LOCATION : '', error: '', isRequired: false },
        CURRENT_COMPANY: { name: 'CURRENT_COMPANY', value: props.candidateDetails ? props.candidateDetails.CURRENT_COMPANY : '', error: '', isRequired: true },
        CURRENT_CTC: { name: 'CURRENT_CTC', value: props.candidateDetails ? props.candidateDetails.CURRENT_CTC : '', error: '', isRequired: true },
        EXPECTED_CTC: { name: 'EXPECTED_CTC', value: props.candidateDetails ? props.candidateDetails.EXPECTED_CTC : '', error: '', isRequired: true },
        SKILLS: { name: 'SKILLS', value: props.candidateDetails ? props.candidateDetails.SKILLS : '', error: '', isRequired: true },
        TOTAL_EXP_YEAR: { name: 'TOTAL_EXP_YEAR', value: props.candidateDetails ? props.candidateDetails.TOTAL_EXP_YEARS : '', error: '', isRequired: true },
        TOTAL_EXP_MONTH: { name: 'TOTAL_EXP_MONTH', value: props.candidateDetails ? props.candidateDetails.TOTAL_EXP_MONTHS : '', error: '', isRequired: true },
        HIGHEST_QUALIFICATION: { name: 'HIGHEST_QUALIFICATION', value: props.candidateDetails ? props.candidateDetails.HIGHEST_QUALIFICATION_ID : '', options: [], error: '', isRequired: true },
        COLLEGE_NAME: { name: 'COLLEGE_NAME', value: props.candidateDetails ? props.candidateDetails.COLLEGE_NAME : '', options: [], error: '', isRequired: false },
        PASS_OUT_YEAR: { name: 'PASS_OUT_YEAR', value: props.candidateDetails ? props.candidateDetails.PASS_OUT_YEAR : '', options: [], error: '', isRequired: false },
        COURSE: { name: 'COURSE', value: props.candidateDetails ? props.candidateDetails.HIGHEST_EDUCATION_QUALIFICATION_ID : '', options: [], error: '', isRequired: true },
        SPECIALIZATION: { name: 'SPECIALIZATION', value: props.candidateDetails ? props.candidateDetails.COURSE_SPECIALIZATION_ID : '', options: [], error: '', isRequired: false },
        NOTICE_PERIOD: { name: 'NOTICE_PERIOD', value: props.candidateDetails ? props.candidateDetails.NOTICE_PERIOD : '', error: '', isRequired: false },
        CURRENT_DESIGNATION: { name: 'CURRENT_DESIGNATION', value: props.candidateDetails ? props.candidateDetails.CURRENT_DESIGNATION : '', error: '', isRequired: false },
        ROLE_ID: { name: 'ROLE_ID', value: props.candidateDetails ? props.candidateDetails.PREFERRED_ROLE_ID : '', options: [], error: '', isRequired: true },

    })
    const [openQuickAdd, setOpenQuickAdd] = useState(false)
    const ratingChanged = (newRating) => {
        setState(prevState => ({
            ...prevState,
            ['RATING']: {
                ...prevState['RATING'],
                value: newRating
            }
        }));
    };
    useEffect(() => {
        v1_qualification().then(res => {
            if (res.status) {
                setOptions('HIGHEST_QUALIFICATION', res.result);
            }
        })


        v3_candidate_preferred_role_options({
            headers: { Authorization: "Bearer " + props.token },
            "Content-Type": "multipart/form-data",
        }).then((res) => {
            if (res.status) {
                setOptions('ROLE_ID', res.result)

            }
            else {

            }
        })

    }, [])



    useEffect(() => {
        if (state.HIGHEST_QUALIFICATION.value) {
            v1_education_qualification(state.HIGHEST_QUALIFICATION.value).then(res => {
                if (res.status) {
                    setOptions('COURSE', res.result);
                }
            })
        }
    }, [state.HIGHEST_QUALIFICATION.value])

    useEffect(() => {
        if (state.COURSE.value) {
            v1_course_specialization(state.COURSE.value).then(res => {
                if (res.status) {
                    setOptions('SPECIALIZATION', res.result);
                }
            })
        }
    }, [state.COURSE.value])

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    const onChange = (e) => {
        const { name, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
        if (name === state.TOTAL_EXP_YEAR.name) {
            if (value !== 'Fresher') {
                setState(prevState => ({
                    ...prevState,
                    ['PASS_OUT_YEAR']: {
                        ...prevState['PASS_OUT_YEAR'],
                        value: '',
                        isRequired: false

                    },
                    ['COLLEGE_NAME']: {
                        ...prevState['COLLEGE_NAME'],
                        value: '',
                        isRequired: false

                    },
                    ['TOTAL_EXP_MONTH']: {
                        ...prevState['TOTAL_EXP_MONTH'],
                        isRequired: true
                    }

                }));
            }
            else {
                setState(prevState => ({
                    ...prevState,
                    ['PASS_OUT_YEAR']: {
                        ...prevState['PASS_OUT_YEAR'],
                        isRequired: true
                    },
                    ['COLLEGE_NAME']: {
                        ...prevState['COLLEGE_NAME'],
                        isRequired: true
                    },
                    ['TOTAL_EXP_MONTH']: {
                        ...prevState['TOTAL_EXP_MONTH'],
                        isRequired: false
                    }
                }));
            }

        }
    }

    const onSubmit = () => {
        debugger
        if (validateFunctionalForm(state, setState)) {
            const model = {
                FULL_NAME: state.FULL_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                WORKING_LOCATION: state.WORKING_LOCATION.value,
                RESIDENTIAL_LOCATION: state.RESIDENTIAL_LOCATION.value,
                CURRENT_COMPANY: state.CURRENT_COMPANY.value,
                CURRENT_CTC: state.CURRENT_CTC.value,
                EXPECTED_CTC: state.EXPECTED_CTC.value,
                SKILLS: state.SKILLS.value,
                TOTAL_EXP_YEAR: state.TOTAL_EXP_YEAR.value,
                TOTAL_EXP_MONTH: state.TOTAL_EXP_MONTH.value,
                HIGHEST_QUALIFICATION: state.HIGHEST_QUALIFICATION.value,
                COURSE: state.COURSE.value,
                SPECIALIZATION: state.SPECIALIZATION.value,
                NOTICE_PERIOD: state.NOTICE_PERIOD.value,
                COLLEGE_NAME: state.COLLEGE_NAME.value,
                PASS_OUT_YEAR: state.PASS_OUT_YEAR.value,
                CURRENT_DESIGNATION: state.CURRENT_DESIGNATION.value,
                ROLE_ID: state.ROLE_ID.value,
                CANDIDATE_ID: props.candidateDetails.CANDIDATE_ID
            }
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }

            props.onSubmit(formData)

        }

    }



    return (
        <React.Fragment>
            <div className="">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between" style={{ paddingLeft: 34, }}>
                                    <h4 className="mb-sm-0">Add Candidate</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Candidate Management</a>
                                            </li>
                                            <li className="breadcrumb-item active">Add Candidate</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="live-preview" >
                                            <div className="row gy-4">
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Full Name
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.FULL_NAME.error.length > 0 && !state.FULL_NAME.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            placeholder="Full Name"
                                                            onChange={onChange}

                                                            name={state.FULL_NAME.name}
                                                            value={state.FULL_NAME.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter a full name.</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Email
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.EMAIL.error.length > 0 && !state.EMAIL.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            placeholder="Email"
                                                            onChange={onChange}
                                                            name={state.EMAIL.name}
                                                            value={state.EMAIL.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter email address.</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Phone
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="number"
                                                            className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            placeholder="Phone"
                                                            onChange={onChange}
                                                            name={state.PHONE.name}
                                                            value={state.PHONE.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter phone number.</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Current Designation
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.CURRENT_DESIGNATION.error.length > 0 && !state.CURRENT_DESIGNATION.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            placeholder="Current Designation"
                                                            onChange={onChange}
                                                            name={state.CURRENT_DESIGNATION.name}
                                                            value={state.CURRENT_DESIGNATION.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter Work Location.</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Work Location (City)
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.WORKING_LOCATION.error.length > 0 && !state.WORKING_LOCATION.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            placeholder="Work Location"
                                                            onChange={onChange}
                                                            name={state.WORKING_LOCATION.name}
                                                            value={state.WORKING_LOCATION.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter Work Location.</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Residential Location (City)
                                                            {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.RESIDENTIAL_LOCATION.error.length > 0 && !state.RESIDENTIAL_LOCATION.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            onChange={onChange}
                                                            placeholder="Residential Location"
                                                            name={state.RESIDENTIAL_LOCATION.name}
                                                            value={state.RESIDENTIAL_LOCATION.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter residential location.</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Current Company
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.CURRENT_COMPANY.error.length > 0 && !state.CURRENT_COMPANY.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            onChange={onChange}
                                                            placeholder="Current Company"
                                                            name={state.CURRENT_COMPANY.name}
                                                            value={state.CURRENT_COMPANY.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter current company</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Current CTC
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.CURRENT_CTC.error.length > 0 && !state.CURRENT_CTC.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            onChange={onChange}
                                                            placeholder="Current CTC"
                                                            name={state.CURRENT_CTC.name}
                                                            value={state.CURRENT_CTC.value}

                                                        />
                                                        <div className="invalid-feedback">Please enter current ctc</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Expected CTC
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.EXPECTED_CTC.error.length > 0 && !state.EXPECTED_CTC.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            onChange={onChange}
                                                            placeholder="Expected CTC"
                                                            name={state.EXPECTED_CTC.name}
                                                            value={state.EXPECTED_CTC.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter expected ctc</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Skills
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.SKILLS.error.length > 0 && !state.SKILLS.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            onChange={onChange}
                                                            placeholder="Skills"
                                                            name={state.SKILLS.name}
                                                            value={state.SKILLS.value}

                                                        />
                                                        <div className="invalid-feedback">Please enter skills</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="row">
                                                        <div className={state.TOTAL_EXP_YEAR.value != 'Fresher' ? "col-lg-6" : "col-lg-12"}>

                                                            <label className="form-label">
                                                                Total Experience
                                                                <span className='text-danger'>&nbsp;&nbsp;*</span>
                                                            </label>
                                                            <select
                                                                type="text"
                                                                className={state.TOTAL_EXP_YEAR.error.length > 0 && !state.TOTAL_EXP_YEAR.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                                onChange={onChange}
                                                                name={state.TOTAL_EXP_YEAR.name}
                                                                value={state.TOTAL_EXP_YEAR.value}
                                                            >

                                                                <option value=''> Select Experience Year</option>
                                                                <option value='Fresher'> Fresher</option>
                                                                <option value='0 Year'> 0 Year</option>
                                                                <option value='1 Year'> 1 Year</option>
                                                                <option value='2 Year'> 2 Year</option>
                                                                <option value='3 Year'> 3 Year</option>
                                                                <option value='4 Year'> 4 Year</option>
                                                                <option value='5 Year'> 5 Year</option>
                                                                <option value='6 Year'> 6 Year</option>
                                                                <option value='7 Year'> 7 Year</option>
                                                                <option value='8 Year'> 8 Year</option>
                                                                <option value='9 Year'> 9 Year</option>
                                                                <option value='10 Year'> 10 Year</option>
                                                                <option value='11 Year'> 11 Year</option>
                                                                <option value='12 Year'> 12 Year</option>
                                                                <option value='13 Year'> 13 Year</option>
                                                                <option value='14 Year'> 14 Year</option>
                                                                <option value='15 Year'> 15 Year</option>
                                                                <option value='16 Year'> 16 Year</option>
                                                                <option value='17 Year'> 17 Year</option>
                                                                <option value='18 Year'> 18 Year</option>
                                                                <option value='19 Year'> 19 Year</option>
                                                                <option value='20 Year'> 20 Year</option>
                                                                <option value='21 Year'> 21 Year</option>
                                                                <option value='22 Year'> 22 Year</option>
                                                                <option value='23 Year'> 23 Year</option>
                                                                <option value='24 Year'> 24 Year</option>
                                                                <option value='25 Year'> 25 Year</option>
                                                                <option value='26 Year'> 26 Year</option>
                                                                <option value='27 Year'> 27 Year</option>
                                                                <option value='28 Year'> 28 Year</option>
                                                                <option value='29 Year'> 29 Year</option>
                                                                <option value='30 Year'> 30 Year</option>
                                                                <option value='31 Year'> 31 Year</option>
                                                                <option value='32 Year'> 32 Year</option>
                                                            </select>
                                                            <div className="invalid-feedback">Please select work experience</div>
                                                        </div>
                                                        {state.TOTAL_EXP_YEAR.value != 'Fresher' && <div className="col-lg-6">

                                                            <label className="form-label">
                                                                <span className='text-danger'>&nbsp;&nbsp;*</span>
                                                            </label>
                                                            <select
                                                                type="text"
                                                                className={state.TOTAL_EXP_MONTH.error.length > 0 && !state.TOTAL_EXP_MONTH.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                                onChange={onChange}
                                                                name={state.TOTAL_EXP_MONTH.name}
                                                                value={state.TOTAL_EXP_MONTH.value}
                                                            >

                                                                <option value=''> Select Experience Month</option>
                                                                <option value='0 Month'> 0 Month</option>
                                                                <option value='1 Month'> 1 Month</option>
                                                                <option value='2 Month'> 2 Month</option>
                                                                <option value='3 Month'> 3 Month</option>
                                                                <option value='4 Month'> 4 Month</option>
                                                                <option value='5 Month'> 5 Month</option>
                                                                <option value='6 Month'> 6 Month</option>
                                                                <option value='7 Month'> 7 Month</option>
                                                                <option value='8 Month'> 8 Month</option>
                                                                <option value='9 Month'> 9 Month</option>
                                                                <option value='10 Month'> 10 Month</option>
                                                                <option value='11 Month'> 11 Month</option>
                                                            </select>
                                                            <div className="invalid-feedback">Please select work experience</div>
                                                        </div>}
                                                    </div>

                                                </div>
                                                {state.TOTAL_EXP_YEAR.value === 'Fresher' &&
                                                    <React.Fragment>

                                                        <div className="col-lg-4 mb-3">
                                                            <div>
                                                                <label htmlFor="companyname-field" className="form-label">
                                                                    College Name
                                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className={state.COLLEGE_NAME.error.length > 0 && !state.COLLEGE_NAME.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                                    onChange={onChange}
                                                                    placeholder="College Name"
                                                                    name={state.COLLEGE_NAME.name}
                                                                    value={state.COLLEGE_NAME.value}
                                                                />
                                                                <div className="invalid-feedback">Please enter college name</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 mb-3">
                                                            <div>
                                                                <label htmlFor="companyname-field" className="form-label">
                                                                    Passing Year
                                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                                </label>
                                                                <input
                                                                    type="month"
                                                                    className={state.PASS_OUT_YEAR.error.length > 0 && !state.PASS_OUT_YEAR.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                                    onChange={onChange}
                                                                    placeholder="Passing Year"
                                                                    name={state.PASS_OUT_YEAR.name}
                                                                    value={state.PASS_OUT_YEAR.value}
                                                                />

                                                                <div className="invalid-feedback">Please enter work experience</div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>

                                                }
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Highest Qualification
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <select
                                                            className={state.HIGHEST_QUALIFICATION.error.length > 0 && !state.HIGHEST_QUALIFICATION.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                            onChange={onChange}
                                                            placeholder="Highest Qualification"
                                                            name={state.HIGHEST_QUALIFICATION.name}
                                                            value={state.HIGHEST_QUALIFICATION.value}
                                                        >
                                                            <option value=''> Select Highest Qualification</option>
                                                            {state.HIGHEST_QUALIFICATION.options && state.HIGHEST_QUALIFICATION.options.map((item) => {
                                                                return (
                                                                    <option value={item.QUALIFICATION_ID}> {item.QUALIFICATION_NAME}</option>

                                                                )
                                                            })}
                                                        </select>
                                                        <div className="invalid-feedback">Please select highest qualification</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Course
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <select
                                                            className={state.COURSE.error.length > 0 && !state.COURSE.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                            onChange={onChange}
                                                            name={state.COURSE.name}
                                                            value={state.COURSE.value}
                                                        >
                                                            <option value=''> Select Course</option>
                                                            {
                                                                state.COURSE.options && state.COURSE.options.map((item) => {
                                                                    return (<option
                                                                        value={item.EDUCATION_QUALIFICATION_ID}

                                                                    >
                                                                        {item.COURSE_STREAM}
                                                                    </option>)
                                                                })

                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">Please select course</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Specialization

                                                        </label>
                                                        <select
                                                            type="text"
                                                            className={state.SPECIALIZATION.error.length > 0 && !state.SPECIALIZATION.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                            onChange={onChange}
                                                            placeholder="Specialization"
                                                            name={state.SPECIALIZATION.name}
                                                            value={state.SPECIALIZATION.value}
                                                        >
                                                            <option value=''> Select Specialization</option>
                                                            {
                                                                state.SPECIALIZATION.options && state.SPECIALIZATION.options.map((item) => {
                                                                    return (<option
                                                                        value={item.SPECIALIZATION_ID}
                                                                    >
                                                                        {item.SPECIALIZATION}
                                                                    </option>)
                                                                })

                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">Please select specialization</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Notice Period
                                                            {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                                        </label>
                                                        <select
                                                            type="text"
                                                            className={state.NOTICE_PERIOD.error.length > 0 && !state.NOTICE_PERIOD.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                            onChange={onChange}
                                                            name={state.NOTICE_PERIOD.name}
                                                            value={state.NOTICE_PERIOD.value}
                                                        >
                                                            <option value=''> Select Notice Period</option>
                                                            <option value='Immediate Joiner'> Immediate Joiner</option>
                                                            <option value='15 Days'> 15 Days</option>
                                                            <option value='1 Month'> 1 Month</option>
                                                            <option value='2 Month'> 2 Month</option>
                                                            <option value='3 Month'> 3 Month or more</option>

                                                        </select>
                                                        <div className="invalid-feedback">Please select notice period</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Preferred Role
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <select
                                                            className={state.ROLE_ID.error.length > 0 && !state.ROLE_ID.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                            onChange={onChange}
                                                            name={state.ROLE_ID.name}
                                                            value={state.ROLE_ID.value}
                                                        >
                                                            <option value=''> Select Preferred Role</option>
                                                            {state.ROLE_ID.options && state.ROLE_ID.options.map((item) => {
                                                                return (
                                                                    <option value={item.ROLE_ID}> {item.ROLE_NAME}</option>
                                                                )
                                                            })}

                                                        </select>
                                                        <div className="invalid-feedback">Please select notice period</div>
                                                    </div>
                                                </div>
                                                <div className="hstack gap-2 justify-content-end">
                                                    {/* <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                                        Cancel
                                                    </button> */}
                                                    <button type="submit" className="btn btn-success"
                                                        onClick={() => { onSubmit() }}
                                                    >
                                                        Save Details
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </React.Fragment >
    )
}

export default Add