import axios from 'axios'
import { getCompanyAdminAuthHeader } from '../../utils';

export const v2_designations = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/designation/${page}`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_documentTypes = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/document-type/${page}`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_add_designations = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/designation`;
    return axios.post(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_add_documentTypes = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/document-type`;
    return axios.post(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_add_roles = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/role`;
    return axios.post(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v2_edit_designations = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/designation`;
    return axios.put(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v2_edit_document_types = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/document-type`;
    return axios.put(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_edit_roles = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/role`;
    return axios.put(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v2_visibility_change_designations = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/designation`;
    return axios.patch(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_visibility_change_document_types = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/document-type`;
    return axios.patch(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_workLocations = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/work-location/${page}`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_add_workLocations = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/work-location`;
    return axios.post(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v2_edit_workLocations = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/work-location`;
    return axios.put(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_visibility_change_workLocations = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/work-location`;
    return axios.patch(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_roles = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/role/${page}`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_visibility_change_roles = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/role`;
    return axios.patch(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}
