import React, { useEffect, useState } from 'react'
import { v1_is_valid_company } from '../../../actions/v1/auth';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import constant from '../../../constant';
import { ToastContainer, toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const AccordionContainer = styled.div`
  position: relative;
`;

const Button = styled.button`
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
`;

const AccordionContent = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;
const Header = (props) => {
    // const [isTop, setIsTop] = useState(true);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollPosition = window.scrollY;
    //         setIsTop(scrollPosition === 0);
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);
    const [isOpen, setIsOpen] = useState(false);
    const [companyCode, setCompanyCode] = useState('');

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleInputChange = (e) => {
        setCompanyCode(e.target.value);
    };
    const checkUserExist = () => {
        v1_is_valid_company(companyCode).then((res) => {
            debugger
            if (res.status) {
                const expirationDate = new Date();
                expirationDate.setTime(expirationDate.getTime() + (2 * 60 * 60 * 1000)); // 2 hours in milliseconds
                // Cookies.set('parent_id', res.result.ID, { expires: expirationDate });
                // Cookies.set('parent_id', res.result.ID);
                // Cookies.set('url', res.result.URL);
                console.log(res.result.URL + constant.nonAuthedcomponent.auth_middleware.url.replace(':parent_id', btoa(res.result.ID)).replace(':url', btoa(res.result.URL)))
                window.location.href = res.result.URL + constant.nonAuthedcomponent.auth_middleware.url.replace(':parent_id', btoa(res.result.ID)).replace(':url', btoa(res.result.URL))
                // window.location.href = res.result.URL + constant.nonAuthedcomponent.middleware.url.replace(':parent_id', res.result.ID).replace(':url', res.result.URL)

                // props.history.push(constant.nonAuthedcomponent.login.url.replace(':user_type', 'user'))
            }
            else {
                toast.error(res.error)
            }
        })
    }
    return (
        // <nav className={`navbar navbar-expand-lg navbar-landing navbar-light fixed-top ${!isTop ? 'is-sticky' : ''}`}>
        <nav className={`navbar navbar-expand-lg navbar-landing navbar-light fixed-top `}>

            <ToastContainer />
            <div className="container">
                <Link className="navbar-brand" to={constant.nonAuthedcomponent.landingPage.url}>
                    {/* <img
          src="/images/logo/recruit-logo.png"
          className="card-logo card-logo-dark"
          alt="logo dark"
          height={47}
        /> */}
                    <img
                        src="/images/logo/recruit-logo.png"
                        className="card-logo card-logo-dark"
                        alt="logo dark"
                        height={47}
                    />
                    <img
                        src="/images/logo/recruit-logo.png"
                        className="card-logo card-logo-light"
                        alt="logo light"
                        height={47}
                    />
                </Link>
                <button
                    className="navbar-toggler py-0 fs-20 customNavbartogglerButtn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="mdi mdi-menu" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example" style={{ marginRight: 'inherit !important' }}>
                        <li className="nav-item">
                            <a className="nav-link fs-14 fw-semibold active" href="#hero">
                                Home
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link fs-14 fw-semibold" href="#marketplace">
                                Our Services
                            </a>
                        </li>

                    </ul>
                    <AccordionContainer>
                        <Button className="loginButtonStyle" onClick={toggleAccordion}>Login</Button>
                        {isOpen && (
                            <AccordionContent>
                                <Input
                                    type="text"
                                    placeholder="Enter Company Code"
                                    value={companyCode}
                                    onChange={handleInputChange}
                                />
                                <SubmitButton className="loginSubmitBtn" onClick={() => {
                                    checkUserExist()
                                }}>Login to Jobzshala Recruit</SubmitButton>
                            </AccordionContent>
                        )}
                    </AccordionContainer>
                </div>
            </div>
        </nav>
    )
}

export default withRouter(Header)