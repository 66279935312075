import React, { useEffect, useState } from 'react'
import List from '../../../components/SuperAdmin/client/demoRequestList'
import { v1_change_demo_enquiry_status, v1_demo_requests } from '../../../actions/v1/crm_client'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
const DemoRequestList = (props) => {

    const [list, setList] = useState(null)
    const [count, setCount] = useState(null)
    const [page, setPage] = useState(1)
    const [keyword, setKeyword] = useState('')
    const [fromDate, setfromDate] = useState(moment().format('YYYY-MM-DD'))
    const [toDate, settoDate] = useState(moment().format('YYYY-MM-DD'))
    const [refresh, setRefresh] = useState(false)
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(null);



    useEffect(() => {

        v1_demo_requests(btoa(JSON.stringify({ page: page, keyword: keyword, fromDate: fromDate, toDate: toDate }))).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count.TOTAL)
            }
            else {

            }
        })

    }, [page, keyword, fromDate, toDate, refresh])

    const onSearch = (model) => {
        setKeyword(model.KEYWORD)
        setfromDate(model.FROM_DATE)
        settoDate(model.TO_DATE)
        setPage(1)
    }
    const onUpdateStatus = (model) => {
        v1_change_demo_enquiry_status(model).then((res) => {
            if (res.status) {
                setRefresh(!refresh)
                setOpenChangeStatusModal(null)
            }
            else {
                toast.error(res.error)
            }
        })
    }
    return (
        <React.Fragment>
            <ToastContainer />
            {list && <List
                openChangeStatusModal={openChangeStatusModal}
                setOpenChangeStatusModal={setOpenChangeStatusModal}
                onUpdateStatus={(model) => { onUpdateStatus(model) }}
                list={list}
                count={count}
                setCurrentPage={(page) => { setPage(page) }}
                currentPage={page}
                onSearch={(model) => onSearch(model)}
            />}
        </React.Fragment>
    )
}

export default DemoRequestList