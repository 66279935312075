import React, { useState } from 'react'
import DrawerModal from '../../../components/common/htmlDrawerModal'
import ClientDetailDrawerModal from './clientDetailDrawerModal'
import moment from 'moment';
import Pagination from '../../common/Pagination';
import Modal from '../../common/htmlModal';
import Edit from './editModal';
import { v2_edit_client } from '../../../actions/v2/client_management';
import { ToastContainer, toast } from 'react-toastify';
const List = (props) => {
    const { list, count, currentPage } = props;

    const [showClientDetailDrawerModal, setShowClientDetailDrawerModal] = useState(false)
    const [clientDetails, setClientDetails] = useState(null)
    const [showEditModal, setShowEditModal] = useState(null)



    const [searchState, setSearchState] = useState({
        CLIENT_NAME: { name: 'CLIENT_NAME', value: '', error: '', isRequired: false },
        EMAIL: { name: 'EMAIL', value: '', visible: false, error: '', isRequired: false },
        PHONE: { name: 'PHONE', value: '', visible: false, error: '', isRequired: false },


    })
    const onReset = () => {
        setSearchState({
            CLIENT_NAME: { name: 'CLIENT_NAME', value: '', error: '', isRequired: false },
            EMAIL: { name: 'EMAIL', value: '', visible: false, error: '', isRequired: false },
            PHONE: { name: 'PHONE', value: '', visible: false, error: '', isRequired: false },
        })
        const model = {
            CLIENT_NAME: '',
            EMAIL: '',
            PHONE: '',
        }

        props.setSearchQuery(model)
    }
    const onSearch = () => {
        const model = {
            CLIENT_NAME: searchState.CLIENT_NAME.value,
            EMAIL: searchState.EMAIL.value,
            PHONE: searchState.PHONE.value,
        }

        props.setSearchQuery(model)
    }
    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));

    }
    const onSubmit = (model) => {
        v2_edit_client(model).then((res) => {
            if (res.status) {
                toast.success('Client Added Successfully')
                setShowEditModal(null)
            }
            else {
                toast.error(res.error)

            }
        }).catch((err) => {
            toast.error(err)

        })
    }
    return (
        <React.Fragment>
            <Modal isOpen={showEditModal}
                header='Edit Client Details'
            >
                <Edit
                    data={showEditModal}
                    onClose={() => { setShowEditModal(null) }}
                    onSubmit={onSubmit}
                />

            </Modal>
            <ToastContainer />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <DrawerModal
                            isOpen={showClientDetailDrawerModal}
                            onClose={() => { setShowClientDetailDrawerModal(false) }}
                        >
                            {clientDetails && <ClientDetailDrawerModal
                                clientDetails={clientDetails}
                            />}
                        </DrawerModal>
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Search Client</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Client Management</a>
                                            </li>
                                            <li className="breadcrumb-item active">Client Search</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-sm-3">
                                                <div className="search-box">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Company Name, SPOC Name"
                                                        name={searchState.CLIENT_NAME.name}
                                                        value={searchState.CLIENT_NAME.value}
                                                        onChange={onChange}
                                                    />
                                                    <i className="ri-search-line search-icon" />
                                                </div>
                                            </div>

                                            <div className="col-sm-2">
                                                <div className="search-box">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Email"
                                                        name={searchState.EMAIL.name}
                                                        value={searchState.EMAIL.value}
                                                        onChange={onChange}
                                                    />
                                                    <i className="ri-mail-line search-icon" />
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="search-box">
                                                    <input
                                                        type="number"
                                                        min={5}
                                                        className="form-control search"
                                                        placeholder="Phone"
                                                        name={searchState.PHONE.name}
                                                        value={searchState.PHONE.value}
                                                        onChange={onChange}
                                                    />
                                                    <i className="ri-phone-line search-icon" />
                                                </div>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="hstack gap">
                                                    <button type="button" onClick={() => { onReset() }} className="btn btn-danger add-btn">
                                                        Reset
                                                    </button>
                                                    <button type="button" onClick={() => {
                                                        onSearch()
                                                    }} className="btn btn-success add-btn ms-1">
                                                        <i className="ri-search-line search-icon align-bottom me-1" />
                                                        Search
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col" style={{ width: 50 }}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="checkAll"
                                                                        defaultValue="option"
                                                                    />
                                                                </div>
                                                            </th>
                                                            <th className="sort">Candidate ID</th>
                                                            <th className="sort">Company Name</th>
                                                            <th className="sort">SPOC Name</th>
                                                            <th className="sort">Email</th>
                                                            <th className="sort">Phone</th>
                                                            <th className="sort">Client Add Date</th>
                                                            <th className="sort">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {list && list.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <th scope="row">
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                            />
                                                                        </div>
                                                                    </th>
                                                                    <td className="id">
                                                                        <a
                                                                            href="javascript:void(0);"
                                                                            className="fw-medium link-primary"
                                                                        >
                                                                            RJZSHCC{item.CLIENT_ID}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <div className="flex-grow-1 name">{item.COMPANY_NAME}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <div className="flex-grow-1 name">{item.CLIENT_NAME}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="company_name">
                                                                        {item.EMAIL}
                                                                    </td>
                                                                    <td className="phone">{item.PHONE}</td>
                                                                    <td className="date">{moment(item.CREATED_ON).format('DD MMM YYYY')}</td>
                                                                    <td>
                                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                                            <li
                                                                                className="list-inline-item edit"
                                                                                x data-bs-original-title="Call"
                                                                            >
                                                                                <a
                                                                                    href={`tel:${item.PHONE}`}
                                                                                    className="text-muted d-inline-block"
                                                                                >
                                                                                    <i className="ri-phone-line fs-16" />
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                className="list-inline-item"
                                                                                onClick={() => {
                                                                                    setClientDetails(item)
                                                                                    setShowClientDetailDrawerModal(true)
                                                                                }}
                                                                            >
                                                                                <a href="javascript:void(0);">
                                                                                    <i className="ri-eye-fill align-bottom text-muted" />
                                                                                </a>
                                                                            </li>
                                                                            <li
                                                                                className="list-inline-item edit"
                                                                            >
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    onClick={() => {
                                                                                        setShowEditModal(item)
                                                                                    }}
                                                                                    className="text-muted d-inline-block"
                                                                                >
                                                                                    <i className="ri-edit-2-line fs-16" />
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })

                                                        }
                                                    </tbody>
                                                </table>
                                                {count === 0 && <div className="noresult" >
                                                    <div className="text-center">
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                                            trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a"
                                                            style={{ width: 75, height: 75 }}
                                                        />
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">
                                                            We've searched more than 150+ leads We did not find any
                                                            leads for you search.
                                                        </p>
                                                    </div>
                                                </div>}
                                            </div>
                                            {count > 0 && < Pagination
                                                limit={20}
                                                totalCount={count}
                                                range={5}
                                                onPageChange={(page) => {
                                                    props.setCurrentPage(page)
                                                }}
                                                currentPage={currentPage}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default List