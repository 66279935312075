import React, { useEffect, useState } from 'react'
import { v3_all_client_options, v3_client_options, v3_job_apply } from '../../../actions/v3/jobApplication';
import { v3_client_wise_active_jobs, v3_edit_form_details } from '../../../actions/v3/jobs';
import JobOverview from '../jobs/jobApplications/jobOverview'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import constant from '../../../constant';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Modal from '../../common/htmlModal';
import JobQuestionsAnswerModal from '../jobs/jobApplications/jobQuestionsAnswerModal';

const JobSelectAndPreviewComponent = (props) => {
    const [state, setState] = useState({
        CLIENT: { name: 'CLIENT', value: '', options: [], error: '', isRequired: true },
        JOBS: { name: 'JOBS', value: '', options: [], error: '', isRequired: true },
        JOB: { name: 'JOB', value: '', options: [], error: '', isRequired: true },

    })

    const [currentApplicationId, setCurrentApplicationId] = useState(null);
    const [showJobQuestionForm, setShowJobQuestionForm] = useState(false);

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    useEffect(() => {
        v3_all_client_options().then((res) => {
            debugger
            if (res.status) {
                setOptions('CLIENT', res.result)
            }
        })
    }, [])


    useEffect(() => {
        if (state.CLIENT.value) {
            v3_client_wise_active_jobs(state.CLIENT.value).then((res) => {
                if (res.status) {
                    setOptions('JOBS', res.result)

                }
            })
        }
        else {
            setOptions('JOBS', [])

        }
    }, [state.CLIENT.value])

    useEffect(() => {
        if (state.JOBS.value) {
            v3_edit_form_details(state.JOBS.value).then((res) => {
                if (res.status) {
                    debugger
                    setState(prevState => ({
                        ...prevState,
                        ['JOB']: {
                            ...prevState['JOB'],
                            value: {
                                JOB_ID: res.result.job_detail.JOB_ID,
                                CLIENT_NAME: res.result.job_detail.CLIENT_NAME,
                                CLIENT_DETAILS: res.result.job_detail.CLIENT_DETAILS,
                                CLIENT_ADDRESS: res.result.job_detail.CLIENT_ADDRESS,
                                COMPANY_NAME: res.result.job_detail.COMPANY_NAME,
                                CLIENT_ID: res.result.job_detail.CLIENT_ID,
                                JOB_TITLE: res.result.job_detail.JOB_TITLE,
                                EMPLOYMENT_TYPE: res.result.job_detail.EMPLOYMENT_TYPE_ID,
                                WORK_EXP_MIN: res.result.job_detail.WORK_EXP_MIN,
                                WORK_EXP_MAX: res.result.job_detail.WORK_EXP_MAX,
                                SALARY_CURRENCY: res.result.job_detail.SALARY_CURRENCY,
                                SALARY_MIN: res.result.job_detail.SALARY_MIN,
                                SALARY_MAX: res.result.job_detail.SALARY_MAX,
                                JOB_DETAILS: res.result.job_detail.JOB_DETAILS,
                                JOB_QUESTIONS: res.result.job_question,
                                INCLUDE_WALK_IN_DETAILS: res.result.job_detail.IS_INCLUDE_WALK_IN,
                                WALK_IN_START_DATE: res.result.job_detail.WALK_IN_START_DATE,
                                WALK_IN_START_TIME: res.result.job_detail.WALK_IN_START_TIME,
                                WALK_IN_END_TIME: res.result.job_detail.WALK_IN_END_TIME,
                                WALK_IN_CONTACT_PERSON: res.result.job_detail.WALK_IN_CONTACT_PERSON,
                                WALK_IN_CONTACT_NUMBER: res.result.job_detail.WALK_IN_CONTACT_NUMBER,
                                WALK_IN_VENUE: res.result.job_detail.WALK_IN_VENUE,
                                GOOGLE_MAP_URL: res.result.job_detail.GOOGLE_MAP_URL,
                                WALK_IN_DURATION: res.result.job_detail.WALK_IN_DURATION,
                                IS_WORK_FROM_HOME_JOB: res.result.job_detail.IS_WORK_FROM_HOME,
                                WORK_FROM_TYPE: res.result.job_detail.TYPE_OF_WORK_FROM_HOME,
                                OFFICE_LOCATION: res.result.job_detail.OFFICE_LOCATION,
                                SKILLS: res.result.job_detail.KEYWORDS,
                                INDUSTRY: res.result.job_detail.INDUSTRY_ID,
                                FUNCTIONAL_AREA: res.result.job_detail.FUNCTIONAL_AREA_ID,
                                ROLE: res.result.job_detail.ROLE_ID,
                                NO_OF_VACANCY: res.result.job_detail.NO_OF_VACANCIES,
                                QUALIFICATION: res.result.qualificationDetails,
                                EDUCATION_QUALIFICATION: res.result.educationQualificationDetails,
                                COURSE_SPECIALIZATION: res.result.specializationDetails,
                                DATE_OF_EXPIRY: res.result.job_detail.DATE_OF_EXPIRY
                            }
                        }
                    }));


                }
            })
        }
        else {
            setOptions('JOBS', null)

        }
    }, [state.JOBS.value])

    const onApplyJob = (item) => {
        debugger
        v3_job_apply({
            JOB_ID: state.JOB.value?.JOB_ID,
            CANDIDATE_ID: props.match.params.id,
            EMAIL: null,
            PHONE: null,
        }).then((res) => {
            if (res.status) {
                toast('Successfully Applied');
                setCurrentApplicationId(res.result)
                if (state.JOB.value?.JOB_QUESTIONS.length > 0) {
                    setShowJobQuestionForm(true)
                }
                else {
                    props.history.push(constant.companyUserComponent.listCandidate.url)

                }
                // props.openJobQuestionForm()
                // props.onClose()

            }
            else {
                Swal.fire('Alert !', res.error, 'warning');
                // props.on Close()
            }
        })
    }
    return (
        <React.Fragment>

            <div className="row">
                <ToastContainer />
                <Modal
                    isOpen={showJobQuestionForm}
                    header='Job Questions'
                    onClose={() => { setShowJobQuestionForm(false) }}
                >
                    {state.JOB.value.JOB_QUESTIONS && <JobQuestionsAnswerModal
                        onClose={() => { setShowJobQuestionForm(false) }}
                        QUESTIONS={state.JOB.value.JOB_QUESTIONS}
                        currentApplicationId={currentApplicationId}
                    />}

                </Modal>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="mb-sm-0">Apply Job</h4>


                        </div>
                        <div className="card-body">
                            <div className="live-preview" >
                                <div className="row gy-4">
                                    <div className="col-lg-6 mb-3">
                                        <div>
                                            <label className="form-label">
                                                Active Clients
                                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                                            </label>
                                            <select
                                                type="text"
                                                className="form-select"
                                                onChange={onChange}
                                                name={state.CLIENT.name}
                                                value={state.CLIENT.value}
                                            >
                                                <option value={''}> Choose Client</option>
                                                {state.CLIENT.options && state.CLIENT.options.map((item) => {
                                                    return (

                                                        <option value={item.CLIENT_ID}>{item.COMPANY_NAME}</option>
                                                    )

                                                })}

                                                <option value='0'> Non Client Requirement</option>
                                            </select>
                                            <div className="invalid-feedback">Please enter a full name.</div>
                                        </div>
                                    </div>
                                    {state.CLIENT.value && <div className="col-lg-6 mb-3">
                                        <div>

                                            <label className="form-label">
                                                Active Jobs
                                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                                            </label>
                                            <select
                                                type="text"
                                                className="form-select"
                                                onChange={onChange}
                                                name={state.JOBS.name}
                                                value={state.JOBS.value}
                                            >
                                                <option value={''}> Choose Job</option>
                                                {state.JOBS.options ? state.JOBS.options.map((item) => {
                                                    return (

                                                        <option value={item.JOB_ID}>{item.JOB_TITLE}</option>
                                                    )

                                                }) :

                                                    <option value=''>No Active Job Found</option>

                                                }

                                            </select>
                                            <div className="invalid-feedback">Please enter a full name.</div>
                                        </div>
                                    </div>}


                                    {state.JOB.value && <div className="col-lg-12 mb-3">
                                        <JobOverview
                                            jobData={state.JOB.value}
                                        />
                                    </div>}

                                </div>
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" onClick={() => {
                                        props.history.push(constant.companyUserComponent.listCandidate.url)
                                    }} className="btn btn-light" >
                                        Skip
                                    </button>
                                    <button type="submit" className="btn btn-success" onClick={() => { onApplyJob() }}>
                                        Apply Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment>
    )
}
export default withRouter(JobSelectAndPreviewComponent)