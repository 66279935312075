import React, { useEffect, useState } from 'react';
import { validateFunctionalForm } from '../../../utils';
import { v3_team_lead_owners } from '../../../actions/v3/team';
import { v3_lead_owners } from '../../../actions/v3/candidates';

const Add = (props) => {
    const [state, setState] = useState({
        LEAD_OWNER: { name: 'LEAD_OWNER', value: props?.data?.LEAD_OWNER ? props.data.LEAD_OWNER : '', error: '', isRequired: true },
    });

    useEffect(() => {
        v3_lead_owners().then(res => {
            if (res.status) {
                setOptions('LEAD_OWNER', res.result);
            }
        });
    }, []);

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const onSubmit = (e) => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                LEAD_OWNER: state.LEAD_OWNER.value,
                CANDIDATE_ID: props?.data?.CANDIDATE_ID // Correct incomplete props
                // Add other properties from state...
            };
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }
            props.onSubmit(formData);
        }
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 mb-3">
                    <div>
                        <label className="form-label">
                            Lead Owners
                        </label>
                        <select
                            className={state.LEAD_OWNER.error && !state.LEAD_OWNER.value ? 'form-select is-invalid' : 'form-select'}
                            onChange={onChange}
                            value={state.LEAD_OWNER.value}
                            name={state.LEAD_OWNER.name}
                        >
                            <option value=''>
                                Select Lead Owner
                            </option>
                            {state.LEAD_OWNER.options && state.LEAD_OWNER.options.map((item) => {
                                return (
                                    <option key={item.USER_ID} value={item.USER_ID}>
                                        {item.FULL_NAME}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-sm-auto ms-auto">
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-success" onClick={() => { onSubmit() }}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Add;
