import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import PDFEmbed from '../../../common/PDFViewer';
import Modal from '../../../common/htmlModal';


// Styled component for profile details
const ProfileDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

// Styled component for detail label
const DetailLabel = styled.span`
  font-weight: bold;
  min-width: 120px;
`;

// Styled component for detail value
const DetailValue = styled.span`
  margin-left: 10px;
`;

const ClientProfileComponent = ({ clientDetails }) => {
    const [showClientDocumentModal, setShowClientDocumentModal] = useState(null)
    const [showServiceAgreementModal, setShowServiceAgreementModal] = useState(null)
    const [showRateCardModal, setShowRateCardModal] = useState(null)
    return (
        <div className="row">
            <Modal isOpen={showClientDocumentModal} onClose={() => { setShowClientDocumentModal(null) }}>
                <PDFEmbed
                    file={`${process.env.REACT_APP_BASE_URL}/v3/client-document/${showClientDocumentModal}`}
                />
            </Modal>
            <Modal isOpen={showServiceAgreementModal} onClose={() => { setShowServiceAgreementModal(null) }}>
                <PDFEmbed
                    file={`${process.env.REACT_APP_BASE_URL}/v3/client-service-agreement/${showServiceAgreementModal}`}
                />
            </Modal>
            <Modal isOpen={showRateCardModal} onClose={() => { setShowRateCardModal(null) }}>
                <PDFEmbed
                    file={`${process.env.REACT_APP_BASE_URL}/v3/client-rate-card/${showRateCardModal}`}
                />
            </Modal>
            <div className="col-lg-12">
                <div className="card">
                    {/* <div className="card-header align-items-center d-flex"></div> */}
                    <div className="card-body profileBodyStyleSection">
                        <div className="live-preview">
                            <div className="row gy-4">
                                <div className="profileTopImg">
                                    <ProfileDetail>
                                        {/* <DetailLabel>Company Name :</DetailLabel> */}
                                        <DetailValue>{
                                            <img style={{ border: '2px solid #e7e7e7' }}
                                                src={clientDetails.LOGO ? `${process.env.REACT_APP_BASE_URL}/v3/client-logo/${clientDetails.LOGO}` : "/assets/images/companies/img-2.png"}
                                                // src="/assets/images/companies/img-2.png"
                                                alt=""
                                                className="avatar-lg rounded-circle"
                                            />
                                        }</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Company Name :</DetailLabel>
                                        <DetailValue>{clientDetails.COMPANY_NAME}</DetailValue>
                                    </ProfileDetail>
                                </div>

                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>SPOC Name :</DetailLabel>
                                        <DetailValue>{clientDetails.CLIENT_NAME}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Email :</DetailLabel>
                                        <DetailValue>{clientDetails.EMAIL}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Phone :</DetailLabel>
                                        <DetailValue>{clientDetails.PHONE}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>GST No :</DetailLabel>
                                        <DetailValue>{clientDetails.GST_NO ? clientDetails.GST_NO : 'NA'}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Agreement Start Date
                                            :</DetailLabel>
                                        <DetailValue>{clientDetails.AGREEMENT_START_DATE ? moment(clientDetails.AGREEMENT_START_DATE).format('DD MMM YYYY') : 'NA'}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Agreement End Date    :</DetailLabel>
                                        <DetailValue>{clientDetails.AGREEMENT_END_DATE ? moment(clientDetails.AGREEMENT_END_DATE).format('DD MMM YYYY') : 'NA'}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Client Address :</DetailLabel>
                                        <DetailValue>{clientDetails.CLIENT_ADDRESS}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Client Detail :</DetailLabel>
                                        <DetailValue>{clientDetails.CLIENT_DETAILS}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Client Document :</DetailLabel>
                                        <DetailValue>{clientDetails.RATE_CARD ? <a href='javascript:void(0)' onClick={() => { setShowClientDocumentModal(clientDetails.CLIENT_DOC) }}>{clientDetails.CLIENT_DOC}</a> : 'NA'}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Service Agreement Document :</DetailLabel>
                                        <DetailValue>{clientDetails.SERVICE_AGREEMENT_DOC ? <a href='javascript:void(0)' onClick={() => { setShowServiceAgreementModal(clientDetails.SERVICE_AGREEMENT_DOC) }}>{clientDetails.SERVICE_AGREEMENT_DOC}</a> : 'NA'}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Rate Card
                                            :</DetailLabel>
                                        <DetailValue>{clientDetails.RATE_CARD ? <a href='javascript:void(0)' onClick={() => { setShowRateCardModal(clientDetails.RATE_CARD) }}>{clientDetails.RATE_CARD}</a> : 'NA'}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Internal SPOC:</DetailLabel>
                                        <DetailValue>
                                            {clientDetails.INTERNAL_SPOC ? clientDetails.INTERNAL_SPOC.map((item) => {
                                                return item.label; 
                                            }).join(',').replace(',', ', ') : 'NA'}
                                        </DetailValue>
                                    </ProfileDetail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
};

export default ClientProfileComponent;
