import React from 'react'
import Add from '../../../components/CompanyAdmin/userManagement/adduser'
import { v2_add_user } from '../../../actions/v2/user_management'
import { ToastContainer, toast } from 'react-toastify'
import constant from '../../../constant'

const AddUser = (props) => {
    const onSubmit = (model) => {
        v2_add_user(model).then((res => {
            if (res.status) {
                toast.success('User Added Successfully')
                if (model.HAVING_TEAM_MEMBERS === '1') {
                    props.history.push(constant.comapnyAdminComponent.editusers.url.replace(':user_id', res.result))
                }

            }
            else {
                toast.error(res.error);
            }
        }))
    }
    return (
        <React.Fragment>
            <Add
                onSubmit={onSubmit}
            />
            <ToastContainer />

        </React.Fragment>
    )
}

export default AddUser