import React from 'react'
import LoginForm from '../../components/Auth/login'
import { v1_login } from '../../actions/v1/auth'
import { ToastContainer, toast } from 'react-toastify';
import { setStorage } from '../../utils';
import constant from '../../constant';
import { v2_login } from '../../actions/v2/auth';
import { v3_login } from '../../actions/v3/auth';
import Cookies from 'js-cookie';

const login = (props) => {
    console.log(props)
    const onSubmit = (model) => {
        if (props.match.params.user_type === 'su') {
            v1_login(model).then((res) => {
                if (res.status) {
                    toast.success('Successfully logged in')
                    setStorage(constant.keys.superAdminToken, res.result.accessToken)
                    setStorage(constant.keys.superAdminDetails, res.result.userDetails)

                    props.history.push(constant.superAdminComponent.dashBoard.url)
                }
                else {
                    toast.error(res.error)

                }
            })
        }
        else if (props.match.params.user_type === 'company') {
            v2_login(model).then((res) => {
                if (res.status) {
                    toast.success('Successfully logged in')
                    setStorage(constant.keys.companyAdminToken, res.result.accessToken)
                    setStorage(constant.keys.companyAdminDetails, res.result.userDetails)
                    props.history.push(constant.comapnyAdminComponent.dashBoard.url)
                }
                else {
                    toast.error(res.error)

                }
            })
        }
        else {
            const parent_id = Cookies.get('parent_id')
            if (parent_id) {
                v3_login({ ...model, parent_id: parent_id }).then((res) => {
                    debugger
                    if (res.status) {
                        toast.success('Successfully logged in')
                        setStorage(constant.keys.companyUserToken, res.result.accessToken)
                        setStorage(constant.keys.companyUserDetails, res.result.userDetails)
                        setStorage(constant.keys.companyUserHavingTeam, Boolean(res.result.teamAdmin))
                        setStorage(constant.keys.companyUserHavingAdminRights, Boolean(res.result.administrator))
                        props.history.push(constant.companyUserComponent.dashBoard.url)
                    }
                    else {
                        toast.error(res.error)

                    }
                })
            }
            else {
                toast.error('User not found')

            }
        }
    }

    return (
        <React.Fragment>
            <LoginForm
                onSubmit={(model) => {
                    onSubmit(model)
                }}
            />
            <ToastContainer />
        </React.Fragment>
    )
}

export default login