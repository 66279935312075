import React, { useRef, useEffect, useState } from 'react';
import styles from './style.module.css';

const Modal = ({ isOpen, onClose, header, children }) => {
  const modalRef = useRef();
  const [isOpenState, setIsOpenState] = useState(isOpen);
  const [modalWidth, setModalWidth] = useState(null);

  useEffect(() => {
    setIsOpenState(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isOpenState) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
      calculateModalWidth();
    } else {
      document.body.style.overflow = ''; // Reset scrolling when modal is closed
    }
    return () => {
      document.body.style.overflow = ''; // Reset scrolling if component unmounts
    };
  }, [isOpenState]);

  const handleClose = () => {
    setIsOpenState(false);
    onClose();
  };

  const calculateModalWidth = () => {
    if (modalRef.current) {
      const contentWidth = modalRef.current.scrollWidth;
      setModalWidth(contentWidth);
    }
  };

  return (
    <div>
      {isOpenState && <div className={styles.backdrop} onClick={handleClose}></div>}
      <div className={`${styles.modal} ${isOpenState ? styles.open : ''}`} ref={modalRef} style={{ width: modalWidth }}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <button className={styles.closeButton} onClick={handleClose}>
              X
            </button>
            {header && <h3 className={styles.headerText}>{header}</h3>}
          </div>
          <div className={styles.modalBody}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
