import React from 'react'
import CountStats from '../../components/SuperAdmin/dashboard/countStats'
import ClientOnboardingStats from '../../components/SuperAdmin/dashboard/clientOnboardingStats'
const Dashboard = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Analytics</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Dashboards</a>
                    </li>
                    <li className="breadcrumb-item active">Analytics</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-5">
              <CountStats />
            </div>
            <div className="col-xxl-7">
            <ClientOnboardingStats/>
            </div>
          </div>
        </div>
      </div>


    </div>

  )
}

export default Dashboard