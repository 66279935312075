import axios from 'axios'
import { getCompanyAdminAuthHeader } from '../../utils';

export const v2_dashboard_count_stats = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/dashboard/count-stats`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v2_dashboard_clients = async (PAGE) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v2/dashboard/clients/${PAGE}`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_dashboard_application_statistics = async (DATE_RANGE) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v2/dashboard/application-statistics/${DATE_RANGE}`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}
