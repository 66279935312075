import React, { useEffect, useState } from 'react'
import { v3_designation_options, v3_user_roles, v3_work_location_options } from '../../../../actions/v3/administrator';

const MasterDetails = (props) => {
    const { data } = props;
    const [state, setState] = useState({
        EMPLOYEE_CODE: { name: 'EMPLOYEE_CODE', value: data?.EMPLOYEE_CODE ? data.EMPLOYEE_CODE : '', error: '', isRequired: true },
        DESIGNATION_ID: { name: 'DESIGNATION_ID', value: data?.DESIGNATION_ID ? data.DESIGNATION_ID : '', options: [], error: '', isRequired: true },
        WORK_LOCATION_ID: { name: 'WORK_LOCATION_ID', value: data?.WORK_LOCATION_ID ? data.WORK_LOCATION_ID : '', options: [], error: '', isRequired: true },
        DATE_OF_JOINING: { name: 'DATE_OF_JOINING', value: data?.DATE_OF_JOINING ? data.DATE_OF_JOINING : '', options: [], error: '', isRequired: true },
        ID_CARD_NUMBER: { name: 'ID_CARD_NUMBER', value: data?.ID_CARD_NUMBER ? data.ID_CARD_NUMBER : '', error: '', isRequired: false },
    })

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }


    useEffect(() => {
        v3_work_location_options().then((res) => {
            if (res.status) {
                setOptions('WORK_LOCATION_ID', res.result)
            }
        })
        v3_designation_options().then((res) => {
            if (res.status) {
                setOptions('DESIGNATION_ID', res.result)


            }
        })
    }, [])


    const onSubmit = () => {
        const model = {
            EMPLOYEE_CODE: state.EMPLOYEE_CODE.value,
            DESIGNATION_ID: state.DESIGNATION_ID.value,
            WORK_LOCATION_ID: state.WORK_LOCATION_ID.value,
            DATE_OF_JOINING: state.DATE_OF_JOINING.value,
            ID_CARD_NUMBER: state.ID_CARD_NUMBER.value,

        }
        props.onSubmit(model)
    }
    return (
        <React.Fragment>
            <div className="tab-content pt-4 text-muted">
                <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                >
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Employee Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.EMPLOYEE_CODE.error && !state.EMPLOYEE_CODE.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.EMPLOYEE_CODE.name}
                                                    value={state.EMPLOYEE_CODE.value}
                                                    onChange={onChange}
                                                    placeholder='Employee Code'

                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="lastnameInput" className="form-label">
                                                    Designation
                                                </label>
                                                <select
                                                    type="text"
                                                    className={state.DESIGNATION_ID.error && !state.DESIGNATION_ID.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.DESIGNATION_ID.name}
                                                    value={state.DESIGNATION_ID.value}
                                                    onChange={onChange}
                                                >
                                                    <option> Select Designation</option>
                                                    {
                                                        state.DESIGNATION_ID.options.map((item) => {
                                                            return (
                                                                <option value={item.DESIGNATION_ID}>{item.NAME}</option>

                                                            )
                                                        })

                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="lastnameInput" className="form-label">
                                                    Work Location
                                                </label>
                                                <select
                                                    className={state.WORK_LOCATION_ID.error && !state.WORK_LOCATION_ID.value ? "form-select is-invalid" : "form-select"}
                                                    name={state.WORK_LOCATION_ID.name}
                                                    value={state.WORK_LOCATION_ID.value}
                                                    onChange={onChange}

                                                >
                                                    <option value=''>Select Work Location</option>
                                                    {
                                                        state.WORK_LOCATION_ID.options.map((item) => {
                                                            return (
                                                                <option value={item.WORK_LOCATION_ID}>{item.NAME}</option>

                                                            )
                                                        })

                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Date Of Joining
                                                </label>
                                                <input
                                                    type="date"
                                                    className={state.DATE_OF_JOINING.error && !state.DATE_OF_JOINING.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.DATE_OF_JOINING.name}
                                                    value={state.DATE_OF_JOINING.value}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    ID Card Number
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.ID_CARD_NUMBER.error && !state.ID_CARD_NUMBER.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.ID_CARD_NUMBER.name}
                                                    value={state.ID_CARD_NUMBER.value}
                                                    onChange={onChange}
                                                    placeholder='ID Card Number'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xxl-12 col-md-12">
                                            <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default MasterDetails