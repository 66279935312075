import React, { useEffect, useRef, useState } from 'react'
import EditMasterDetails from '../../../../components/companyUser/administrator/CRMUser/editCRMUserMasterDetailsComponent';
import EditWorkDetails from '../../../../components/companyUser/administrator/CRMUser/editCRMUserWorkDetailsComponent';
import EditBasicDetails from '../../../../components/companyUser/administrator/CRMUser/editCRMUserBasicDetailsComponent';
import EditStatutoryDetails from '../../../../components/companyUser/administrator/CRMUser/editCRMUserStatutoryDetails';
import EditTeamDetails from '../../../../components/companyUser/administrator/CRMUser/editTeamDetailsComponent';
import DocumentList from '../../../../components/companyUser/administrator/CRMUser/CRMUserDocumentList';

import { v3_crm_user_profile_details, v3_edit_user_basic_details, v3_edit_user_master_details, v3_edit_user_profile_image, v3_edit_user_statutory_details, v3_edit_user_work_details, v3_user_team_mapping_update } from '../../../../actions/v3/administrator';
import { ToastContainer, toast } from 'react-toastify';

const Preview = (props) => {
  const [selectedTab, setSelectedTab] = useState('master')
  const [mode, setMode] = useState('edit');
  const [masterDetails, setMasterDetails] = useState(null);
  const [workDetails, setWorkDetails] = useState(null);
  const [baiscDetails, setBasicDetails] = useState(null);
  const [statutoryDetails, setStatutoryDetails] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [teamDetails, setTeamDetails] = useState(null);
  const [documentDetails, setDocumentDetails] = useState(null);



  useEffect(() => {
    v3_crm_user_profile_details(props.match.params.id).then((res) => {
      if (res.status) {
        setMasterDetails(res.result.master_detail);
        setWorkDetails(res.result.work_detail);
        setBasicDetails(res.result.basic_detail);
        setStatutoryDetails(res.result.statutory_detail);
        setProfileImage(res.result.master_detail.PROFILE_IMAGE)
        setTeamDetails(res.result.team_detail)
      }
    })
  }, [])

  const onEditMasterDetail = (model) => {
    model.USER_ID = props.match.params.id
    v3_edit_user_master_details(model).then((res) => {
      if (res.status) {
        toast.success('Master Details Saved Successfuly')
      }
      else {
        toast.error(res.error)

      }
    })
  }

  const onEditWorkDetail = (model) => {
    model.USER_ID = props.match.params.id
    v3_edit_user_work_details(model).then((res) => {
      if (res.status) {
        toast.success('Work Details Saved Successfuly')
      }
      else {
        toast.error(res.error)

      }
    })
  }

  const onEditBasicDetail = (model) => {
    model.USER_ID = props.match.params.id
    v3_edit_user_basic_details(model).then((res) => {
      if (res.status) {
        toast.success('Basic Details Saved Successfuly')
      }
      else {
        toast.error(res.error)

      }
    })
  }
  const onEditStatutoryDetail = (model) => {
    model.USER_ID = props.match.params.id
    v3_edit_user_statutory_details(model).then((res) => {
      if (res.status) {
        toast.success('Statutory Details Saved Successfuly')
      }
      else {
        toast.error(res.error)

      }
    })
  }
  const onUpdateTeamMapping = (model) => {
    v3_user_team_mapping_update(model, props.match.params.id).then((res) => {
      if (res.status) {
        toast.success('Team Details Saved Successfuly')
      }
      else {
        toast.error(res.error)

      }
    })
  }
  const onUploadImage = (e) => {

    const formData = new FormData()
    formData.append('image', e.target.files[0])
    formData.append('USER_ID', props.match.params.id)

    v3_edit_user_profile_image(formData).then((res) => {
      if (res.status) {

      }
    })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="profile-foreground position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg">
                {/* <img
                  src="/assets/images/profile-bg.jpg"
                  alt=""
                  className="profile-wid-img"
                /> */}
              </div>
            </div>


            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
              <div className="row g-4">
                <div className="col-auto">
                  <div className="avatar-lg">
                    <div className="avatar-container">
                      <img
                        src={profileImage ? `${process.env.REACT_APP_BASE_URL}/v3/profile-image/${props.match.params.id}/${profileImage}` : "/assets/images/users/user-dummy-img.jpg"}
                        className="img-thumbnail rounded-circle"
                      />
                      <label htmlFor="profile_pic" className="ri-edit-2-line edit-icon" ></label>
                      <input
                        type="file"
                        id="profile_pic"
                        style={{ display: 'none' }}
                        accept="image/*" onChange={onUploadImage}
                      />
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col">
                  <div className="p-2">
                    <h3 className="text-white mb-1">{masterDetails?.FULL_NAME}</h3>
                    <p className="text-white text-opacity-75">{workDetails?.DESIGNATION_NAME}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="d-flex profile-wrapper">
                    <ul
                      className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link fs-14 ${selectedTab === 'master' ? 'active' : ''}`}
                          href="javascript:void(0)"
                          onClick={() => { setSelectedTab('master') }}
                        >
                          <i className="ri-airplay-fill d-inline-block d-md-none" />
                          <span className="d-none d-md-inline-block">Master</span>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link fs-14 ${selectedTab === 'work' ? 'active' : ''}`}

                          href="javascript:void(0)"
                          onClick={() => { setSelectedTab('work') }}

                        >
                          <i className="ri-list-unordered d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">Work</span>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link fs-14 ${selectedTab === 'basic' ? 'active' : ''}`}

                          href="javascript:void(0)"
                          onClick={() => { setSelectedTab('basic') }}

                        >
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">Basic</span>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link fs-14 ${selectedTab === 'statutory' ? 'active' : ''}`}

                          href="javascript:void(0)"
                          onClick={() => { setSelectedTab('statutory') }}

                        >
                          <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">Statutory</span>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link fs-14 ${selectedTab === 'team' ? 'active' : ''}`}

                          href="javascript:void(0)"
                          onClick={() => { setSelectedTab('team') }}

                        >
                          <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">Team</span>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link fs-14 ${selectedTab === 'document' ? 'active' : ''}`}

                          href="javascript:void(0)"
                          onClick={() => { setSelectedTab('document') }}

                        >
                          <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">Documents</span>
                        </a>
                      </li>
                    </ul>
                    <div className="flex-shrink-0">
                      <a
                        href="pages-profile-settings.html"
                        className="btn btn-success"
                      >
                        <i className="ri-edit-box-line align-bottom" /> Edit Profile
                      </a>
                    </div>
                  </div>
                  {mode === 'edit' && selectedTab === 'master' && masterDetails && <EditMasterDetails
                    data={masterDetails}
                    onSubmit={(model) => { onEditMasterDetail(model) }}
                  />}
                  {mode === 'edit' && selectedTab === 'work' && <EditWorkDetails
                    data={workDetails}
                    onSubmit={(model) => { onEditWorkDetail(model) }}
                  />}
                  {mode === 'edit' && selectedTab === 'basic' && <EditBasicDetails
                    data={baiscDetails}
                    onSubmit={(model) => { onEditBasicDetail(model) }}
                  />}
                  {mode === 'edit' && selectedTab === 'statutory' && <EditStatutoryDetails
                    data={statutoryDetails}
                    onSubmit={(model) => { onEditStatutoryDetail(model) }}
                  />}
                  {mode === 'edit' && selectedTab === 'team' && <EditTeamDetails
                    data={teamDetails}
                    onSubmit={(model) => { onUpdateTeamMapping(model) }}
                  />}
                  {selectedTab === 'document' && <DocumentList
                    data={teamDetails}
                    onSubmit={(model) => { onUpdateTeamMapping(model) }}
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </React.Fragment>
  )
}

export default Preview