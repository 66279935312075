import React, { useRef, useEffect, useState } from 'react';
import styles from './style.module.css';

const Modal = ({ isOpen, onClose, header, children }) => {
  const modalRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const calculateWidth = () => {
      const contentWidth = modalRef.current.scrollWidth;
      const maxWidth = window.innerWidth - 40;
      const newWidth = Math.min(contentWidth + 40, maxWidth);
      modalRef.current.style.width = `${newWidth}px`;
    };

    if (isOpen) {
      calculateWidth();
      window.addEventListener('resize', calculateWidth);
    }

    return () => {
      window.removeEventListener('resize', calculateWidth);
    };
  }, [isOpen]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    setPosition({
      x: e.clientX - startPosition.x,
      y: e.clientY - startPosition.y
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  if (!isOpen) return null;

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={`${styles.modal} ${isDragging ? styles.dragging : ''}`}

        ref={modalRef}
        style={{ top: position.y, left: position.x }}
        onMouseDown={handleMouseDown}
        onClick={(e) => e.stopPropagation()}
      >
        {onClose && (
          <button className={styles.closeButton} onClick={onClose}>
            X
          </button>
        )}
        {header && (
          <div className={styles.modalHeader}>
            <h3 className={styles.headerText}>{header}</h3>
            <hr className={styles.headerHr} />
          </div>
        )}

        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;