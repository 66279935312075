import React, { useEffect, useState } from 'react'
import { validateFunctionalForm } from '../../../utils';
import { v3_crm_user_option_based_on_role } from '../../../actions/v3/administrator';
import moment from 'moment';

const ReminderForm = (props) => {
    const [state, setState] = useState({
        DATE: { name: 'DATE', value: '', error: '', isRequired: true },
        TIME: { name: 'TIME', value: '', error: '', isRequired: true },
        TO: { name: 'TO', value: '', error: '', isRequired: true },
        REMINDER_USER: { name: 'REMINDER_USER', value: '', options: [], error: '', isRequired: true },
        REMINDER_NOTE: { name: 'REMINDER_NOTE', value: '', error: '', isRequired: true },
        ADD_REMARK: { name: 'ADD_REMARK', value: false, error: '', isRequired: true },
        CLOSE_OLD_REMINDER: { name: 'TIME', value: false, error: '', isRequired: true },
        REMARK_TYPE: { name: 'REMARK_TYPE', value: '', error: '', isRequired: false },
        REMARK: { name: 'REMARK', value: '', error: '', isRequired: false },
    });
    useEffect(() => {
        if (state.ADD_REMARK.value) {
            setState(prevState => ({
                ...prevState,
                ['REMARK_TYPE']: {
                    ...prevState['REMARK_TYPE'],
                    isRequired: true
                },
                ['REMARK']: {
                    ...prevState['REMARK'],
                    isRequired: true
                }

            }));

        }
        else {
            setState(prevState => ({
                ...prevState,
                ['REMARK_TYPE']: {
                    ...prevState['REMARK_TYPE'],
                    isRequired: false
                },
                ['REMARK']: {
                    ...prevState['REMARK'],
                    isRequired: false
                }

            }));
        }
    }, [state.ADD_REMARK.value])

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }
    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }


    const onSubmit = (e) => {
        e.preventDefault();
        debugger
        if (validateFunctionalForm(state, setState)) {
            const model = {
                CANDIDATE_ID: props.data.CANDIDATE_ID,
                DATE: state.DATE.value,
                TIME: state.TIME.value,
                REMINDER_USER: state.REMINDER_USER.value,
                REMARK: state.REMARK.value,
                REMINDER_NOTE: state.REMINDER_NOTE.value,
                REMARK_TYPE: state.REMARK_TYPE.value,
                CLOSE_OLD_REMINDER:state.CLOSE_OLD_REMINDER.value
            }
            props.onSubmit(model)

        }
    }
    useEffect(() => {
        v3_crm_user_option_based_on_role().then((res) => {
            if (res.status) {
                setOptions('REMINDER_USER', res.result);
            }
        })
    }, [])
    console.log(state.REMINDER_USER)
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="col-lg-12 mb-2">
                    <div>
                        <label className="form-label fw-bold customTextsize">
                            Name :
                            <span className='spanColoerPopup'>&nbsp;&nbsp; {props.data.FULL_NAME}</span>
                        </label>
                    </div>
                    <div>
                        <label className="form-label fw-bold customTextsize">
                            Candidate Id :
                            <span className='spanColoerPopup'>&nbsp;&nbsp; RJSHC{props.data.CANDIDATE_ID}</span>

                        </label>

                    </div>
                </div>
                <form className="live-preview" onSubmit={(e) => { onSubmit(e) }}>

                        <div class="col-md-12 CustomHoleBGPopup">
                            <div className="row">
                                <div className="col-lg-6 mb-3">
                                    <div>
                                        <label className="form-label" style={{ fontSize: 16}}>
                                            Date
                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                        </label>
                                        <input
                                            type="date"
                                            min={moment().format('YYYY-MM-DD')}
                                            className={state.DATE.error.length > 0 && !state.DATE.value ? "form-control is-invalid" : "form-control"}
                                            onChange={onChange}
                                            name={state.DATE.name}
                                            value={state.DATE.value}
                                        />
                                        <div className="invalid-feedback">Please select date</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <div>
                                        <label className="form-label" style={{ fontSize: 16}}>
                                            Time
                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                        </label>
                                        <input
                                            type="time"
                                            className={state.TIME.error.length > 0 && !state.TIME.value ? "form-control is-invalid" : "form-control"}
                                            onChange={onChange}
                                            name={state.TIME.name}
                                            value={state.TIME.value}
                                        />
                                        <div className="invalid-feedback">Please select Time</div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-2 mb-2">
                                    <div class="row">
                                        <div class="col-md-1">
                                            <label className="form-label" style={{ fontSize: 16}}>
                                                To
                                                <span className='text-danger'>&nbsp;&nbsp;*</span>
                                            </label>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div className='col-auto customPopupCheckbox'>
                                                    <label className="form-label">
                                                        Lead Owner
                                                    </label>
                                                    <input
                                                        type="radio"
                                                        checked={state.TO.value === 'LEAD_OWNER'}
                                                        onClick={() => {
                                                            setState(prevState => ({
                                                                ...prevState,
                                                                ['TO']: {
                                                                    ...prevState['TO'],
                                                                    value: 'LEAD_OWNER'
                                                                },
                                                                ['REMINDER_USER']: {
                                                                    ...prevState['TO'],
                                                                    value: String(props.data.LEAD_OWNER)
                                                                },

                                                            }));
                                                        }}
                                                    />
                                                </div>

                                                <div className='col-auto customPopupCheckbox'>
                                                    <label className="form-label">
                                                        Other User
                                                    </label>
                                                    <input
                                                        type="radio"
                                                        checked={state.TO.value === 'OTHER_USER'}
                                                        onClick={() => {
                                                            setState(prevState => ({
                                                                ...prevState,
                                                                ['TO']: {
                                                                    ...prevState['TO'],
                                                                    value: 'OTHER_USER'
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {state.TO.value === 'OTHER_USER' && <div className='col-auto'>
                                                <label className="form-label">
                                                    Users
                                                </label>
                                                <select
                                                    className={state.REMINDER_USER.error.length > 0 && !state.REMINDER_USER.value ? "form-control is-invalid" : "form-control"}
                                                    onChange={onChange}
                                                    name={state.REMINDER_USER.name}
                                                    value={state.REMINDER_USER.value}
                                                >
                                                    <option value={''}>
                                                        Select User
                                                    </option>
                                                    {state.REMINDER_USER.options && state.REMINDER_USER.options.map((item) => {
                                                        return (<option value={item.USER_ID}>
                                                            {item.FULL_NAME}
                                                        </option>)
                                                    })}
                                                </select>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-3 mt-2 mb-2">
                                    <div>
                                        {/* <label className="form-label">
                                            Reminder Note
                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                        </label> */}
                                        <input
                                            type="text"
                                            placeholder="Add Reminder Note Here *"
                                            className={state.REMINDER_NOTE.error.length > 0 && !state.REMINDER_NOTE.value ? "form-control is-invalid" : "form-control"}
                                            onChange={onChange}
                                            name={state.REMINDER_NOTE.name}
                                            value={state.REMINDER_NOTE.value}
                                        />
                                        <div className="invalid-feedback">Please enter</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 customdownPopRemark">
                            <div class="row">
                                <div className="col-lg-6 mb-0">
                                    <div className="customPopupCheckbox">
                                        <label className="form-label">
                                            Add Remark
                                        </label>
                                        <input
                                            type="radio"
                                            checked={state.ADD_REMARK.value}
                                            onClick={() => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    ['ADD_REMARK']: {
                                                        ...prevState['ADD_REMARK'],
                                                        value: !state.ADD_REMARK.value
                                                    }
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-0">
                                    <div className="customPopupCheckbox">
                                        <label className="form-label">
                                            Close All Old Reminder

                                        </label>
                                        <input
                                            type="radio"
                                            checked={state.CLOSE_OLD_REMINDER.value}
                                            onClick={() => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    ['CLOSE_OLD_REMINDER']: {
                                                        ...prevState['CLOSE_OLD_REMINDER'],
                                                        value: !state.CLOSE_OLD_REMINDER.value
                                                    }
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {state.ADD_REMARK.value && 
                            <React.Fragment>
                                <div class="col-md-12 CustomHoleBGPopup">
                                    <div className="col-lg-6 mb-0">
                                        {/* <label className="form-label">
                                            Remark Type
                                        </label> */}
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div className='customPopupCheckbox'>
                                                    <label className="form-label">
                                                        Feedback
                                                    </label>
                                                    <input
                                                        type="radio"
                                                        checked={state.REMARK_TYPE.value === 'FEEDBACK'}
                                                        onClick={() => {
                                                            setState(prevState => ({
                                                                ...prevState,
                                                                ['REMARK_TYPE']: {
                                                                    ...prevState['REMARK_TYPE'],
                                                                    value: 'FEEDBACK'
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div className='customPopupCheckbox'>
                                                    <label className="form-label">
                                                        General
                                                    </label>
                                                    <input
                                                        type="radio"
                                                        checked={state.REMARK_TYPE.value === 'GENERAL'}
                                                        onClick={() => {
                                                            setState(prevState => ({
                                                                ...prevState,
                                                                ['REMARK_TYPE']: {
                                                                    ...prevState['REMARK_TYPE'],
                                                                    value: 'GENERAL'
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-0 mt-2">
                                    <div>
                                        {/* <label className="form-label">
                                            Remark
                                        </label> */}
                                        <textarea
                                            className={state.REMARK.error.length > 0 && !state.REMARK.value ? "form-control is-invalid" : "form-control"}
                                            row='3'
                                            placeholder='Add Remark Here..'
                                            name={state.REMARK.name}
                                            value={state.REMARK.value}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                </div>
                                
                            </React.Fragment>
                        }
                        <div className="hstack gap-2 justify-content-end mt-4">
                            <button type="button" className="btn btn-light" onClick={() => { props.onClose() }}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-success" id="add-btn">
                                Save
                            </button>
                        </div>
                </form>
            </div>
        </div>
    )
}

export default ReminderForm