import React, { useEffect, useState } from 'react'
import ListComponent from '../../../components/SuperAdmin/offer_letter/list'
import { v1_offer_letters } from '../../../actions/v1/offer_letter'
const List = () => {
    const [list, setList] = useState(null)
    const [count, setCount] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchState, setSearchState] = useState({
        KEYWORD: { name: 'KEYWORD', value: '' },
    });

    useEffect(() => {
        v1_offer_letters({ page: currentPage, keyword: searchState.KEYWORD.value }).then((res) => {
            if (res.status) {
                setCount(res.result.count)
                setList(res.result.list)
                setCurrentPage(currentPage)
            }

        })

    }, [currentPage, searchState])
    return (
        <React.Fragment>
            <ListComponent
                currentPage={currentPage}
                list={list}
                count={count}
                state={searchState}
                setState={setSearchState}
            />
        </React.Fragment>
    )
}

export default List