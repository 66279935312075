import React, { Profiler, useEffect, useRef, useState } from 'react'
import EditMasterDetails from '../../../components/companyUser/profile/editCRMUserMasterDetailsComponent';
import EditWorkDetails from '../../../components/companyUser/profile/editCRMUserWorkDetailsComponent';
import EditBasicDetails from '../../../components/companyUser/profile/editCRMUserBasicDetailsComponent';
import EditStatutoryDetails from '../../../components/companyUser/profile/editCRMUserStatutoryDetails';
import { v3_my_profile, v3_edit_my_basic_details, v3_edit_my_statutory_details } from '../../../actions/v3/profile';

import { v3_edit_user_profile_image, } from '../../../actions/v3/administrator';
import { ToastContainer, toast } from 'react-toastify';
import ProfilePreview from '../../../components/companyUser/profile/ProfilePreview';

const Preview = (props) => {
    const [selectedTab, setSelectedTab] = useState('master')
    const [mode, setMode] = useState('edit');
    const [masterDetails, setMasterDetails] = useState(null);
    const [workDetails, setWorkDetails] = useState(null);
    const [baiscDetails, setBasicDetails] = useState(null);
    const [statutoryDetails, setStatutoryDetails] = useState(null);
    const [profileImage, setProfileImage] = useState(null);

    useEffect(() => {
        v3_my_profile().then((res) => {
            if (res.status) {
                setMasterDetails(res.result.master_detail);
                setWorkDetails(res.result.work_detail);
                setBasicDetails(res.result.basic_detail);
                setStatutoryDetails(res.result.statutory_detail);
                setProfileImage(res.result.master_detail.PROFILE_IMAGE)
            }
        })
    }, [])

    const onEditBasicDetail = (model) => {
        v3_edit_my_basic_details(model).then((res) => {
            if (res.status) {
                toast.success('Basic Details Saved Successfuly')
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const onEditStatutoryDetail = (model) => {
        v3_edit_my_statutory_details(model).then((res) => {
            if (res.status) {
                toast.success('Statutory Details Saved Successfuly')
            }
            else {
                toast.error(res.error)

            }
        })
    }

    const onUploadImage = (e) => {

        const formData = new FormData()
        formData.append('image', e.target.files[0])
        v3_edit_user_profile_image(formData).then((res) => {
            if (res.status) {

            }
        })
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="profile-foreground position-relative mx-n4 mt-n4 customProfileLoginBG">
                            <div className="profile-wid-bg">
                            </div>
                        </div>


                        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
                            <div className="row g-4">
                                <div className="col-auto">
                                    <div className="avatar-lg">
                                        <div className="avatar-container">
                                            <img
                                                src={profileImage ? `${process.env.REACT_APP_BASE_URL}/v3/profile-image/${masterDetails.USER_ID}/${profileImage}` : "/assets/images/users/user-dummy-img.jpg"}
                                                className="img-thumbnail rounded-circle"
                                            />
                                            <label htmlFor="profile_pic" className="ri-edit-2-line edit-icon customProfileEditIcon" ></label>
                                            <input
                                                type="file"
                                                id="profile_pic"
                                                style={{ display: 'none' }}
                                                accept="image/*" onChange={onUploadImage}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*end col*/}
                                <div className="col">
                                    <div className="pt-4">
                                        <h3 className="text-white mb-1">{masterDetails?.FULL_NAME}</h3>
                                        <h6 className="text-white text-opacity-75">{workDetails?.DESIGNATION_NAME}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <div className="d-flex profile-wrapper profileLoginNavButtons">
                                        <ul
                                            className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                            role="tablist"
                                        >
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className={`nav-link fs-14 ${selectedTab === 'master' ? 'active' : ''}`}
                                                    href="javascript:void(0)"
                                                    onClick={() => { setSelectedTab('master') }}
                                                >
                                                    <i className="ri-airplay-fill d-inline-block d-md-none" />
                                                    <span className="d-none d-md-inline-block">Master</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className={`nav-link fs-14 ${selectedTab === 'work' ? 'active' : ''}`}
                                                    href="javascript:void(0)"
                                                    onClick={() => { setSelectedTab('work') }}

                                                >
                                                    <i className="ri-list-unordered d-inline-block d-md-none" />{" "}
                                                    <span className="d-none d-md-inline-block">Work</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className={`nav-link fs-14 ${selectedTab === 'basic' ? 'active' : ''}`}

                                                    href="javascript:void(0)"
                                                    onClick={() => { setSelectedTab('basic') }}

                                                >
                                                    <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                                                    <span className="d-none d-md-inline-block">Basic</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className={`nav-link fs-14 ${selectedTab === 'statutory' ? 'active' : ''}`}

                                                    href="javascript:void(0)"
                                                    onClick={() => { setSelectedTab('statutory') }}

                                                >
                                                    <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                                                    <span className="d-none d-md-inline-block">Statutory</span>
                                                </a>
                                            </li>
                                            {/* <li className="nav-item" role="presentation">
                                                <a
                                                    className={`nav-link fs-14 ${selectedTab === 'document' ? 'active' : ''}`}

                                                    href="javascript:void(0)"
                                                    onClick={() => { setSelectedTab('document') }}

                                                >
                                                    <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
                                                    <span className="d-none d-md-inline-block">Documents</span>
                                                </a>
                                            </li> */}
                                        </ul>
                                        {mode === 'edit' && <div className="flex-shrink-0">
                                            <a
                                                href="javascript:void(0)"
                                                className="btn editProfileButton"
                                                onClick={() => { setMode('preview') }}
                                            >
                                                <i className="ri-edit-box-line align-bottom" /> Preview Profile
                                            </a>
                                        </div>}
                                        {mode === 'preview' && <div className="flex-shrink-0">
                                            <a
                                                href="javascript:void(0)"
                                                className="btn editProfileButton"
                                                onClick={() => { setMode('edit') }}
                                            >
                                                <i className="ri-edit-box-line align-bottom" /> Edit Profile
                                            </a>
                                        </div>}
                                    </div>
                                    {mode === 'preview' && <ProfilePreview />}
                                    {mode === 'edit' && selectedTab === 'master' && masterDetails && <EditMasterDetails
                                        data={masterDetails}
                                    />}
                                    {mode === 'edit' && selectedTab === 'work' && <EditWorkDetails
                                        data={workDetails}
                                    />}
                                    {mode === 'edit' && selectedTab === 'basic' && <EditBasicDetails
                                        data={baiscDetails}
                                        onSubmit={(model) => { onEditBasicDetail(model) }}
                                    />}
                                    {mode === 'edit' && selectedTab === 'statutory' && <EditStatutoryDetails
                                        data={statutoryDetails}
                                        onSubmit={(model) => { onEditStatutoryDetail(model) }}
                                    />}
                                    {/* {mode === 'edit' && selectedTab === 'document' && <EditStatutoryDetails
                                        data={statutoryDetails}
                                        onSubmit={(model) => { onEditStatutoryDetail(model) }}
                                    />} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}

export default Preview


// import React, { useEffect, useState } from 'react'
// import MasterDetails from '../../../components/companyUser/profile/masterDetails';
// import { v3_my_profile } from '../../../actions/v3/profile';

// const Preview = () => {
//     const [selectedTab, setSelectedTab] = useState('master')
//     const [mode, setMode] = useState('preview');
//     const [masterDetails, setMasterDetails] = useState(null);
//     useEffect(() => {
//         v3_my_profile().then((res) => {
//             if (res.status) {
//                 setMasterDetails(res.result.master_detail);
//             }
//         })
//     }, [])
//     return (
//         <React.Fragment>
//             <div className="main-content">
//                 <div className="page-content">
//                     <div className="container-fluid">
//                         <div className="profile-foreground position-relative mx-n4 mt-n4">
//                             <div className="profile-wid-bg">
//                                 {/* <img
//                                     src="assets/images/profile-bg.jpg"
//                                     alt=""
//                                     className="profile-wid-img"
//                                 /> */}
//                             </div>
//                         </div>
//                         <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
//                             <div className="row g-4">
//                                 <div className="col-auto">
//                                     <div className="avatar-lg">
//                                         <img
//                                             src="assets/images/users/avatar-1.jpg"
//                                             alt="user-img"
//                                             className="img-thumbnail rounded-circle"
//                                         />
//                                     </div>
//                                 </div>
//                                 {/*end col*/}
//                                 <div className="col">
//                                     <div className="p-2">
//                                         <h3 className="text-white mb-1">{masterDetails?.FULL_NAME}</h3>
//                                         <p className="text-white text-opacity-75">Owner &amp; Founder</p>
//                                         {/* <div className="hstack text-white-50 gap-1">
//                                             <div className="me-2">
//                                                 <i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle" />
//                                                 California, United States
//                                             </div>
//                                             <div>
//                                                 <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle" />
//                                                 Themesbrand
//                                             </div>
//                                         </div> */}
//                                     </div>
//                                 </div>
//                             </div>
//                             {/*end row*/}
//                         </div>
//                         <div className="row">
//                             <div className="col-lg-12">
//                                 <div>
//                                     <div className="d-flex profile-wrapper">
//                                         {/* Nav tabs */}
//                                         <ul
//                                             className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
//                                             role="tablist"
//                                         >
//                                             <li className="nav-item" role="presentation">
//                                                 <a
//                                                     className={`nav-link fs-14 ${selectedTab === 'master' ? 'active' : ''}`}
//                                                     href="javascript:void(0)"
//                                                     onClick={() => { setSelectedTab('master') }}
//                                                 >
//                                                     <i className="ri-airplay-fill d-inline-block d-md-none" />
//                                                     <span className="d-none d-md-inline-block">Master</span>
//                                                 </a>
//                                             </li>
//                                             <li className="nav-item" role="presentation">
//                                                 <a
//                                                     className={`nav-link fs-14 ${selectedTab === 'work' ? 'active' : ''}`}

//                                                     href="javascript:void(0)"
//                                                     onClick={() => { setSelectedTab('work') }}

//                                                 >
//                                                     <i className="ri-list-unordered d-inline-block d-md-none" />{" "}
//                                                     <span className="d-none d-md-inline-block">Work</span>
//                                                 </a>
//                                             </li>
//                                             <li className="nav-item" role="presentation">
//                                                 <a
//                                                     className={`nav-link fs-14 ${selectedTab === 'basic' ? 'active' : ''}`}

//                                                     href="javascript:void(0)"
//                                                     onClick={() => { setSelectedTab('basic') }}

//                                                 >
//                                                     <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
//                                                     <span className="d-none d-md-inline-block">Basic</span>
//                                                 </a>
//                                             </li>
//                                             <li className="nav-item" role="presentation">
//                                                 <a
//                                                     className={`nav-link fs-14 ${selectedTab === 'statutory' ? 'active' : ''}`}

//                                                     href="javascript:void(0)"
//                                                     onClick={() => { setSelectedTab('statutory') }}

//                                                 >
//                                                     <i className="ri-folder-4-line d-inline-block d-md-none" />{" "}
//                                                     <span className="d-none d-md-inline-block">Statutory</span>
//                                                 </a>
//                                             </li>
//                                         </ul>
//                                         <div className="flex-shrink-0">
//                                             <a
//                                                 href="pages-profile-settings.html"
//                                                 className="btn btn-success"
//                                             >
//                                                 <i className="ri-edit-box-line align-bottom" /> Edit Profile
//                                             </a>
//                                         </div>
//                                     </div>
//                                     {mode === 'preview' && selectedTab === 'master' && masterDetails && <MasterDetails
//                                         data={masterDetails}
//                                     />}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//             </div>

//         </React.Fragment>
//     )
// }

// export default Preview