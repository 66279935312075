import React, { useEffect, useState } from 'react'
import { validateFunctionalForm } from '../../../utils'
import { v1_city, v1_state } from '../../../actions/v1/master_data'

const AddUser = (props) => {

    const [imageUrl, setImageUrl] = useState(null);

    const [state, setState] = useState({
        COMPANY_NAME: { name: 'COMPANY_NAME', value: '', error: '', isRequired: true },
        USER_NAME: { name: 'USER_NAME', value: '', error: '', isRequired: true },
        ADMIN_NAME: { name: 'ADMIN_NAME', value: '', error: '', isRequired: true },
        ADMIN_DESIGNATION: { name: 'ADMIN_DESIGNATION', value: '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: true },
        MOBILE: { name: 'MOBILE', value: '', error: '', isRequired: true },
        GST_NUMBER: { name: 'GST_NUMBER', value: '', error: '', isRequired: false },
        ADDRESS: { name: 'ADDRESS', value: '', error: '', isRequired: true },
        COUNTRY_ID: { name: 'COUNTRY_ID', value: '102', error: '', isRequired: true },
        STATE_ID: { name: 'STATE_ID', value: '', error: '', isRequired: true },
        CITY_ID: { name: 'CITY_ID', value: '', error: '', isRequired: true },
        STATUS: { name: 'STATUS', value: true, error: '', isRequired: true },
        LOGO: { name: 'LOGO', value: '', error: '', isRequired: false },


    })
    const clearForm = () => {
        setState({
            COMPANY_NAME: { name: 'COMPANY_NAME', value: '', error: '', isRequired: true },
            ADMIN_NAME: { name: 'ADMIN_NAME', value: '', error: '', isRequired: true },
            ADMIN_DESIGNATION: { name: 'ADMIN_DESIGNATION', value: '', error: '', isRequired: true },
            EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: true },
            MOBILE: { name: 'MOBILE', value: '', error: '', isRequired: true },
            GST_NUMBER: { name: 'GST_NUMBER', value: '', error: '', isRequired: true },
            ADDRESS: { name: 'ADDRESS', value: '', error: '', isRequired: true },
            STATE_ID: { name: 'STATE_ID', value: '', options: [], error: '', isRequired: true },
            CITY_ID: { name: 'CITY_ID', value: '', options: [], error: '', isRequired: true },
            STATUS: { name: 'STATUS', value: true, error: '', isRequired: true },
            LOGO: { name: 'LOGO', value: '', error: '', isRequired: false },

        });
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setState(prevState => ({
                ...prevState,
                ['LOGO']: {
                    ...prevState['LOGO'],
                    value: file
                }
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                // Update the state with the data URL
                setImageUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }


    const onSubmit = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                COMPANY_NAME: state.COMPANY_NAME.value,
                USER_NAME: state.USER_NAME.value,
                ADMIN_NAME: state.ADMIN_NAME.value,
                ADMIN_DESIGNATION: state.ADMIN_DESIGNATION.value,
                EMAIL: state.EMAIL.value,
                MOBILE: state.MOBILE.value,
                GST_NUMBER: state.GST_NUMBER.value,
                ADDRESS: state.ADDRESS.value,
                COUNTRY_ID: state.COUNTRY_ID.value,
                STATE_ID: state.STATE_ID.value,
                CITY_ID: state.CITY_ID.value,
                STATUS: state.STATUS.value ? 1 : 0,
                LOGO: state.LOGO.value,
            }
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }
            props.onSubmit(formData)
        }
    }

    // useEffect(() => {
    //     v1_country().then(res => {
    //         if (res.status) {
    //             setOptions('COUNTRY_ID', res.result)
    //         }
    //     })
    // }, [])
    useEffect(() => {
        v1_state('102').then(res => {
            if (res.status) {
                setOptions('STATE_ID', res.result)
            }
        })
    }, [])
    useEffect(() => {
        if (state.STATE_ID.value) {
            v1_city(state.STATE_ID.value).then(res => {
                if (res.status) {
                    setOptions('CITY_ID', res.result)
                }
            })
        }
    }, [state.STATE_ID.value])
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Add Client</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Client Management</a>
                                            </li>
                                            <li className="breadcrumb-item active">Add Client</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">CRM CLIENT</h4>

                                    </div>
                                    <div className="card-body">
                                        <div className="live-preview">
                                            <div className="row gy-4">
                                                <div className="col-xxl-3 col-md-6">

                                                    <div className="flex-shink-0">
                                                        <img
                                                            src={imageUrl ? imageUrl : "/assets/images/companies/default-logo.png"}
                                                            alt=""
                                                            className="avatar-lg rounded"
                                                        />
                                                        <label htmlFor="profile_pic" className="ri-upload-2-line edit-icon" >&nbsp; Click here to Upload</label>
                                                        <input
                                                            type="file"
                                                            id="profile_pic"
                                                            accept="image/*"
                                                            onChange={handleFileChange}
                                                            style={{ display: "none" }}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Company Name
                                                            <span className='text-danger'> *</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Company Name'
                                                            name={state.COMPANY_NAME.name}
                                                            value={state.COMPANY_NAME.value}
                                                            onChange={onChange}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Admin Name
                                                            <span className='text-danger'> *</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Admin Name'
                                                            name={state.ADMIN_NAME.name}
                                                            value={state.ADMIN_NAME.value}
                                                            onChange={onChange}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Admin Designation
                                                            <span className='text-danger'> *</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Admin Designation'
                                                            name={state.ADMIN_DESIGNATION.name}
                                                            value={state.ADMIN_DESIGNATION.value}
                                                            onChange={onChange}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div >
                                                        <label className="form-label">
                                                            Email
                                                            <span className='text-danger'> *</span>

                                                        </label>
                                                        <div className='form-icon'>
                                                            <input
                                                                type="email"
                                                                placeholder="Email"
                                                                className="form-control form-control-icon"
                                                                name={state.EMAIL.name}
                                                                value={state.EMAIL.value}
                                                                onChange={onChange}
                                                            />
                                                            <i className='ri-mail-unread-line' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Mobile no.
                                                            <span className='text-danger'> *</span>

                                                        </label>
                                                        <div className='form-icon'>
                                                            <input
                                                                type="phone"
                                                                className="form-control form-control-icon"
                                                                placeholder="Mobile no."
                                                                name={state.MOBILE.name}
                                                                value={state.MOBILE.value}
                                                                onChange={onChange}
                                                            />
                                                            <i className='ri-phone-line' />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Username
                                                            <span className='text-danger'> *</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Username"
                                                            name={state.USER_NAME.name}
                                                            value={state.USER_NAME.value}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            GST Number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="GST Number"
                                                            name={state.GST_NUMBER.name}
                                                            value={state.GST_NUMBER.value}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Address
                                                            <span className='text-danger'> *</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Address"
                                                            name={state.ADDRESS.name}
                                                            value={state.ADDRESS.value}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>

                                                {/* <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Country
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            name={state.COUNTRY_ID.name}
                                                            value={state.COUNTRY_ID.value}
                                                            onChange={onChange}
                                                        >
                                                            <option value={''}>Select Country</option>
                                                            {state.COUNTRY_ID.options && state.COUNTRY_ID.options.map((item) => {
                                                                return (
                                                                    <option value={item.COUNTRY_ID}>{item.COUNTRY}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            State
                                                            <span className='text-danger'> *</span>

                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            name={state.STATE_ID.name}
                                                            value={state.STATE_ID.value}
                                                            onChange={onChange}
                                                        >
                                                            <option value={''}>Select State</option>
                                                            {state.STATE_ID.options && state.STATE_ID.options.map((item) => {
                                                                return (
                                                                    <option value={item.STATE_ID}>{item.STATE}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            City
                                                            <span className='text-danger'> *</span>

                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            name={state.CITY_ID.name}
                                                            value={state.CITY_ID.value}
                                                            onChange={onChange}
                                                        >
                                                            <option value={''}>Select Country</option>
                                                            {state.CITY_ID.options && state.CITY_ID.options.map((item) => {
                                                                return (
                                                                    <option value={item.CITY_ID}>{item.CITY}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div className="form-check form-switch form-switch-right form-switch-md mt-4">
                                                        <label htmlFor="form-grid-showcode" className="form-label text-muted">
                                                            &nbsp;
                                                        </label>
                                                        <label htmlFor="form-grid-showcode" className="form-label text-muted">
                                                            Status
                                                            <span className='text-danger'> *</span>

                                                        </label>
                                                        <input
                                                            className="form-check-input code-switcher"
                                                            type="checkbox"
                                                            checked={state.STATUS.value}
                                                            onClick={() => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    ['STATUS']: {
                                                                        ...prevState['STATUS'],
                                                                        value: !state.STATUS.value
                                                                    }
                                                                }));
                                                            }}
                                                            id="form-grid-showcode"
                                                        />
                                                    </div>

                                                </div>


                                                <div className="col-xxl-12 col-md-12">
                                                    <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">Add New</button>
                                                    <button type="button" onClick={() => { clearForm() }} class="btn btn-danger waves-effect waves-light mx-3">Clear Form</button>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>

            </div>

        </React.Fragment>
    )
}

export default AddUser