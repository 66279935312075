import axios from 'axios'
import { getCompanyUserAuthHeader } from '../../utils';

export const v3_user_roles = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/roles`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_add_user = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_edit_user_master_details = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users/master-details`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_edit_user_work_details = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users/work-details`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v3_edit_user_basic_details = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users/basic-details`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v3_edit_user_statutory_details = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users/statutory-details`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v3_edit_user_profile_image = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users/profile-image`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_user_list = async (page, user_name) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users/${page}?USER_NAME=${user_name}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_user_team_mapping_list = async (USER_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users/team-mapping/${USER_ID}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_user_team_mapping_update = async (model, USER_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users/team-map/${USER_ID}`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_user_status_update = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/users/status`;
    return axios.patch(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v3_add_client = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/client`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_edit_client = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/client`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_search_client = async (query) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/search-client?query=${query}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v3_crm_user_profile_details = async (USER_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/crm-user/${USER_ID}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v3_work_location_options = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/work-location-options`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_designation_options = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/designations-options`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_admin_count_stats = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/admin/count-stats`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v3_client_details = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/client/${id}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_admin_user_hierarchy = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/admin/users-hierarchy`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_org_chart_performance = async (id, from, to) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/org-chart-performance/${id}/${from}/${to}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_administrator_dashboard_job_status_wise_candidates = async (STATUS_ID, PAGE, QUERY) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/admin-dashboard/job-status-wise/candidates/${STATUS_ID}/${PAGE}/${QUERY}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_team_leader_options = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/team-leader-options`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v3_crm_user_option_based_on_role = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/crm-user-options-based-on-role`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_admin_dashbaord_today_candidate_reminder = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/admin-dashboard/today-candidate-reminder`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}






export const v3_admin_dashbaord_billing_cycle_candidates = async (PAGE) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/admin-dashboard/billing-cycle/candidates/${PAGE}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_admin_dashboard_application_statistics = async (DATE_RANGE) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/admin-dashboard/application-statistics/${DATE_RANGE}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
