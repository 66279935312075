import React from 'react'
import Parser from 'html-react-parser';

const SelectPackages = (props) => {
    const { packages, SelectPackage, selectedPackage } = props;
    console.log(props)
    return (
        <React.Fragment>
            <div className="row justify-content-center mt-5">
                <div className="col-lg-4">
                    <div className="text-center mb-4 pb-2">
                        <h4 className="fs-22">Pricing Plan</h4>
                        <p className="text-muted mb-4 fs-15">
                            Simple pricing. No hidden fees. Advanced features for you business.
                        </p>
                    </div>
                </div>
                {/*end col*/}
            </div>
            <div className="row">
                {packages && packages.map((item) => {
                    return (
                        <div className="col-lg-6">
                            <div className="card pricing-box ribbon-box ribbon-fill text-center">
                                {item.POPULAR ? <div className="ribbon ribbon-primary">Popular</div> : ''}
                                <div className="row g-0">
                                    <div className="col-lg-6">
                                        <div className="card-body h-100">
                                            <div>
                                                <h5 className="mb-1">{item.PACKAGE_NAME}</h5>
                                                <p className="text-muted">Professional plans</p>
                                            </div>
                                            <div className="py-4">
                                                <h2>
                                                    <sup>
                                                        <small>₹</small>
                                                    </sup>
                                                    {item.PRICE} {item.PACKAGE_ID !== 3 ? <span className="fs-13 text-muted">/ {item.VALIDITY_PERIOD_MONTH} Months</span> : ''}
                                                </h2>
                                            </div>
                                            <div className="text-center plan-btn mt-2">
                                                <a
                                                    href="javascript:void(0);"
                                                    onClick={() => {
                                                        SelectPackage(item)
                                                    }}
                                                    className="btn btn-success w-sm waves-effect waves-light"
                                                >
                                                    {selectedPackage && selectedPackage.PACKAGE_ID == item.PACKAGE_ID ? 'Choosed Plan' : 'Choose Plan'}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card-body border-start mt-4 mt-lg-0">
                                            <div className="card-header bg-light">
                                                <h5 className="fs-15 mb-0">Plan Features:</h5>
                                            </div>
                                            <div className="card-body pb-0">
                                                {Parser(item.PACKAGE_DETAIL)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                })}
            </div>

        </React.Fragment>
    )
}

export default SelectPackages