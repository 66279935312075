import React from 'react'
import AddCandidate from '../../../components/companyUser/candidates/add'
import { ToastContainer, toast } from 'react-toastify';
import { v3_add_candidate } from '../../../actions/v3/candidates';
import constant from '../../../constant';

const Add = (props) => {
    
    const onSubmit = (model) => {
        v3_add_candidate(model).then((res) => {
            if (res.status) {
                toast.success('Candidate Lead Added Successfully')
                props.history.push(constant.companyUserComponent.candidateProfilePreviewAndApplyJob.url.replace(':id', res.result).replace(':user_name', model.FULL_NAME))
            }
            else {
                toast.error(res.error)

            }
        })
    }
    return (
        <React.Fragment>
            <ToastContainer />
            <AddCandidate
                onSubmit={onSubmit}
            />
        </React.Fragment>
    )
}

export default Add