import React from 'react'

const RequestDemoResponse = () => {
  return (
    <div className="auth-page-wrapper pt-5">
  {/* auth page bg */}
  <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
    <div className="bg-overlay" />
    <div className="shape">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1440 120"
      >
        <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
      </svg>
    </div>
    <canvas
      className="particles-js-canvas-el"
      width={1898}
      height={475}
      style={{ width: "100%", height: "100%" }}
    />
  </div>
  {/* auth page content */}
  <div className="auth-page-content">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center mt-sm-5 pt-4 mb-4">
            <div className="mb-sm-5 pb-sm-4 pb-5">
              <img
                src="assets/images/comingsoon.png"
                alt=""
                height={120}
                className="move-animation"
              />
            </div>
            <div className="mb-5">
              <h1 className="display-2 coming-soon-text">Thanks for Request a demo</h1>
            </div>
            <div>
              <div className="mt-5">
                <h4>Our Representative will contact you soon</h4>
                {/* <p className="text-muted">
                  Don't worry we will not spam you 😊
                </p> */}
              </div>
             
            </div>
          </div>
        </div>
      </div>
      {/* end row */}
    </div>
    {/* end container */}
  </div>
  {/* end auth page content */}
  {/* footer */}
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <p className="mb-0 text-muted">
              © 2024 Velzon. Crafted with{" "}
              <i className="mdi mdi-heart text-danger" /> by Themesbrand
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/* end Footer */}
</div>

  )
}

export default RequestDemoResponse