import React, { useEffect, useState } from 'react'
import LeadProfileComponent from '../../../components/companyUser/candidates/LeadProfileComponent'
import { v3_candidate_details } from '../../../actions/v3/candidates'
import JobSelectAndPreviewComponent from '../../../components/companyUser/candidates/jobSelectAndPreviewComponent'
import constant from '../../../constant'

const ProfilePreviewAndJobApply = (props) => {
    const [details, setDetails] = useState(null)
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
      v3_candidate_details(props.match.params.id, props.match.params.user_name).then((res) => {
            if (res.status) {
                setDetails(res.result)
            }
        })
    }, [refresh])
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Candidate Lead Profile</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Candidate Management</a>
                                            </li>
                                            <li className="breadcrumb-item active">Add Candidate</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {details && <LeadProfileComponent
                            leadDetails={details}
                            setRefresh={setRefresh}
                            refresh={refresh}
                        />}
                        
                        {/* {details && <JobSelectAndPreviewComponent
                        />} */}


                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default ProfilePreviewAndJobApply