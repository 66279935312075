import React, { useEffect, useState } from 'react'
import { v3_non_posted_jobs } from '../../../actions/v3/jobs'
import NonPosted from '../../../components/companyUser/jobs/jobList/non_posted'

const JobList = (props) => {

  const [jobList, setJobList] = useState(null)
  useEffect(() => {
    v3_non_posted_jobs().then((res) => {
      if (res.status) {
        setJobList(res.result)
      }
    })
  }, [])

  return (
    <React.Fragment>
      <NonPosted
        jobList={jobList}
      />
    </React.Fragment>
  )
}

export default JobList