import React, { useEffect, useState } from 'react'
import List from '../../../../components/companyUser/statusWiseCandidateList/administrator/list'
import { v3_team_dashboard_job_status_wise_candidates } from '../../../../actions/v3/team'
import { v3_administrator_dashboard_job_status_wise_candidates } from '../../../../actions/v3/administrator'
const Interview = () => {
    const [list, setList] = useState(null)
    const [count, setCount] = useState(null)
    const [page, setPage] = useState(1)
    const [searchQuery, setSearchQuery] = useState({
        LEAD_OWNER: '',
        FROM_DATE: '',
        TO_DATE: '',
    })

    useEffect(() => {
        v3_administrator_dashboard_job_status_wise_candidates('5', page, btoa(JSON.stringify(searchQuery))).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
            }
        })
    }, [page])

    useEffect(() => {
        v3_administrator_dashboard_job_status_wise_candidates('5', 1, btoa(JSON.stringify(searchQuery))).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
            }
        })
    }, [searchQuery])

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Rejected Candidates</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Dashboard</a>
                                            </li>
                                            <li className="breadcrumb-item active">Rejected Candidates</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {list && <List
                            list={list}
                            count={count}
                            currentPage={page}
                            setCurrentPage={(page) => { setPage(page) }}
                            setSearchQuery={setSearchQuery}
                        />}
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Interview