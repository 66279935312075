
import axios from 'axios'
import { getCompanyUserAuthHeader } from '../../utils';

export const v3_my_profile = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/my-profile`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_edit_my_basic_details = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/my-basic-details`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v3_edit_my_statutory_details = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/my-statutory-details`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v3_edit_my_profile_image = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/my-profile-image`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}