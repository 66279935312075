import React, { useEffect, useState } from "react";
import DrawerModal from "../../../components/common/htmlDrawerModal";
import CanididateDetailDrawerModal from "./canididateDetailDrawerModal";
import Pagination from "../../common/Pagination";
import Modal from "../../common/htmlModal";
import Edit from "./editModal";
import constant from "../../../constant";
import { v3_add_candidate_reminder, v3_edit_candidate, v3_lead_owners, v3_role_wise_lead_owners } from "../../../actions/v3/candidates";
import { ToastContainer, toast } from "react-toastify";
import { v1_application_status_options, v1_candidate_status_options } from "../../../actions/v1/master_data";
import ReminderForm from "./reminderForm";
import moment from "moment";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const List = (props) => {
  const { list, count, currentPage, todayCount } = props;

  const [showCandidateDetailDrawerModal, setShowCandidateDetailDrawerModal] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [showEditModal, setShowEditModal] = useState(null);
  const [showAddReminderModal, setShowAddRemoinderModal] = useState(null);
  const [showSearchOptions, setShowSearchOptions] = useState(false);



  const [searchState, setSearchState] = useState({
    CANDIDATE_NAME: {
      name: "CANDIDATE_NAME",
      value: "",
      error: "",
      isRequired: false,
    },
    EMAIL: {
      name: "EMAIL",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    PHONE: {
      name: "PHONE",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    SKILLS: {
      name: "SKILLS",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    CURRENT_CTC_MIN: {
      name: "CURRENT_CTC_MIN",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    CURRENT_CTC_MAX: {
      name: "CURRENT_CTC_MAX",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    NOTICE_PERIOD: {
      name: "NOTICE_PERIOD",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    EXPERIENCE_MIN: {
      name: "EXPERIENCE_MIN",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    EXPERIENCE_MAX: {
      name: "EXPERIENCE_MAX",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    AVERAGE_RATING: {
      name: "AVERAGE_RATING",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    SOURCE: {
      name: "SOURCE",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    LEAD_OWNER: {
      name: "LEAD_OWNER",
      value: "",
      options: [],

      visible: false,
      error: "",
      isRequired: false,
    },
    CANDIDATE_STATUS: {
      name: "CANDIDATE_STATUS",
      value: "",
      options: [],

      visible: false,
      error: "",
      isRequired: false,
    },
    APPLICATION_STATUS: {
      name: "APPLICATION_STATUS",
      value: "",
      options: [],

      visible: false,
      error: "",
      isRequired: false,
    },
    FROM_DATE: {
      name: "FROM_DATE",
      value: '',
      options: "",

      visible: false,
      error: "",
      isRequired: false,
    },
    TO_DATE: {
      name: "TO_DATE",
      value: '',
      options: "",

      visible: false,
      error: "",
      isRequired: false,
    },
  });
  const setOptions = (name, options) => {
    setSearchState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        options: options
      }
    }));
  }

  const onDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(list.map((item) => {
      const model = {
        'Candidate ID ': 'RJZSHCL' + item.CANDIDATE_ID,
        'Email': item.EMAIL,
        'Mobile': item.PHONE,
        'Skills': item.SKILLS,
        'Current Company': item.CURRENT_COMPANY ? item.CURRENT_COMPANY : 'Not Available',
        'Current Designation': item.CURRENT_DESIGNATION ? item.CURRENT_DESIGNATION : 'Not Available',
        'Notice Period': item.NOTICE_PERIOD ? item.NOTICE_PERIOD : 'Not Available',
        'Notice Period': item.PREFERRED_ROLE_NAME ? item.PREFERRED_ROLE_NAME : 'Not Available',
        'Current Status': item.STATUS_NAME,
        'Lead Owner': item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available',
        'Added By': item.CREATED_BY_NAME ? item.CREATED_BY_NAME + ' at ' + moment(item.CREATED_ON).fromNow() : 'Not Available'
      }
      return model
    }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'candidate_lead.xlsx');
  }

  useEffect(() => {
    v3_role_wise_lead_owners().then((res) => {
      if (res.status) {
        setOptions('LEAD_OWNER', res.result)
      }
    })
    v1_candidate_status_options().then((res) => {
      if (res.status) {
        setOptions('CANDIDATE_STATUS', res.result)
      }
    })
    v1_application_status_options().then((res) => {
      if (res.status) {
        setOptions('APPLICATION_STATUS', res.result)
      }
    })

  }, [])

  const onReset = () => {
    setSearchState({
      CANDIDATE_NAME: {
        name: "CANDIDATE_NAME",
        value: "",
        error: "",
        isRequired: false,
      },
      EMAIL: {
        name: "EMAIL",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      PHONE: {
        name: "PHONE",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      SKILLS: {
        name: "SKILLS",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      CURRENT_CTC_MIN: {
        name: "CURRENT_CTC_MIN",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      CURRENT_CTC_MAX: {
        name: "CURRENT_CTC_MAX",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      NOTICE_PERIOD: {
        name: "NOTICE_PERIOD",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      EXPERIENCE_MIN: {
        name: "EXPERIENCE_MIN",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      EXPERIENCE_MAX: {
        name: "EXPERIENCE_MAX",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      AVERAGE_RATING: {
        name: "AVERAGE_RATING",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      SOURCE: {
        name: "SOURCE",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
    });
    const model = {
      CANDIDATE_NAME: "",
      EMAIL: "",
      PHONE: "",
      SKILLS: "",
      CURRENT_CTC_MIN: "",
      CURRENT_CTC_MAX: "",
      NOTICE_PERIOD: "",
      EXPERIENCE_MIN: "",
      EXPERIENCE_MAX: "",
      AVERAGE_RATING: "",
      SOURCE: "",
    };

    props.setSearchQuery(model);
  };
  const onSearch = () => {
    const model = {
      CANDIDATE_NAME: searchState.CANDIDATE_NAME.value,
      EMAIL: searchState.EMAIL.value,
      PHONE: searchState.PHONE.value,
      SKILLS: searchState.SKILLS.value,
      CURRENT_CTC_MIN: searchState.CURRENT_CTC_MIN.value,
      CURRENT_CTC_MAX: searchState.CURRENT_CTC_MAX.value,
      NOTICE_PERIOD: searchState.NOTICE_PERIOD.value,
      EXPERIENCE_MIN: searchState.EXPERIENCE_MIN.value,
      EXPERIENCE_MAX: searchState.EXPERIENCE_MAX.value,
      AVERAGE_RATING: searchState.AVERAGE_RATING.value,
      SOURCE: searchState.SOURCE.value,
      LEAD_OWNER: searchState.LEAD_OWNER.value,
      FROM_DATE: searchState.FROM_DATE.value,
      TO_DATE: searchState.TO_DATE.value,
      CANDIDATE_STATUS: searchState.CANDIDATE_STATUS.value,
      APPLICATION_STATUS: searchState.APPLICATION_STATUS.value,

    };

    props.setSearchQuery(model);
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
      },
    }));
  };

  const onEdit = (model) => {
    v3_edit_candidate(model).then((res) => {
      if (res.status) {
        setShowEditModal(false)
        toast.success('Candidate Lead Saved')
        props.setRefresh(!props.refresh)
      }
      else {
        toast.error(res.error)
      }
    })
  }
  const onAddRemninder = (model) => {
    v3_add_candidate_reminder(model).then((res) => {
      if (res.status) {
        setShowAddRemoinderModal(null)
        toast.success('Reminder Added Successfully')


      }

    })
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <DrawerModal
              isOpen={showCandidateDetailDrawerModal}
              onClose={() => {
                setShowCandidateDetailDrawerModal(false);
              }}
            >
              {candidateDetails && (
                <CanididateDetailDrawerModal
                  showEditOption={true}
                  candidateDetails={candidateDetails}
                />
              )}
            </DrawerModal>
            <Modal isOpen={showEditModal} header={'Edit Candidate Lead Details'}
            onClose={() => { setShowEditModal(null) }}
            >
              <Edit
                data={showEditModal}
                onClose={() => { setShowEditModal(null) }}
                onSubmit={(model) => { onEdit(model) }}
              />
            </Modal>
            <Modal isOpen={showAddReminderModal} header={'Add Reminder'}>
              <ReminderForm
                onSubmit={(model) => (onAddRemninder(model))}
                onClose={() => setShowAddRemoinderModal(null)}
                data={showAddReminderModal}
              />
            </Modal>
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">WALK IN CANDIDATES</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Candidate Management
                        </a>
                      </li>
                      <li className="breadcrumb-item active">Walk-in(Qr)
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card" id="leadsList">
                  <div className="card-header border-0">
                    <div className="row g-4 align-items-center">
                      <div className="col-sm-3">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search for..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      <div className="col-sm-auto ms-auto">
                        <div className="hstack gap-2">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-toggle="offcanvas"
                            href="#offcanvasExample"
                          >
                            <i className="ri-filter-3-line align-bottom me-1" />{" "}
                            Fliters
                          </button>
                          <button
                            className="btn btn-soft-info fs-14"
                            type="button"
                            onClick={() => onDownloadExcel()}

                          >
                            <i className="mdi mdi-file-excel"
                            />
                            Download Excel
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div>
                      <div className="table-responsive table-card">
                        <table className="table align-middle" id="customerTable">
                          <thead className="table-light">
                            <tr>
                              <th className="sort" data-sort="name">
                                S.No
                              </th>
                              <th className="sort" data-sort="name">
                                Name
                              </th>
                              <th classNamfe="sort" data-sort="company_name">
                                Contact info.
                              </th>
                              <th className="sort" data-sort="leads_score">
                                Leads Owner
                              </th>
                              <th className="sort" data-sort="date">
                                Create Date
                              </th>
                              <th className="sort" data-sort="action">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">

                            {list && list.map((item, index) => {
                              debugger
                              return (
                                <tr>
                                  <td>
                                    {20 * currentPage + (index + 1) - 20}
                                  </td>
                                  <td>

                                    {item.FULL_NAME} {item.REPEATED ? <span className="text-warning">(Repeated)</span> : ''}

                                  </td>
                                  <td className="contact_info">
                                    <tr>
                                      <td className="phone">{item.PHONE}</td>
                                    </tr>

                                    <tr>

                                      <td className="email">{item.EMAIL}</td>
                                    </tr>

                                  </td>
                                  <td className="lead-owner">{item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available'}</td>

                                  <td className="date">{moment(item.WALK_IN_DATE).format('DD MMM, YYYY')}</td>
                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      <li
                                        className="list-inline-item edit"

                                      >
                                        <a href="javascript:void(0)" onClick={() => {
                                          setShowEditModal(item)
                                        }}
                                          className="text-muted"
                                        >
                                          <i class="ri-edit-line"></i>
                                        </a>
                                      </li>
                                      <li
                                        className="list-inline-item edit"

                                      >
                                        <a
                                          className="text-muted"

                                          onClick={() => {
                                            setCandidateDetails(item);
                                            setShowCandidateDetailDrawerModal(true);
                                          }}
                                        >
                                          <i class="ri-eye-fill"></i>
                                        </a>
                                      </li>
                                      <li
                                        className="list-inline-item edit"

                                      >
                                        <a href={`tel:${item.PHONE}`}
                                          className="text-muted"
                                        >
                                          <i class="ri-phone-fill"></i>
                                        </a>
                                      </li>
                                      <li
                                        className="list-inline-item edit"

                                      >
                                        <a href={
                                          constant.companyUserComponent.candidateProfilePreviewAndApplyJob.url.replace(':id', item.CANDIDATE_ID).replace(':user_name', item.FULL_NAME)
                                        }
                                          className="text-muted"

                                        >
                                          <i class="ri-home-gear-fill"></i>
                                        </a>
                                      </li>
                                      <li
                                        className="list-inline-item edit"
                                      >
                                        <a
                                          className="text-muted"
                                          href="javascript:void(0)" onClick={() => { setShowAddRemoinderModal(item) }}>
                                          <i class="mdi mdi-reminder" />
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              )
                            })
                            }
                          </tbody>
                        </table>
                        <h5>Today's Uploaded Count : {todayCount}</h5>
                        {count === 0 && <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: 75, height: 75 }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">
                              We've searched more than 150+ leads We did not find any
                              leads for you search.
                            </p>
                          </div>
                        </div>}
                      </div>
                      {count > 0 &&
                        <div className="customPaginationStyle">
                          < Pagination
                            limit={20}
                            totalCount={count}
                            range={5}
                            onPageChange={(page) => {
                              props.setCurrentPage(page)
                            }}
                            currentPage={currentPage}
                          />
                        </div>

                      }
                    </div>


                    <div
                      className="offcanvas offcanvas-end"
                      tabIndex={-1}
                      id="offcanvasExample"
                      aria-labelledby="offcanvasExampleLabel"
                    >
                      <div className="offcanvas-header bg-light">
                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                          Leads Filters
                        </h5>
                        <button
                          type="button"
                          className="btn-close text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        />
                      </div>
                      {/*end offcanvas-header*/}
                      <form
                        action="#"
                        className="d-flex flex-column justify-content-end h-100"
                      >
                        <div className="offcanvas-body">
                          <React.Fragment>
                            <div className="row g-4 align-items-center">
                              <div className="mb-2">
                                <div className="search-box">
                                  <input
                                    type="text"
                                    className="form-control search customRadiusSearch"
                                    placeholder="Candidate Name"
                                    id='candidateName'
                                    name={searchState.CANDIDATE_NAME.name}
                                    value={searchState.CANDIDATE_NAME.value}
                                    onChange={onChange}
                                  />
                                  {/* <label htmlFor="candidateName"> Candidate Name</label> */}
                                  <i className="ri-search-line search-icon" />
                                </div>
                              </div>

                              <div className="mb-2">
                                <div className="search-box">
                                  <input
                                    type="text"
                                    className="form-control search customRadiusSearch"
                                    placeholder="Email"
                                    name={searchState.EMAIL.name}
                                    value={searchState.EMAIL.value}
                                    onChange={onChange}
                                  />
                                  <i className="ri-mail-line search-icon" />
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="search-box">
                                  <input
                                    type="number"
                                    min={5}
                                    className="form-control search customRadiusSearch"
                                    placeholder="Phone"
                                    name={searchState.PHONE.name}
                                    value={searchState.PHONE.value}
                                    onChange={onChange}
                                  />
                                  <i className="ri-phone-line search-icon" />
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="">
                                  <input
                                    type="text"
                                    className="form-control customRadiusSearch"
                                    placeholder="Skills"
                                    name={searchState.SKILLS.name}
                                    value={searchState.SKILLS.value}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <input
                                      type="number"
                                      className="form-control customRadiusSearch"
                                      placeholder="CTC Min"
                                      name={searchState.CURRENT_CTC_MIN.name}
                                      value={searchState.CURRENT_CTC_MIN.value}
                                      onChange={onChange}
                                    />
                                  </div>

                                  <div className="col-sm-6">

                                    {/* <i className="ri-skills-line search-icon" /> */}
                                    <input
                                      type="number"
                                      className="form-control customRadiusSearch"
                                      placeholder="CTC Max"
                                      name={searchState.CURRENT_CTC_MAX.name}
                                      value={searchState.CURRENT_CTC_MAX.value}
                                      onChange={onChange}
                                    />
                                    {/* <i className="ri-skills-line search-icon" /> */}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2">
                                <select
                                  className="form-select customRadiusSearch"
                                  name={searchState.NOTICE_PERIOD.name}
                                  value={searchState.NOTICE_PERIOD.value}
                                  onChange={onChange}
                                >
                                  <option value=""> Notice Period</option>
                                  <option value="Immediate Joiner">
                                    {" "}
                                    Immediate Joiner
                                  </option>
                                  <option value="15 Days"> 15 Days</option>
                                  <option value="1 Month"> 1 Month</option>
                                  <option value="2 Month"> 2 Month</option>
                                  <option value="3 Month"> 3 Month or more</option>
                                </select>
                              </div>
                              <div className="mb-2">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <input
                                      type="number"
                                      className="form-control customRadiusSearch"
                                      placeholder="Experiece Min"
                                      name={searchState.EXPERIENCE_MIN.name}
                                      value={searchState.EXPERIENCE_MIN.value}
                                      onChange={onChange}
                                    />
                                  </div>

                                  <div className="col-sm-6">

                                    {/* <i className="ri-skills-line search-icon" /> */}
                                    <input
                                      type="number"
                                      className="form-control customRadiusSearch"
                                      placeholder="Experiece Max"
                                      name={searchState.EXPERIENCE_MAX.name}
                                      value={searchState.EXPERIENCE_MAX.value}
                                      onChange={onChange}
                                    />
                                    {/* <i className="ri-skills-line search-icon" /> */}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2">
                                <select
                                  className="form-select customRadiusSearch"
                                  name={searchState.SOURCE.name}
                                  value={searchState.SOURCE.value}
                                  onChange={onChange}
                                >
                                  <option value=''> Select Lead Source</option>
                                  <option value='Found It / Monster'> Found It / Monster</option>
                                  <option value='Naukri'> Naukri</option>
                                  <option value='LinkedIn'> LinkedIn</option>
                                  <option value='Instagram'> Instagram</option>
                                  <option value='apna'> apna</option>
                                  <option value='Campus'> Campus</option>
                                  <option value='Other Portal'> Other Portal</option>
                                  <option value='Referral'> Referral</option>
                                  <option value='Social Media'> Social Media</option>
                                  <option value='Walk-In'> Walk-In</option>
                                </select>
                              </div>

                              <div className="mb-2">
                                <input
                                  className="form-control customRadiusSearch"
                                  type="number"
                                  name={searchState.AVERAGE_RATING.name}
                                  value={searchState.AVERAGE_RATING.value}
                                  onChange={onChange}
                                  placeholder="Rating"
                                />
                              </div>
                              <div className="mb-2">
                                <select
                                  className="form-select customRadiusSearch"
                                  name={searchState.LEAD_OWNER.name}
                                  value={searchState.LEAD_OWNER.value}
                                  onChange={onChange}
                                >
                                  <option value=""> Lead Owner</option>
                                  {searchState.LEAD_OWNER.options && searchState.LEAD_OWNER.options?.map((item) => {
                                    return (
                                      <option value={item.USER_ID}> {item.FULL_NAME}</option>

                                    )
                                  })}
                                </select>
                              </div>
                              <div className="mb-2">
                                <select
                                  className="form-select customRadiusSearch"
                                  name={searchState.CANDIDATE_STATUS.name}
                                  value={searchState.CANDIDATE_STATUS.value}
                                  onChange={onChange}
                                >
                                  <option value=""> Candidate Status</option>
                                  {searchState.CANDIDATE_STATUS?.options && searchState.CANDIDATE_STATUS?.options?.map((item) => {
                                    return (
                                      <option value={item.STATUS_ID}> {item.STATUS_NAME}</option>
                                    )
                                  })}
                                </select>
                              </div>
                              <div className="row g-2 align-items-center">
                                <div className="col-lg">
                                  <input
                                    type="date"
                                    id='fromDate'
                                    className="form-control customRadiusSearch"
                                    name={searchState.FROM_DATE.name}
                                    value={searchState.FROM_DATE.value}
                                    onChange={onChange}
                                    max={searchState.TO_DATE.value}
                                  />
                                  <label className="labelDate" htmlFor="fromDate"> From Date</label>
                                </div>
                                <div className="col-lg-auto">To</div>
                                <div className="col-lg">
                                  <input
                                    type="date"
                                    id="toDate"
                                    className="form-control customRadiusSearch"
                                    name={searchState.TO_DATE.name}
                                    value={searchState.TO_DATE.value}
                                    onChange={onChange}
                                    min={searchState.FROM_DATE.value}
                                  />
                                  <label className="labelDate" htmlFor="toDate"> To Date</label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-3">
                                <button
                                  type="button"
                                  onClick={() => {
                                    onReset();
                                  }}
                                  className="btn btn-danger add-btn resettyleBtn"
                                >
                                  Reset
                                </button>
                              </div>
                              <div class="col-md-4">
                                <button
                                  type="button"
                                  onClick={() => {
                                    onSearch();
                                  }}
                                  className="btn btn-success add-btn ms-1 customReportClorButton"
                                >
                                  <i className="ri-search-line search-icon align-bottom me-1" />
                                  Search
                                </button>
                              </div>

                            </div>

                          </React.Fragment>
                        </div>
                        {/*end offcanvas-body*/}
                        <div className=" offcanvas-footer border-top p-3 text-center hstack gap-2" style={{ visibility: 'hidden' }}>
                          <button className="btn btn-light w-100">
                            Clear Filter
                          </button>
                          <button type="submit" className="btn btn-success w-100">
                            Filters
                          </button  >
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </React.Fragment >
  );
};

export default List;
