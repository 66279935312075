import React, { useState } from 'react'
import Pagination from '../../../common/Pagination'
import DrawerModal from '../../../../components/common/htmlDrawerModal'
import ClientDetailDrawerModal from '../../administrator/clientManagement/clientDetailDrawerModal'
import moment from 'moment'

const Clients = (props) => {
    const { clientList, clientCount, clientListCurrentPage, setClientListCurrentPage } = props
    const [showClientDetailDrawerModal, setShowClientDetailDrawerModal] = useState(null);

    return (
        <React.Fragment>
            <DrawerModal
                isOpen={showClientDetailDrawerModal}
                onClose={() => { setShowClientDetailDrawerModal(null) }}
            >
                {showClientDetailDrawerModal && <ClientDetailDrawerModal
                    clientDetails={showClientDetailDrawerModal}
                />}
            </DrawerModal>

            <div class="col-md-12 mainclientStyleSection">
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                            Our Clients
                        </h4>
                    </div>
                    <div className="card-body">

                        {clientList && clientList.map((item) => {
                            return (
                                <a href="javascript:void(0)" class="clientSection">
                                    <div class="row">
                                        <div class="col-md-1 clientProfileImg">
                                            <img style={{ border: '2px solid #e7e7e7' }}
                                                className="rounded-circle"
                                                src={item.LOGO ? `${process.env.REACT_APP_BASE_URL}/v3/client-logo/${item.LOGO}` : "/assets/images/companies/img-2.png"}

                                                alt="Header Avatar"
                                            />
                                        </div>
                                        <div class="col-md-5 clientSectionLeft">
                                            <h3>
                                                {item.COMPANY_NAME}
                                                {/* <span>
                                                    <h6>Bangalore, India</h6>
                                                </span> */}
                                            </h3>

                                            <p><i class="ri-user-fill"></i> Contact Person Name : {item.CLIENT_NAME}</p>
                                            <p><i class="ri-user-settings-fill"></i> GST No :  {item.GST_NO ? item.GST_NO : 'Not Available'}</p>
                                            <p><i class="ri-mail-fill"></i> Email : xxxxxxx
                                             {/* {item.EMAIL} */}

                                            </p>
                                            <p><i class="ri-phone-fill"></i> Mobile : xxxxxxx
                                            {/* {item.PHONE} */}
                                            </p>

                                        </div>
                                        <div class="col-md-5 clientSectionRight">
                                            {/* <p><i class="ri-building-fill"></i> Industry : Information Technology</p>
                                            <p><i class="ri-bar-chart-grouped-line"></i> Business : IT Domain</p> */}
                                            <p><i class="ri-building-fill"></i> Agreement Start : {item.AGREEMENT_START_DATE ? moment(item.AGREEMENT_START_DATE).format('DD-MM-YYYY') : 'Not Available'}</p>
                                            <p><i class="ri-bar-chart-grouped-line"></i> Agreement End : {item.AGREEMENT_END_DATE ? moment(item.AGREEMENT_END_DATE).format('DD-MM-YYYY') : 'Not Available'}</p>

                                            <p><i class="ri-chat-new-line"></i> Create On : {moment(item.CREATED_ON).fromNow()}</p>
                                            <p><i class="ri-time-fill"></i> Last Modified : {moment(item.MODIFIED_ON).format('DD-MM-YYYY')}</p>
                                        </div>
                                        <div class="col-md-1 clientMenuBtnSection">
                                            <a href="javascript:void(0)" style={{ visibility: 'hidden' }}>
                                                <i class="ri-more-2-line"></i>
                                            </a>
                                            {/* <a href="javascript:void(0)"
                                                onClick={() => { setShowClientDetailDrawerModal(item) }}
                                            >
                                                <i class="ri-eye-fill"></i>
                                            </a> */}
                                            {/* <a href={`tel:${item.PHONE}`}>
                                                <i class="ri-phone-fill"></i>
                                            </a> */}
                                            {/* <a href={constant.companyUserComponent.clientProfile.url.replace(":id", item.CLIENT_ID)}>
                                                <i class="ri-home-gear-fill"></i>
                                            </a> */}
                                        </div>
                                    </div>
                                </a>

                            )
                        })}

                        <div className="customPaginationStyle">
                            <Pagination
                                totalCount={clientCount}
                                limit={5}
                                range={3}
                                currentPage={clientListCurrentPage}
                                onPageChange={(page) => props.setClientListCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                        Our Clients
                    </h4>
                </div>
                <div className="card-body">
                    <div className="table-responsive table-card">
                        <table className="table table-centered table-hover align-middle table-nowrap mb-0">
                            <tbody>
                                {clientList && clientList.map((item) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center">

                                                    <h6 className="mb-0">{item.COMPANY_NAME}</h6>
                                                </div>
                                            </td>
                                            <td>
                                                
                                                {item.CLIENT_NAME}
                                            </td>
                                            <td>
                                                <tr>
                                                    

                                                    {item.EMAIL}</tr>
                                                <tr>
                                                    

                                                    {item.PHONE}</tr>

                                                <tr></tr>

                                            </td>
                                            <td>
                                                <a href="javascript:void(0)" onClick={() => { setShowClientDetailDrawerModal(item) }} className="btn btn-link btn-sm viwMoreBtnLink">
                                                    View More{" "}
                                                    <i className="ri-arrow-right-line align-bottom" />
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                    <div className="customPaginationStyle">
                        <div className="flex-shrink-0">
                            
                        </div>
                        <Pagination
                            totalCount={clientCount}
                            limit={5}
                            range={3}
                            currentPage={clientListCurrentPage}
                            onPageChange={(page) => props.setClientListCurrentPage(page)}
                        />

                       
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    )
}

export default Clients