import React from 'react';

const Preview = ({ formData }) => {
  const handlePrint = () => {
    const printContents = document.getElementById('demo').innerHTML;
    const newWindow = window.open('', '', 'width=800,height=600');
    
    newWindow.document.write(`
      <html>
        <head>
          <title>Print Offer Letter</title>
    <script src="/assets/js/layout.js"></script>
    <link href="/assets/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
    <link href="/assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <link href="/assets/css/app.min.css" rel="stylesheet" type="text/css" />
    <link href="/assets/css/custom.min.css" rel="stylesheet" type="text/css" />
          <style>
          
@media print {
  .no-print {
    display: none !important;
  }
}

          </style>
        </head>
        <body onload="window.print();">
          ${printContents}
        </body>
      </html>
    `);
    
    newWindow.document.close();
  };

  return (
    <div className="card" id="demo">
      <div className="row">
        <div className="col-lg-12">
          <div className="card-header border-bottom-dashed p-4">
            <div className="d-flex">
              <div className="flex-grow-1">
                <img src={formData.companyLogo || 'assets/images/logo-dark.png'} alt="logo" height={50} />
                <div className="mt-sm-5 mt-4">
                  <h6 className="text-muted text-uppercase fw-semibold">{formData.companyName || 'Company Name'}</h6>
                  <h6 className="text-muted">Offer Letter</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="card-body p-4">
            <h5>Dear {formData.employeeName || 'Employee Name'},</h5>
            <p>We are pleased to offer you the position of <strong>{formData.jobPosition || 'Job Position'}</strong> at <strong>{formData.companyName || 'Company Name'}</strong>.</p>
            <p>Your starting salary will be <strong>{formData.salary || 'Salary'}</strong>.</p>
            <p>You are expected to join us on <strong>{formData.joiningDate || 'Joining Date'}</strong>.</p>
            <p>Employment Type: <strong>{formData.employmentType || 'N/A'}</strong></p>
            <p>Working Hours: <strong>{formData.workingHours || 'N/A'}</strong></p>
            <p>Job Location: <strong>{formData.jobLocation || 'N/A'}</strong></p>
            <h6>Benefits:</h6>
            <p>{formData.benefits || 'N/A'}</p>
            <p>We look forward to welcoming you to our team!</p>
            <p>Sincerely,</p>
            <p>{formData.signerName || 'Signer Name'}</p>
            <p>{formData.offerDate || 'Offer Date'}</p>

            <a className="btn btn-primary mt-4 no-print" href="javascript:void(0);" onClick={handlePrint}>
              Print Offer Letter
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
