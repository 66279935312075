import React, { useState } from 'react';
import styles from './style.module.css';
import PDFEmbed from '../../../../common/PDFViewer';
import Modal from '../../../../common/htmlModal';
const Detail = ({ label, value }) => {
    return (
        <div className={styles.detail}>
            <span className={styles.label}>{label} : </span>
            <span className={styles.value}>{value}</span>
        </div>
    );
};

const CanididateDetailDrawerModal = (props) => {
    const { clientDetails } = props;
    const [showClientDocumentModal, setShowClientDocumentModal] = useState(null)
    const [showServiceAgreementModal, setShowServiceAgreementModal] = useState(null)
    const [showRateCardModal, setShowRateCardModal] = useState(null)

    return (
        <div>
            <Modal isOpen={showClientDocumentModal} onClose={() => { setShowClientDocumentModal(null) }}>
                <PDFEmbed
                    file={`${process.env.REACT_APP_BASE_URL}/v3/client-document/${showClientDocumentModal}`}
                />
            </Modal>
            <Modal isOpen={showServiceAgreementModal} onClose={() => { setShowServiceAgreementModal(null) }}>
                <PDFEmbed
                    file={`${process.env.REACT_APP_BASE_URL}/v3/client-service-agreement/${showServiceAgreementModal}`}
                />
            </Modal>
            <Modal isOpen={showRateCardModal} onClose={() => { setShowRateCardModal(null) }}>
                <PDFEmbed
                    file={`${process.env.REACT_APP_BASE_URL}/v3/client-rate-card/${showRateCardModal}`}
                />
            </Modal>

            <h2>Client Details</h2>
            <div className={styles['details-container']}>
                <Detail label="Client ID" value={'RJZSHCC' + clientDetails.CLIENT_ID} />
                <Detail label="Company Name" value={clientDetails.COMPANY_NAME} />
                <Detail label="SPOC Name" value={clientDetails.CLIENT_NAME} />
                <Detail label="Email" value={clientDetails.EMAIL} />
                <Detail label="Phone" value={clientDetails.PHONE} />
                <Detail label="Agreement Start Date" value={clientDetails.AGREEMENT_START_DATE} />
                <Detail label="Agreement End Date" value={clientDetails.AGREEMENT_END_DATE} />
                <Detail label="Client Document" value={<a href='javascript:void(0)' onClick={() => { setShowClientDocumentModal(clientDetails.CLIENT_DOC) }}>{clientDetails.CLIENT_DOC}</a>} />
                <Detail label="Service Agreement Document" value={<a href='javascript:void(0)' onClick={() => { setShowServiceAgreementModal(clientDetails.SERVICE_AGREEMENT_DOC) }}>{clientDetails.SERVICE_AGREEMENT_DOC}</a>} />
                <Detail label="Rate Card Document" value={<a href='javascript:void(0)' onClick={() => { setShowRateCardModal(clientDetails.RATE_CARD) }}>{clientDetails.RATE_CARD}</a> }/>
                <Detail label="Client Detail" value={clientDetails.CLIENT_DETAILS} />
                <Detail label="Client Address" value={clientDetails.CLIENT_ADDRESS} />

            </div>
        </div>
    );
};

export default CanididateDetailDrawerModal;
