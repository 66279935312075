import React, { useEffect, useState } from 'react'
import { validateFunctionalForm } from '../../../../utils';
import { v1_application_status_options, v1_course_specialization, v1_education_qualification, v1_qualification } from '../../../../actions/v1/master_data';
import { v3_job_apply, v3_job_apply_and_add_candidate } from '../../../../actions/v3/jobApplication';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import BoxRating from '../../../common/BoxRating'



const AddCandidateModalForm = (props) => {
    const [state, setState] = useState({
        FULL_NAME: { name: 'FULL_NAME', value: '', error: '', isRequired: true },
        CURRENT_DESIGNATION: { name: 'CURRENT_DESIGNATION', value: '', error: '', isRequired: false },

        EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: true },
        PHONE: { name: 'PHONE', value: '', error: '', isRequired: true },
        WORKING_LOCATION: { name: 'WORKING_LOCATION', value: '', error: '', isRequired: false },
        RESIDENTIAL_LOCATION: { name: 'RESIDENTIAL_LOCATION', value: '', error: '', isRequired: true },
        CURRENT_COMPANY: { name: 'CURRENT_COMPANY', value: '', error: '', isRequired: false },
        CURRENT_CTC: { name: 'CURRENT_CTC', value: '', error: '', isRequired: false },
        EXPECTED_CTC: { name: 'EXPECTED_CTC', value: '', error: '', isRequired: true },
        SKILLS: { name: 'SKILLS', value: '', error: '', isRequired: true },
        TOTAL_EXP_YEAR: { name: 'TOTAL_EXP_YEAR', value: '', error: '', isRequired: true },
        HIGHEST_QUALIFICATION: { name: 'HIGHEST_QUALIFICATION', value: '', options: [], error: '', isRequired: true },
        COURSE: { name: 'COURSE', value: '', options: [], error: '', isRequired: true },
        SPECIALIZATION: { name: 'SPECIALIZATION', value: '', options: [], error: '', isRequired: false },
        NOTICE_PERIOD: { name: 'NOTICE_PERIOD', value: '', error: '', isRequired: false },
        SOURCE: { name: 'SOURCE', value: '', error: '', isRequired: true },
        RATING: { name: 'RATING', value: '', error: '', isRequired: false },
        RESUME: { name: 'RESUME', value: '', error: '', isRequired: false },
        COLLEGE_NAME: { name: 'COLLEGE_NAME', value: '', options: [], error: '', isRequired: false },
        PASS_OUT_YEAR: { name: 'PASS_OUT_YEAR', value: '', options: [], error: '', isRequired: false },
        NOTE: { name: 'NOTE', value: '', options: [], error: '', isRequired: false },
        STATUS: { name: 'STATUS', value: '', options: [], error: '', isRequired: true },


    })

    const ratingChanged = (newRating) => {
        setState(prevState => ({
            ...prevState,
            ['RATING']: {
                ...prevState['RATING'],
                value: newRating
            }
        }));
    };
    useEffect(() => {
        v1_qualification().then(res => {
            if (res.status) {
                setOptions('HIGHEST_QUALIFICATION', res.result);
            }
        })
        v1_application_status_options().then((res) => {
            if (res.status) {
                setOptions('STATUS', res.result);

            }
            else {

            }
        })
    }, [])

    useEffect(() => {
        if (state.HIGHEST_QUALIFICATION.value) {
            v1_education_qualification(state.HIGHEST_QUALIFICATION.value).then(res => {
                if (res.status) {
                    setOptions('COURSE', res.result);
                }
            })
        }
    }, [state.HIGHEST_QUALIFICATION.value])

    useEffect(() => {
        if (state.COURSE.value) {
            v1_course_specialization(state.COURSE.value).then(res => {
                if (res.status) {
                    setOptions('SPECIALIZATION', res.result);
                }
            })
        }
    }, [state.COURSE.value])

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }


    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
        if (name === state.TOTAL_EXP_YEAR.name) {
            if (value !== 'Fresher') {
                setState(prevState => ({
                    ...prevState,
                    ['PASS_OUT_YEAR']: {
                        ...prevState['PASS_OUT_YEAR'],
                        value: '',
                        isRequired: false

                    },
                    ['COLLEGE_NAME']: {
                        ...prevState['COLLEGE_NAME'],
                        value: '',
                        isRequired: false

                    }
                }));
            }
            else {
                setState(prevState => ({
                    ...prevState,
                    ['PASS_OUT_YEAR']: {
                        ...prevState['PASS_OUT_YEAR'],
                        isRequired: true
                    },
                    ['COLLEGE_NAME']: {
                        ...prevState['COLLEGE_NAME'],
                        isRequired: true
                    }
                }));
            }

        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                FULL_NAME: state.FULL_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                WORKING_LOCATION: state.WORKING_LOCATION.value,
                RESIDENTIAL_LOCATION: state.RESIDENTIAL_LOCATION.value,
                CURRENT_COMPANY: state.CURRENT_COMPANY.value,
                CURRENT_CTC: state.CURRENT_CTC.value,
                EXPECTED_CTC: state.EXPECTED_CTC.value,
                SKILLS: state.SKILLS.value,
                TOTAL_EXP_YEAR: state.TOTAL_EXP_YEAR.value,
                HIGHEST_QUALIFICATION: state.HIGHEST_QUALIFICATION.value,
                COURSE: state.COURSE.value,
                SPECIALIZATION: state.SPECIALIZATION.value,
                NOTICE_PERIOD: state.NOTICE_PERIOD.value,
                RESUME: state.RESUME.value,
                SOURCE: state.SOURCE.value,
                RATING: state.RATING.value,
                JOB_ID: props.match.params.job_id,
                COLLEGE_NAME: state.COLLEGE_NAME.value,
                PASS_OUT_YEAR: state.PASS_OUT_YEAR.value,
                NOTE: state.NOTE.value,
                STATUS_ID: state.STATUS.value,
                CURRENT_DESIGNATION: state.CURRENT_DESIGNATION.value
            }
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }
            v3_job_apply_and_add_candidate(formData).then((res) => {
                if (res.status) {
                    debugger
                    toast.success('Candidate Added Sucessfully');
                    props.setCurrentApplicationId(res.result)
                    props.openJobQuestionForm()
                    props.onClose()


                }
                else {
                    Swal.fire({
                        title: 'Alert',
                        text: res.error,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Apply to this job',
                        cancelButtonText: 'Review It'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            v3_job_apply({
                                JOB_ID: props.match.params.job_id,
                                CANDIDATE_ID: null,
                                EMAIL: state.EMAIL.value,
                                PHONE: state.PHONE.value,
                                STATUS_ID: state.STATUS.value,


                            }).then((res) => {
                                if (res.status) {
                                    debugger
                                    toast('Successfully Applied');
                                    props.setCurrentApplicationId(res.result)
                                    props.openJobQuestionForm()
                                    props.onClose()

                                }
                                else {
                                    Swal.fire('Alert !', res.error, 'warning');
                                    props.onClose()
                                }
                            })


                        } else {
                            // Handle the canceled action here
                            // Swal.fire('Cancelled', 'Your file is safe :)', 'info');
                        }
                    })
                }

            })

        }

    }
    return (
        <React.Fragment>
            <ToastContainer />
            <form onSubmit={(e) => { onSubmit(e) }}>
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Full Name
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <input
                                type="text"
                                className={state.FULL_NAME.error.length > 0 && !state.FULL_NAME.value ? "form-control is-invalid" : "form-control"}
                                placeholder="Full Name"
                                onChange={onChange}

                                name={state.FULL_NAME.name}
                                value={state.FULL_NAME.value}
                            />
                            <div className="invalid-feedback">Please enter a full name.</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Email
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <input
                                type="text"
                                className={state.EMAIL.error.length > 0 && !state.EMAIL.value ? "form-control is-invalid" : "form-control"}
                                placeholder="Email"
                                onChange={onChange}
                                name={state.EMAIL.name}
                                value={state.EMAIL.value}
                            />
                            <div className="invalid-feedback">Please enter email address.</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Phone
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <input
                                type="number"
                                className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control is-invalid" : "form-control"}
                                placeholder="Phone"
                                onChange={onChange}
                                name={state.PHONE.name}
                                value={state.PHONE.value}
                            />
                            <div className="invalid-feedback">Please enter phone number.</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Current Designation

                            </label>
                            <input
                                type="text"
                                className={state.CURRENT_DESIGNATION.error.length > 0 && !state.CURRENT_DESIGNATION.value ? "form-control is-invalid" : "form-control"}
                                placeholder="Current Designation"
                                onChange={onChange}
                                name={state.CURRENT_DESIGNATION.name}
                                value={state.CURRENT_DESIGNATION.value}
                            />
                            <div className="invalid-feedback">Please enter Current Designation.</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Work Location

                            </label>
                            <input
                                type="text"
                                className={state.WORKING_LOCATION.error.length > 0 && !state.WORKING_LOCATION.value ? "form-control is-invalid" : "form-control"}
                                placeholder="Work Location"
                                onChange={onChange}
                                name={state.WORKING_LOCATION.name}
                                value={state.WORKING_LOCATION.value}
                            />
                            <div className="invalid-feedback">Please enter Work Location.</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Residential Location
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <input
                                type="text"
                                className={state.RESIDENTIAL_LOCATION.error.length > 0 && !state.RESIDENTIAL_LOCATION.value ? "form-control is-invalid" : "form-control"}
                                onChange={onChange}
                                placeholder="Residential Location"
                                name={state.RESIDENTIAL_LOCATION.name}
                                value={state.RESIDENTIAL_LOCATION.value}
                            />
                            <div className="invalid-feedback">Please enter residential location.</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Current Company

                            </label>
                            <input
                                type="text"
                                className={state.CURRENT_COMPANY.error.length > 0 && !state.CURRENT_COMPANY.value ? "form-control is-invalid" : "form-control"}
                                onChange={onChange}
                                placeholder="Current Company"
                                name={state.CURRENT_COMPANY.name}
                                value={state.CURRENT_COMPANY.value}
                            />
                            <div className="invalid-feedback">Please enter current company</div>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Current CTC
                            </label>
                            <input
                                type="text"
                                className={state.CURRENT_CTC.error.length > 0 && !state.CURRENT_CTC.value ? "form-control is-invalid" : "form-control"}
                                onChange={onChange}
                                placeholder="Current CTC"
                                name={state.CURRENT_CTC.name}
                                value={state.CURRENT_CTC.value}

                            />
                            <div className="invalid-feedback">Please enter current ctc</div>
                        </div>
                    </div>



                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Expected CTC
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <input
                                type="text"
                                className={state.RESIDENTIAL_LOCATION.error.length > 0 && !state.RESIDENTIAL_LOCATION.value ? "form-control is-invalid" : "form-control"}
                                onChange={onChange}
                                placeholder="Expected CTC"
                                name={state.EXPECTED_CTC.name}
                                value={state.EXPECTED_CTC.value}
                            />
                            <div className="invalid-feedback">Please enter expected ctc</div>
                        </div>
                    </div> <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Skills
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <input
                                type="text"
                                className={state.SKILLS.error.length > 0 && !state.SKILLS.value ? "form-control is-invalid" : "form-control"}
                                onChange={onChange}
                                placeholder="Skills"
                                name={state.SKILLS.name}
                                value={state.SKILLS.value}

                            />
                            <div className="invalid-feedback">Please enter skills</div>
                        </div>
                    </div> <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Total Years of Experience
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <select
                                type="text"
                                className={state.TOTAL_EXP_YEAR.error.length > 0 && !state.TOTAL_EXP_YEAR.value ? "form-select is-invalid" : "form-select"}
                                onChange={onChange}
                                placeholder="Total Years of Experience"
                                name={state.TOTAL_EXP_YEAR.name}
                                value={state.TOTAL_EXP_YEAR.value}
                            >

                                <option value=''> Select Experience Year</option>
                                <option value='Fresher'> Fresher</option>
                                <option value='1 Year'> 1 Year</option>
                                <option value='2 Year'> 2 Year</option>
                                <option value='3 Year'> 3 Year</option>
                                <option value='4 Year'> 4 Year</option>
                                <option value='5 Year'> 5 Year</option>
                                <option value='6 Year'> 6 Year</option>
                                <option value='7 Year'> 7 Year</option>
                                <option value='8 Year'> 8 Year</option>
                                <option value='9 Year'> 9 Year</option>
                                <option value='10 Year'> 10 Year</option>
                                <option value='11 Year'> 11 Year</option>
                                <option value='12 Year'> 12 Year</option>
                                <option value='13 Year'> 13 Year</option>
                                <option value='14 Year'> 14 Year</option>
                                <option value='15 Year'> 15 Year</option>
                                <option value='16 Year'> 16 Year</option>
                                <option value='17 Year'> 17 Year</option>
                                <option value='18 Year'> 18 Year</option>
                                <option value='19 Year'> 19 Year</option>
                                <option value='20 Year'> 20 Year</option>
                                <option value='21 Year'> 21 Year</option>
                                <option value='22 Year'> 22 Year</option>
                                <option value='23 Year'> 23 Year</option>
                                <option value='24 Year'> 24 Year</option>
                                <option value='25 Year'> 25 Year</option>
                                <option value='26 Year'> 26 Year</option>
                                <option value='27 Year'> 27 Year</option>
                                <option value='28 Year'> 28 Year</option>
                                <option value='29 Year'> 29 Year</option>
                                <option value='30 Year'> 30 Year</option>
                                <option value='31 Year'> 31 Year</option>
                                <option value='32 Year'> 32 Year</option>
                            </select>
                            <div className="invalid-feedback">Please enter work experience</div>
                        </div>
                    </div>



                    {state.TOTAL_EXP_YEAR.value === 'Fresher' &&
                        <React.Fragment>

                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label htmlFor="companyname-field" className="form-label">
                                        College Name
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.COLLEGE_NAME.error.length > 0 && !state.COLLEGE_NAME.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        placeholder="College Name"
                                        name={state.COLLEGE_NAME.name}
                                        value={state.COLLEGE_NAME.value}
                                    />
                                    <div className="invalid-feedback">Please enter college name</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label htmlFor="companyname-field" className="form-label">
                                        Passing Year
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="month"
                                        className={state.PASS_OUT_YEAR.error.length > 0 && !state.PASS_OUT_YEAR.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        placeholder="Total Years of Experience"
                                        name={state.PASS_OUT_YEAR.name}
                                        value={state.PASS_OUT_YEAR.value}
                                    />

                                    <div className="invalid-feedback">Please enter work experience</div>
                                </div>
                            </div>
                        </React.Fragment>

                    }
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Highest Qualification
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <select
                                className={state.HIGHEST_QUALIFICATION.error.length > 0 && !state.HIGHEST_QUALIFICATION.value ? "form-select is-invalid" : "form-select"}
                                onChange={onChange}
                                placeholder="Highest Qualification"
                                name={state.HIGHEST_QUALIFICATION.name}
                                value={state.HIGHEST_QUALIFICATION.value}
                            >
                                <option value=''> Select Highest Qualification</option>
                                {state.HIGHEST_QUALIFICATION.options && state.HIGHEST_QUALIFICATION.options.map((item) => {
                                    return (
                                        <option value={item.QUALIFICATION_ID}> {item.QUALIFICATION_NAME}</option>

                                    )
                                })}
                            </select>
                            <div className="invalid-feedback">Please select highest qualification</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Course
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <select
                                className={state.COURSE.error.length > 0 && !state.COURSE.value ? "form-select is-invalid" : "form-select"}
                                onChange={onChange}
                                name={state.COURSE.name}
                                value={state.COURSE.value}
                            >
                                <option value=''> Select Course</option>
                                {
                                    state.COURSE.options && state.COURSE.options.map((item) => {
                                        return (<option
                                            value={item.EDUCATION_QUALIFICATION_ID}

                                        >
                                            {item.COURSE_STREAM}
                                        </option>)
                                    })

                                }
                            </select>
                            <div className="invalid-feedback">Please select course</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Specialization

                            </label>
                            <select
                                type="text"
                                className={state.SPECIALIZATION.error.length > 0 && !state.SPECIALIZATION.value ? "form-select is-invalid" : "form-select"}
                                onChange={onChange}
                                placeholder="Specialization"
                                name={state.SPECIALIZATION.name}
                                value={state.SPECIALIZATION.value}
                            >
                                <option value=''> Select Specialization</option>
                                {
                                    state.SPECIALIZATION.options && state.SPECIALIZATION.options.map((item) => {
                                        return (<option
                                            value={item.SPECIALIZATION_ID}
                                        >
                                            {item.SPECIALIZATION}
                                        </option>)
                                    })

                                }
                            </select>
                            <div className="invalid-feedback">Please select specialization</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Notice Period
                                {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                            </label>
                            <select
                                type="text"
                                className={state.NOTICE_PERIOD.error.length > 0 && !state.NOTICE_PERIOD.value ? "form-select is-invalid" : "form-select"}
                                onChange={onChange}
                                name={state.NOTICE_PERIOD.name}
                                value={state.NOTICE_PERIOD.value}
                            >
                                <option value=''> Select Notice Period</option>
                                <option value='Immediate Joiner'> Immediate Joiner</option>
                                <option value='15 Days'> 15 Days</option>
                                <option value='1 Month'> 1 Month</option>
                                <option value='2 Month'> 2 Month</option>
                                <option value='3 Month'> 3 Month or more</option>

                            </select>
                            <div className="invalid-feedback">Please select notice period</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label className="form-label">
                                Lead Source
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <select
                                className={state.SOURCE.error.length > 0 && !state.SOURCE.value ? "form-select is-invalid" : "form-select"}
                                onChange={onChange}
                                name={state.SOURCE.name}
                                value={state.SOURCE.value}
                            >
                                <option value=''> Select Lead Source</option>
                                <option value='Found It / Monster'> Found It / Monster</option>
                                <option value='Naukri'> Naukri</option>
                                <option value='LinkedIn'> LinkedIn</option>
                                <option value='Instagram'> Instagram</option>
                                <option value='apna'> apna</option>
                                <option value='Campus'> Campus</option>
                                <option value='Other Portal'> Other Portal</option>
                                <option value='Referral'> Referral</option>
                                <option value='Social Media'> Social Media</option>
                                <option value='Walk-In'> Walk-In</option>
                            </select>
                            <div className="invalid-feedback">Please select Lead Source</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div>
                            <label className="form-label">
                                Status
                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                            </label>
                            <select
                                className={state.STATUS.error.length > 0 && !state.STATUS.value ? "form-select is-invalid" : "form-select"}
                                onChange={onChange}
                                name={state.STATUS.name}
                                value={state.STATUS.value}
                            >
                                <option value=''> Select Status</option>
                                {
                                    state.STATUS.options?.map((item) => {
                                        return (
                                            <option value={item.STATUS_ID}> {item.STATUS_NAME}</option>

                                        )
                                    })
                                }
                            </select>
                            <div className="invalid-feedback">Please select Lead Source</div>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <div >
                            <label className="form-label">
                                Candidate Quality
                                {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                            </label>
                            {/* <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={24}
                                value={state.RATING.value}
                                isHalf={true}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                            /> */}
                            <BoxRating
                                onChange={ratingChanged}
                                value={state.RATING.value}
                            />
                            {state.RATING.error && !state.RATING.value && <span className="text-danger">Please select Lead Source</span>}
                        </div>
                    </div>

                    <div className="col-lg-12  mb-3">
                        <div>
                            <label htmlFor="companyname-field" className="form-label">
                                Resume
                                {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                            </label>
                            <input
                                type="file"
                                className={state.RESUME.error.length > 0 && !state.RESUME.value ? "form-control is-invalid" : "form-control"}
                                onChange={(e) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        ['RESUME']: {
                                            ...prevState['RESUME'],
                                            value: e.target.files[0]
                                        }
                                    }));
                                }}
                            />
                            <div className="invalid-feedback">Please upload resume.</div>
                        </div>
                    </div>
                    <div className="col-lg-12  mb-3">
                        <div>
                            <label className="form-label">
                                Note
                                {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                            </label>
                            <textarea
                                placeholder='Enter Note / Remarks'
                                className={state.NOTE.error.length > 0 && !state.NOTE.value ? "form-control is-invalid" : "form-control"}
                                onChange={onChange}
                                rows={3}
                                name={state.NOTE.name}
                                value={state.NOTE.value}
                            />
                            <div className="invalid-feedback">Please upload resume.</div>
                        </div>
                    </div>
                    <div className="hstack gap-2 justify-content-end">
                        <button type="button" onClick={() => { props.onClose() }} className="btn btn-light" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="submit" className="btn btn-success" id="add-btn">
                            Add Lead
                        </button>
                        {/* <button type="button" class="btn btn-success" id="edit-btn">Update</button> */}
                    </div>

                </div>
            </form>

        </React.Fragment>
    )
}

export default withRouter(AddCandidateModalForm)