import React, { useEffect, useState } from 'react'
import Packages from '../../../components/SuperAdmin/manage_subscription/selectPackages'
import GenerateInvoice from '../../../components/SuperAdmin/manage_subscription/generateInvoice'

import { v1_crm_available_packages, v1_crm_invoice_generation_details } from '../../../actions/v1/subscription_management'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
const SelectPackages = (props) => {
    const [packages, setPackages] = useState(null)
    const [selectedPackage, setSelectedPackage] = useState(null)
    const [billGenerationDetails, setBillGenerationDetails] = useState(null)


    useEffect(() => {
        v1_crm_available_packages().then((res) => {
            if (res.status) {
                setPackages(res.result)
            }
        })

        v1_crm_invoice_generation_details(props.match.params.id).then((res) => {
            if (res.status) {
                setBillGenerationDetails(res.result)
            }
        })
    }, [])
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {packages && <Packages
                            selectedPackage={selectedPackage}
                            SelectPackage={setSelectedPackage}
                            packages={packages}
                        />}
                        {
                            selectedPackage &&
                            <GenerateInvoice
                                billGenerationDetails={billGenerationDetails}
                                selectedPackage={selectedPackage}
                                // clientDetail={props.clientDetail}
                            />
                        }
                    </div>
                </div>
            </div>



        </React.Fragment>
    )
}

export default withRouter(SelectPackages)