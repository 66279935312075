import React, { useEffect, useState } from 'react';
import { getStorage, validateFunctionalForm } from '../../../utils';
import constant from '../../../constant';

const Add = (props) => {
    const [state, setState] = useState({
        TICKET_STATUS: { name: 'TICKET_STATUS', value: props?.data?.TICKET_STATUS ? props.data.TICKET_STATUS : '', options: ['Completed', 'In Progress', 'Pending', 'Closed'], error: '', isRequired: true },
    });

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const onSubmit = (e) => {
        if (validateFunctionalForm(state, setState)) {
            const { FULL_NAME } = getStorage(constant.keys.companyUserDetails)
            const model = {
                TICKET_STATUS: state.TICKET_STATUS.value,
                CANDIDATE_ID: props?.data?.CANDIDATE_ID,
                FULL_NAME: props?.data?.FULL_NAME,
                RECRUITER_NAME: FULL_NAME,
                CALLING_ASSIGNEE_ID: props?.data?.CALLING_ASSIGNEE_ID ? props?.data?.CALLING_ASSIGNEE_ID : props?.data?.LEAD_OWNER,
                LEAD_OWNER:props?.data?.LEAD_OWNER
            };
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }
            props.onSubmit(formData);
        }
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 mb-3">
                    <div>
                        <label className="form-label">
                            Lead Owners
                        </label>
                        <select
                            className={state.TICKET_STATUS.error && !state.TICKET_STATUS.value ? 'form-select is-invalid' : 'form-select'}
                            onChange={onChange}
                            value={state.TICKET_STATUS.value}
                            name={state.TICKET_STATUS.name}
                        >
                            <option value='' disabled>
                                Select Ticket Status
                            </option>
                            {state.TICKET_STATUS.options && state.TICKET_STATUS.options.map((item) => {
                                return (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-sm-auto ms-auto">
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-success" onClick={() => { onSubmit() }}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Add;
