import React from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Report = ({ list,TYPE }) => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <ReactHTMLTableToExcel
                        id="xls-button" // Set a unique id for the button
                        className="download-table-xls-button customExcelButton"
                        table="table-to-xls"
                        filename="job_application-report"
                        sheet="data"
                        buttonText="Download as XLS" />


                    <div className="table-responsive customReportTableStyle">
                        <table className="table align-middle mb-0" id="table-to-xls">
                            <thead className="table-light">
                                <tr>

                                    <th scope="col">#</th>
                                    <th scope="col">{TYPE === 'RECRUITER' ? 'Recruiter Name' : TYPE === 'COMPANY' ? 'Process Name' : TYPE === 'TEAM' ? 'Team Leader Name' : ''}</th>
                                    <th scope="col">HR Shortlisted</th>
                                    <th scope="col">Client Shortlisted</th>
                                    <th scope="col">Interview Scheduled</th>
                                    <th scope="col">Rejected</th>
                                    <th scope="col">Selected</th>
                                    <th scope="col">Offered</th>
                                    <th scope="col">Joined</th>
                                    <th scope="col">Dropped</th>


                                </tr>
                            </thead>
                            <tbody>
                                {list && list.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td>
                                                <a href="#" className="fw-semibold">
                                                    {item.job_detail?.JOB_TITLE}
                                                    {item.user_detail?.label}

                                                </a>
                                                <p>
                                                    {item.job_detail?.COMPANY_NAME}
                                                </p>
                                            </td>
                                            <td>{item.hr_shortlisted}</td>
                                            <td >
                                                {item.client_shortlisted}
                                            </td>
                                            <td>
                                                {item.interview_scheduled}

                                            </td>
                                            <td>{item.rejected}</td>
                                            <td>{item.selected}</td>
                                            <td>{item.offered}</td>
                                            <td>{item.joined}</td>
                                            <td>{item.dropped}</td>

                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        {/* end table */}
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default Report