import React, { useEffect, useState } from 'react'
import { validateFunctionalForm } from '../../../utils'
import { toast } from 'react-toastify'
import { v3_lead_owners } from '../../../actions/v3/candidates'
const Add = (props) => {
    const [state, setState] = useState({
        FULL_NAME: { name: 'FULL_NAME', value: '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: true },
        PHONE: { name: 'PHONE', value: '', error: '', isRequired: true },
        LEAD_OWNER: { name: 'LEAD_OWNER', value: '', options: [], error: '', isRequired: true },
        SOURCE: { name: 'SOURCE', value: '', error: '', isRequired: false },
        RESUME: { name: 'RESUME', value: '', options: [], error: '', isRequired: true },
    })


    const onChange = (e) => {
        const { name, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));

    }
    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }
    useEffect(() => {
        debugger
        if (props.token) {
            v3_lead_owners(
                {
                    headers: { Authorization: "Bearer " + props.token },
                    "Content-Type": "multipart/form-data",
                }
            ).then(res => {
                if (res.status) {
                    setOptions('LEAD_OWNER', res.result);
                }
            });
        }
    }, [props.token])

    const onSubmit = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                FULL_NAME: state.FULL_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                LEAD_OWNER: state.LEAD_OWNER.value,
                RESUME: state.RESUME.value,
                SOURCE: state.SOURCE.value

            }
            props.setCandidateDetails(model)

            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }
            props.onSendOtp(formData)

        }

    }


    return (
        <React.Fragment>

            <div className="">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between" style={{ paddingLeft: 34, }}>
                                    <h4 className="mb-sm-0">Add Candidate</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            {/* <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Candidate Management</a>
                                            </li> */}
                                            <li className="breadcrumb-item active">Walk In Candidate</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="live-preview" >
                                            <div className="row gy-4">
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Full Name
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.FULL_NAME.error.length > 0 && !state.FULL_NAME.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            placeholder="Full Name"
                                                            onChange={onChange}

                                                            name={state.FULL_NAME.name}
                                                            value={state.FULL_NAME.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter a full name.</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Email
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.EMAIL.error.length > 0 && !state.EMAIL.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            placeholder="Email"
                                                            onChange={onChange}
                                                            name={state.EMAIL.name}
                                                            value={state.EMAIL.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter email address.</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Phone
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="number"
                                                            className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            placeholder="Phone"
                                                            onChange={onChange}
                                                            name={state.PHONE.name}
                                                            value={state.PHONE.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter phone number.</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div >
                                                        <label className="form-label">
                                                            Lead Owner
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <select
                                                            className={state.LEAD_OWNER.error && !state.LEAD_OWNER.value ? 'form-select is-invalid' : 'form-select'}
                                                            onChange={onChange}
                                                            value={state.LEAD_OWNER.value}
                                                            name={state.LEAD_OWNER.name}
                                                        >
                                                            <option value=''>
                                                                Select Lead Owner
                                                            </option>
                                                            {state.LEAD_OWNER.options && state.LEAD_OWNER.options.map((item) => {
                                                                return (
                                                                    <option key={item.USER_ID} value={item.USER_ID}>
                                                                        {item.FULL_NAME}
                                                                    </option>
                                                                )
                                                            })}
                                                        </select>
                                                        {state.LEAD_OWNER.error && !state.LEAD_OWNER.value && <span className="text-danger">Please select Lead Source</span>}
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Source
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <select
                                                            className={state.SOURCE.error.length > 0 && !state.SOURCE.value ? "form-select is-invalid" : "form-select"}
                                                            onChange={onChange}
                                                            name={state.SOURCE.name}
                                                            value={state.SOURCE.value}
                                                        >
                                                            <option value=''> Select Lead Source</option>
                                                            <option value='Found It / Monster'> Found It / Monster</option>
                                                            <option value='Naukri'> Naukri</option>
                                                            <option value='LinkedIn'> LinkedIn</option>
                                                            <option value='Instagram'> Instagram</option>
                                                            <option value='apna'> apna</option>
                                                            <option value='Campus'> Campus</option>
                                                            <option value='Other Portal'> Other Portal</option>
                                                            <option value='Referral'> Referral</option>
                                                            <option value='Social Media'> Social Media</option>
                                                            <option value='Walk-In'> Walk-In</option>
                                                        </select>
                                                        <div className="invalid-feedback">Please select Lead Source</div>
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-4  mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Resume
                                                        </label>
                                                        <input
                                                            type="file"
                                                            className={state.RESUME.error.length > 0 && !state.RESUME.value ? "form-control is-invalid" : "form-control"}
                                                            onChange={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    ['RESUME']: {
                                                                        ...prevState['RESUME'],
                                                                        value: e.target.files[0]
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        <div className="invalid-feedback">Please upload resume.</div>
                                                    </div>
                                                </div>
                                                <div className="hstack gap-2 justify-content-end">
                                                    {/* <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                                        Cancel
                                                    </button> */}
                                                    <button type="submit" className="btn btn-success"
                                                        onClick={() => { onSubmit() }}
                                                    >
                                                        Next
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </React.Fragment >
    )
}

export default Add