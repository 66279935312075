
import axios from 'axios'
import { getCompanyUserAuthHeader } from '../../utils';

export const v3_job_applications = async (job_id, page_number, applicationStatus, STATUS_ID, LEAD_OWNER, FROM_DATE, TO_DATE) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-application/${job_id}-${page_number}-${applicationStatus}?STATUS_ID=${STATUS_ID}&&LEAD_OWNER=${LEAD_OWNER}&&FROM_DATE=${FROM_DATE}&&TO_DATE=${TO_DATE}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_job_apply_and_add_candidate = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-application-add-apply-candidate`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_job_apply = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/apply-job`;
    return axios.patch(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_answer_job_questions = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/answer-job-question`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_client_options = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/client-options`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_all_client_options = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/all-client-options`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_send_job_applications_mail = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-applicaition-mail`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_update_hiring_status = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/hiring-status`;
    return axios.patch(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_job_applications_report = async (query) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-application-report?query=${query}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}