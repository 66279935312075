import React from 'react'
import ResponseComponent from '../../components/nonAuthed/requestDemoResponse'
const RequestDemoResponse = () => {
    return (
        <React.Fragment>
            <ResponseComponent />
        </React.Fragment>
    )
}

export default RequestDemoResponse