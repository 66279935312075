import React, { useEffect, useState } from 'react'
import { v3_admin_user_hierarchy } from '../../../../actions/v3/administrator';
import Hierarchy from '../../../../components/common/Hierarchy'
import PeroformanceTable from '../../../../components/companyUser/administrator/CRMUser/peroformanceTable';
import { getStorage } from '../../../../utils';
import constant from '../../../../constant';

const Index = () => {
    const [userHierarchy, setUserHierarchy] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const isAdministrator = getStorage(constant.keys.companyUserHavingAdminRights);

    useEffect(() => {
        v3_admin_user_hierarchy().then((res) => {
            if (res.status) {
                setUserHierarchy(res.result)
            }
        })

    }, [])
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Organization Chart</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">Administrator</a>
                                        </li>
                                        <li className="breadcrumb-item active">Organization Chart</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                {/* <div className="card-header">
                                    <h4 className="card-title mb-0">Organization Chart</h4>
                                </div> */}
                                <div className="card-body customHierarchyStyle">
                                    {userHierarchy && <Hierarchy
                                        json={userHierarchy}
                                        setSelected={setSelectedUser}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isAdministrator && selectedUser && <PeroformanceTable
                        id={selectedUser}
                    />}
                </div>
            </div>
        </div>

    )
}

export default Index