import React, { useEffect, useState } from 'react'
import ProflePreview from '../../../../components/companyUser/administrator/clientManagement/profile'
import { v3_client_details } from '../../../../actions/v3/administrator'
const Profile = (props) => {
    const [details, setDetails] = useState(null)

    useEffect(() => {
        v3_client_details(props.match.params.id).then((res) => {
            if (res.status) {
                setDetails(res.result.details)
            }

        })

    }, [])
    return (
        <React.Fragment>

            <React.Fragment>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Client Profile</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <a href="javascript: void(0);">Client</a>
                                                </li>
                                                <li className="breadcrumb-item active">Client Profile</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {details && <ProflePreview
                                clientDetails={details}
                            />}

                        </div>
                    </div>
                </div>

            </React.Fragment>
        </React.Fragment>
    )
}

export default Profile