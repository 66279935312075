import React, { useEffect, useState } from 'react'
import Report from '../../../components/companyUser/jobs/jobApplications/Report'
import { v3_client_options, v3_job_applications_report } from '../../../actions/v3/jobApplication'
import { v3_client_wise_active_jobs } from '../../../actions/v3/jobs'
import Select from 'react-select';
import { validateFunctionalForm } from '../../../utils';
import { v3_lead_owners } from '../../../actions/v3/candidates';
import { v3_team_leader_options } from '../../../actions/v3/administrator';
import moment from 'moment';

const JobApplicationReport = () => {
    const [state, setState] = useState({
        TIME_PERIOD: {
            name: 'TIME_PERIOD', value: '7', options: [{ label: 'Last One week', value: '7' },
            { label: 'Last 15 Days', value: '15' },
            { label: 'Last One month', value: '30' },
            { label: 'Last 3 Month', value: '90' },
            { label: 'Custom', value: 'CUSTOM' }
            ],
            isRequired: true
        },
        FROM_DATE: {
            name: 'FROM_DATE', value: '',
            isRequired: false
        }, TO_DATE: {
            name: 'TO_DATE', value: '',
            isRequired: false
        },
        CLIENT: {
            name: 'CLIENT', value: '', options: [],
            isRequired: false

        },
        JOB: {
            name: 'JOB', value: '', options: [],
            isRequired: false
        },
        FIND_BY: {
            name: 'FIND_BY', value: '', options: [],
            isRequired: true
        },
        USERS: {
            name: 'USERS', value: '', options: [],
            isRequired: false
        },
    })

    const [list, setList] = useState(null)

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }


    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));

    }
    useEffect(() => {
        v3_client_options().then((res) => {
            if (res.status) {
                setOptions('CLIENT', res.result.map((item) => {
                    return { value: item.CLIENT_ID, label: item.COMPANY_NAME }
                }))
            }
        })
    }, [])

    useEffect(() => {
        if (state.FIND_BY.value === 'RECRUITER') {
            setState(prevState => ({
                ...prevState,
                ['USERS']: {
                    ...prevState['USERS'],
                    isRequired: true
                }
            }));


            v3_lead_owners().then((res) => {
                if (res.status) {
                    setOptions('USERS', res.result.map((item) => {
                        return { label: item.FULL_NAME, value: item.USER_ID }
                    }))
                }
            })
        }
        else if (state.FIND_BY.value === 'TEAM') {
            setState(prevState => ({
                ...prevState,
                ['USERS']: {
                    ...prevState['USERS'],
                    isRequired: true
                }
            }));
            v3_team_leader_options().then((res) => {
                if (res.status) {
                    setOptions('USERS', res.result.map((item) => {
                        return { label: item.FULL_NAME, value: item.USER_ID }
                    }))
                }
            })
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['CLIENT']: {
                    ...prevState['CLIENT'],
                    isRequired: false,
                    value: '',
                },
                ['JOB']: {
                    ...prevState['JOB'],
                    isRequired: false,
                    value: '',
                },
                ['USERS']: {
                    ...prevState['JOB'],
                    isRequired: false,
                    value: '',

                },
            }));
        }

        if (state.TIME_PERIOD.value === 'CUSTOM') {
            setState(prevState => ({
                ...prevState,
                ['TO_DATE']: {
                    ...prevState['TO_DATE'],
                    isRequired: true,
                    value: '',
                },
                ['FROM_DATE']: {
                    ...prevState['FROM_DATE'],
                    isRequired: true,
                    value: '',

                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['TO_DATE']: {
                    ...prevState['TO_DATE'],
                    isRequired: false,
                    value: '',
                },
                ['FROM_DATE']: {
                    ...prevState['FROM_DATE'],
                    isRequired: false,
                    value: '',
                }
            }));
        }
    }, [state.FIND_BY.value, state.TIME_PERIOD.value])
    const onSearch = () => {
        debugger

        if (validateFunctionalForm(state, setState)) {
            const model = {
                TIME_PERIOD: state.TIME_PERIOD.value,
                CLIENT: state.CLIENT.value,
                JOB: state.JOB.value,
                FIND_BY: state.FIND_BY.value,
                USERS: state.USERS.value,
                FROM_DATE: state.FROM_DATE.value,
                TO_DATE: state.TO_DATE.value,
            }
            v3_job_applications_report(btoa(JSON.stringify(model))).then((res) => {
                if (res.status) {
                    setList(res.result.report)
                }
            })
        }
    }
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Job Application Report</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Dashboard</a>
                                            </li>
                                            <li className="breadcrumb-item active">Job Application Report</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card reportCardHeaderSearchEngine">
                                    <div className="card-header">
                                        <form>
                                            <div className="row g-3">
                                                <div className="col-xxl-3 col-sm-12 col-12">
                                                    <div className="search-box">
                                                        <select
                                                            className="form-select search bg-light border-light customRadiusSearch"
                                                            name={state.TIME_PERIOD.name}
                                                            value={state.TIME_PERIOD.value}
                                                            onChange={onChange}
                                                        >
                                                            {state.TIME_PERIOD.options.map((item) => {
                                                                return (<option value={item.value}> {item.label}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                {state.TIME_PERIOD.value === 'CUSTOM' &&
                                                    <React.Fragment>
                                                        <div className="col-xxl-3 col-sm-12 col-12">
                                                            <div className="form-floating search-box">

                                                                <input
                                                                    type="date"
                                                                    id='fromDate'
                                                                    className="form-control search border-light customRadiusSearch"
                                                                    name={state.FROM_DATE.name}
                                                                    value={state.FROM_DATE.value}
                                                                    onChange={onChange}
                                                                    max={moment().format('YYYY-MM-DD')}

                                                                    // max={state.TO_DATE.value}
                                                                />
                                                                <label htmlFor="fromDate"> From Date</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3 col-sm-12 col-12">
                                                            <div className="form-floating search-box">

                                                                <input
                                                                    type="date"
                                                                    id='toDate'
                                                                    className="form-control search border-light customRadiusSearch"
                                                                    name={state.TO_DATE.name}
                                                                    value={state.TO_DATE.value}
                                                                    onChange={onChange}
                                                                    max={moment().format('YYYY-MM-DD')}
                                                                />
                                                                <label htmlFor="toDate"> To Date</label>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                <div className="col-xxl-3 col-sm-12 col-12">
                                                    <div className="search-box">
                                                        <select
                                                            className={state.FIND_BY.error && !state.FIND_BY.value ? "form-select is-invalid" : "form-select customRadiusSearch"}
                                                            name={state.FIND_BY.name}
                                                            value={state.FIND_BY.value}
                                                            onChange={onChange}

                                                        >
                                                            <option value=''> Find By</option>
                                                            <option value='RECRUITER'> Recruiter Wise</option>
                                                            <option value='TEAM'> Team Leader Wise</option>
                                                            <option value='COMPANY'> Company Wise</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                {state.FIND_BY.value &&
                                                    <React.Fragment>
                                                        {(state.FIND_BY.value === 'RECRUITER' || state.FIND_BY.value === 'TEAM') &&
                                                            <div className="col-xxl-3 col-sm-12 col-12">
                                                                <Select
                                                                    isMulti
                                                                    name="USERS"
                                                                    options={state.USERS.options}
                                                                    value={state.USERS.value}
                                                                    onChange={(e) => {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            ['USERS']: {
                                                                                ...prevState['USERS'],
                                                                                value: e
                                                                            }
                                                                        }));
                                                                    }}
                                                                    className="basic-multi-select customRadiusSearch"
                                                                    classNamePrefix="select"
                                                                />
                                                            </div>}

                                                        {state.FIND_BY.value === 'COMPANY' &&
                                                            <React.Fragment>

                                                                <div className="col-xxl-3 col-sm-3 col-12">
                                                                    <Select
                                                                        isMulti
                                                                        name="CLIENT"
                                                                        options={state.CLIENT.options}
                                                                        value={state.CLIENT.value}
                                                                        onChange={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                ['CLIENT']: {
                                                                                    ...prevState['CLIENT'],
                                                                                    value: e
                                                                                }
                                                                            }));
                                                                            v3_client_wise_active_jobs(e.map(item => { return item.value })).then((res) => {
                                                                                if (res.status) {
                                                                                    setOptions('JOB', res.result.map((item) => {
                                                                                        return { value: item.JOB_ID, label: item.JOB_TITLE }
                                                                                    }))
                                                                                }
                                                                            })
                                                                        }}
                                                                        className="basic-multi-select customRadiusSearch"
                                                                        classNamePrefix="select"
                                                                        placeholder='Select Company'
                                                                    />
                                                                </div>
                                                                <div className="col-xxl-3 col-sm-3 col-12">
                                                                    <div className="input-light">
                                                                        <Select
                                                                            // defaultValue={[colourOptions[2], colourOptions[3]]}
                                                                            isMulti
                                                                            name="JOB"
                                                                            options={state.JOB.options}
                                                                            value={state.JOB.value}
                                                                            onChange={(e) => {
                                                                                debugger
                                                                                setState(prevState => ({
                                                                                    ...prevState,
                                                                                    ['JOB']: {
                                                                                        ...prevState['JOB'],
                                                                                        value: e
                                                                                    }
                                                                                }));
                                                                            }}
                                                                            className="basic-multi-select customRadiusSearch"
                                                                            classNamePrefix="select"
                                                                            placeholder='Select Process'

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>

                                                        }
                                                    </React.Fragment>

                                                }
                                                <div className="col-xxl-3">
                                                    <button type="button" className="btn customReportClorButton" onClick={() => { onSearch() }}>
                                                        <i className="me-1 align-bottom" /> Search
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card reportCardBodySearchEngine">
                                    <div className="card-body">
                                        {list && <Report
                                            list={list}
                                            TYPE={state.FIND_BY.value}
                                        />}

                                        {list === null && <div className="noresult" >
                                            <div className="text-center">
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/msoeawqm.json"
                                                    trigger="loop"
                                                    colors="primary:#121331,secondary:#08a88a"
                                                    style={{ width: 75, height: 75 }}
                                                />
                                                <h5 className="mt-2">Apply Filter to get records as per your requirement</h5>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default JobApplicationReport