import React, { useEffect, useState } from 'react'
import { v3_answer_job_questions } from '../../../../actions/v3/jobApplication'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const JobQuestionsAnswerModal = (props) => {

    const [state, setState] = useState([])
    const [error, setError] = useState(false)

    useEffect(() => {
        const model = props.QUESTIONS.map((item) => {
            return ({ QUESTION_ID: item.QUESTION_ID, QUESTION: item.QUESTION, value: '' })
        })
        setState(model)
    }, [])
    const handleInputChange = (index, value) => {
        const updatedState = [...state];
        updatedState[index].value = value;
        setState(updatedState);
    };


    const onSubmit = (e) => {
        e.preventDefault();
        debugger
        const hasEmptyValues = state.some((item) => !item.value.trim());

        if (hasEmptyValues) {
            setError(true);
        } else {
            setError(false);
            v3_answer_job_questions({ ANSWERS: state, APPLICATION_ID: props.currentApplicationId }).then((res) => {
                if (res.status) {
                    Swal.fire('Success', 'Application Completed', 'success');
                    props.onClose();

                }
                else {
                    toast.error(res.error)
                }
            }).catch((error) => {
                toast.error(error)
            });
        }
    };
    return (
        <React.Fragment>

            <form onSubmit={(e) => { onSubmit(e) }}>
                <div className="row">
                    {state && state.map((item, index) => {
                        return (
                            <div className="col-lg-12 mb-3">
                                <div>
                                    <label htmlFor="companyname-field" className="form-label">
                                        {index + 1}. {item.QUESTION}
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={!item.value && error ? "form-control is-invalid" : "form-control"}
                                        placeholder={item.QUESTION}
                                        id={`question-field-${index}`}
                                        value={item.value}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                    />
                                    <div className="invalid-feedback">Please answer</div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="hstack gap-2 justify-content-end">
                        <button type="button" className="btn btn-light" >
                            Close
                        </button>
                        <button type="submit" className="btn btn-success" id="add-btn">
                            Submit
                        </button>
                    </div>
                </div>


            </form>

        </React.Fragment>
    )
}

export default (JobQuestionsAnswerModal)