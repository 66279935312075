import React, { useState } from 'react'
import ClientDetailComponent from '../../../components/SuperAdmin/client/clientDetail'
import { v1_client } from '../../../actions/v1/crm_client'
import SelectPackages from '../manage_subscription/selectPackages'

const ClientDetail = (props) => {
  const [clientDetail, setClientDetail] = useState(null)
  useState(() => {
    v1_client(props.match.params.id).then((res) => {
      if (res.status) {
        setClientDetail(res.result)
      }
    })
  }, [])
  return (
    <React.Fragment>
      <ClientDetailComponent
        clientDetail={clientDetail}
      />
      <SelectPackages
      // clientDetail={clientDetail}
      />
    </React.Fragment>
  )
}

export default ClientDetail