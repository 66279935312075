import React, { useEffect, useState } from 'react'
import SearchCandidate from '../../../components/companyUser/candidates/hiringStatusWise'
import { v3_search_job_status_wise_candidates } from '../../../actions/v3/candidates'

const List = () => {

    const [list, setList] = useState(null);
    const [count, setCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [refresh, setRefresh] = useState(false);


    const [searchQuery, setSearchQuery] = useState({
        CANDIDATE_NAME: '',
        EMAIL: '',
        PHONE: '',
        SKILLS: '',
        FROM_DATE: '',
        TO_DATE: '',
        APPLICATION_STATUS: ''

    });


    useEffect(() => {

        v3_search_job_status_wise_candidates(btoa(JSON.stringify({ ...searchQuery })), currentPage).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
            }
        })
    }, [currentPage, searchQuery, refresh])



    return (
        <React.Fragment>

            <SearchCandidate
                setSearchQuery={setSearchQuery}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                list={list}
                count={count}
                setRefresh={setRefresh}
                refresh={refresh}
            />
        </React.Fragment>
    )
}

export default List