import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
`;

const Heading = styled.h1`
    color: #333;
    margin-bottom: 20px;
`;

const SubHeading = styled.p`
    color: #666;
    margin-bottom: 30px;
`;

const StyledInput = styled.input`
    width: 40px;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    font-size: 1.5em;
`;

const SubmitButton = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #45a049;
    }
`;

export default function App(props) {
    const [otp, setOtp] = useState('');

    const onSubmit = () => {
        debugger;
        if (String(otp).length === 6) {
            props.onSubmit(otp);
        }
    };

    return (
        <Container>
            <Heading>Verify Your Account</Heading>
            <SubHeading>Please enter the OTP sent to Desk Recruiter</SubHeading>
            <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <StyledInput {...props} />}
            />
            <SubmitButton onClick={onSubmit}>Submit</SubmitButton>
        </Container>
    );
}
