import React, { useEffect, useState } from 'react'
import { v3_designation_options, v3_user_roles, v3_work_location_options } from '../../../../actions/v3/administrator';

const MasterDetails = (props) => {
    const { data } = props;
    const [state, setState] = useState({
        FATHER_NAME: { name: 'FATHER_NAME', value: data?.FATHER_NAME ? data.FATHER_NAME : '', error: '', isRequired: true },
        MOTHER_NAME: { name: 'MOTHER_NAME', value: data?.MOTHER_NAME ? data.MOTHER_NAME : '', options: [], error: '', isRequired: true },
        NATIONALITY: { name: 'NATIONALITY', value: data?.NATIONALITY ? data.NATIONALITY : '', options: [], error: '', isRequired: true },
        DOB: { name: 'DOB', value: data?.DOB ? data.DOB : '', error: '', isRequired: true },
        GENDER: { name: 'GENDER', value: data?.GENDER ? data.GENDER : '', options: [{ value: 'MALE', label: 'Male' }, { value: 'FEMALE', label: 'Female' }, { value: 'PREFER_NOT_TO_SAY', label: 'Prefer not to say' }], error: '', isRequired: false },
        MARITAL_STATUS: { name: 'MARITAL_STATUS', value: data?.MARITAL_STATUS ? data.MARITAL_STATUS : '', options:[{ value: 'MARRIED', label: 'Married' }, { value: 'SINGLE', label: 'Single' }, { value: 'WIDOW', label: 'Widow' }], error: '', isRequired: false },
        PERSONAL_EMAIL: { name: 'PERSONAL_EMAIL', value: data?.PERSONAL_EMAIL ? data.PERSONAL_EMAIL : '', error: '', isRequired: false },

    })

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }





    const onSubmit = () => {
        const model = {
            FATHER_NAME: state.FATHER_NAME.value,
            MOTHER_NAME: state.MOTHER_NAME.value,
            NATIONALITY: state.NATIONALITY.value,
            DOB: state.DOB.value,
            GENDER: state.GENDER.value,
            MARITAL_STATUS: state.MARITAL_STATUS.value,
            PERSONAL_EMAIL: state.PERSONAL_EMAIL.value,


        }
        props.onSubmit(model)
    }
    return (
        <React.Fragment>
            <div className="tab-content pt-4 text-muted">
                <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                >
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Father Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.FATHER_NAME.error && !state.FATHER_NAME.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.FATHER_NAME.name}
                                                    value={state.FATHER_NAME.value}
                                                    onChange={onChange}
                                                    placeholder='Father Name'

                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Mother Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.MOTHER_NAME.error && !state.MOTHER_NAME.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.MOTHER_NAME.name}
                                                    value={state.MOTHER_NAME.value}
                                                    onChange={onChange}
                                                    placeholder='Mother Name'

                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Nationality
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.NATIONALITY.error && !state.NATIONALITY.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.NATIONALITY.name}
                                                    value={state.NATIONALITY.value}
                                                    onChange={onChange}
                                                    placeholder='Nationality    '

                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Date of bith
                                                </label>
                                                <input
                                                    type="date"
                                                    className={state.DOB.error && !state.DOB.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.DOB.name}
                                                    value={state.DOB.value}
                                                    onChange={onChange}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="lastnameInput" className="form-label">
                                                Gender
                                                </label>
                                                <select
                                                    className={state.GENDER.error && !state.GENDER.value ? "form-select is-invalid" : "form-select"}
                                                    name={state.GENDER.name}
                                                    value={state.GENDER.value}
                                                    onChange={onChange}
                                                >
                                                    <option> Select Gender</option>
                                                    {
                                                        state.GENDER.options.map((item) => {
                                                            return (
                                                                <option value={item.value}>{item.label}</option>

                                                            )
                                                        })

                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="lastnameInput" className="form-label">
                                                    Marital Status
                                                </label>
                                                <select
                                                    className={state.MARITAL_STATUS.error && !state.MARITAL_STATUS.value ? "form-select is-invalid" : "form-select"}
                                                    name={state.MARITAL_STATUS.name}
                                                    value={state.MARITAL_STATUS.value}
                                                    onChange={onChange}

                                                >
                                                    <option value=''>Select Marital Status</option>
                                                    {
                                                        state.MARITAL_STATUS.options.map((item) => {
                                                            return (
                                                                <option value={item.value}>{item.label}</option>

                                                            )
                                                        })

                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        
                                        <div className="col-xxl-12 col-md-12">
                                            <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default MasterDetails