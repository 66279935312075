import React, { useState } from 'react'
import { validateFunctionalForm } from '../../../utils';

const DesignationForm = (props) => {

    const [state, setState] = useState({
        NAME: { name: 'NAME', value: props.data?.NAME ? props.data.NAME : '', error: '', isRequired: true },
        STATUS: { name: 'STATUS', value: props.data?.STATUS ? Boolean(props.data.STATUS) : true, error: '', isRequired: true },
        DETAIL: { name: 'DETAIL', value: props.data?.DETAILS ? props.data.DETAILS : '', error: '', isRequired: false },
    })
    const clearForm = () => {
        setState({
            NAME: { name: 'NAME', value: '', error: '', isRequired: true },
            STATUS: { name: 'STATUS', value: true, error: '', isRequired: true },
            DETAIL: { name: 'DETAIL', value: '', error: '', isRequired: false },
        });
    }


    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const onSubmit = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                NAME: state.NAME.value,
                STATUS: state.STATUS.value,
                DETAIL: state.DETAIL.value
            }
            props.onSubmit(model)
        }
    }
    return (
        <React.Fragment>

            <div className="row">
                <div className="col-lg-12">
                    <div className="live-preview">
                        <div className="row gy-4">
                            <div className="col-xxl-6 col-md-6">
                                <div>
                                    <label className="form-label">
                                        Designation Name
                                    </label>
                                    <input
                                        type="text"
                                        className={state.NAME.error.length > 0 && !state.NAME.value ? "form-control is-invalid" : "form-control "}
                                        placeholder='Designation Name'
                                        name={state.NAME.name}
                                        value={state.NAME.value}
                                        onChange={onChange}

                                    />
                                    <div className="invalid-feedback">Please enter designation</div>

                                </div>
                            </div>
                            <div className="col-xxl-6 col-md-6">
                                <div>

                                    <label className="form-label">
                                        Status
                                    </label>
                                    <div class="form-check form-switch form-switch-lg">
                                        <input class="form-check-input code-switcher" type="checkbox"
                                            checked={state.STATUS.value}
                                            onClick={() => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    ['STATUS']: {
                                                        ...prevState['STATUS'],
                                                        value: !state.STATUS.value
                                                    }
                                                }));
                                            }}
                                            id="icon-button" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-12 col-md-6">
                                <div>
                                    <label className="form-label">
                                        Designation Detail
                                    </label>
                                    <textarea
                                        className={state.DETAIL.error.length > 0 && !state.DETAIL.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Designation Detail"
                                        name={state.DETAIL.name}
                                        value={state.DETAIL.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-xxl-12 col-md-12">
                                <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">{props.buttonName}</button>
                                <button type="button" onClick={() => { clearForm() }} class="btn btn-danger waves-effect waves-light mx-3">Clear Form</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default DesignationForm