import axios from 'axios'
import { getCompanyAdminAuthHeader } from '../../utils';

export const v2_user_roles = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/roles`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_add_user = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/users`;
    return axios.post(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_edit_user = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/users`;
    return axios.put(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_user_list = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/users/${page}`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_user_team_mapping_list = async (USER_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/users/team-mapping/${USER_ID}`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_user_team_mapping_update = async (model, USER_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/users/team-map/${USER_ID}`;
    return axios.put(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_user_status_update = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/users/status`;
    return axios.patch(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}
