import React, { useState } from 'react';
import styles from './style.module.css';
import moment from 'moment';
const Detail = ({ label, value }) => {
    return (
        <div className={styles.detail}>
            <span className={styles.label}>{label} : </span>
            <span className={styles.value}>{value}</span>
        </div>
    );
};

const CanididateDetailDrawerModal = (props) => {
    const { detail } = props;
    return (
        <div>
            <h2>Client Lead Details for demo</h2>
            <div className={styles['details-container']}>
                <Detail label="Request ID" value={detail.REQUEST_ID} />
                <Detail label="Company Name" value={detail.COMPANY_NAME} />
                <Detail label="Requester Name" value={detail.NAME} />
                <Detail label="Current Status" value={detail.CURRENT_STATUS} />
                <Detail label="Recruiter Size" value={detail.RECRUITERS_SIZE} />
                <Detail label="Business Email" value={detail.BUSINESS_EMAIL} />
                <Detail label="Phone" value={detail.PHONE} />
                <Detail label="Location" value={detail.LOCATION} />
                <Detail label="Requirement Details" value={detail.REQUIREMENT_DETAILS} />
                <Detail label="Requested Date" value={moment(detail.CREATED_ON).format('DD MMM YYYY')} />


            </div>
        </div>
    );
};

export default CanididateDetailDrawerModal;
