import React from 'react'
import AddForm from '../../../components/SuperAdmin/client/add'
import { v1_onboard_client } from '../../../actions/v1/crm_client'
import { ToastContainer, toast } from 'react-toastify'
import constant from '../../../constant'
const Add = (props) => {

  const onSubmit = (model) => {
    v1_onboard_client(model).then((res) => {
      if (res.status) {
        // toast.success('Client Added Sucessfully')
        props.history.push(constant.superAdminComponent.clientDetail.url.replace(':id', res.result))

      }
      else {
        toast.error(res.error)

      }
    })
  }
  return (
    <React.Fragment>
      <AddForm
        onSubmit={onSubmit}
      />
      <ToastContainer />
    </React.Fragment>
  )
}

export default Add



