import React from 'react'
import Cookies from 'js-cookie';
import constant from '../../constant';

const Middleware = (props) => {
    console.log(atob(props.match.params.url), window.location.protocol + '//' + window.location.host)
    debugger
    if (atob(props.match.params.url) === window.location.protocol + '//' + window.location.host) {
        Cookies.set('parent_id', atob(props.match.params.parent_id));
        props.history.push(constant.nonAuthedcomponent.login.url.replace(':user_type', 'user'))
    }
    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default Middleware