import Loader from "react-loader-spinner";
import React from "react";
export default function App() {
    return (
        <React.Fragment>
            <div className="loader-overlay">
                {/* <Loader
                    type="Puff"
                    color="#008bd2"
                    height={100}
                    width={100}
                    // timeout={3000} //3 secs
                /> */}

                {/* <Loader
                    type="BallTriangle"
                    color="#008bd2"
                    height={80}
                    width={80}
                    // timeout={10000}
                /> */}

                {/* <Loader
                    type="Audio"
                    color="#008bd2"
                    height={80}
                    width={80}
                    // timeout={10000}
                /> */}

                {/* <Loader
                    type="Bars"
                    color="#008bd2"
                    height={80}
                    width={80}
                    // timeout={10000}
                /> */}

                <Loader
                    type="Circles"
                    color="#008bd2"
                    height={80}
                    width={80}
                />


                {/* <Loader
                    type="Grid"
                    color="#008bd2"
                    height={80}
                    width={80}
                    // timeout={10000}
                /> */}

                {/* <Loader
                    type="Hearts"
                    color="#008bd2"
                    height={80}
                    width={80}
                    // timeout={10000}
                /> */}

                {/* <Loader
                    type="Oval"
                    color="#008bd2"
                    height={80}
                    width={80}
                    // timeout={10000}
                /> */}

                {/* <Loader
                    type="Rings"
                    color="#008bd2"
                    height={80}
                    width={80}
                    // timeout={10000}
                /> */}

                {/* <Loader
                    type="TailSpin"
                    color="#008bd2"
                    height={80}
                    width={80}
                    // timeout={10000}
                /> */}

                {/* <Loader
                    type="ThreeDots"
                    color="#008bd2"
                    height={80}
                    width={80}
                    // timeout={10000}
                /> */}
            </div>
        </React.Fragment>
    );
}
