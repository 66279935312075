import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { v3_admin_dashboard_application_statistics } from '../../../../actions/v3/administrator';

export const options = {
    hAxis: {
        title: "Month",
    },
    series: {
        1: { curveType: "function" },
    },
};


const ApplicationStatistics = () => {
    const [data, setData] = useState(null)
    const [dateRange, setDateRange] = useState('1 Month')
    useEffect(() => {
        v3_admin_dashboard_application_statistics(dateRange).then(res => {
            if (res.status) {
                setData(res.result)
            }
        })
    }, [dateRange])

    return (
        <React.Fragment>
            <div className="card card-height-100">
                <div className="card-header border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Applications Statistic</h4>

                    <div className="chartBaseBtn">
                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => {
                            setDateRange('1 Month')
                        }}>
                            1M
                        </button>
                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => {
                            setDateRange('3 Month')
                        }}>
                            3M
                        </button>
                        <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => {
                            setDateRange('6 Month')
                        }}>
                            6Y
                        </button>
                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => {
                            setDateRange('12 Month')
                        }}>
                            1Y
                        </button>
                    </div>
                </div>
                {/* end card header */}
                <div className="card-header border-0" style={{ padding: '0px 10px !important', }}>
                    <div className="row g-0 text-center">
                        <div className="col-6 col-sm-3 p-2">
                            <div className="p-3 border border-dashed border-start-0 custondashedStyleSection">
                                <h5 className="mb-1">
                                    <span className="counter-value" >
                                        {data?.totalCandidates}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">New Candidates</p>
                            </div>
                        </div>
                        {/*end col*/}
                        <div className="col-6 col-sm-3 p-2">
                            <div className="p-3 border border-dashed border-start-0 custondashedStyleSection">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {data?.totalApplications}

                                    </span>
                                </h5>
                                <p className="text-muted mb-0">New Applications</p>
                            </div>
                        </div>
                        {/*end col*/}
                        <div className="col-6 col-sm-3 p-2">
                            <div className="p-3 border border-dashed border-start-0 custondashedStyleSection">
                                <h5 className="mb-1">
                                    <span className="counter-value" >
                                        {data?.totalSelected}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Hired</p>
                            </div>
                        </div>
                        {/*end col*/}
                        <div className="col-6 col-sm-3 p-2">
                            <div className="p-3 border border-dashed custondashedStyleSection">
                                <h5 className="mb-1 text-success">
                                    <span className="counter-value" >
                                        {data?.totalJoined}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Joined</p>
                            </div>
                        </div>
                        {/*end col*/}
                    </div>
                </div>
                {/* end card header */}
                <div className="card-body p-0 pb-2 customChartStyleLegend">
                    <div className="w-100">
                        {data && <Chart
                            chartType="LineChart"
                            width="100%"
                            height="400px"
                            data={data.data}
                            options={options}
                        />}
                    </div>
                </div>
                {/* end card body */}
            </div>
        </React.Fragment>
    )
}

export default ApplicationStatistics