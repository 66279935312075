import React from 'react'
import moment from 'moment'
import constant from '../../../../constant'

const NonPosted = (props) => {
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Incomplete Jobs</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">View Jobs</a>
                                            </li>
                                            <li className="breadcrumb-item active">Non Posted Jobs</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end page title */}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form>
                                            <div className="row g-3">
                                                <div className="col-xxl-4 col-sm-12">
                                                    <div className="search-box">
                                                        <input
                                                            type="text"
                                                            className="form-control search bg-light border-light"
                                                            id="searchJob"
                                                            autoComplete="off"
                                                            placeholder="Search for jobs or companies..."
                                                        />
                                                        <i className="ri-search-line search-icon" />
                                                    </div>
                                                </div>
                                                {/*end col*/}
                                                <div className="col-xxl-3 col-sm-4">
                                                    <input
                                                        type="text"
                                                        className="form-control bg-light border-light"
                                                        id="datepicker"
                                                        data-provider="flatpickr"
                                                        data-date-format="d M, Y"
                                                        data-range-date="true"
                                                        placeholder="Select date"
                                                    />
                                                </div>
                                                {/*end col*/}
                                                <div className="col-xxl-2 col-sm-4">
                                                    <div className="input-light">
                                                        <select
                                                            className="form-control"
                                                            data-choices=""
                                                            data-choices-search-false=""
                                                            name="choices-idType"
                                                            id="idType"
                                                        >
                                                            <option value="all" selected="">
                                                                All
                                                            </option>
                                                            <option value="Full Time">Full Time</option>
                                                            <option value="Part Time">Part Time</option>
                                                            <option value="Internship">Internship</option>
                                                            <option value="Freelance">Freelance</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/*end col*/}
                                                <div className="col-xxl-2 col-sm-4">
                                                    <div className="input-light">
                                                        <select
                                                            className="form-control"
                                                            data-choices=""
                                                            data-choices-search-false=""
                                                            name="choices-single-default"
                                                            id="idStatus"
                                                        >
                                                            <option value="all" selected="">
                                                                All
                                                            </option>
                                                            <option value="Active">Active</option>
                                                            <option value="New">New</option>
                                                            <option value="Close">Close</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/*end col*/}
                                                <div className="col-xxl-1 col-sm-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100"
                                                        onclick="filterData();"
                                                    >
                                                        <i className="ri-equalizer-fill me-1 align-bottom" />{" "}
                                                        Filters
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="flex-grow-1">
                                        <p className="text-muted fs-14 mb-0">
                                            Result: <span id="total-result" />
                                        </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="dropdown">
                                            <a
                                                className="text-muted fs-14 dropdown-toggle"
                                                href="#"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                All View
                                            </a>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" id="job-list">
                            <div className="col-12">
                                <div className="list-group">
                                    {props.jobList && props.jobList.map((item) => {
                                        return (<a
                                            href={constant.companyUserComponent.updateJob.url.replace(':step',item.COMPLETED_STEPS).replace(':job_id',item.JOB_ID)}
                                            className="list-group-item list-group-item-action"
                                        >
                                            <div className="float-end"><span class="badge badge-label bg-warning"><i class="mdi mdi-circle-medium"></i> Pending</span>
                                                <div className="alert border-0 alert-warning  mt-2" role="alert">
                                                    {item.COMPLETED_STEPS == 2 ? 'Please Complete Step 3' : item.COMPLETED_STEPS == 3 ? 'Review and Post Job' : ''}
                                                </div>

                                            </div>
                                            <div className="d-flex mb-2 align-items-center">
                                                <div className="flex-shrink-0">

                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h5 className="list-title fs-15 mb-1">{item.JOB_TITLE}</h5>
                                                    <h6 className="list-title fs-12 mb-1">{item.COMPANY_NAME}</h6>
                                           
                                                    <p className="list-text mb-3 fs-12"><strong>Last Updated </strong> {moment(item.MODIFIED_ON).fromNow()}</p>
                                                    <p className="list-text mb-0 fs-12"><strong>Work Experience :</strong> {item.WORK_EXP_MIN + ' Year - ' + item.WORK_EXP_MAX + ' Year'}, <strong>Salary Range :</strong> {item.SALARY_MIN + ' Lakh - ' + item.SALARY_MAX + ' Lakh'}</p>
                                                    <p className="list-text mb-3 fs-12"></p>

                                                </div>
                                            </div>

                                        </a>)
                                    })
                                    }

                                </div>
                            </div>


                        </div>
                        {/* <div className="row g-0 justify-content-end mb-4" id="pagination-element">
                            <div className="col-sm-6">
                                <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                                    <div className="page-item">
                                        <a
                                            href="javascript:void(0);"
                                            className="page-link"
                                            id="page-prev"
                                        >
                                            Previous
                                        </a>
                                    </div>
                                    <span id="page-num" className="pagination" />
                                    <div className="page-item">
                                        <a
                                            href="javascript:void(0);"
                                            className="page-link"
                                            id="page-next"
                                        >
                                            Next
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                
            </div>

        </React.Fragment>
    )
}

export default NonPosted