import React, { useEffect, useState } from 'react'
import { v1_employment_type, v1_functional_area, v1_industry, v1_job_role } from '../../../../actions/v1/master_data';
import nl2br from 'react-nl2br'
const JobOverview = (props) => {

    const { jobData } = props;
    const [employmentType, setEmploymentType] = useState([])
    const [functionalArea, setFunctionalArea] = useState([])
    const [industry, setIndustry] = useState([])
    const [roles, setRoles] = useState([])

    useEffect(() => {
        v1_employment_type().then((res) => {
            if (res.status) {
                setEmploymentType(res.result)
            }
            else {
                console.error(res.error)
            }
        })

        v1_industry().then((res) => {
            if (res.status) {
                setIndustry(res.result)
            }
            else {
                console.error(res.error);
            }
        })

        v1_functional_area().then((res) => {
            if (res.status) {
                setFunctionalArea(res.result)
            }
            else {
                console.error(res.error);
            }
        })


        v1_job_role(jobData.FUNCTIONAL_AREA).then((res) => {
            if (res.status) {
                setRoles(res.result)
            }
            else {
                console.error(res.error);
            }
        })

    }, [])
    function getEmploymentTypeById(employmentTypeId, employmentTypes) {
        // Find the object with the matching EMPLOYMENT_TYPE_ID
        const foundType = employmentTypes.find(type => type.EMPLOYMENT_TYPE_ID === employmentTypeId);

        // If a matching object is found, return its EMPLOYMENT_TYPE value
        if (foundType) {
            return foundType.EMPLOYMENT_TYPE;
        } else {
            // If no matching object is found, return null or an appropriate value
            return null; // Or you can return a default value like "Unknown Employment Type"
        }
    }

    function getIndustryById(ID, Industry) {
        // Find the object with the matching EMPLOYMENT_TYPE_ID
        const foundType = Industry.find(type => type.INDUSTRY_ID === ID);

        // If a matching object is found, return its EMPLOYMENT_TYPE value
        if (foundType) {
            return foundType.INDUSTRY;
        } else {
            // If no matching object is found, return null or an appropriate value
            return null; // Or you can return a default value like "Unknown Employment Type"
        }
    }


    function getRoleById(ID, roles) {

        const foundType = roles.find(type => type.ROLE_ID === ID);
        if (foundType) {
            return foundType.ROLE_NAME;
        } else {
            return null;
        }
    }


    function getFunctionalAreaById(ID, functionalArea) {
        // Find the object with the matching EMPLOYMENT_TYPE_ID
        const foundType = functionalArea.find(type => type.FUNCTIONAL_AREA_ID === ID);

        // If a matching object is found, return its EMPLOYMENT_TYPE value
        if (foundType) {
            return foundType.FUNCTIONAL_AREA;
        } else {
            // If no matching object is found, return null or an appropriate value
            return null; // Or you can return a default value like "Unknown Employment Type"
        }
    }


    return (
        <React.Fragment>
            <div className="tab-content">
                <div
                    className="tab-pane fade active show"
                    id="pills-gen-info"
                    role="tabpanel"
                    aria-labelledby="pills-gen-info-tab"
                >
                    <div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card mt-n4 mx-n4">
                                        <div className="bg-warning-subtle">
                                            <div className="card-body px-4 pb-4">
                                                <div className="row mb-3">
                                                    <div className="col-md">
                                                        <div className="row align-items-center g-3">
                                                            <div className="col-md-auto">

                                                            </div>
                                                            <div className="col-md">
                                                                <div>
                                                                    <h4 className="fw-bold">{jobData.JOB_TITLE}</h4>
                                                                    <div className="hstack gap-3 flex-wrap">
                                                                        <div>
                                                                            <i className="ri-building-line align-bottom me-1" />{" "}
                                                                            {jobData.COMPANY_NAME}
                                                                        </div>
                                                                        <div className="vr" />
                                                                        <div>
                                                                            <i className="ri-map-pin-2-line align-bottom me-1" />{" "}
                                                                            {jobData.CLIENT_ADDRESS}
                                                                        </div>
                                                                        <div className="vr" />
                                                                        {/* <div>
                                                                            Post Date :{" "}
                                                                            <span className="fw-medium">15 Sep, 2021</span>
                                                                        </div> */}
                                                                        {/* <div className="vr" /> */}
                                                                        <div className="badge rounded-pill bg-success fs-12">
                                                                            {getEmploymentTypeById(jobData.EMPLOYMENT_TYPE, employmentType)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-n5">
                                <div className="col-xxl-9">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-3">Job Description</h5>
                                            <p className="text-muted mb-2">
                                                {nl2br(jobData.JOB_DETAILS)}
                                            </p>


                                            <div>
                                                <h5 className="mb-3">Skill &amp; Experience</h5>
                                                <ul className="text-muted vstack gap-2">
                                                    {jobData.SKILLS.split(',').map((item) => {
                                                        return (<li>{item}</li>)
                                                    })}
                                                </ul>
                                            </div>
                                            {jobData.JOB_QUESTIONS.length > 0 && <ul className="list-inline mb-0">
                                                <li className="list-inline-item">
                                                    <h5 className="mb-3">Job Questions:</h5>
                                                </li>
                                                {jobData.JOB_QUESTIONS.map((item, index) => {
                                                    return (
                                                        <li className="">
                                                            <h6  >
                                                                {(index + 1) + '. ) '}  {item.QUESTION}
                                                            </h6>
                                                        </li>
                                                    )
                                                })
                                                }
                                            </ul>}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xxl-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">Job Overview</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td className="fw-medium">Experience</td>
                                                            <td>{(jobData.WORK_EXP_MIN == 0 ? 'Fresher' : jobData.WORK_EXP_MIN + ' Years') + ' to ' + jobData.WORK_EXP_MAX + ' Years'}</td>

                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Salary Range</td>
                                                            <td>{jobData.SALARY_CURRENCY + ' ' + jobData.SALARY_MIN + ' Lakh to ' + jobData.SALARY_MAX + ' Lakh'}</td>

                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Total Vacancy</td>
                                                            <td>{jobData.NO_OF_VACANCY}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Working Type</td>
                                                            <td>{jobData.IS_WORK_FROM_HOME_JOB === 'N' ? 'Office' : jobData.WORK_FROM_TYPE === 'PRJ' ? 'Fully Remote' : 'Flexible'}</td>
                                                        </tr>
                                                        {(jobData.IS_WORK_FROM_HOME_JOB === 'N' || jobData.WORK_FROM_TYPE === 'TRJ') && <tr>
                                                            <td className="fw-medium">Office Location</td>
                                                            <td>{jobData.OFFICE_LOCATION}</td>
                                                        </tr>}
                                                        <tr>
                                                            <td className="fw-medium">Qualification</td>
                                                            <td>{jobData.QUALIFICATION.map((item) => { return item.label }).join(',').replace(',', ', ')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Course</td>
                                                            <td>{jobData.EDUCATION_QUALIFICATION.map((item) => { return item.label }).join(',').replace(',', ', ')}</td>
                                                        </tr>
                                                        {jobData.COURSE_SPECIALIZATION.length > 0 && <tr>
                                                            <td className="fw-medium">Specialization</td>
                                                            <td>{jobData.COURSE_SPECIALIZATION.map((item) => { return item.label }).join(',').replace(',', ', ')}</td>
                                                        </tr>}
                                                        <tr>
                                                            <td className="fw-medium">Industry</td>
                                                            <td>
                                                                {getIndustryById(jobData.INDUSTRY, industry)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Functional Area</td>
                                                            <td>
                                                                {getFunctionalAreaById(jobData.FUNCTIONAL_AREA, functionalArea)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Role</td>
                                                            <td>
                                                                {getRoleById(jobData.ROLE, roles)}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    {/*end card*/}
                                    {jobData.INCLUDE_WALK_IN_DETAILS === 'Y' && <div className="card">
                                        <div className="card-body">

                                            <div className="text-center">
                                                <a href="javascript:void(0)">
                                                    <h5 className="mt-3">Walk-In Details</h5>
                                                </a>
                                                {/* <p className="text-muted">IT Department</p> */}
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td className="fw-medium">Start Date</td>
                                                            <td>{jobData.WALK_IN_START_DATE}</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="fw-medium">Start Time</td>
                                                            <td>{jobData.WALK_IN_START_TIME}</td>
                                                        </tr> <tr>
                                                            <td className="fw-medium">Start Time</td>
                                                            <td>{jobData.WALK_IN_END_TIME}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Duration (Days)</td>
                                                            <td>{jobData.WALK_IN_DURATION}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Contact Person</td>
                                                            <td>{jobData.WALK_IN_CONTACT_PERSON}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Contact Number</td>
                                                            <td>{jobData.WALK_IN_CONTACT_NUMBER}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Venue</td>
                                                            <td>{jobData.WALK_IN_VENUE}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <div className="ratio ratio-4x3">
                                                                    <iframe
                                                                        src={jobData.GOOGLE_MAP_URL}
                                                                        height={450}
                                                                        style={{ border: 0 }}
                                                                        allowFullScreen=""
                                                                        loading="lazy"
                                                                        referrerPolicy="no-referrer-when-downgrade"
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                {/*end table*/}
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="text-center">
                                                <a href="javascript:void(0)">
                                                    <h5 className="mt-3">Client Details</h5>
                                                </a>
                                                {/* <p className="text-muted">IT Department</p> */}
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td className="fw-medium">Name</td>
                                                            <td>{jobData.CLIENT_NAME}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-medium">Address</td>
                                                            <td>{jobData.CLIENT_ADDRESS}</td>
                                                        </tr>
                                                        <tr >
                                                            <td colSpan={2}>
                                                                {jobData.CLIENT_DETAILS}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )

}

export default JobOverview