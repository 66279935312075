import axios from 'axios'
import { getCompanyAdminAuthHeader } from '../../utils';

export const v2_add_client = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/client`;
    return axios.post(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v2_edit_client = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/client`;
    return axios.put(url, model, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v2_search_client = async (query) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/search-client?query=${query}`;
    return axios.get(url, getCompanyAdminAuthHeader()).then((res) => {
        return res.data;
    })

}
