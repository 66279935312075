
import axios from 'axios'
import { getSuperAdminAuthHeader } from '../../utils';

export const v1_dashboard_count_stats = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/dashboard/count-stats`;
    return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}
export const crm_client_bar_chart_record = async (from, to) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/dashboard/crm-client-bar-chart-record/${from}/${to}`;
    return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}
