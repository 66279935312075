import React, { useEffect, useState } from 'react'
import { validateFunctionalForm } from '../../../../utils'
import Select from 'react-select';
import { v3_lead_owners } from '../../../../actions/v3/candidates';

const Edit = (props) => {

    const [state, setState] = useState({
        CLIENT_NAME: { name: 'CLIENT_NAME', value: props.data.CLIENT_NAME ? props.data.CLIENT_NAME : '', error: '', isRequired: true },
        COMPANY_NAME: { name: 'COMPANY_NAME', value: props.data.COMPANY_NAME ? props.data.COMPANY_NAME : '', error: '', isRequired: true },
        PHONE: { name: 'PHONE', value: props.data.PHONE ? props.data.PHONE : '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: props.data.EMAIL ? props.data.EMAIL : '', error: '', isRequired: true },
        CLIENT_ADDRESS: { name: 'CLIENT_ADDRESS', value: props.data.CLIENT_ADDRESS ? props.data.CLIENT_ADDRESS : '', error: '', isRequired: true },
        CLIENT_DETAILS: { name: 'CLIENT_DETAILS', value: props.data.CLIENT_DETAILS ? props.data.CLIENT_DETAILS : '', error: '', isRequired: false },
        AGREEMENT_START_DATE: { name: 'AGREEMENT_START_DATE', value: props.data.AGREEMENT_START_DATE ? props.data.AGREEMENT_START_DATE : '', error: '', isRequired: true },
        AGREEMENT_END_DATE: { name: 'AGREEMENT_END_DATE', value: props.data.AGREEMENT_END_DATE ? props.data.AGREEMENT_END_DATE : '', error: '', isRequired: true },
        CLIENT_DOC: { name: 'CLIENT_DOC', value: props.data.CLIENT_DOC ? props.data.CLIENT_DOC : '', error: '', isRequired: false },
        SERVICE_AGREEMENT_DOC: { name: 'SERVICE_AGREEMENT_DOC', value: props.data.SERVICE_AGREEMENT_DOC ? props.data.SERVICE_AGREEMENT_DOC : '', error: '', isRequired: false },
        RATE_CARD: { name: 'RATE_CARD', value: props.data.RATE_CARD ? props.data.RATE_CARD : '', error: '', isRequired: false },
        GST_NO: { name: 'GST_NO', value: props.data.GST_NO ? props.data.GST_NO : '', error: '', isRequired: false },
        INTERNAL_SPOC: { name: 'INTERNAL_SPOC', value: props.data.INTERNAL_SPOC ? props.data.INTERNAL_SPOC : '', error: '', isRequired: false },
   
    })
    
    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }
    useEffect(()=>{
        v3_lead_owners().then((res)=>{
          setOptions('INTERNAL_SPOC',res.result.map((item) => {
            return {
                value: item.USER_ID,
                label: item.FULL_NAME
            }
        }))  
        })
    },[])

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    };

    const onSubmit = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                CLIENT_NAME: state.CLIENT_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                COMPANY_NAME: state.COMPANY_NAME.value,
                CLIENT_ADDRESS: state.CLIENT_ADDRESS.value,
                CLIENT_DETAILS: state.CLIENT_DETAILS.value,
                AGREEMENT_START_DATE: state.AGREEMENT_START_DATE.value,
                AGREEMENT_END_DATE: state.AGREEMENT_END_DATE.value,
                CLIENT_DOC: state.CLIENT_DOC.value,
                SERVICE_AGREEMENT_DOC: state.SERVICE_AGREEMENT_DOC.value,
                RATE_CARD: state.RATE_CARD.value,
                GST_NO: state.GST_NO.value,
                CLIENT_ID: props.data.CLIENT_ID,
                INTERNAL_SPOC: state.INTERNAL_SPOC.value.map(item=>{return item.value}),
                INTERNAL_SPOC_CHANGED: state.INTERNAL_SPOC.value != props.data.INTERNAL_SPOC,


            }
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }
            props.onSubmit(formData)
        }
    }
    return (
        <React.Fragment>
            <div className="live-preview">
                <div className="row gy-4">
                    <div className="col-xxl-3 col-md-6">
                        <div>
                            <label className="form-label">
                                Company Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder='Company Name'
                                name={state.COMPANY_NAME.name}
                                value={state.COMPANY_NAME.value}
                                onChange={onChange}

                            />
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6">
                        <div>
                            <label className="form-label">
                                SPOC Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder='SPOC Name'
                                name={state.CLIENT_NAME.name}
                                value={state.CLIENT_NAME.value}
                                onChange={onChange}

                            />
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6">
                        <div >
                            <label className="form-label">
                                Email
                            </label>
                            <div className='form-icon'>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="form-control form-control-icon"
                                    name={state.EMAIL.name}
                                    value={state.EMAIL.value}
                                    onChange={onChange}
                                />
                                <i className='ri-mail-unread-line' />
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6">
                        <div>
                            <label className="form-label">
                                Phone
                            </label>
                            <div className='form-icon'>
                                <input
                                    type="phone"
                                    className="form-control form-control-icon"
                                    placeholder="Phone"
                                    name={state.PHONE.name}
                                    value={state.PHONE.value}
                                    onChange={onChange}
                                />
                                <i className='ri-phone-line' />

                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6">
                        <div>
                            <label className="form-label">
                                GST No
                            </label>
                            <div className=''>
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder="GST No"
                                    name={state.GST_NO.name}
                                    value={state.GST_NO.value}
                                    onChange={onChange}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6">
                        <div>
                            <label className="form-label">
                                Agreement Start Date
                            </label>
                            <div className=''>
                                <input
                                    type="date"
                                    className="form-control"
                                    name={state.AGREEMENT_START_DATE.name}
                                    value={state.AGREEMENT_START_DATE.value}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-md-6">
                        <div>
                            <label className="form-label">
                                Agreement End Date
                            </label>
                            <div className=''>
                                <input
                                    type="date"
                                    className="form-control "
                                    name={state.AGREEMENT_END_DATE.name}
                                    value={state.AGREEMENT_END_DATE.value}
                                    onChange={onChange}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6">
                        <div>
                            <label className="form-label">
                                Client Document
                            </label>
                            <div className=''>
                                <input
                                    type="file"
                                    className="form-control"
                                    name={state.CLIENT_DOC.name}
                                    onChange={(e) => {
                                        setState(prevState => ({
                                            ...prevState,
                                            ['CLIENT_DOC']: {
                                                ...prevState['CLIENT_DOC'],
                                                value: e.target.files[0]
                                            }
                                        }));
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6">
                        <div>
                            <label className="">
                                Service Agreement Document
                            </label>
                            <div className='form-icon'>
                                <input
                                    type="file"
                                    className="form-control"
                                    name={state.SERVICE_AGREEMENT_DOC.name}
                                    onChange={(e) => {
                                        setState(prevState => ({
                                            ...prevState,
                                            ['SERVICE_AGREEMENT_DOC']: {
                                                ...prevState['SERVICE_AGREEMENT_DOC'],
                                                value: e.target.files[0]
                                            }
                                        }));
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6">
                        <div>
                            <label className="form-label">
                                Rate Card
                            </label>
                            <div className=''>
                                <input
                                    type="file"
                                    className="form-control"
                                    name={state.RATE_CARD.name}
                                    onChange={(e) => {
                                        setState(prevState => ({
                                            ...prevState,
                                            ['RATE_CARD']: {
                                                ...prevState['RATE_CARD'],
                                                value: e.target.files[0]
                                            }
                                        }));
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Internal SPOC
                                                            <span className='text-danger'>&nbsp;*</span>

                                                        </label>
                                                        <Select
                                                            isMulti
                                                            name="INTERNAL_SPOC"
                                                            options={state.INTERNAL_SPOC.options}
                                                            value={state.INTERNAL_SPOC.value}
                                                            onChange={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    ['INTERNAL_SPOC']: {
                                                                        ...prevState['INTERNAL_SPOC'],
                                                                        value: e
                                                                    }
                                                                }));
                                                            }}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                        />
                                                        <div className="invalid-feedback">
                                                            Please select internal spoc
                                                        </div>
                                                    </div>
                                                </div>
                    <div className="col-xxl-12 col-md-12">
                        <div>
                            <label className="form-label">
                                Client Address
                            </label>
                            <textarea
                                className="form-control"
                                placeholder="Client Address"
                                name={state.CLIENT_ADDRESS.name}
                                value={state.CLIENT_ADDRESS.value}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-xxl-12 col-md-12">
                        <div>
                            <label className="form-label">
                                Client Detail
                            </label>
                            <textarea
                                className="form-control"
                                placeholder="Client Detail"
                                name={state.CLIENT_DETAILS.name}
                                value={state.CLIENT_DETAILS.value}
                                onChange={onChange}
                            />
                        </div>
                    </div>

                    <div className="col-xxl-12 col-md-12">
                        <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">Save</button>
                        <button type="button" onClick={() => { props.onClose() }} class="btn btn-danger waves-effect waves-light mx-3">Cancel</button>

                    </div>

                </div>
                {/*end row*/}
            </div>

        </React.Fragment>
    )
}

export default Edit