import './App.css';
import React, { useEffect, useState } from 'react';
import Router from './Router';
import { io } from 'socket.io-client';
import { getStorage, setSessionStorage } from './utils';
import constant from './constant';
import Swal from 'sweetalert2';

const socket = io(`${process.env.REACT_APP_BASE_URL}`, {
  auth: {
    token: getStorage(constant.keys.companyUserToken)
  }
});

function App() {
  useEffect(() => {
    // Handle socket events
    socket.on("receive_popup_message", (message) => {

      Swal.fire({
        title: 'New Candidate Walk-in Notification',
        html: `
            <div style="text-align: left;">
                <p><strong>Candidate Name:</strong> ${message.CANDIDATE_NAME}</p>
                <p><strong>Date and Time:</strong> ${message.TIME}</p>
                <p>${message.text}</p>
            </div>
        `,
        icon: 'info',
        confirmButtonText: 'OK'
      });
    });

    // Store socket ID in session storage once the connection is established
    socket.on("connect", () => {
      setSessionStorage(constant.keys.companyUserSocketId, socket.id);
    });

    // Cleanup listeners on component unmount
    // return () => {
    //   socket.off("receive_popup_message");
    //   socket.off("connect");
    // };
  }, []);

  return (
    <React.Fragment>
      <Router />
    </React.Fragment>
  );
}


export default App;



