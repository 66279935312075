import React, { useEffect, useState } from 'react'
import TemplatesListComponent from '../../../components/companyUser/candidates/templatesList'
import { v3_add_candidate_message_template, v3_candidate_message_template_list, v3_edit_candidate_message_template } from '../../../actions/v3/candidates'
import Modal from '../../../components/common/htmlModal/index'
import MessageTemplateModalForm from '../../../components/companyUser/candidates/messageTemplateModalForm'
import { ToastContainer, toast } from 'react-toastify'
const TemplatesList = (props) => {


    const [templateList, setTemplateList] = useState(null)
    const [count, setCount] = useState(null)
    const [showAddTemplateModal, setShowAddTemplateModal] = useState(false)
    const [showEditTemplateModal, setShowEditTemplateModal] = useState(null)

    const [page, setPage] = useState(1)
    const [refresh, setRefresh] = useState(false)



    const onAddNewTemplate = (model) => {
        v3_add_candidate_message_template(model).then((res) => {
            if (res.status) {
                setRefresh(!refresh)
                toast.success('Template Added Successfully')
                setPage(1)
                setShowAddTemplateModal(false)
            }
            else {
                toast.error(res.error)
            }
        })
    }

    const onEditTemplate = (model) => {
        model.TEMPLATE_ID =  showEditTemplateModal.TEMPLATE_ID
        v3_edit_candidate_message_template(model).then((res) => {
            if (res.status) {
                setRefresh(!refresh)
                toast.success('Template Saved Successfully')
                setShowEditTemplateModal(null)
            }
            else {
                toast.error(res.error)
            }
        })
    }
    useEffect(() => {
        v3_candidate_message_template_list(page).then((res => {
            if (res.status) {
                setTemplateList(res.result.list)
                setCount(res.result.count)
            }
        }))
    }, [page, refresh])
    return (
        <React.Fragment>
            <ToastContainer />
            <Modal isOpen={showAddTemplateModal} onClose={() => setShowAddTemplateModal(false)} header={'Add New Template'}>
                <MessageTemplateModalForm
                    onClose={() => setShowAddTemplateModal(false)}
                    onSubmit={(model) => { onAddNewTemplate(model) }}
                />

            </Modal>
            <Modal isOpen={showEditTemplateModal} onClose={() => setShowEditTemplateModal(false)} header={'Edit Template'}>
                {showEditTemplateModal && <MessageTemplateModalForm
                    onClose={() => setShowEditTemplateModal(null)}
                    onSubmit={(model) => { onEditTemplate(model) }}
                    data={showEditTemplateModal}
                />}

            </Modal>
            {templateList && <TemplatesListComponent
                list={templateList}
                count={count}
                setCurrentPage={(page) => setPage(page)}
                currentPage={page}
                setShowAddTemplateModal={setShowAddTemplateModal}
                onEditTemplate={setShowEditTemplateModal}

            />}
        </React.Fragment>
    )
}

export default TemplatesList