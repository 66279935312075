import React from 'react'

const CRMUserDocumentList = () => {
  return (
    <React.Fragment>
      <div className="tab-content pt-4 text-muted">
        <div
          className="tab-pane active"
          id="overview-tab"
          role="tabpanel"
        >
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
                <div className="flex-shrink-0">
                  <input className="form-control d-none" type="file" id="formFile" />
                  <label htmlFor="formFile" className="btn btn-danger">
                    <i className="ri-upload-2-fill me-1 align-bottom" /> Upload File
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <table className="table table-borderless align-middle mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">File Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Upload Date</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm">
                                <div className="avatar-title bg-primary-subtle text-primary rounded fs-20">
                                  <i className="ri-file-pdf-fill" />
                                </div>
                              </div>
                              <div className="ms-3 flex-grow-1">
                                <h6 className="fs-14 mb-0">
                                  <a href="javascript:void(0)" className="text-body">
                                    Artboard-documents.zip
                                  </a>
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>Zip File</td>
                          <td>12 Dec 2021</td>
                          <td>
                            <div className="dropdown">
                              <a
                                href="javascript:void(0);"
                                className="btn btn-light btn-icon"
                                id="dropdownMenuLink15"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <i className="ri-equalizer-fill" />
                              </a>
                              <ul
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="dropdownMenuLink15"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <i className="ri-eye-fill me-2 align-middle text-muted" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                    Download
                                  </a>
                                </li>
                                <li className="dropdown-divider" />
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default CRMUserDocumentList