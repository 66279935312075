import React from 'react';
import styled from 'styled-components';

const RatingContainer = styled.div`
  display: flex;
`;

const RatingBox = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 5px;
  background-color: ${props => props.color};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #fff;
`;

const Rating = (props) => {
    const handleRatingClick = (value) => {
        props.onChange(value);
    };

    return (
        <RatingContainer>
            <RatingBox color={props.value >= 1 ? "#d9534f" : "#a4a4a4"} onClick={() => handleRatingClick(1)}>1</RatingBox>
            <RatingBox color={props.value >= 2 ? "#f0ad4e" : "#a4a4a4"} onClick={() => handleRatingClick(2)}>2</RatingBox>
            <RatingBox color={props.value >= 3 ? "#5bc0de" : "#a4a4a4"} onClick={() => handleRatingClick(3)}>3</RatingBox>
            <RatingBox color={props.value >= 3.5 ? "#5cb85c" : "#a4a4a4"} onClick={() => handleRatingClick(3.5)}>3.5</RatingBox>
            <RatingBox color={props.value >= 4 ? "#0275d8" : "#a4a4a4"} onClick={() => handleRatingClick(4)}>4</RatingBox>
            <RatingBox color={props.value >= 4.5 ? "#ff00ff" : "#a4a4a4"} onClick={() => handleRatingClick(4.5)}>4.5</RatingBox>
            <RatingBox color={props.value >= 5 ? "#663399" : "#a4a4a4"} onClick={() => handleRatingClick(5)}>5</RatingBox>
            <RatingBox color={props.value >= 6 ? "#5cb85c" : "#a4a4a4"} onClick={() => handleRatingClick(6)}>5+</RatingBox>
        </RatingContainer>
    );
};

export default Rating;
