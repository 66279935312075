import React from 'react'
import AddClient from '../../../../components/companyUser/administrator/clientManagement/add'
import { ToastContainer, toast } from 'react-toastify';
import { v3_add_client } from '../../../../actions/v3/administrator';
import constant from '../../../../constant';

const Add = (props) => {
    const onSubmit = (model) => {
        v3_add_client(model).then((res) => {
            if (res.status) {
                toast.success('Client Added Successfully')
                props.history.push(constant.companyUserComponent.clientProfile.url.replace(':id', res.result))
            }
            else {
                toast.error(res.error)

            }
        }).catch((err) => {
            toast.error(err)

        })
    }
    return (
        <React.Fragment>

            <AddClient
                onSubmit={onSubmit}
            />
            <ToastContainer />
        </React.Fragment>
    )
}

export default Add