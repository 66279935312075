import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import constant from '../../../constant';


const Stats = (props) => {
  const { countStats } = props;
  return (
    <React.Fragment>
      <div className="d-flex flex-column h-100">
        <div className="row">
          <div className="col-md-3 customDashboardGrid">
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox8BG">

              <div className="card-body" style={{ zIndex: 1 }} onClick={() => { props.history.push(constant.companyUserComponent.listCandidate.url) }}>
                <div className="row">

                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" >
                        {countStats?.total_candidates ? countStats?.total_candidates : 0}
                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      {" "}
                      Total Candidates
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-team-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid" onClick={() => { props.history.push(constant.companyUserComponent.postedJobs.url) }}>
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox1BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">

                    <h4 className="gridCountStyle">
                      <span className="counter-value" >
                        {countStats?.total_jobs ? countStats?.total_jobs : 0}
                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      {" "}
                      Total Jobs
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-briefcase-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 customDashboardGrid">
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox3BG">

              <div className="card-body" style={{ zIndex: 1 }} onClick={() => { props.history.push(constant.companyUserComponent.postedJobs.url) }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={4305}>
                        {countStats?.active_jobs ? countStats?.active_jobs : 0}
                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Active Jobs
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-add-circle-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid" onClick={() => { props.history.push(constant.companyUserComponent.company_shortlisted.url) }}>
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox2BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" >
                        {countStats?.apply_jobs ? countStats?.apply_jobs : 0}
                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      {" "}
                      HR Shortlisted
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-find-replace-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.company_client_shortlisted.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox5BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={3948}>
                        {countStats?.client_shortlisted ? countStats?.client_shortlisted : 0}
                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Client Shortlisted
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-dv-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.company_interview.url) }}
          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox4BG">
              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={5021}>
                        {countStats?.interviewed ? countStats?.interviewed : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      {" "}
                      Interview Scheduled
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-folder-user-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.company_rejected.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox12BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={1340}>
                        {countStats?.rejected ? countStats?.rejected : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Rejected
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-close-line"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.company_selected.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox6BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" >
                        {countStats?.rejected ? countStats?.rejected : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Selected
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-check-line"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.company_offered.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox7BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={1340}>
                        {countStats?.offered ? countStats?.offered : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Offered
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-hand-heart-fill"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.company_joined.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox9BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" >
                        {countStats?.joined ? countStats?.joined : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Joined
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-medal-fill"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.company_dropped.url) }}
          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox10BG">
              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value">
                        {countStats?.dropped ? countStats?.dropped : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Dropped
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-paint-fill"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.company_billing.url) }}
          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox11BG">
              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value">
                      {countStats?.billing ? countStats?.billing : 0}
                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Billing Cycle
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-bike-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Stats)