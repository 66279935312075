import React, { useState } from 'react'
import { validateFunctionalForm } from '../../../utils';

const MessageTemplateModalForm = (props) => {
    const [state, setState] = useState({
        NAME: { name: 'NAME', value: props.data ? props.data.NAME : '', error: '', isRequired: true },
        TEMPLATE_BODY: { name: 'TEMPLATE_BODY', value: props.data ? props.data.TEMPLATE_BODY : '', error: '', isRequired: true },
        STATUS: { name: 'STATUS', value: props.data ? props.data.STATUS : '', error: '', isRequired: true },
        TYPE: { name: 'TYPE', value: props.data ? props.data.TYPE : '', error: '', isRequired: true },
    })
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }
    const onSubmit = (e) => {
        debugger
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                NAME: state.NAME.value,
                TEMPLATE_BODY: state.TEMPLATE_BODY.value,
                STATUS: state.STATUS.value,
                TYPE: state.TYPE.value,
            }
            props.onSubmit(model)

        }
    }
    return (
        <div className="row">
            <div className="col-lg-12">

                <form className="live-preview" onSubmit={(e) => { onSubmit(e) }}>
                    <div className="row gy-4">
                        <div className="col-lg-12 mb-3">
                            <div>
                                <label className="form-label">
                                    Template Name
                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                </label>
                                <input
                                    type="text"
                                    className={state.NAME.error.length > 0 && !state.NAME.value ? "form-control is-invalid" : "form-control"}
                                    placeholder="Template Name"
                                    onChange={onChange}
                                    name={state.NAME.name}
                                    value={state.NAME.value}
                                />
                                <div className="invalid-feedback">Please enter template name.</div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Template Category
                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                </label>
                                <select
                                    className={state.TYPE.error.length > 0 && !state.TYPE.value ? "form-select is-invalid" : "form-select"}
                                    onChange={onChange}
                                    name={state.TYPE.name}
                                    value={state.TYPE.value}
                                >
                                    <option value=''> Select Template Category</option>
                                    <option value={'Mail'}> Mail</option>
                                    <option value={'WhatsApp'}>Whatsapp</option>

                                </select>
                                <div className="invalid-feedback">Please select template category.</div>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-md-6">
                            <div>

                                <label className="form-label">
                                    Status
                                </label>
                                <div class="form-check form-switch form-switch-lg">
                                    <input class="form-check-input code-switcher" type="checkbox"
                                        checked={state.STATUS.value}
                                        onClick={() => {
                                            setState(prevState => ({
                                                ...prevState,
                                                ['STATUS']: {
                                                    ...prevState['STATUS'],
                                                    value: !state.STATUS.value
                                                }
                                            }));
                                        }}
                                        id="icon-button" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mb-3">
                            <div>
                                <label className="form-label">
                                    Message Body
                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                </label>
                                <textarea
                                    className={state.TEMPLATE_BODY.error.length > 0 && !state.TEMPLATE_BODY.value ? "form-control is-invalid" : "form-control"}
                                    onChange={onChange}
                                    name={state.TEMPLATE_BODY.name}
                                    value={state.TEMPLATE_BODY.value}
                                    rows={5}
                                />
                                <div className="invalid-feedback">Please select template category.</div>
                            </div>
                        </div>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => { props.onClose() }}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-success" id="add-btn">
                                Save
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}

export default MessageTemplateModalForm