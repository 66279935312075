import React, { useState } from 'react';

const Form = ({ formData, onInputChange, onLogoChange, onSave }) => {
  const handleSave = () => {
    const payload = {
      employeeName: formData.employeeName,
      jobPosition: formData.jobPosition,
      salary: formData.salary,
      offerDate: formData.offerDate,
      joiningDate: formData.joiningDate,
      employmentType: formData.employmentType,
      otherEmploymentType: formData.otherEmploymentType || '', // Add this if 'OTHER' is selected
      workingHours: formData.workingHours,
      jobLocation: formData.jobLocation,
      otherJobLocation: formData.otherJobLocation || '', // Add this if 'OTHER' is selected
      companyName: formData.companyName,
      companyLogo: formData.companyLogo,
      signerName: formData.signerName,
      benefits: formData.benefits,
    };

    // Call the onSave function with the payload
    onSave(payload);
  };

  return (
    <div className="card">
      <div className="needs-validation" noValidate="" id="invoice_form">
        <div className="card-body border-bottom border-bottom-dashed p-4">
          <div className="row">
            <form>
              <div className="mb-3">
                <label htmlFor="employeeName" className="form-label">Employee Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="employeeName"
                  name="employeeName"
                  value={formData.employeeName}
                  onChange={onInputChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="jobPosition" className="form-label">Job Position</label>
                <input
                  type="text"
                  className="form-control"
                  id="jobPosition"
                  name="jobPosition"
                  value={formData.jobPosition}
                  onChange={onInputChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="salary" className="form-label">Salary</label>
                <input
                  type="text"
                  className="form-control"
                  id="salary"
                  name="salary"
                  value={formData.salary}
                  onChange={onInputChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="offerDate" className="form-label">Offer Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="offerDate"
                  name="offerDate"
                  value={formData.offerDate}
                  onChange={onInputChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="joiningDate" className="form-label">Joining Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="joiningDate"
                  name="joiningDate"
                  value={formData.joiningDate}
                  onChange={onInputChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="employmentType" className="form-label">Employment Type</label>
                <div>
                  <input
                    className='ms-2'
                    type="radio"
                    id="internship"
                    name="employmentType"
                    value="Internship"
                    checked={formData.employmentType === 'Internship'}
                    onChange={onInputChange}
                  />
                  <label htmlFor="internship" className="form-label ms-2">Internship</label>
                  <input
                    className='ms-2'
                    type="radio"
                    id="contract"
                    name="employmentType"
                    value="Contract"
                    checked={formData.employmentType === 'Contract'}
                    onChange={onInputChange}
                  />
                  <label htmlFor="contract" className="form-label ms-2">Contract</label>
                  <input
                    className='ms-2'
                    type="radio"
                    id="fullTime"
                    name="employmentType"
                    value="Full-time"
                    checked={formData.employmentType === 'Full-time'}
                    onChange={onInputChange}
                  />
                  <label htmlFor="fullTime" className="form-label ms-2">Full-time</label>
                  <input
                    className='ms-2'
                    type="radio"
                    id="partTime"
                    name="employmentType"
                    value="Part-time"
                    checked={formData.employmentType === 'Part-time'}
                    onChange={onInputChange}
                  />
                  <label htmlFor="partTime" className="form-label ms-2">Part-time</label>
                  <input
                    className='ms-2'
                    type="radio"
                    id="other"
                    name="employmentType"
                    value="Other"
                    checked={formData.employmentType === 'Other'}
                    onChange={onInputChange}
                  />
                  <label htmlFor="other" className="form-label ms-2">Other</label>
                  {formData.employmentType === 'Other' && (
                    <input
                      type="text"
                      className="form-control mt-2"
                      name="employmentTypeOther"
                      value={formData.employmentTypeOther || ''}
                      onChange={onInputChange}
                      placeholder="Please specify"
                    />
                  )}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="workingHours" className="form-label">Working Hours</label>
                <input
                  type="text"
                  className="form-control"
                  id="workingHours"
                  name="workingHours"
                  value={formData.workingHours}
                  onChange={onInputChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="jobLocation" className="form-label">Job Location</label>
                <div>
                  <input
                    className='ms-2'
                    type="radio"
                    id="remote"
                    name="jobLocation"
                    value="Remote"
                    checked={formData.jobLocation === 'Remote'}
                    onChange={onInputChange}
                  />
                  <label htmlFor="remote" className="form-label ms-2">Remote</label>
                  <input
                    className='ms-2'
                    type="radio"
                    id="inOffice"
                    name="jobLocation"
                    value="In-Office"
                    checked={formData.jobLocation === 'In-Office'}
                    onChange={onInputChange}
                  />
                  <label htmlFor="inOffice" className="form-label ms-2">In-Office</label>
                  <input
                    className='ms-2'
                    type="radio"
                    id="hybrid"
                    name="jobLocation"
                    value="Hybrid"
                    checked={formData.jobLocation === 'Hybrid'}
                    onChange={onInputChange}
                  />
                  <label htmlFor="hybrid" className="form-label ms-2">Hybrid</label>
                  <input
                    className='ms-2'
                    type="radio"
                    id="otherLocation"
                    name="jobLocation"
                    value="Other"
                    checked={formData.jobLocation === 'Other'}
                    onChange={onInputChange}
                  />
                  <label htmlFor="otherLocation" className="form-label ms-2">Other</label>
                  {formData.jobLocation === 'Other' && (
                    <input

                      type="text"
                      className="form-control"
                      name="jobLocationOther"
                      value={formData.jobLocationOther || ''}
                      onChange={onInputChange}
                      placeholder="Please specify"
                    />
                  )}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="companyName" className="form-label">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={onInputChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="companyLogo" className="form-label">Company Logo</label>
                <input
                  type="file"
                  className="form-control"
                  id="companyLogo"
                  name="companyLogo"
                  onChange={onLogoChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="signerName" className="form-label">Signer Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="signerName"
                  name="signerName"
                  value={formData.signerName}
                  onChange={onInputChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="benefits" className="form-label">Benefits</label>
                <textarea
                  className="form-control"
                  id="benefits"
                  name="benefits"
                  value={formData.benefits}
                  onChange={onInputChange}
                />
              </div>
              <button type="button" className="btn btn-primary" onClick={handleSave}>
                Save Offer Letter
              </button>
            </form>
          </div></div></div></div >
  );
};

export default Form;
