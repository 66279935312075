import React, { useState } from 'react'
import Pagination from '../../common/Pagination'
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import constant from '../../../constant';

const List = (props) => {
    const { list, count, currentPage } = props;

    const [state, setState] = useState({
        KEYWORD: { name: 'KEYWORD', value: '' },
        STATUS: { name: 'STATUS', value: '' },
    })
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Client List</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Dashboard</a>
                                            </li>
                                            <li className="breadcrumb-item active">Client List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-sm-5">
                                                <div className="search-box">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Company Name, SPOC Name"
                                                        name={state.KEYWORD.name}
                                                        value={state.KEYWORD.value}
                                                        onChange={onChange}
                                                    />
                                                    <i className="ri-search-line search-icon" />
                                                </div>
                                            </div>


                                            <div className="col-sm-2">
                                                <input
                                                    className="form-control"
                                                    name={state.STATUS.name}
                                                    value={state.STATUS.value}
                                                    onChange={onChange}
                                                />
                                            </div>


                                            <div className="col-sm-auto">
                                                <div className="hstack gap">
                                                    <button type="button"
                                                        // onClick={() => { onReset() }}
                                                        className="btn btn-danger add-btn">
                                                        Reset
                                                    </button>
                                                    <button type="button"
                                                        //  onClick={() => {
                                                        //     onSearch()
                                                        // }} 
                                                        className="btn btn-success add-btn ms-1">
                                                        <i className="ri-search-line search-icon align-bottom me-1" />
                                                        Search
                                                    </button>
                                                    <button type="button"
                                                        //  onClick={() => {
                                                        //     downloadExcel()
                                                        // }}
                                                        className="btn btn-primary add-btn ms-1">
                                                        <i className="ri-file-excel-2-line align-bottom me-1" />
                                                        Download
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>

                                                            <th className="sort">Company Name</th>
                                                            <th className="sort">Contact Details</th>
                                                            <th className="sort">Current Status</th>
                                                            <th className="sort">Last Modified</th>
                                                            {/* <th className="sort">Client Add Date</th> */}
                                                            <th className="sort">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {list && list.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td className="id">
                                                                        {item.COMPANY_NAME}
                                                                    </td>
                                                                    <td>
                                                                        <tr className="flex-grow-1 name"> <i className="ri-mail-line text-primary me-1 align-bottom" />&nbsp;&nbsp;{item.EMAIL}</tr>
                                                                        <tr className="flex-grow-1 name"> <i className="ri-phone-line text-primary me-1 align-bottom" />&nbsp;&nbsp;{item.MOBILE}</tr>
                                                                    </td>
                                                                    <td>
                                                                        <tr className="flex-grow-1 name"> Database Status: &nbsp;&nbsp;{item.DB_NAME ? item.DB_NAME : 'Not Assigneds'}</tr>
                                                                        <tr className="flex-grow-1 name"> URL Status:&nbsp;&nbsp;{item.URL ? item.URL : 'Not Assigned'}</tr>
                                                                        <tr className="flex-grow-1 name"> Storage Status:&nbsp;&nbsp;{item.STORAGE_BUCKET_NAME ? item.STORAGE_BUCKET_NAME : 'Not Assigned'}</tr>
                                                                        <tr className="flex-grow-1 name"> Working Status:&nbsp;&nbsp;{item.STATUS ==='A' ? 'Active' : 'Inactive'}</tr>

                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <div className="flex-grow-1 name">{moment(item.MODIFIED_ON).format('DD MMM YYYY on hh:mm A')}</div>
                                                                        </div>
                                                                    </td>
                                                                    
                                                                    <td>
                                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                                            <li
                                                                                className="list-inline-item"

                                                                            >
                                                                                <Link to={constant.superAdminComponent.clientDetail.url.replace(":id",item.USER_ID)}
                                                                                
                                                                                >
                                                                                    <i className="ri-eye-fill align-bottom text-muted" />
                                                                                </Link>
                                                                            </li>
                                                                            <li
                                                                                className="list-inline-item edit"
                                                                            >
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    // onClick={() => { setOpenChangeStatusModal(item) }}

                                                                                    className="text-muted d-inline-block"
                                                                                >
                                                                                    <i className="ri-edit-2-line fs-16" />
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })

                                                        }
                                                    </tbody>
                                                </table>
                                                {count === 0 && <div className="noresult" >
                                                    <div className="text-center">
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                                            trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a"
                                                            style={{ width: 75, height: 75 }}
                                                        />
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">
                                                            We've searched more than 150+ leads We did not find any
                                                            leads for you search.
                                                        </p>
                                                    </div>
                                                </div>}
                                            </div>
                                            {count > 0 && < Pagination
                                                limit={20}
                                                totalCount={count}
                                                range={5}
                                                onPageChange={(page) => {
                                                    props.setCurrentPage(page)
                                                }}
                                                currentPage={currentPage}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(List)