import React, { useState } from 'react';
import Form from '../../../components/SuperAdmin/offer_letter/form';
import Preview from '../../../components/SuperAdmin/offer_letter/preview';
import { v1_create_offer_letter } from '../../../actions/v1/offer_letter';

const CreateOfferLetter = () => {
  const [formData, setFormData] = useState({
    employeeName: '',
    jobPosition: '',
    salary: '',
    offerDate: '',
    joiningDate: '',
    employmentType: '',
    employmentTypeOther: '',
    workingHours: '',
    jobLocation: '',
    jobLocationOther: '',
    companyName: '',
    companyLogo: '',
    signerName: '',
    benefits: '',
  });

  const onInputChange = (e) => {
    debugger
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          companyLogo: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSave = async (payload) => {
    try {
      v1_create_offer_letter(payload).then((res) => {
        if (res.status) {

        }
        else {
          console.error('Error saving data:', res.error);

        }
      })
      // Handle success (e.g., show a message, redirect, etc.)
    } catch (error) {
      console.error('Error saving data:', error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-6">
              <Form formData={formData} onInputChange={onInputChange} onLogoChange={onLogoChange} onSave={handleSave} />
            </div>
            <div className="col-xxl-6">
              <Preview formData={formData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOfferLetter;
