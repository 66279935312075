import React, { useState } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import { Chart } from "react-google-charts";


// export default function SelectionDemo({ json, setSelected }) {
//     const [selection, setSelection] = useState([]);

//     function buildHierarchy(data) {
//         const map = new Map();
//         const hierarchyWithChildren = [];
//         const hierarchyWithoutChildren = [];

//         // First, create a map of user IDs to their corresponding data
//         data.forEach(user => {
//             map.set(user.USER_ID, {
//                 id: user.USER_ID,
//                 expanded: false,
//                 type: 'person',
//                 data: {
//                     image: user.PROFILE_IMAGE ? `${process.env.REACT_APP_BASE_URL}/v3/profile-image/${user.USER_ID}/${user.PROFILE_IMAGE}` : "/assets/images/users/user-dummy-img.jpg",
//                     name: user.FULL_NAME,
//                     title: user.DESIGNATION_NAME || '' // If DESIGNATION_NAME is null, use empty string
//                 },
//                 children: []
//             });
//         });

//         // Then, loop through the data to build the hierarchy
//         data.forEach(user => {
//             const parent = map.get(user.RM_ID);
//             const currentUser = map.get(user.USER_ID);

//             if (parent) {
//                 parent.children.push(currentUser);
//             } else {
//                 hierarchyWithoutChildren.push(currentUser);
//             }
//         });

//         // Find and add users with children to the hierarchy
//         map.forEach(user => {
//             if (user.children.length > 0) {
//                 hierarchyWithChildren.push(user);
//             }
//         });

//         // Concatenate the two hierarchies
//         const hierarchy = [...hierarchyWithChildren, ...hierarchyWithoutChildren];

//         return hierarchy;
//     }

//     const collectNestedUsers = (node, users) => {
//         if (!node.children || node.children.length === 0) return;
//         node.children.forEach(child => {
//             users.push({
//                 USER_ID: child.id,
//                 FULL_NAME: child.data.name,
//                 DESIGNATION: child.data.title,
//                 IMAGE: child.data.image
//             });
//             collectNestedUsers(child, users);
//         });
//     };

//     const handleNodeClick = (node) => {
//         const clickedUser = {
//             USER_ID: node.id,
//             FULL_NAME: node.data.name,
//             DESIGNATION: node.data.title,
//             IMAGE: node.data.image
//         };
//         const clickedUserAndBelow = [clickedUser];
//         const belowUsers = [];

//         collectNestedUsers(node, belowUsers);
//         clickedUserAndBelow.push(...belowUsers);

//         const selectedIds = [];
//         selectedIds.push(node.id);
//         collectNestedUsers(node, selectedIds); // Corrected function name here
//         setSelection(selectedIds);
//         setSelected({ clickedUser, clickedUserAndBelow, belowUsers }); // Call the setSelected function with the selected data
//     };

//     const data = buildHierarchy(json);
//     const nodeTemplate = (node) => {
//         if (node.type === 'person') {
//             return (
//                 <div className="d-flex flex-column justify-content-center align-items-center text-center position-relative">
//                     <div className="position-absolute top-0 start-50 translate-middle">
//                         {node.children && (
//                             <i className="pi pi-fw pi-chevron-down"></i>
//                         )}
//                     </div>
//                     <div>
//                         <img
//                             alt={node.data.name}
//                             src={node.data.image}
//                             className="mb-2"
//                             style={{ width: 'auto', height: '50px' }}
//                             onClick={() => handleNodeClick(node)}
//                         />
//                         <div className="mainHearHireOrder">
//                             <div className="nameHead">
//                                 <span className="font-bold">{node.data.name}</span>
//                             </div>
//                             <div className="nameSubHead">
//                                 <span>{node.data.title}</span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             );
//         }

//         return node.label;
//     };

//     return (
//         <div className="overflow-x-auto">
//             <OrganizationChart value={data} selectionMode="multiple" selection={selection} onSelectionChange={(e) => setSelection(e.data)} nodeTemplate={nodeTemplate} />
//         </div>
//     );
// }

// import React from "react";

export const convertJsonToChartData = (json) => {
    const data = json.map((user) => [
        { v: user.USER_ID.toString(), f: user.FULL_NAME.toString() + `<div style="color:red; font-style:italic">${user.DESIGNATION_NAME ? user.DESIGNATION_NAME : ''}</div>` },
        user.RM_ID ? user.RM_ID.toString() : "", // parent ID
        user.DESIGNATION_NAME || "", // title or designation
    ]);
    return data;
};

export const options = {
    allowHtml: true,
    // Add additional options for styling or interactivity as needed
};

export default function OrgChart({ json, setSelected }) {
    const data = convertJsonToChartData(json);

    const handleNodeStateChange = ({ chartWrapper }) => {
        debugger
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();

        // Extract info and user ID(s) of selected nodes
        const selectedNodes = selection.map((item) => {
            const userObject = data[item.row]; // Assuming data starts from row 1
            return {
                userId: userObject[0]['v']
                // ... add other relevant properties from userObject if needed
            };
        });

        // Do something with selected nodes (user IDs, full names, etc.)
        console.log("Selected Nodes:", selectedNodes); // Example logging


        if (setSelected) {
            setSelected(selectedNodes?.length ? selectedNodes[0]['userId'] : ''); // Pass selected node information to parent
        }
    };
    console.log("JSON:", JSON.stringify(json));

    return (
        <Chart
            chartType="OrgChart"
            data={[
                [{ v: "", f: "" }, "", ""], // Header row
                ...data,
            ]}
            options={options}
            width="100%"
            height="400px"
            chartEvents={[
                {
                    eventName: 'select',
                    callback: handleNodeStateChange,
                },
            ]}
        />
    );
}
