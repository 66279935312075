import React, { useState } from 'react';
import Pagination from '../../common/Pagination';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import constant from '../../../constant';

const List = (props) => {
    const { list, count, currentPage, state, setState } = props;
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    };

    const onReset = () => {
        setState(prevState => ({
            ...prevState,
            ['KEYWORD']: {
                ...prevState['KEYWORD'],
                value: ''
            }
        }));
    };
    console.log(list)
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Offer Letters</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript:void(0);">Dashboard</a>
                                            </li>
                                            <li className="breadcrumb-item active">Offer Letter</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-sm-5">
                                                <div className="search-box">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Employee Name, Company Name"
                                                        name={state.KEYWORD.name}
                                                        value={state.KEYWORD.value}
                                                        onChange={onChange}
                                                    />
                                                    <i className="ri-search-line search-icon" />
                                                </div>
                                            </div>

                                            <div className="col-sm-auto">
                                                <div className="hstack gap">
                                                    <button type="button"
                                                        onClick={onReset}
                                                        className="btn btn-danger add-btn">
                                                        Reset
                                                    </button>

                                                    <a type="button"
                                                        href={constant.superAdminComponent.createOfferLetter.url}
                                                        className="btn btn-primary add-btn ms-1">
                                                        <i className="ri-add-line align-bottom me-1" />
                                                        Create New
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="sort">Employee Details</th>
                                                            <th className="sort">Company Details</th>
                                                            <th className="sort">Created On</th>
                                                            <th className="sort">Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {list && list.length > 0 ? (
                                                            list.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <strong>{item.EMPLOYEE_NAME}</strong>
                                                                        <div>Position: {item.JOB_POSITION}</div>
                                                                        <div>Salary: {item.SALARY}</div>
                                                                        <div>Offer Date: {moment(item.OFFER_DATE).format('DD MMM YYYY')}</div>
                                                                        <div>Joining Date: {moment(item.JOINING_DATE).format('DD MMM YYYY')}</div>
                                                                        <div>Employment Type: {item.EMPLOYMENT_TYPE === 'Other' ? item.OTHER_EMPLOYMENT_TYPE : item.EMPLOYMENT_TYPE}</div>
                                                                        <div>Working Hours: {item.WORKING_HOURS}</div>
                                                                        <div>Job Location: {item.JOB_LOCATION === 'Other' ? item.OTHER_JOB_LOCATION : item.JOB_LOCATION}</div>
                                                                    </td>
                                                                    <td>
                                                                        <strong>{item.COMPANY_NAME}</strong>
                                                                        <div>
                                                                            <img src={item.COMPANY_LOGO} alt="Company Logo" style={{ width: '50px', height: '50px' }} />
                                                                        </div>
                                                                        <div>Signer Name: {item.SIGNER_NAME}</div>
                                                                        <div>Benefits: {item.BENEFITS || 'None'}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div>Created At: {moment(item.CREATED_AT).format('DD MMM YYYY, hh:mm A')}</div>
                                                                        <div>Updated At: {moment(item.UPDATED_AT).format('DD MMM YYYY, hh:mm A')}</div>
                                                                    </td>
                                                                    <td>
                                                                        <a className='btn btn-success' href={constant.superAdminComponent.editOfferLetter.url.replace(':id', item.ID)}>Edit and Preview</a>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">
                                                                    <div className="noresult">
                                                                        <div className="text-center">
                                                                            <lord-icon
                                                                                src="https://cdn.lordicon.com/msoeawqm.json"
                                                                                trigger="loop"
                                                                                colors="primary:#121331,secondary:#08a88a"
                                                                                style={{ width: 75, height: 75 }}
                                                                            />
                                                                            <h5 className="mt-2">Sorry! No Result Found</h5>
                                                                            <p className="text-muted mb-0">We didn't find any offers matching your search.</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>

                                                </table>

                                                {count > 0 && (
                                                    <Pagination
                                                        limit={20}
                                                        totalCount={count}
                                                        range={5}
                                                        onPageChange={(page) => {
                                                            props.setCurrentPage(page);
                                                        }}
                                                        currentPage={currentPage}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(List);
