import moment from 'moment';
import React from 'react'
import Pagination from '../../common/Pagination';

const DocumentTypeList = (props) => {
    const { list, count, currentPage, setCurrentPage } = props;

    return (
        <React.Fragment>
            <table className="table table-nowrap table-striped-columns mb-0">
                <thead className="table-light">
                    <tr>
                        <th scope="col">Document Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Modified On</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {list && list.map((item) => {
                        return (
                            <tr>

                                <td>
                                    <a href="#" className="fw-semibold">
                                        {item.NAME}
                                    </a>
                                </td>
                                <td>
                                    {item.STATUS ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                </td>
                                <td>{moment(item.MODIFIED_ON).fromNow()}</td>
                                <td>
                                    <div className="hstack gap-3 flex-wrap">
                                        <a href="javascript:void(0);" className="link-success fs-15" onClick={() => { props.setShowEditModal(item) }}>
                                            <i className="ri-edit-2-line" />
                                        </a>
                                        <a href="javascript:void(0);" className="link-danger fs-15" onClick={() => { props.onChangeVisibility(item.DOCUMENT_TYPE_ID, !Boolean(item.STATUS)) }}>
                                            {item.STATUS ? <i className="ri-delete-bin-line" /> : <i className="ri-login-box-line" />}
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )

                    })
                    }

                </tbody>
            </table>
            {count && <Pagination
                totalCount={count}
                range={3}
                limit={20}
                onPageChange={(page) => { setCurrentPage(page) }}
                currentPage={currentPage}
            />}

        </React.Fragment>
    )
}

export default DocumentTypeList