import React, { useEffect, useState } from 'react'
import TeamProgressTable from '../../../../components/companyUser/team/teamInsights/teamProgressTable'
import { v3_team_table_insight } from '../../../../actions/v3/team'
import moment from 'moment';
import queryString from 'query-string';


const Index = (props) => {
  const [tableData, setTableData] = useState(null);
  const [state, setState] = useState(
    {
      FROM_DATE: {
        name: 'FROM_DATE', value: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        isRequired: false
      }, TO_DATE: {
        name: 'TO_DATE', value: moment().format('YYYY-MM-DD'),
        isRequired: false
      },
    }
  )
  useEffect(() => {
    const requestedUrl = props.location.search;
    let { id } = queryString.parse(requestedUrl)
    v3_team_table_insight({ FROM_DATE: state.FROM_DATE.value, TO_DATE: state.TO_DATE.value, USER_ID: id ? id : null }).then((res) => {
      if (res.status) {
        setTableData(res.result)
      }
    })
  }, [state.FROM_DATE.value, state.TO_DATE.value])

  const onChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value
      }
    }));

  }
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Team insights</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Dashboards</a>
                      </li>
                      <li className="breadcrumb-item active">Team insights</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Team Progress</h4>
                  <div className="flex-shrink-0">
                    <div className="row">

                      <div className="col-xxl-6 col-sm-12 col-12">
                        <input
                          type="date"
                          id='fromDate'
                          className="form-control customRadiusSearch"
                          name={state.FROM_DATE.name}
                          value={state.FROM_DATE.value}
                          onChange={onChange}
                        // max={state.TO_DATE.value}
                        />
                        <label htmlFor="fromDate" className="labelDate"> From Date</label>
                      </div>
                      <div className="col-xxl-6 col-sm-12 col-12">
                        <input
                          type="date"
                          id='toDate'
                          className="form-control customRadiusSearch"
                          name={state.TO_DATE.name}
                          value={state.TO_DATE.value}
                          onChange={onChange}
                        // max={state.FROM_DATE.value}
                        />
                        <label htmlFor="toDate" className="labelDate"> To Date</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-12">
                <TeamProgressTable
                  data={tableData}
                />
              </div> */}
              <div className="card reportCardBodySearchEngine p-0">
                <div className="card-body">
                  {tableData && <TeamProgressTable
                    data={tableData}
                  />}

                  {tableData && tableData.length === 0 && <div className="noresult" >
                    <div className="text-center">
                      <lord-icon
                        src="https://cdn.lordicon.com/msoeawqm.json"
                        trigger="loop"
                        colors="primary:#121331,secondary:#08a88a"
                        style={{ width: 75, height: 75 }}
                      />
                      <h5 className="mt-2">Apply Filter to get records as per your requirement</h5>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default Index