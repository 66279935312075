import React, { useState } from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const EditTeamDetailsComponent = (props) => {
    const { data } = props;
    const [selected, setSelected] = useState(data?.filter(item => item.RM_ID == props.match.params.id).map((item)=>{return item.USER_ID}));

    const onSubmit = () => {
        props.onSubmit({ SELECTED_USER_ID: selected })
    }
    return (
        <React.Fragment>
            <React.Fragment>
                <div className="tab-content pt-4 text-muted">
                    <div
                        className="tab-pane active"
                        id="overview-tab"
                        role="tabpanel"
                    >
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="listjs-table" id="customerList">
                                            <div className="row g-4 mb-3">
                                                {/* <div className="col-sm-auto">
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success add-btn"
                                                            data-bs-toggle="modal"
                                                            id="create-btn"
                                                            data-bs-target="#showModal"
                                                        >
                                                            <i className="ri-add-line align-bottom me-1" /> Add
                                                        </button>
                                                        <button className="btn btn-soft-danger" onclick="deleteMultiple()">
                                                            <i className="ri-delete-bin-2-line" />
                                                        </button>
                                                    </div>
                                                </div> */}
                                                <div className="col-sm">
                                                    <div className="d-flex justify-content-sm-end">
                                                        <div className="search-box ms-2">
                                                            <input
                                                                type="text"
                                                                className="form-control search"
                                                                placeholder="Search..."
                                                            />
                                                            <i className="ri-search-line search-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive table-card mt-3 mb-1">
                                                <table className="table align-middle table-nowrap" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col" style={{ width: 50 }}>
                                                                {/* <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="checkAll"
                                                                        defaultValue="option"
                                                                    />
                                                                </div> */}
                                                            </th>
                                                            <th className="sort" data-sort="customer_name">
                                                                Name
                                                            </th>
                                                            <th className="sort" data-sort="email">
                                                                Email
                                                            </th>
                                                            <th className="sort" data-sort="phone">
                                                                Phone
                                                            </th>
                                                            <th className="sort" data-sort="date">
                                                                Work Location
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {data && data.length > 0 && data.map((item, index) => {
                                                            return (< tr >
                                                                <th scope="row">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={selected.some(user_id => user_id === item.USER_ID)}
                                                                            onChange={() => {
                                                                                const index = selected.findIndex(user_id => user_id === item.USER_ID);
                                                                                if (index !== -1) {
                                                                                    // Remove the user ID from selected
                                                                                    setSelected(prevSelected => prevSelected.filter(user_id => user_id !== item.USER_ID));
                                                                                } else {
                                                                                    // Add the user ID to selected
                                                                                    setSelected(prevSelected => [...prevSelected, item.USER_ID]);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </th>

                                                                <td className="customer_name"><div className="d-flex align-items-start">
                                                                    <div className="flex-shrink-0 me-3">
                                                                        <div>
                                                                            <img
                                                                                className="image avatar-xs rounded-circle"
                                                                                alt=""
                                                                                src={item.PROFILE_IMAGE ? `${process.env.REACT_APP_BASE_URL}/v3/profile-image/${item.USER_ID}/${item.PROFILE_IMAGE}` : "/assets/images/users/user-dummy-img.jpg"}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <h5 className="contact-name fs-13 mb-1">
                                                                            <a href="#" className="link text-body">
                                                                                {item.FULL_NAME}
                                                                            </a>
                                                                        </h5>
                                                                        <p className="contact-born text-muted mb-0">{item.DESIGNATION_NAME ? item.DESIGNATION_NAME : '--'}</p>
                                                                    </div>

                                                                </div>
                                                                </td>
                                                                <td className="email">{item.EMAIL}</td>
                                                                <td className="phone">{item.PHONE}</td>
                                                                <td className="date">{item.WORK_LOCATION_NAME ? item.WORK_LOCATION_NAME : '--'}</td>

                                                            </tr>)
                                                        })}
                                                    </tbody>
                                                </table>
                                                <div className="noresult" style={{ display: "none" }}>
                                                    <div className="text-center">
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                                            trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a"
                                                            style={{ width: 75, height: 75 }}
                                                        ></lord-icon>
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">
                                                            We've searched more than 150+ Orders We did not find any orders for
                                                            you search.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success"
                                                        onClick={() => { onSubmit() }}
                                                    >
                                                        <i className="align-bottom me-1" /> Save
                                                    </button>

                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="row">
                    
                    <div className="col-xxl-12 col-md-12">
                      <button type="button" class="btn btn-primary waves-effect waves-light">Save</button>
                    </div>
                  </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        </React.Fragment >
    )
}

export default withRouter(EditTeamDetailsComponent)