import React, { useEffect, useState } from 'react'
import { v2_user_roles } from '../../../actions/v2/user_management'
import { validateFunctionalForm } from '../../../utils'

const Add = (props) => {

    const [state, setState] = useState({
        CLIENT_NAME: { name: 'CLIENT_NAME', value: '', error: '', isRequired: true },
        COMPANY_NAME: { name: 'COMPANY_NAME', value: '', error: '', isRequired: true },
        PHONE: { name: 'PHONE', value: '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: true },
        CLIENT_ADDRESS: { name: 'CLIENT_ADDRESS', value: '', error: '', isRequired: true },
        CLIENT_DETAILS: { name: 'CLIENT_DETAILS', value: '', error: '', isRequired: false },
        AGREEMENT_START_DATE: { name: 'AGREEMENT_START_DATE', value: '', error: '', isRequired: true },
        AGREEMENT_END_DATE: { name: 'AGREEMENT_END_DATE', value: '', error: '', isRequired: true },
        GST_NO: { name: 'GST_NO', value: '', error: '', isRequired: false },
        CLIENT_DOC: { name: 'CLIENT_DOC', value: '', error: '', isRequired: false },
        SERVICE_AGREEMENT_DOC: { name: 'SERVICE_AGREEMENT_DOC', value: '', error: '', isRequired: false },
        RATE_CARD: { name: 'RATE_CARD', value: '', error: '', isRequired: false },





    })
    const clearForm = () => {
        setState({
            CLIENT_NAME: { name: 'CLIENT_NAME', value: '', error: '', isRequired: true },
            COMPANY_NAME: { name: 'COMPANY_NAME', value: '', error: '', isRequired: true },
            PHONE: { name: 'PHONE', value: '', error: '', isRequired: true },
            EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: true },
            CLIENT_ADDRESS: { name: 'CLIENT_ADDRESS', value: '', error: '', isRequired: true },
            CLIENT_DETAILS: { name: 'CLIENT_DETAILS', value: '', error: '', isRequired: false },
            AGREEMENT_START_DATE: { name: 'AGREEMENT_START_DATE', value: '', error: '', isRequired: true },
            AGREEMENT_END_DATE: { name: 'AGREEMENT_END_DATE', value: '', error: '', isRequired: true },
            CLIENT_DOC: { name: 'CLIENT_DOC', value: '', error: '', isRequired: false },
            SERVICE_AGREEMENT_DOC: { name: 'SERVICE_AGREEMENT_DOC', value: '', error: '', isRequired: false },
            RATE_CARD: { name: 'RATE_CARD', value: '', error: '', isRequired: false },
            GST_NO: { name: 'GST_NO', value: '', error: '', isRequired: false },

        });
    }


    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === state.PHONE.name) {
            const numericPhoneNumber = value.replace(/\D/g, '');
            setState(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: numericPhoneNumber
                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: value
                }
            }));
        }

    };

    const onSubmit = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                CLIENT_NAME: state.CLIENT_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                COMPANY_NAME: state.COMPANY_NAME.value,
                CLIENT_ADDRESS: state.CLIENT_ADDRESS.value,
                CLIENT_DETAILS: state.CLIENT_DETAILS.value,
                AGREEMENT_START_DATE: state.AGREEMENT_START_DATE.value,
                AGREEMENT_END_DATE: state.AGREEMENT_END_DATE.value,
                CLIENT_DOC: state.CLIENT_DOC.value,
                SERVICE_AGREEMENT_DOC: state.SERVICE_AGREEMENT_DOC.value,
                RATE_CARD: state.RATE_CARD.value,
                GST_NO: state.GST_NO.value

            }
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }
            props.onSubmit(formData)
        }
    }
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Add Client</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Client Management</a>
                                            </li>
                                            <li className="breadcrumb-item active">Add Client</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Client</h4>

                                    </div>
                                    <div className="card-body">
                                        <div className="live-preview">
                                            <div className="row gy-4">
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Company Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Company Name'
                                                            name={state.COMPANY_NAME.name}
                                                            value={state.COMPANY_NAME.value}
                                                            onChange={onChange}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            SPOC Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='SPOC Name'
                                                            name={state.CLIENT_NAME.name}
                                                            value={state.CLIENT_NAME.value}
                                                            onChange={onChange}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div >
                                                        <label className="form-label">
                                                            Email
                                                        </label>
                                                        <div className='form-icon'>
                                                            <input
                                                                type="email"
                                                                placeholder="Email"
                                                                className="form-control form-control-icon"
                                                                name={state.EMAIL.name}
                                                                value={state.EMAIL.value}
                                                                onChange={onChange}
                                                            />
                                                            <i className='ri-mail-unread-line' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Phone
                                                        </label>
                                                        <div className='form-icon'>
                                                            <input
                                                                type="tel"
                                                                className="form-control form-control-icon"
                                                                placeholder="Phone"
                                                                name={state.PHONE.name}
                                                                value={state.PHONE.value}
                                                                onChange={onChange}
                                                            />
                                                            <i className='ri-phone-line' />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            GST No
                                                        </label>
                                                        <div className=''>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control"
                                                                placeholder="GST No"
                                                                name={state.GST_NO.name}
                                                                value={state.GST_NO.value}
                                                                onChange={onChange}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Agreement Start Date
                                                        </label>
                                                        <div className=''>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name={state.AGREEMENT_START_DATE.name}
                                                                value={state.AGREEMENT_START_DATE.value}
                                                                onChange={onChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Agreement End Date
                                                        </label>
                                                        <div className=''>
                                                            <input
                                                                type="date"
                                                                className="form-control "
                                                                name={state.AGREEMENT_END_DATE.name}
                                                                value={state.AGREEMENT_END_DATE.value}
                                                                onChange={onChange}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Client Document
                                                        </label>
                                                        <div className=''>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name={state.CLIENT_DOC.name}
                                                                onChange={(e) => {
                                                                    setState(prevState => ({
                                                                        ...prevState,
                                                                        ['CLIENT_DOC']: {
                                                                            ...prevState['CLIENT_DOC'],
                                                                            value: e.target.files[0]
                                                                        }
                                                                    }));
                                                                }}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="">
                                                            Service Agreement Document
                                                        </label>
                                                        <div className='form-icon'>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name={state.SERVICE_AGREEMENT_DOC.name}
                                                                onChange={(e) => {
                                                                    setState(prevState => ({
                                                                        ...prevState,
                                                                        ['SERVICE_AGREEMENT_DOC']: {
                                                                            ...prevState['SERVICE_AGREEMENT_DOC'],
                                                                            value: e.target.files[0]
                                                                        }
                                                                    }));
                                                                }}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Rate Card
                                                        </label>
                                                        <div className=''>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name={state.RATE_CARD.name}
                                                                onChange={(e) => {
                                                                    setState(prevState => ({
                                                                        ...prevState,
                                                                        ['RATE_CARD']: {
                                                                            ...prevState['RATE_CARD'],
                                                                            value: e.target.files[0]
                                                                        }
                                                                    }));
                                                                }}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-12 col-md-12">
                                                    <div>
                                                        <label className="form-label">
                                                            Client Address
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Client Address"
                                                            name={state.CLIENT_ADDRESS.name}
                                                            value={state.CLIENT_ADDRESS.value}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xxl-12 col-md-12">
                                                    <div>
                                                        <label className="form-label">
                                                            Client Detail
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Client Detail"
                                                            name={state.CLIENT_DETAILS.name}
                                                            value={state.CLIENT_DETAILS.value}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-xxl-12 col-md-12">
                                                    <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">Add New</button>
                                                    <button type="button" onClick={() => { clearForm() }} class="btn btn-danger waves-effect waves-light mx-3">Clear Form</button>

                                                </div>

                                            </div>
                                            {/*end row*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*end col*/}
                        </div>
                    </div>{" "}
                </div>

            </div>

        </React.Fragment>
    )
}

export default Add