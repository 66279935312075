import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { crm_client_bar_chart_record } from '../../../actions/v1/dashboard';


export const options = {
    chart: {
        title: "CRM User Chart",
        subtitle: "Monthly Record",
    },
};



const ClientOnboardingStats = () => {
    const [data, setData] = useState(null)
    const [searchState, setSearchState] = useState({
        FROM_DATE: {
            name: "FROM_DATE",
            value: moment().subtract(4, 'months').format('YYYY-MM'),
            error: "",
            isRequired: false,
        },
        TO_DATE: {
            name: "TO_DATE",
            value: moment().format('YYYY-MM'),
            error: "",
            isRequired: false,
        },
    });

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchState((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
            },
        }));
    };

    useEffect(() => {
        crm_client_bar_chart_record(searchState.FROM_DATE.value, searchState.TO_DATE.value).then((res) => {
            if (res.status) {
                setData(res.result)
            }
        })
    }, [searchState.FROM_DATE.value, searchState.TO_DATE.value])

    return (
        <React.Fragment>
            <div className="row h-100">
                <div className="col-xl-12">
                    <div className="card card-height-100">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-3">
                                    <input
                                        type="month"
                                        id='fromDate'
                                        className="form-control customRadiusSearch"
                                        name={searchState.FROM_DATE.name}
                                        value={searchState.FROM_DATE.value}
                                        onChange={onChange}
                                        max={searchState.TO_DATE.value}
                                    />
                                    <label className="labelDate" htmlFor="fromDate"> From Date</label>

                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="month"
                                        id="toDate"
                                        className="form-control customRadiusSearch"
                                        name={searchState.TO_DATE.name}
                                        value={searchState.TO_DATE.value}
                                        onChange={onChange}
                                        min={searchState.FROM_DATE.value}
                                    />
                                    <label className="labelDate" htmlFor="toDate"> To Date</label>
                                </div>
                            </div>
                            {data && < Chart
                                chartType="Bar"
                                width="100%"
                                height="400px"
                                data={data}
                                options={options}
                            />}
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default ClientOnboardingStats