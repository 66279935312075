import React from 'react'
import { v1_is_valid_company } from '../../actions/v1/auth';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import constant from '../../constant';

import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

// const AccordionContainer = styled.div`
//   position: relative;
// `;

// const Button = styled.button`
//   background-color: #28a745;
//   color: #fff;
//   border: none;
//   padding: 8px 16px;
//   border-radius: 5px;
//   cursor: pointer;
// `;

// const AccordionContent = styled.div`
//   position: absolute;
//   top: calc(100% + 10px);
//   right: 0;
//   width: 300px;
//   background-color: #fff;
//   border: 1px solid #ccc;
//   border-radius: 10px;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
//   padding: 20px;
// `;

// const Input = styled.input`
//   width: 100%;
//   padding: 10px;
//   margin-bottom: 20px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
// `;

// const SubmitButton = styled.button`
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
// `;


const Landingpage = (props) => {
  // const [isTop, setIsTop] = useState(true);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY;
  //     setIsTop(scrollPosition === 0);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  // const [isOpen, setIsOpen] = useState(false);
  // const [companyCode, setCompanyCode] = useState('');

  // const toggleAccordion = () => {
  //   setIsOpen(!isOpen);
  // };

  // const handleInputChange = (e) => {
  //   setCompanyCode(e.target.value);
  // };
  // const checkUserExist = () => {
  //   v1_is_valid_company(companyCode).then((res) => {
  //     if (res.status) {
  //       const expirationDate = new Date();
  //       expirationDate.setTime(expirationDate.getTime() + (2 * 60 * 60 * 1000)); // 2 hours in milliseconds
  //       Cookies.set('parent_id', res.result, { expires: expirationDate });
  //       props.history.push(constant.nonAuthedcomponent.login.url.replace(':user_type', 'user'))
  //     }
  //     else {
  //       toast.error(res.error)
  //     }
  //   })
  // }

  return (
    <React.Fragment>
      <section className="section nft-hero" id="hero">
        {/* <ToastContainer /> */}
        <div className="bg-overlay" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-6">
              <div className="text-left">
                <h1 className="text-white" style={{ fontSize: 36, marginBottom: 15 }}>
                  <span style={{ color: '#fdcc01' }}>Jobzshala Recruit</span>– Advanced Applicant Tracking System
                </h1>
                <p className="text-white" style={{ fontSize: '1.25rem', }}>
                  Revolutionize Your Operations: From Candidate Sourcing to Contingent Workforce Management Streamline Your Staffing Journey with Jobzshala Recruit
                </p>
                <div className="bannerButtons mt-4">
                  <Link to={constant.nonAuthedcomponent.requestDemo.url} className="bannerBtnFull">
                    Request Free Demo{" "}
                    {/* <i className="ri-arrow-right-line align-middle ms-1" /> */}
                  </Link>
                  {/* <a href="javascript:void(0)" className="bannerBtnOutline">
                    Request Free Demo{" "}
                  </a> */}
                </div>
              </div>
            </div>
            <div class="col-md-5 col-lg-6">
              <div class="video-app-wrap ml-auto mt--120 d-none d-sm-none d-md-block d-lg-block">
                <div class="iphone-mask">
                  <img src="/images/BannerRight.svg" width="100%" alt="app" class="img-fluid mask-img" />
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/* end row */}
        </div>
        <div className="shape-bottom overflow-hidden">
          <img
            src="/images/hero-shape.svg" alt="shape" className="bottom-shape"
          />
        </div>
        {/* end container */}
      </section>
      {/* end hero section */}
      {/* start wallet */}
      <section className="section" id="wallet">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-left mb-4 walletSectionHeading">
                <h2 className="mb-3">Exclusively Designed for <span style={{ color: '#fdcc01' }}>Staffing Agencies</span></h2>
                <p className="text-muted">
                  Addressing Your Data Management Struggles and Work Culture Dynamics
                </p>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}

          <div className="col-lg-12">
            <div class="row">
              <div class="col-md-6">
                <div className="text-left mb-5 walletSectionHeadingInner">
                  {/* <div class="walletSectionHeadingInnerIcon">
                    <i class="ri-user-settings-fill"></i>
                  </div> */}
                  <h4 className="mb-3 mt-5">In the dynamic world of staffing and recruitment, efficiency is paramount. Jobzshala Recruit
                    is your comprehensive solution, tailored specifically for staffing agencies. We understand the
                    intricacies of your industry and have crafted a platform that addresses your unique
                    challenges, from data management to fostering a collaborative work culture.
                  </h4>
                </div>
              </div>
              <div class="col-md-6">
                <img src="assets/images/homepage/ExcusiveContent.svg" class="img-fluid" style={{ width: '70%'}} alt="" />
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>

      <section className="section bg-light creatorsSectionStyle" id="creators">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="text-center mb-5 creatorsSectionHeadingStyle">
                <h2 className="mb-3">AI-Powered Recruitment Management</h2>
                <p className="text-muted">
                  Transform your recruitment approach with Jobzshala Recruit's 
                  innovative AI technology. Witness a remarkable increase in your
                   pool of potential candidates effortlessly. Experience seamless 
                   integration of AI into your workflow, enabling swift 
                   identification of the perfect match for your organization. 
                   Jobzshala Recruit's advanced applicant tracking system 
                   streamlines talent acquisition, providing unmatched efficiency 
                   and invaluable data insights.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <Carousel data-bs-theme="dark" className="customCarousel">
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-6">
                    <img src="/assets/images/homepage/dasboard-screen-1.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <div class="col-md-6">
                    <Carousel.Caption>
                      <h5>Exclusively Designed for Staffing Agencies</h5>
                      <p>In the dynamic world of staffing and recruitment, efficiency is paramount. Jobzshala Recruit
                        is your comprehensive solution, tailored specifically for staffing agencies. We understand the
                        intricacies of your industry and have crafted a platform that addresses your unique
                        challenges, from data management to fostering a collaborative work culture.</p>
                    </Carousel.Caption>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-6">
                    <img src="assets/images/homepage/dasboard-screen-3.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <div class="col-md-6">
                    <Carousel.Caption>
                      <h5>Streamlined Operations, End-to-End</h5>
                      <p>From the initial stages of candidate sourcing and engagement to the final selection, offer,
                        and contingent workforce management, Jobzshala Recruit streamlines every aspect of your
                        operations. Our platform integrates seamlessly into your workflow, saving you time and
                        resources at every step of the recruitment process.</p>
                    </Carousel.Caption>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-6">
                    <img src="assets/images/homepage/dasboard-screen-4.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <div class="col-md-6">
                    <Carousel.Caption>
                      <h5>Objective Screening for Optimal Results</h5>
                      <p>Say goodbye to subjective decision-making. Jobzshala Recruit empowers you with objective
                        screening tools that ensure you find the best-fit candidates for your clients. Our AI-powered
                        algorithms analyze candidate data and match it against predefined criteria, allowing you to
                        make informed decisions quickly and confidently.</p>
                    </Carousel.Caption>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-6">
                    <img src="assets/images/homepage/dasboard-screen-5.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <div class="col-md-6">
                    <Carousel.Caption>
                      <h5>Exclusive Solutions for Staffing Agencies</h5>
                      <p>Unlike generic ATS software, Jobzshala Recruit is exclusively designed to meet the needs of
                        staffing agencies. We&#39;ve identified the pain points of data management and work culture
                        dynamics within your industry and have developed features specifically to address them.
                        With Jobzshala Recruit, you&#39;re not just getting a software solution – you&#39;re getting a partner
                        dedicated to your success.</p>
                    </Carousel.Caption>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>



      <section id="how-it-work" class="section workProcessSection">
        <div class="container">
          <div class="row justify-content-center">
              <div class="col-md-9 col-lg-8">
                  <div class="section-heading text-center workProcessSectionHeading">
                      <h2>Why <span style={{ color: '#fdcc01'}}>JOBZSHALA</span> Recruit</h2>
                      {/* <p>Distinctively grow go forward manufactured products and optimal networks. Enthusiastically
                          disseminate user-centric outsourcing through revolutionary</p> */}
                  </div>
              </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <div class="work-process-wrap">
                    <div class="single-work-process text-center mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                        <div class="work-process-icon-wrap color-1-bg rounded">
                            {/* <i class="ti-vector icon-md color-1"></i> */}
                            {/* <img src="assets/images/homepage/step1Img.png" alt="" style={{padding: 10,}}
                            /> */}
                            <i class="ri-history-line" style={{color: '#ff164e', fontSize: 40}}></i>
                            <span class="process-step white-bg color-primary shadow-sm">1</span>
                        </div>
                        <span class="work-process-divider">
                          <i class="ri-arrow-right-line align-bottom customDivider"></i>
                        </span>
                        <div class="work-process-content mt-4">
                            <h5>Faster Hiring</h5>
                            <p>Jobzshala Recruit streamlines the hiring process, saving you valuable time and resources.</p>
                        </div>
                    </div>
                    <div class="single-work-process text-center mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                        <div class="work-process-icon-wrap color-2-bg rounded">
                            {/* <i class="ti-layout-list-thumb icon-md color-2"></i> */}
                            {/* <img src="assets/images/homepage/step2Img.png" alt="" style={{padding: 10,}}
                            /> */}
                            <i class="ri-slideshow-line" style={{color: '#9123ff', fontSize: 40}}></i>
                            <span class="process-step white-bg color-primary shadow-sm">2</span>
                        </div>
                        <span class="work-process-divider">
                          <i class="ri-arrow-right-line align-bottom customDivider"></i>
                        </span>
                        <div class="work-process-content mt-4">
                            <h5>User-Friendly Interface</h5>
                            <p>Our platform is designed to be intuitive and easy to navigate, ensuring a seamless experience for all users.</p>
                        </div>
                    </div>
                    <div class="single-work-process text-center mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                        <div class="work-process-icon-wrap color-3-bg rounded">
                            {/* <i class="ti-palette icon-md color-3"></i> */}
                            {/* <img src="assets/images/homepage/step3Img.png" alt="" style={{padding: 10,}}
                            /> */}
                            <i class="ri-global-fill" style={{color: '#3413f7', fontSize: 40}}></i>
                            <span class="process-step white-bg color-primary shadow-sm">3</span>
                        </div>
                        <span class="work-process-divider">
                          <i class="ri-arrow-right-line align-bottom customDivider"></i>
                        </span>
                        <div class="work-process-content mt-4">
                            <h5>Affordable Solutions</h5>
                            <p>We believe in making advanced recruitment technology accessible to everyone, offering cost-effective options for businesses of all sizes.</p>
                        </div>
                    </div>
                    <div class="single-work-process text-center mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                        <div class="work-process-icon-wrap color-4-bg rounded">
                            {/* <i class="ti-cup icon-md color-4"></i> */}
                            {/* <img src="assets/images/homepage/step4Img.png" alt="" style={{padding: 10,}}
                            /> */}
                            <i class="ri-hand-coin-fill" style={{color: '#ff7c3f', fontSize: 40}}></i>
                            <span class="process-step white-bg color-primary shadow-sm">4</span>
                        </div>
                        <span class="work-process-divider">
                          <i class="ri-arrow-right-line align-bottom customDivider"></i>
                        </span>
                        <div class="work-process-content mt-4">
                            <h5>AI-Driven Matching</h5>
                            <p>Utilizing artificial intelligence, Jobzshala Recruit ensures precise and efficient candidate matching, enhancing your recruitment outcomes.</p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section sellSectionStyle" id="marketplace">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="text-center mb-5 sellSectionHeadingStyle">
                <h2 className="mb-3">Recruiting Obstacles</h2>
                <p className="text-muted">
                  The process of creating an NFT may cost less than a dollar, but
                  the process of selling it can cost up to a thousand dollars. For
                  example, Allen Gannett, a software developer.
                </p>
              </div>
            </div>
            {/* end col */}
          </div>


          <div class="row">
            <div class="col-md-6 col-12">
              <div class="features mb-sm-0 mb-md-5 mb-lg-5">
                <span class="customRoundStyleSellSection ti-layout icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left" style={{ paddingTop: '20px !important', }}>
                  <i class="ri-time-line"></i>
                </span>
                <div class="d-block overflow-hidden featuresBodySection">
                  <h5 class="mb-2">Slow Hiring</h5>
                  <p>80% of recruiters fail to complete requisitions within the deadline.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="features mb-sm-0 mb-md-5 mb-lg-5">
                <span class="customRoundStyleSellSection ti-eye icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left">
                  <i class="ri-thumb-up-fill"></i>
                </span>
                <div class="d-block overflow-hidden featuresBodySection">
                  <h5 class="mb-2">Hiring Manager's Challenge</h5>
                  <p>70% of hiring managers struggle to attract talented candidates.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="features mb-sm-0 mb-md-5 mb-lg-5">
                <span class="customRoundStyleSellSection ti-mobile icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left">
                  <i class="ri-team-line"></i>
                </span>
                <div class="d-block overflow-hidden featuresBodySection">
                  <h5 class="mb-2">Lack of Collaboration</h5>
                  <p>Recruiters and hiring managers face challenges due to lack of collaboration.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="features mb-sm-0 mb-md-5 mb-lg-5">
                <span class="customRoundStyleSellSection ti-mobile icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left">
                  <i class="ri-computer-line"></i>
                </span>
                <div class="d-block overflow-hidden featuresBodySection">
                  <h5 class="mb-2">Common Candidate Screening</h5>
                  <p>60% of recruiters find candidate screening to be a challenge.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="features mb-sm-0 mb-md-5 mb-lg-0">
                <span class="customRoundStyleSellSection ti-mobile icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left">
                  <i class="ri-bank-line"></i>
                </span>
                <div class="d-block overflow-hidden featuresBodySection">
                  <h5 class="mb-2">No Centralized Profile Bank</h5>
                  <p>Ineffective management of candidate databases leads to
                    fragmented data storage.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* end Work Process */}
      {/* start cta */}
      <section className="py-5 bg-primary position-relative CtaSection">
        <div className="bg-overlay bg-overlay-pattern opacity-50" />
        <div className="container">
          <div className="row align-items-center gy-4">
            <div className="col-md-6 m-0 CtaSectionInner">
              <h4 className="text-white">
              Unlock your opportunities to attract more clients and expand your 
              businesses with Jobzshala Recruit. Join our ecosystem and transform 
              your candidate management effortlessly with AI technology.
              </h4>
              <div className="ctaButtons mt-4">
                {/* <a
                  href="apps-nft-create.html"
                  className="btn ctaButtonsOutline"
                >
                  Create NFT
                </a> */}
                <Link
                  to={constant.nonAuthedcomponent.requestDemo.url}
                  className="btn ctaButtonsFull"
                >
                  Request Demo
                </Link>
              </div>
            </div>

            <div class="col-md-5 col-md-4">
              <div class="d-flex align-items-end">
                <img src="assets/images/homepage/ctaDemo.svg" class="img-fluid" alt="download" />
              </div>
            </div>
            {/* end col */}
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end cta */}

      <section id="features" class="section featuresSectionStyle">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-8 featuresSectionHeadingStyle">
              <div class="section-heading text-center mb-5">
                <h2>Service Excellence Guaranteed</h2>
                <p class="text-muted para-desc mb-0 mx-auto">Start working with that can provide everything you need to generate awareness, drive traffic, connect. Efficiently transform granular value with client-focused content. Energistically redefine market.</p>
              </div>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
              <div class="row">
                <div class="col-md-12 col-12">
                  <div class="features mb-sm-0 mb-md-5 mb-lg-5">
                    <span class="customRoundStyleFeatues ti-layout icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left" style={{ paddingTop: '20px !important', }}>
                      <i class="ri-smartphone-line"></i>
                    </span>
                    <div class="d-block overflow-hidden featuresBodySection">
                      <h5 class="mb-2">24/7 Support</h5>
                      <p>We're here when you need us. Our dedicated support team is available
                        round-the-clock to assist you with any queries or concerns, ensuring uninterrupted service
                        and peace of mind.</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-12">
                  <div class="features mb-sm-0 mb-md-5 mb-lg-5">
                    <span class="customRoundStyleFeatues ti-eye icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left">
                      <i class="ri-thumb-up-fill"></i>
                    </span>
                    <div class="d-block overflow-hidden featuresBodySection">
                      <h5 class="mb-2">Comprehensive Training</h5>
                      <p>Empower your team with the knowledge and skills they need to
                        make the most of Jobzshala Recruit. Our experts provide thorough training sessions to your
                        users, ensuring seamless adoption and utilization of our platform.</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-12">
                  <div class="features mb-sm-0 mb-md-5 mb-lg-0">
                    <span class="customRoundStyleFeatues ti-mobile icon-sm p-3 color-2 color-2-bg mr-4 mt-1 rounded-circle float-left">
                      <i class="ri-building-4-line"></i>
                    </span>
                    <div class="d-block overflow-hidden featuresBodySection">
                      <h5 class="mb-2">Customization at No Extra Cost:</h5>
                      <p>Your satisfaction is our priority. We understand that every
                        agency is unique, which is why we offer customization options tailored to your specific
                        needs. Whether it's modifying features or adding new functionalities, we&#39;ll make it happen at
                        no extra cost to you.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-5 col-md-4 col-12">
              <img src="/images/Vector-Service.svg" width="100%" alt="app" class="img-fluid mask-img" />
            </div>
          </div>
        </div>
      </section>

      <section class="how-it-work section recuritTimeSectionMain" id="categories" >
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-md-7 col-lg-7">
              <div class="img-wrap-recuritTime">
                <img src="assets/images/homepage/TimeMangementChart.svg" alt="" style={{ padding: 10, }} />
              </div>
            </div>
            <div class="col-md-5 col-lg-5">
              <div class="content-info-wrap recuritTimeSection">
                <h2>Bring Along Your Favorite Time</h2>
                <p>Energistically disseminate sustainable services without e-business imperatives. Holisticly maximize world-class collaboration and idea-sharing vis-a-vis reliable. Connect your existing plugins and amplify your mobile app.</p>

                <div class="row mt-5">
                  <div class="col-md-6 col-lg-6 col-12">
                    <div class="detail-info-box">
                      <span class="d-inline-block mb-4 text-white p-3 secondary-bg rounded IconMainStyle">
                        <i class="ri-sun-fill customSunIcon"></i>
                      </span>
                      <div class="detail-info-content">
                        <h5 class="mb-2">Design Focused</h5>
                        <p>Appropriately recaptiualize multimedia based top-line e-services.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6 col-12">
                    <div class="detail-info-box">
                      <span class="d-inline-block mb-4 text-white p-3 secondary-bg rounded IconMainStyle">
                        <i class="ri-store-3-line customSunIcon"></i>
                      </span>
                      <div class="detail-info-content">
                        <h5 class="mb-2">Marketing Activity</h5>
                        <p>Dynamically recaptiualize fully researched e-services applications.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="reviews" class="section testimonialSection">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-8">
              <div class="section-heading mb-5 text-center testimonialHeadingSection">
                <h2>Testimonials What Clients Say</h2>
                <p class="lead">
                  Rapidiously morph transparent internal or "organic" sources whereas resource sucking
                  e-business. Conveniently innovate compelling internal.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-lg-4">
              <div class="testimonial-single shadow-sm gray-light-bg rounded p-4">
                <blockquote>
                  Assertively procrastinate distributed relationships whereas equity invested intellectual capital everything energistically underwhelm proactive.
                </blockquote>
                <div class="client-ratting mt-2">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <span class="ri-star-line ratting-color"></span>
                    </li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                  </ul>
                  <h6 class="font-weight-bold">5.0 <small class="font-weight-lighter">BizBite</small></h6>
                </div>
              </div>
              <div class="client-info-wrap d-flex align-items-center mt-5">
                <div class="client-img mr-3">
                  <img src="assets/images/homepage/client-1.jpg" alt="client" width="60" class="img-fluid rounded-circle shadow-sm" />
                </div>
                <div class="client-info">
                  <h5 class="mb-0">Austin Cesar</h5>
                  <p class="mb-0">ThemeTags</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="testimonial-single shadow-sm gray-light-bg rounded p-4">
                <blockquote>
                  Intrinsicly facilitate functional imperatives without next-generation meta-services. Compellingly revolutionize worldwide users vis-a-vis enterprise best practices.
                </blockquote>
                <div class="client-ratting mt-2">
                  <ul class="list-inline">
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                  </ul>
                  <h6 class="font-weight-bold">5.0 <small class="font-weight-lighter">BizBite</small></h6>
                </div>
              </div>
              <div class="client-info-wrap d-flex align-items-center mt-5">
                <div class="client-img mr-3">
                  <img src="assets/images/homepage/client-2.jpg" alt="client" width="60" class="img-fluid rounded-circle shadow-sm" />
                </div>
                <div class="client-info">
                  <h5 class="mb-0">Pirtle Karol</h5>
                  <p class="mb-0">ThemeTags</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="testimonial-single shadow-sm gray-light-bg rounded p-4">
                <blockquote>
                  Interactively grow backend scenarios through one paradigms. Distinctively and communicate efficient information without effective meta-services.
                </blockquote>
                <div class="client-ratting mt-2">
                  <ul class="list-inline">
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                  </ul>
                  <h6 class="font-weight-bold">5.0 <small class="font-weight-lighter">BizBite</small></h6>
                </div>
              </div>
              <div class="client-info-wrap d-flex align-items-center mt-5">
                <div class="client-img mr-3">
                  <img src="assets/images/homepage/client-3.jpg" alt="client" width="60" class="img-fluid rounded-circle shadow-sm" />
                </div>
                <div class="client-info">
                  <h5 class="mb-0">Aminul Islam</h5>
                  <p class="mb-0">ThemeTags</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>



      {/* Start footer */}
      
    </React.Fragment>

  )
}

export default Landingpage