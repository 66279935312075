import axios from 'axios'
import { getCompanyUserAuthHeader } from '../../utils';

export const v3_notifications = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/notification`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_delete_notifications = async (ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/notification/${ID}`;
    return axios.delete(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}