import React from 'react'

const step_three = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card mt-4">
                                <div className="card-body p-4 text-center">
                                    <div className="avatar-lg mx-auto mt-2">
                                        <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                            <i className="ri-checkbox-circle-fill" />
                                        </div>
                                    </div>
                                    <div className="mt-4 pt-2">
                                        <h4>Well done !</h4>
                                        <p className="text-muted mx-4">
                                            Registration has been completed.
                                        </p>
                                        <p className="text-muted mx-4">
                                            Please Contact with Desk Recruiter for further steps.
                                        </p>
                                        <div className="mt-4">
                                            <a
                                                href="/"
                                                className="btn btn-success w-100"
                                            >
                                                Back to Dashboard
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* end card body */}
                            </div>
                            {/* end card */}
                        </div>
                    </div>
                    {/* end row */}
                </div>
                </div>


        </React.Fragment>
    )
}

export default step_three