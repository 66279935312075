import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const SelectWrapper = styled.div`
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 150px;
  padding: 5px;
  margin-right: 5px;
  max-width: 300px; /* Adjust the max-width as needed */
`;

const Option = styled.a`
  display: block;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  &.selected {
    background-color: #f0f0f0;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #ccc;
`;

const LeftArrow = styled(Arrow)`
  left: calc(50% - 10px); /* Adjust position */
  clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
  z-index: 1; /* Ensure the left arrow is above the right arrow */
  margin-right: 5px; /* Add separation */
`;

const RightArrow = styled(Arrow)`
  right: calc(50% - 10px); /* Adjust position */
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  z-index: 1; /* Ensure the right arrow is above the left arrow */
  margin-left: 5px; /* Add separation */
`;


const MultiSelect = (props) => {
    // State for all users
    const [allUsers, setAllUsers] = useState(props.allUsers);

    // State for selected user IDs
    const { selectedUserIds, setSelectedUserIds } = props;

    const toggleOption = (userId) => {
        if (selectedUserIds.includes(userId)) {
            setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
        } else {
            setSelectedUserIds([...selectedUserIds, userId]);
        }
    };

    return (
        <Container>
            <Wrapper>
                {/* All users */}
                <SelectWrapper>
                    {allUsers?.map((user) => (
                        <Option
                            key={user.USER_ID}
                            className={selectedUserIds.includes(user.USER_ID) ? 'item selected' : 'item'}
                            onClick={() => toggleOption(user.USER_ID)}
                        >
                            {user.FULL_NAME}
                        </Option>
                    ))}
                </SelectWrapper>
                <LeftArrow />
                <RightArrow />
                {/* Selected users (display only) */}
                <SelectWrapper>
                    {selectedUserIds.map((userId) => {
                        const selectedUser = allUsers.find((user) => user.USER_ID === userId);
                        return (
                            <Option
                                key={userId}
                                className="item selected"
                                onClick={() => toggleOption(userId)} // Allow deselection by clicking
                            >
                                {selectedUser?.FULL_NAME}
                            </Option>
                        );
                    })}
                </SelectWrapper>
            </Wrapper>
        </Container>
    );
};

export default MultiSelect;
