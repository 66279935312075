import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import Parser from 'html-react-parser';
import html2canvas from 'html2canvas';
import { v1_send_crm_invoice } from '../../../actions/v1/subscription_management';
import { toast } from 'react-toastify';



const InvoicePreview = (props) => {
    const { invoiceDetails } = props;

    const sendInvoice = () => {
        const htmlElement = document.getElementById('invoice');
        html2canvas(htmlElement).then(canvas => {
            const base64Image = canvas.toDataURL('image/png');
            // Now you can send base64Image to your backend
            v1_send_crm_invoice({ COMPANY_ID: invoiceDetails.COMPANY_ID, invoice: base64Image }).then((res) => {
                if (res.status) {
                    toast.success('Invoice Sent')
                }
                else {
                    toast.error(res.error)

                }
            })
        });
    }

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Invoice Details</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Invoices</a>
                                            </li>
                                            <li className="breadcrumb-item active">Invoice Details</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xxl-9">
                                <div className="card" id="demo">
                                    <div className="row" id='invoice'>
                                        <div className="col-lg-12">
                                            <div className="card-header border-bottom-dashed p-4">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <img
                                                            src="/images/logo/recruit-logo.png"
                                                            className="card-logo card-logo-dark"
                                                            alt="logo dark"
                                                            height={50}
                                                        />

                                                        <div className="mt-sm-5 mt-4">
                                                            <h6 className="text-muted text-uppercase fw-semibold">
                                                                Address
                                                            </h6>
                                                            <p className="text-muted mb-1" id="address-details">
                                                                {invoiceDetails.JOBZSHALA_ADDRESS}
                                                            </p>
                                                            <p className="text-muted mb-0" id="zip-code">
                                                                <span>Zip-code:</span> {invoiceDetails.JOBZSHALA_POSTAL_CODE}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0 mt-sm-0 mt-3">
                                                        <h6>
                                                            <span className="text-muted fw-normal">
                                                                Legal Registration No:
                                                            </span>
                                                            <span id="legal-register-no">{invoiceDetails.JOBZSHALA_LEGAL_REGISTRATION_NUMBER}</span>
                                                        </h6>
                                                        <h6>
                                                            <span className="text-muted fw-normal">Email:</span>
                                                            <span id="email">{invoiceDetails.JOBZSHALA_EMAIL_ADDRESS}</span>
                                                        </h6>
                                                        <h6>
                                                            <span className="text-muted fw-normal">Website:</span>{" "}
                                                            <a
                                                                href={invoiceDetails.JOBZSHALA_WEBSITE}
                                                                className="link-primary"
                                                                target="_blank"
                                                                id="website"
                                                            >
                                                                {invoiceDetails.JOBZSHALA_WEBSITE}
                                                            </a>
                                                        </h6>
                                                        <h6 className="mb-0">
                                                            <span className="text-muted fw-normal">
                                                                Contact No:{" "}
                                                            </span>
                                                            <span id="contact-no"> {invoiceDetails.JOBZSHALA_CONTACT_NUMBER}</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="card-body p-4">
                                                <div className="row g-3">
                                                    <div className="col-lg-3 col-6">
                                                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                            Invoice No
                                                        </p>
                                                        <h5 className="fs-14 mb-0">
                                                            {invoiceDetails.INVOICE_NUMBER}
                                                        </h5>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                            Date
                                                        </p>
                                                        <h5 className="fs-14 mb-0">
                                                            {invoiceDetails.INVOICE_DATE}
                                                        </h5>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                            Payment Status
                                                        </p>
                                                        <span
                                                            className="badge bg-success-subtle text-success fs-11"
                                                            id="payment-status"
                                                        >
                                                            {invoiceDetails.PAYMENT_STATUS === 'S' ? 'Paid' : 'Unpaid'}
                                                        </span>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                            Total Amount
                                                        </p>
                                                        <h5 className="fs-14 mb-0">
                                                            ₹<span id="total-amount">{invoiceDetails.TOTAL_AMOUNT}</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="card-body p-4 border-top border-top-dashed">
                                                <div className="row g-3">
                                                    <div className="col-6">
                                                        <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                                            Billing Address
                                                        </h6>
                                                        <p className="fw-medium mb-2" id="billing-name">
                                                            {invoiceDetails.BILLER_NAME}
                                                        </p>
                                                        <p
                                                            className="text-muted mb-1"
                                                            id="billing-address-line-1"
                                                        >
                                                            {invoiceDetails.BILLER_ADDRESS}
                                                        </p>
                                                        <p className="text-muted mb-1">
                                                            <span>Phone: +</span>
                                                            <span id="billing-phone-no">{invoiceDetails.BILLER_PHONE}</span>
                                                        </p>
                                                        {invoiceDetails.BILLER_GST ? <p className="text-muted mb-0">
                                                            <span>Tax: </span>
                                                            <span id="billing-tax-no">{invoiceDetails.BILLER_GST}</span>{" "}
                                                        </p> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="card-body p-4">
                                                <div className="table-responsive">
                                                    <table className="table table-borderless text-center table-nowrap align-middle mb-0">
                                                        <thead>
                                                            <tr className="table-active">
                                                                <th scope="col" style={{ width: 50 }}>
                                                                    #
                                                                </th>
                                                                <th scope="col">Product Details</th>
                                                                <th scope="col">Rate</th>
                                                                <th scope="col">No of Users</th>
                                                                <th scope="col" className="text-end">
                                                                    Amount
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="products-list">
                                                            <tr>
                                                                <th scope="row">01</th>
                                                                <td className="text-start">
                                                                    <span className="fw-medium">
                                                                        {invoiceDetails.PACKAGE_NAME}
                                                                    </span>
                                                                    <p className="text-muted mb-0">
                                                                        {Parser(invoiceDetails.PACKAGE_DETAILS)}
                                                                    </p>
                                                                </td>
                                                                <td>₹{invoiceDetails.PLAN_AMOUNT}</td>
                                                                <td>{invoiceDetails.NO_OF_LOGINS}</td>
                                                                <td className="text-end">₹{invoiceDetails.PLAN_AMOUNT}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="border-top border-top-dashed mt-2">
                                                    <table
                                                        className="table table-borderless table-nowrap align-middle mb-0 ms-auto"
                                                        style={{ width: 250 }}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td>Sub Total</td>
                                                                <td className="text-end">₹{invoiceDetails.PLAN_AMOUNT}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tax (18%)</td>
                                                                <td className="text-end">₹{invoiceDetails.TAX_AMOUNT}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Discount{" "}
                                                                </td>
                                                                <td className="text-end">- ₹{invoiceDetails.DISCOUNT}</td>
                                                            </tr>
                                                            <tr className="border-top border-top-dashed fs-15">
                                                                <th scope="row">Total Amount</th>
                                                                <th className="text-end">₹{invoiceDetails.TOTAL_AMOUNT}</th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="mt-3">
                                                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                                        Payment Details:
                                                    </h6>
                                                    <p className="text-muted mb-1">
                                                        Payment Method:{" "}
                                                        <span className="fw-medium" id="payment-method">
                                                            {invoiceDetails.MODE_OF_PAYMENT}
                                                        </span>
                                                    </p>
                                                    {/* <p className="text-muted mb-1">
                                                        Card Holder:{" "}
                                                        <span className="fw-medium" id="card-holder-name">
                                                            David Nichols
                                                        </span>
                                                    </p> */}
                                                    {/* <p className="text-muted mb-1">
                                                        Card Number:{" "}
                                                        <span className="fw-medium" id="card-number">
                                                            xxx xxxx xxxx 1234
                                                        </span>
                                                    </p> */}
                                                    <p className="text-muted">
                                                        Transaction Id:{" "}

                                                        <span id="card-total-amount">{invoiceDetails.TXN_ID}</span>
                                                    </p>
                                                </div>
                                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                                    <a
                                                        href="javascript:window.print()"
                                                        className="btn btn-success"
                                                    >
                                                        <i className="ri-printer-line align-bottom me-1" /> Print
                                                    </a>
                                                    <a href="javascript:void(0);" className="btn btn-danger" onClick={() => { sendInvoice() }}>
                                                        <i className="ri-send-plane-fill align-bottom me-1" /> Send Invoice
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default withRouter(InvoicePreview)