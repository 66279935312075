import React, { useEffect, useState } from "react";
import DrawerModal from "../../../components/common/htmlDrawerModal";
import CanididateDetailDrawerModal from "./canididateDetailDrawerModal";
import Pagination from "../../common/Pagination";
import Modal from "../../common/htmlModal";
import Edit from "./editModal";
import constant from "../../../constant";
import { v3_add_candidate_reminder, v3_edit_candidate, v3_lead_owners, v3_role_wise_lead_owners } from "../../../actions/v3/candidates";
import { ToastContainer, toast } from "react-toastify";
import { v1_application_status_options, v1_candidate_status_options } from "../../../actions/v1/master_data";
import ReminderForm from "./reminderForm";
import moment from "moment";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const List = (props) => {
  const { list, count, currentPage, todayCount } = props;

  const [showCandidateDetailDrawerModal, setShowCandidateDetailDrawerModal] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [showEditModal, setShowEditModal] = useState(null);
  const [showAddReminderModal, setShowAddRemoinderModal] = useState(null);

  const [searchState, setSearchState] = useState({
    CANDIDATE_NAME: {
      name: "CANDIDATE_NAME",
      value: "",
      error: "",
      isRequired: false,
    },
    EMAIL: {
      name: "EMAIL",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    PHONE: {
      name: "PHONE",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    SKILLS: {
      name: "SKILLS",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    CURRENT_CTC_MIN: {
      name: "CURRENT_CTC_MIN",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    CURRENT_CTC_MAX: {
      name: "CURRENT_CTC_MAX",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    NOTICE_PERIOD: {
      name: "NOTICE_PERIOD",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    EXPERIENCE_MIN: {
      name: "EXPERIENCE_MIN",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    EXPERIENCE_MAX: {
      name: "EXPERIENCE_MAX",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    AVERAGE_RATING: {
      name: "AVERAGE_RATING",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    SOURCE: {
      name: "SOURCE",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    LEAD_OWNER: {
      name: "LEAD_OWNER",
      value: "",
      options: [],

      visible: false,
      error: "",
      isRequired: false,
    },
    CANDIDATE_STATUS: {
      name: "CANDIDATE_STATUS",
      value: "",
      options: [],

      visible: false,
      error: "",
      isRequired: false,
    },
    APPLICATION_STATUS: {
      name: "APPLICATION_STATUS",
      value: "",
      options: [],

      visible: false,
      error: "",
      isRequired: false,
    },
    FROM_DATE: {
      name: "FROM_DATE",
      value: '',
      options: "",

      visible: false,
      error: "",
      isRequired: false,
    },
    TO_DATE: {
      name: "TO_DATE",
      value: '',
      options: "",

      visible: false,
      error: "",
      isRequired: false,
    },
    ADDED_VIA: {
      name: "ADDED_VIA",
      value: '',
      visible: false,
      error: "",
      isRequired: false,
    },
  });
  const setOptions = (name, options) => {
    setSearchState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        options: options
      }
    }));
  }

  const onDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(list.map((item) => {
      const model = {
        'Candidate ID ': 'RJZSHCL' + item.CANDIDATE_ID,
        'Email': item.EMAIL,
        'Mobile': item.PHONE,
        'Skills': item.SKILLS,
        'Current Company': item.CURRENT_COMPANY ? item.CURRENT_COMPANY : 'Not Available',
        'Current Designation': item.CURRENT_DESIGNATION ? item.CURRENT_DESIGNATION : 'Not Available',
        'Notice Period': item.NOTICE_PERIOD ? item.NOTICE_PERIOD : 'Not Available',
        'Notice Period': item.PREFERRED_ROLE_NAME ? item.PREFERRED_ROLE_NAME : 'Not Available',
        'Current Status': item.STATUS_NAME,
        'Lead Owner': item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available',
        'Added By': item.CREATED_BY_NAME ? item.CREATED_BY_NAME + ' at ' + moment(item.CREATED_ON).fromNow() : 'Not Available'
      }
      return model
    }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'candidate_lead.xlsx');
  }

  useEffect(() => {
    v3_role_wise_lead_owners().then((res) => {
      if (res.status) {
        setOptions('LEAD_OWNER', res.result)
      }
    })
    v1_candidate_status_options().then((res) => {
      if (res.status) {
        setOptions('CANDIDATE_STATUS', res.result)
      }
    })
    v1_application_status_options().then((res) => {
      if (res.status) {
        setOptions('APPLICATION_STATUS', res.result)
      }
    })

  }, [])

  const onReset = () => {
    setSearchState({
      CANDIDATE_NAME: {
        name: "CANDIDATE_NAME",
        value: "",
        error: "",
        isRequired: false,
      },
      EMAIL: {
        name: "EMAIL",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      PHONE: {
        name: "PHONE",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      SKILLS: {
        name: "SKILLS",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      CURRENT_CTC_MIN: {
        name: "CURRENT_CTC_MIN",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      CURRENT_CTC_MAX: {
        name: "CURRENT_CTC_MAX",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      NOTICE_PERIOD: {
        name: "NOTICE_PERIOD",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      EXPERIENCE_MIN: {
        name: "EXPERIENCE_MIN",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      EXPERIENCE_MAX: {
        name: "EXPERIENCE_MAX",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      AVERAGE_RATING: {
        name: "AVERAGE_RATING",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      SOURCE: {
        name: "SOURCE",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
    });
    const model = {
      CANDIDATE_NAME: "",
      EMAIL: "",
      PHONE: "",
      SKILLS: "",
      CURRENT_CTC_MIN: "",
      CURRENT_CTC_MAX: "",
      NOTICE_PERIOD: "",
      EXPERIENCE_MIN: "",
      EXPERIENCE_MAX: "",
      AVERAGE_RATING: "",
      SOURCE: "",
    };

    props.setSearchQuery(model);
  };
  const onSearch = () => {
    const model = {
      CANDIDATE_NAME: searchState.CANDIDATE_NAME.value,
      EMAIL: searchState.EMAIL.value,
      PHONE: searchState.PHONE.value,
      SKILLS: searchState.SKILLS.value,
      CURRENT_CTC_MIN: searchState.CURRENT_CTC_MIN.value,
      CURRENT_CTC_MAX: searchState.CURRENT_CTC_MAX.value,
      NOTICE_PERIOD: searchState.NOTICE_PERIOD.value,
      EXPERIENCE_MIN: searchState.EXPERIENCE_MIN.value,
      EXPERIENCE_MAX: searchState.EXPERIENCE_MAX.value,
      AVERAGE_RATING: searchState.AVERAGE_RATING.value,
      SOURCE: searchState.SOURCE.value,
      LEAD_OWNER: searchState.LEAD_OWNER.value,
      FROM_DATE: searchState.FROM_DATE.value,
      TO_DATE: searchState.TO_DATE.value,
      CANDIDATE_STATUS: searchState.CANDIDATE_STATUS.value,
      APPLICATION_STATUS: searchState.APPLICATION_STATUS.value,
      ADDED_VIA: searchState.ADDED_VIA.value

    };

    props.setSearchQuery(model);
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
      },
    }));
  };

  const onEdit = (model) => {
    v3_edit_candidate(model).then((res) => {
      if (res.status) {
        setShowEditModal(false)
        toast.success('Candidate Lead Saved')
        props.setRefresh(!props.refresh)
      }
      else {
        toast.error(res.error)
      }
    })
  }
  const onAddRemninder = (model) => {
    v3_add_candidate_reminder(model).then((res) => {
      if (res.status) {
        setShowAddRemoinderModal(null)
        toast.success('Reminder Added Successfully')


      }

    })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <DrawerModal
              isOpen={showCandidateDetailDrawerModal}
              onClose={() => {
                setShowCandidateDetailDrawerModal(false);
              }}
            >
              {candidateDetails && (
                <CanididateDetailDrawerModal
                  showEditOption={true}
                  candidateDetails={candidateDetails}
                />
              )}
            </DrawerModal>
            <Modal isOpen={showEditModal} header={'Edit Candidate Lead Details'}
              onClose={() => { setShowEditModal(null) }}
            >
              <Edit
                data={showEditModal}
                onClose={() => { setShowEditModal(null) }}
                onSubmit={(model) => { onEdit(model) }}
              />
            </Modal>
            <Modal isOpen={showAddReminderModal} header={'Add Reminder'}>
              <ReminderForm
                onSubmit={(model) => (onAddRemninder(model))}
                onClose={() => setShowAddRemoinderModal(null)}
                data={showAddReminderModal}
              />
            </Modal>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Search Candidate</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Candidate Management</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Candidate Search
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 canditateSerachMain">
                <div className="card">
                  <div className="card-header border-0">
                    <div className="row g-4 align-items-center">
                      <div className="col-sm-3">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search customRadiusSearch"
                            placeholder="Candidate Name"
                            id='candidateName'
                            name={searchState.CANDIDATE_NAME.name}
                            value={searchState.CANDIDATE_NAME.value}
                            onChange={onChange}
                          />
                          {/* <label htmlFor="candidateName"> Candidate Name</label> */}
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search customRadiusSearch"
                            placeholder="Email"
                            name={searchState.EMAIL.name}
                            value={searchState.EMAIL.value}
                            onChange={onChange}
                          />
                          <i className="ri-mail-line search-icon" />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="search-box">
                          <input
                            type="number"
                            min={5}
                            className="form-control search customRadiusSearch"
                            placeholder="Phone"
                            name={searchState.PHONE.name}
                            value={searchState.PHONE.value}
                            onChange={onChange}
                          />
                          <i className="ri-phone-line search-icon" />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="">
                          <input
                            type="text"
                            className="form-control customRadiusSearch"
                            placeholder="Skills"
                            name={searchState.SKILLS.name}
                            value={searchState.SKILLS.value}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="row">
                          <div className="col-sm-6">
                            <input
                              type="number"
                              className="form-control customRadiusSearch"
                              placeholder="CTC Min"
                              name={searchState.CURRENT_CTC_MIN.name}
                              value={searchState.CURRENT_CTC_MIN.value}
                              onChange={onChange}
                            />
                          </div>

                          <div className="col-sm-6">
                            {/* <i className="ri-skills-line search-icon" /> */}
                            <input
                              type="number"
                              className="form-control customRadiusSearch"
                              placeholder="CTC Max"
                              name={searchState.CURRENT_CTC_MAX.name}
                              value={searchState.CURRENT_CTC_MAX.value}
                              onChange={onChange}
                            />
                            {/* <i className="ri-skills-line search-icon" /> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <select
                          className="form-select customRadiusSearch"
                          name={searchState.NOTICE_PERIOD.name}
                          value={searchState.NOTICE_PERIOD.value}
                          onChange={onChange}
                        >
                          <option value=""> Notice Period</option>
                          <option value="Immediate Joiner">
                            {" "}
                            Immediate Joiner
                          </option>
                          <option value="15 Days"> 15 Days</option>
                          <option value="1 Month"> 1 Month</option>
                          <option value="2 Month"> 2 Month</option>
                          <option value="3 Month"> 3 Month or more</option>
                        </select>
                      </div>
                      <div className="col-sm-3">
                        <div className="row">
                          <div className="col-sm-6">
                            <input
                              type="number"
                              className="form-control customRadiusSearch"
                              placeholder="Experiece Min"
                              name={searchState.EXPERIENCE_MIN.name}
                              value={searchState.EXPERIENCE_MIN.value}
                              onChange={onChange}
                            />
                          </div>

                          <div className="col-sm-6">
                            {/* <i className="ri-skills-line search-icon" /> */}
                            <input
                              type="number"
                              className="form-control customRadiusSearch"
                              placeholder="Experiece Max"
                              name={searchState.EXPERIENCE_MAX.name}
                              value={searchState.EXPERIENCE_MAX.value}
                              onChange={onChange}
                            />
                            {/* <i className="ri-skills-line search-icon" /> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <select
                          className="form-select customRadiusSearch"
                          name={searchState.SOURCE.name}
                          value={searchState.SOURCE.value}
                          onChange={onChange}
                        >
                          <option value=''> Select Lead Source</option>
                          <option value='Found It / Monster'> Found It / Monster</option>
                          <option value='Naukri'> Naukri</option>
                          <option value='LinkedIn'> LinkedIn</option>
                          <option value='Instagram'> Instagram</option>
                          <option value='apna'> apna</option>
                          <option value='Campus'> Campus</option>
                          <option value='Other Portal'> Other Portal</option>
                          <option value='Referral'> Referral</option>
                          <option value='Social Media'> Social Media</option>
                          <option value='Walk-In'> Walk-In</option>
                        </select>
                      </div>

                      <div className="col-sm-3">
                        <input
                          className="form-control customRadiusSearch"
                          type="number"
                          name={searchState.AVERAGE_RATING.name}
                          value={searchState.AVERAGE_RATING.value}
                          onChange={onChange}
                          placeholder="Rating"
                        />
                        {/* //   <option value=""> Rating</option>
                        //   <option value="1"> 1</option>
                        //   <option value="2"> 2</option>
                        //   <option value="3"> 3</option>
                        //   <option value="4"> 4</option>
                        //   <option value="5"> 5</option>
                        // </select> */}
                      </div>
                      <div className="col-sm-3">
                        <select
                          className="form-select customRadiusSearch"
                          name={searchState.LEAD_OWNER.name}
                          value={searchState.LEAD_OWNER.value}
                          onChange={onChange}
                        >
                          <option value=""> Lead Owner</option>
                          {searchState.LEAD_OWNER.options && searchState.LEAD_OWNER.options?.map((item) => {
                            return (
                              <option value={item.USER_ID}> {item.FULL_NAME}</option>

                            )
                          })}
                        </select>
                      </div>
                      <div className="col-sm-3">
                        <select
                          className="form-select customRadiusSearch"
                          name={searchState.CANDIDATE_STATUS.name}
                          value={searchState.CANDIDATE_STATUS.value}
                          onChange={onChange}
                        >
                          <option value=""> Candidate Status</option>
                          {searchState.CANDIDATE_STATUS?.options && searchState.CANDIDATE_STATUS?.options?.map((item) => {
                            return (
                              <option value={item.STATUS_ID}> {item.STATUS_NAME}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className="col-sm-3">
                        <select
                          className="form-select customRadiusSearch"
                          name={searchState.ADDED_VIA.name}
                          value={searchState.ADDED_VIA.value}
                          onChange={onChange}
                        >
                          <option value=""> Added Via</option>
                          <option value="ATS Recruiter"> ATS Recruiter</option>
                          <option value="Quick Add"> Quick Add</option>
                          <option value="Bulk Upload"> Bulk Upload</option>
                          <option value="Walk-in QR"> Walk-in QR</option>
                        </select>
                      </div>
                      <div className="col-sm-3">
                        <input
                          type="date"
                          id='fromDate'
                          className="form-control customRadiusSearch"
                          name={searchState.FROM_DATE.name}
                          value={searchState.FROM_DATE.value}
                          onChange={onChange}
                          max={searchState.TO_DATE.value}
                        />
                        <label className="labelDate" htmlFor="fromDate"> From Date</label>

                      </div>
                      <div className="col-sm-3">
                        <input
                          type="date"
                          id="toDate"
                          className="form-control customRadiusSearch"
                          name={searchState.TO_DATE.name}
                          value={searchState.TO_DATE.value}
                          onChange={onChange}
                          min={searchState.FROM_DATE.value}
                        />
                        <label className="labelDate" htmlFor="toDate"> To Date</label>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-3">
                        <button
                          type="button"
                          onClick={() => {
                            onReset();
                          }}
                          className="btn btn-danger add-btn resettyleBtn"
                        >
                          Reset
                        </button>
                      </div>
                      <div class="col-md-3">
                        <button
                          type="button"
                          onClick={() => {
                            onSearch();
                          }}
                          className="btn btn-success add-btn ms-1 customReportClorButton"
                        >
                          <i className="ri-search-line search-icon align-bottom me-1" />
                          Search
                        </button>
                      </div>
                      <div className="col-md-2">
                        <button
                          type="button"
                          className="btn btn-primary add-btn customReportClorButton"
                          onClick={() => onDownloadExcel()}
                        >
                          <i className="mdi mdi-file-excel align-bottom me-1" />
                          <span className="ms-1 d-none d-sm-inline-block">Download Excel</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mainclientStyleSection">
                    <div className="card mb-0">
                      <div
                        className="card-header align-items-center d-flex mb-0 pb-0"
                        style={{ paddingLeft: 15 }}
                      >
                      </div>
                      <div className="card-body">
                        {list && list.map(item => {
                          return (
                            <a class="clientSection">
                              <div class="row">
                                {/* <div class="col-md-1 clientProfileImg">
                                  <img
                                    style={{ border: "2px solid #e7e7e7" }}
                                    className="rounded-circle"
                                    src="/assets/images/companies/img-2.png"
                                    alt="Header Avatar"
                                  />
                                </div> */}
                                <div class="col-md-6 clientSectionLeft">
                                  <h3 className="mb-0">
                                    {item.FULL_NAME} <span className="text-success fs-12 ms-2" onClick={() => {
                                      setCandidateDetails(item);
                                      setShowCandidateDetailDrawerModal(true);
                                    }}> View Full Profile </span>
                                    <span className="d-flex">
                                      {item.TOTAL_EXP_YEARS && <h6> <i class="ri-suitcase-fill" />&nbsp;{item.TOTAL_EXP_YEARS}&nbsp;&nbsp;</h6>}
                                      {item.CURRENT_CTC && <h6> <i class="ri-money-dollar-box-fill" />&nbsp;{item.CURRENT_CTC}&nbsp;&nbsp;</h6>}
                                      {item.WORKING_LOCATION && <h6> <i class="ri-map-pin-fill" />&nbsp;{item.WORKING_LOCATION}&nbsp;&nbsp;</h6>}
                                    </span>
                                  </h3>

                                  <p>
                                    <i class="ri-user-fill"></i> Current Designation
                                    :{item.CURRENT_DESIGNATION ? item.CURRENT_DESIGNATION : 'Not Available'}
                                  </p>
                                  <p>
                                    <i class="ri-building-fill"></i> Current Company : {item.CURRENT_COMPANY ? item.CURRENT_COMPANY : 'Not Available'}
                                  </p>
                                  <p>
                                    <i class="ri-user-settings-fill"></i> Skills : {item.SKILLS}
                                  </p>
                                  <p>
                                    <i class="ri-user-settings-fill"></i> Preferred Role : {item.PREFERRED_ROLE_NAME ? item.PREFERRED_ROLE_NAME : 'Not Available'}
                                  </p>
                                  <p>
                                    <i class="ri-user-settings-fill"></i> Add Via : {item.SOURCE_TYPE ? item.SOURCE_TYPE : 'Not Available'}
                                  </p>

                                </div>
                                <div class="col-md-5 clientSectionRight">
                                  <p>
                                    <i class="ri-mail-fill"></i> Email : {item.EMAIL}
                                  </p>
                                  <p>
                                    <i class="ri-phone-fill"></i> Mobile : {item.PHONE}
                                  </p>
                                  <p>
                                    <i class="ri-file-list-fill"></i>{" "}
                                    Notice Period : {item.NOTICE_PERIOD ? item.NOTICE_PERIOD : 'Not Available'}
                                  </p>
                                  <p>
                                    <i class="ri-line-chart-fill"></i>{" "}
                                    Current Status : {item.STATUS_NAME ? item.STATUS_NAME + (item.CLIENT_NAME ? ' at ' + item.CLIENT_NAME : '') : 'Not Available'}
                                  </p>
                                  <p>
                                    <i class="ri-shield-user-line"></i>{" "}
                                    Lead Owner : {item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available'}
                                  </p>
                                  <p>
                                    <i class="ri-shield-user-line"></i>{" "}
                                    Assigned HR : {item.ASSIGNEE_CALLER ? item.ASSIGNEE_CALLER : 'Not Available'}
                                  </p>
                                  <p>
                                    <i class="ri-user-add-fill"></i>{" "}
                                    Added By : {item.CREATED_BY_NAME ? item.CREATED_BY_NAME + ' at ' + moment(item.CREATED_ON).fromNow() : 'Not Available'}
                                  </p>

                                  {/* <p>
                                    <i class="ri-chat-new-line"></i> Create On : {moment(item.CREATED_ON).fromNow()}
                                  </p> */}
                                  <p>
                                    <i class="ri-time-fill"></i> Last Modified : {moment(item.MODIFIED_ON).format('DD-MM-YYYY')}

                                  </p>
                                </div>
                                <div class="col-md-1 clientMenuBtnSection">
                                  <a href="javascript:void(0)" onClick={() => {
                                    setShowEditModal(item)
                                  }}>
                                    <i class="ri-edit-line"></i>
                                  </a>
                                  <a
                                    onClick={() => {
                                      setCandidateDetails(item);
                                      setShowCandidateDetailDrawerModal(true);
                                    }}
                                  >
                                    <i class="ri-eye-fill"></i>
                                  </a>
                                  <a href={`tel:${item.PHONE}`}>
                                    <i class="ri-phone-fill"></i>
                                  </a>


                                  <a href={
                                    constant.companyUserComponent.candidateProfilePreviewAndApplyJob.url.replace(':id', item.CANDIDATE_ID).replace(':user_name', item.FULL_NAME)
                                  }>
                                    <i class="ri-home-gear-fill"></i>
                                  </a>
                                  <a href="javascript:void(0)" onClick={() => { setShowAddRemoinderModal(item) }}>
                                    <i class="mdi mdi-reminder" />
                                  </a>
                                </div>
                              </div>
                            </a>
                          )
                        })
                        }
                        {count === 0 && <div className="noresult" >
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: 75, height: 75 }}
                            />
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">
                              We've searched more than 150+ leads We did not find any
                              leads for you search.
                            </p>
                          </div>
                        </div>}
                        <h5>Today's Uploaded Count : {todayCount}</h5>
                        {count > 0 &&
                          <div className="customPaginationStyle">
                            < Pagination
                              limit={20}
                              totalCount={count}
                              range={5}
                              onPageChange={(page) => {
                                props.setCurrentPage(page)
                              }}
                              currentPage={currentPage}
                            />
                          </div>

                        }
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-body canditateSerachMainBody">
                        <div>
                            <div className="table-responsive table-card">
                                <table className="table align-middle" id="customerTable">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" style={{ width: 50 }}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="checkAll"
                                                        defaultValue="option"
                                                    />
                                                </div>
                                            </th>
                                            <th className="sort">Candidate ID</th>
                                            <th className="sort">Name</th>
                                            <th className="sort">Email</th>
                                            <th className="sort">Phone</th>
                                            <th className="sort">Lead Owner</th>
                                            <th className="sort">Lead Add Date</th>
                                            <th className="sort">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {list && list.map((item) => {
                                            return (
                                                <tr>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                            />
                                                        </div>
                                                    </th>
                                                    <td className="id">
                                                        <a
                                                            href="javascript:void(0);"
                                                            className="fw-medium link-primary"
                                                        >
                                                            RJZSHCL{item.CANDIDATE_ID}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1 name">{item.FULL_NAME}</div>
                                                        </div>
                                                    </td>
                                                    <td className="company_name">
                                                        {item.EMAIL}
                                                    </td>
                                                    <td className="phone">{item.PHONE}</td>
                                                    <td className="phone">{item.CREATED_BY_USER_NAME}</td>

                                                    <td className="date">{moment(item.CREATED_ON).format('DD MMM YYYY')}</td>
                                                    <td>
                                                        <ul className="list-inline hstack gap-2 mb-0 iconsTableSection">

                                                            <li
                                                                className="list-inline-item edit"
                                                                x data-bs-original-title="Call"
                                                            >
                                                                <a
                                                                    href={`tel:${item.PHONE}`}
                                                                    className="text-muted d-inline-block"
                                                                >
                                                                    <i className="ri-phone-line fs-16" />
                                                                </a>
                                                            </li>

                                                            <li
                                                                className="list-inline-item"
                                                                onClick={() => {
                                                                    setCandidateDetails(item)
                                                                    setShowCandidateDetailDrawerModal(true)
                                                                }}
                                                            >
                                                                <a href="javascript:void(0);">
                                                                    <i className="ri-eye-fill align-bottom text-muted" />
                                                                </a>
                                                            </li>

                                                            <li
                                                                className="list-inline-item"
                                                                onClick={() => {

                                                                }}
                                                            >
                                                                <a href="javascript:void(0);">
                                                                    <i className="ri-markdown-fill align-bottom text-muted" />
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                        }
                                    </tbody>
                                </table>
                                {count === 0 && <div className="noresult" >
                                    <div className="text-center">
                                        <lord-icon
                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                            trigger="loop"
                                            colors="primary:#121331,secondary:#08a88a"
                                            style={{ width: 75, height: 75 }}
                                        />
                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                        <p className="text-muted mb-0">
                                            We've searched more than 150+ leads We did not find any
                                            leads for you search.
                                        </p>
                                    </div>
                                </div>}
                            </div>
                            {count > 0 && < Pagination
                                limit={20}
                                totalCount={count}
                                range={5}
                                onPageChange={(page) => {
                                    props.setCurrentPage(page)
                                }}
                                currentPage={currentPage}
                            />}
                            <div className="d-flex justify-content-end mt-3">
                                <div className="pagination-wrap hstack gap-2">
                                    <a className="page-item pagination-prev disabled" href="#">
                                        Previous
                                    </a>
                                    <ul className="pagination listjs-pagination mb-0">
                                        <li className="active">
                                            <a className="page" href="#" data-i={1} data-page={8}>
                                                1
                                            </a>
                                        </li>
                                    </ul>
                                    <a className="page-item pagination-next" href="#">
                                        Next
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default List;
