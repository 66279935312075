import axios from 'axios'
import { getCompanyUserAuthHeader } from '../../utils';

export const v1_employment_type = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employment-type`;
    return axios.get(url).then((res) => {
        return res.data;
    })

}

export const v1_industry = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/industries`;
    return axios.get(url).then((res) => {
        return res.data;
    })

}


export const v1_functional_area = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/functional-area`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}


export const v1_job_role = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/job-role/${id}`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}



export const v1_qualification = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/qualification`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}



export const v1_education_qualification = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/education-qualification/${id}`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}



export const v1_course_specialization = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/course-specialization/${id}`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}


export const v1_country = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/country`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}

export const v1_state = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/state/${id}`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}
export const v1_city = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/city/${id}`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}

export const v1_application_status_options = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/application-status-options`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}

export const v1_candidate_status_options = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/candidate-status-options`;
    return axios.get(url).then((res) => {
        return res.data;
    })
}


export const v1_add_education_qualification_option = async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/education-qualification`;
    return axios.put(url, payload, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}

