

import React, { useEffect, useState } from 'react'
import { v1_candidate_status_options, v1_course_specialization, v1_education_qualification, v1_qualification } from '../../../actions/v1/master_data'
import { validateFunctionalForm } from '../../../utils'
import { v3_all_client_options, v3_client_options } from '../../../actions/v3/jobApplication'
import { v3_client_wise_active_jobs } from '../../../actions/v3/jobs'
import { v3_candidate_preferred_role_options, v3_lead_owners } from '../../../actions/v3/candidates'

const Add = (props) => {
    const [state, setState] = useState({
        FULL_NAME: { name: 'FULL_NAME', value: props?.data?.FULL_NAME ? props.data.FULL_NAME : '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: props?.data?.EMAIL ? props.data.EMAIL : '', error: '', isRequired: true },
        PHONE: { name: 'PHONE', value: props?.data?.PHONE ? props.data.PHONE : '', error: '', isRequired: true },
        WORKING_LOCATION: { name: 'WORKING_LOCATION', value: props?.data?.WORKING_LOCATION ? props.data.WORKING_LOCATION : '', error: '', isRequired: true },
        RESIDENTIAL_LOCATION: { name: 'RESIDENTIAL_LOCATION', value: props?.data?.RESIDENTIAL_LOCATION ? props.data.RESIDENTIAL_LOCATION : '', error: '', isRequired: true },
        CURRENT_COMPANY: { name: 'CURRENT_COMPANY', value: props?.data?.CURRENT_COMPANY ? props.data.CURRENT_COMPANY : '', error: '', isRequired: true },
        CURRENT_CTC: { name: 'CURRENT_CTC', value: props?.data?.CURRENT_CTC ? props.data.CURRENT_CTC : '', error: '', isRequired: true },
        EXPECTED_CTC: { name: 'EXPECTED_CTC', value: props?.data?.EXPECTED_CTC ? props.data.EXPECTED_CTC : '', error: '', isRequired: true },
        SKILLS: { name: 'SKILLS', value: props?.data?.SKILLS ? props.data.SKILLS : '', error: '', isRequired: true },
        TOTAL_EXP_YEAR: { name: 'TOTAL_EXP_YEAR', value: props?.data?.TOTAL_EXP_YEARS ? props.data.TOTAL_EXP_YEARS : '', error: '', isRequired: false },
        TOTAL_EXP_MONTH: { name: 'TOTAL_EXP_MONTH', value: props?.data?.TOTAL_EXP_MONTHS ? props.data.TOTAL_EXP_MONTHS : '', error: '', isRequired: false },
        HIGHEST_QUALIFICATION: { name: 'HIGHEST_QUALIFICATION', value: props?.data?.HIGHEST_QUALIFICATION_ID ? props.data.HIGHEST_QUALIFICATION_ID : '', options: [], error: '', isRequired: true },
        COURSE: { name: 'COURSE', value: props?.data?.HIGHEST_EDUCATION_QUALIFICATION_ID ? props.data.HIGHEST_EDUCATION_QUALIFICATION_ID : '', options: [], error: '', isRequired: true },
        SPECIALIZATION: { name: 'SPECIALIZATION', value: props?.data?.COURSE_SPECIALIZATION_ID ? props.data.COURSE_SPECIALIZATION_ID : '', options: [], error: '', isRequired: false },
        NOTICE_PERIOD: { name: 'NOTICE_PERIOD', value: props?.data?.NOTICE_PERIOD ? props.data.NOTICE_PERIOD : '', error: '', isRequired: false },
        SOURCE: { name: 'SOURCE', value: props?.data?.SOURCE ? props.data.SOURCE : '', error: '', isRequired: true },
        RATING: { name: 'RATING', value: props?.data?.RATING ? props.data.RATING : '', error: '', isRequired: false },
        RESUME: { name: 'RESUME', value: props?.data?.RESUME ? props.data.RESUME : '', error: '', isRequired: false },
        COLLEGE_NAME: { name: 'COLLEGE_NAME', value: props?.data?.COLLEGE_NAME ? props.data.COLLEGE_NAME : '', options: [], error: '', isRequired: false },
        PASS_OUT_YEAR: { name: 'PASS_OUT_YEAR', value: props?.data?.PASS_OUT_YEAR ? props.data.PASS_OUT_YEAR : '', options: [], error: '', isRequired: false },
        NOTE: { name: 'NOTE', value: props?.data?.NOTE ? props.data.NOTE : '', options: [], error: '', isRequired: false },
        CLIENT: { name: 'CLIENT', value: props?.data?.CLIENT_ID ? props.data.CLIENT_ID : '', options: [], error: '', isRequired: false },
        JOBS: { name: 'JOBS', value: props?.data?.JOB_ID ? props.data.JOB_ID : '', options: [], error: '', isRequired: false },
        STATUS: { name: 'STATUS', value: props?.data?.STATUS_ID ? props.data.STATUS_ID : '', options: [], error: '', isRequired: false },
        CURRENT_DESIGNATION: { name: 'CURRENT_DESIGNATION', value: props?.data?.CURRENT_DESIGNATION ? props.data.CURRENT_DESIGNATION : '', error: '', isRequired: false },
        LINED_UP_DATE: { name: 'LINED_UP_DATE', value: props?.data?.LINED_UP_DATE ? props.data.LINED_UP_DATE : '', error: '', isRequired: false },
        LINED_UP_TIME: { name: 'LINED_UP_TIME', value: props?.data?.LINED_UP_TIME ? props.data.LINED_UP_TIME : '', error: '', isRequired: false },
        LEAD_OWNER: { name: 'LEAD_OWNER', value: props?.data?.LEAD_OWNER ? props.data.LEAD_OWNER : '', options: [], error: '', isRequired: true },
        ROLE_ID: { name: 'ROLE_ID', value: props?.data?.PREFERRED_ROLE_ID ? props.data.PREFERRED_ROLE_ID : '', options: [], error: '', isRequired: false },

    })
    const ratingChanged = (newRating) => {
        setState(prevState => ({
            ...prevState,
            ['RATING']: {
                ...prevState['RATING'],
                value: newRating
            }
        }));
    };
    useEffect(() => {
        if (state.STATUS.value == 3 || state.STATUS.value == 4) {
            setState(prevState => ({
                ...prevState,
                ['CLIENT']: {
                    ...prevState['CLIENT'],
                    isRequired: true
                },
                ['JOBS']: {
                    ...prevState['JOBS'],
                    isRequired: true
                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['CLIENT']: {
                    ...prevState['CLIENT'],
                    isRequired: false,
                    value: ''
                },
                ['JOBS']: {
                    ...prevState['JOBS'],
                    isRequired: false,
                    value: ''

                }
            }));
        }

        if (state.STATUS.value == 5 || state.STATUS.value == 6 || state.STATUS.value == 7) {
            setState(prevState => ({
                ...prevState,
                ['LINED_UP_DATE']: {
                    ...prevState['LINED_UP_DATE'],
                    isRequired: true
                },
                ['LINED_UP_TIME']: {
                    ...prevState['LINED_UP_TIME'],
                    isRequired: true
                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['LINED_UP_DATE']: {
                    ...prevState['LINED_UP_DATE'],
                    isRequired: false,
                    value: ''

                },
                ['LINED_UP_TIME']: {
                    ...prevState['LINED_UP_TIME'],
                    isRequired: false,
                    value: ''

                }
            }));
        }
    }, [state.STATUS.value])
    useEffect(() => {
        v1_qualification().then(res => {
            if (res.status) {
                setOptions('HIGHEST_QUALIFICATION', res.result);
            }
        })

        v3_all_client_options().then((res) => {
            if (res.status) {
                setOptions('CLIENT', res.result)
            }
        })
        v1_candidate_status_options().then((res) => {
            if (res.status) {
                setOptions('STATUS', res.result)
            }
            else {

            }
        })
        v3_lead_owners().then(res => {
            if (res.status) {
                setOptions('LEAD_OWNER', res.result);
            }
        });
        v3_candidate_preferred_role_options().then((res) => {
            if (res.status) {
                setOptions('ROLE_ID', res.result)

            }
            else {

            }
        })
    }, [])

    useEffect(() => {
        if (state.CLIENT.value) {
            v3_client_wise_active_jobs(state.CLIENT.value).then((res) => {
                if (res.status) {
                    setOptions('JOBS', res.result)

                }
            })
        }
        else {
            setOptions('JOBS', [])

        }
    }, [state.CLIENT.value])

    useEffect(() => {
        if (state.HIGHEST_QUALIFICATION.value) {
            v1_education_qualification(state.HIGHEST_QUALIFICATION.value).then(res => {
                if (res.status) {
                    setOptions('COURSE', res.result);
                }
            })
        }
    }, [state.HIGHEST_QUALIFICATION.value])

    useEffect(() => {
        if (state.COURSE.value) {
            v1_course_specialization(state.COURSE.value).then(res => {
                if (res.status) {
                    setOptions('SPECIALIZATION', res.result);
                }
            })
        }
    }, [state.COURSE.value])

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'PHONE' && value.length > 10) {
            return false
        }
        if (name === state.RATING.name) {
            // Check if the value is greater than or equal to 0
            if (parseFloat(value) >= 0 || value == '') {
                setState(prevState => ({
                    ...prevState,
                    [name]: {
                        ...prevState[name],
                        value: value
                    }
                }));
            }
        }
        else {
            setState(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: value
                }
            }));
        }
        if (name === state.TOTAL_EXP_YEAR.name) {
            if (value !== 'Fresher') {
                setState(prevState => ({
                    ...prevState,
                    ['PASS_OUT_YEAR']: {
                        ...prevState['PASS_OUT_YEAR'],
                        value: '',
                        isRequired: false

                    },
                    ['COLLEGE_NAME']: {
                        ...prevState['COLLEGE_NAME'],
                        value: '',
                        isRequired: false

                    },
                    ['TOTAL_EXP_MONTH']: {
                        ...prevState['TOTAL_EXP_MONTH'],
                        isRequired: true
                    }

                }));
            }
            else {
                setState(prevState => ({
                    ...prevState,
                    ['PASS_OUT_YEAR']: {
                        ...prevState['PASS_OUT_YEAR'],
                        isRequired: true
                    },
                    ['COLLEGE_NAME']: {
                        ...prevState['COLLEGE_NAME'],
                        isRequired: true
                    },
                    ['TOTAL_EXP_MONTH']: {
                        ...prevState['TOTAL_EXP_MONTH'],
                        isRequired: false
                    }
                }));
            }

        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        debugger
        if (validateFunctionalForm(state, setState)) {
            const model = {
                FULL_NAME: state.FULL_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                WORKING_LOCATION: state.WORKING_LOCATION.value,
                RESIDENTIAL_LOCATION: state.RESIDENTIAL_LOCATION.value,
                CURRENT_COMPANY: state.CURRENT_COMPANY.value,
                CURRENT_CTC: state.CURRENT_CTC.value,
                EXPECTED_CTC: state.EXPECTED_CTC.value,
                SKILLS: state.SKILLS.value,
                TOTAL_EXP_YEAR: state.TOTAL_EXP_YEAR.value,
                TOTAL_EXP_MONTH: state.TOTAL_EXP_MONTH.value,
                HIGHEST_QUALIFICATION: state.HIGHEST_QUALIFICATION.value,
                COURSE: state.COURSE.value,
                SPECIALIZATION: state.SPECIALIZATION.value,
                NOTICE_PERIOD: state.NOTICE_PERIOD.value,
                RESUME: state.RESUME.value,
                SOURCE: state.SOURCE.value,
                RATING: state.RATING.value,
                COLLEGE_NAME: state.COLLEGE_NAME.value,
                PASS_OUT_YEAR: state.PASS_OUT_YEAR.value,
                NOTE: state.NOTE.value,
                JOB_ID: state.JOBS.value,
                STATUS_ID: state.STATUS.value,
                CURRENT_DESIGNATION: state.CURRENT_DESIGNATION.value,
                CLIENT_ID: state.CLIENT.value,
                LINED_UP_DATE: state.LINED_UP_DATE.value,
                LINED_UP_TIME: state.LINED_UP_TIME.value,
                CANDIDATE_ID: props?.data.CANDIDATE_ID,
                LEAD_OWNER: state.LEAD_OWNER.value,
                PREVIOUS_RECORD: JSON.stringify(props.data),
                ROLE_ID: state.ROLE_ID.value,


            }
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }

            props.onSubmit(formData)

        }

    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">

                    <form className="live-preview" onSubmit={(e) => { onSubmit(e) }}>
                        <div className="row gy-4">
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Full Name
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.FULL_NAME.error.length > 0 && !state.FULL_NAME.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Full Name"
                                        onChange={onChange}

                                        name={state.FULL_NAME.name}
                                        value={state.FULL_NAME.value}
                                    />
                                    <div className="invalid-feedback">Please enter a full name.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Email
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.EMAIL.error.length > 0 && !state.EMAIL.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Email"
                                        onChange={onChange}
                                        name={state.EMAIL.name}
                                        value={state.EMAIL.value}
                                    />
                                    <div className="invalid-feedback">Please enter email address.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Phone
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="number"
                                        className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Phone"
                                        onChange={onChange}
                                        name={state.PHONE.name}
                                        value={state.PHONE.value}
                                    />
                                    <div className="invalid-feedback">Please enter phone number.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Current Designation

                                    </label>
                                    <input
                                        type="text"
                                        className={state.CURRENT_DESIGNATION.error.length > 0 && !state.CURRENT_DESIGNATION.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Current Designation"
                                        onChange={onChange}
                                        name={state.CURRENT_DESIGNATION.name}
                                        value={state.CURRENT_DESIGNATION.value}
                                    />
                                    <div className="invalid-feedback">Please enter Work Location.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Work Location
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.WORKING_LOCATION.error.length > 0 && !state.WORKING_LOCATION.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Work Location"
                                        onChange={onChange}
                                        name={state.WORKING_LOCATION.name}
                                        value={state.WORKING_LOCATION.value}
                                    />
                                    <div className="invalid-feedback">Please enter Work Location.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Residential Location
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.RESIDENTIAL_LOCATION.error.length > 0 && !state.RESIDENTIAL_LOCATION.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        placeholder="Residential Location"
                                        name={state.RESIDENTIAL_LOCATION.name}
                                        value={state.RESIDENTIAL_LOCATION.value}
                                    />
                                    <div className="invalid-feedback">Please enter residential location.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Current Company
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.CURRENT_COMPANY.error.length > 0 && !state.CURRENT_COMPANY.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        placeholder="Current Company"
                                        name={state.CURRENT_COMPANY.name}
                                        value={state.CURRENT_COMPANY.value}
                                    />
                                    <div className="invalid-feedback">Please enter current company</div>
                                </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Current CTC
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.CURRENT_CTC.error.length > 0 && !state.CURRENT_CTC.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        placeholder="Current CTC"
                                        name={state.CURRENT_CTC.name}
                                        value={state.CURRENT_CTC.value}

                                    />
                                    <div className="invalid-feedback">Please enter current ctc</div>
                                </div>
                            </div>



                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Expected CTC
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.EXPECTED_CTC.error.length > 0 && !state.EXPECTED_CTC.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        placeholder="Expected CTC"
                                        name={state.EXPECTED_CTC.name}
                                        value={state.EXPECTED_CTC.value}
                                    />
                                    <div className="invalid-feedback">Please enter expected ctc</div>
                                </div>
                            </div> <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Skills
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.SKILLS.error.length > 0 && !state.SKILLS.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        placeholder="Skills"
                                        name={state.SKILLS.name}
                                        value={state.SKILLS.value}

                                    />
                                    <div className="invalid-feedback">Please enter skills</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="row">
                                    <div className={state.TOTAL_EXP_YEAR.value != 'Fresher' ? "col-lg-6" : "col-lg-12"}>

                                        <label className="form-label">
                                            Total Experience
                                            <span className='text-danger'>&nbsp;&nbsp;*</span>
                                        </label>
                                        <select
                                            type="text"
                                            className={state.TOTAL_EXP_YEAR.error.length > 0 && !state.TOTAL_EXP_YEAR.value ? "form-select is-invalid" : "form-select"}
                                            onChange={onChange}
                                            name={state.TOTAL_EXP_YEAR.name}
                                            value={state.TOTAL_EXP_YEAR.value}
                                        >

                                            <option value=''> Select Experience Year</option>
                                            <option value='Fresher'> Fresher</option>
                                            <option value='0 Year'> 0 Year</option>
                                            <option value='1 Year'> 1 Year</option>
                                            <option value='2 Year'> 2 Year</option>
                                            <option value='3 Year'> 3 Year</option>
                                            <option value='4 Year'> 4 Year</option>
                                            <option value='5 Year'> 5 Year</option>
                                            <option value='6 Year'> 6 Year</option>
                                            <option value='7 Year'> 7 Year</option>
                                            <option value='8 Year'> 8 Year</option>
                                            <option value='9 Year'> 9 Year</option>
                                            <option value='10 Year'> 10 Year</option>
                                            <option value='11 Year'> 11 Year</option>
                                            <option value='12 Year'> 12 Year</option>
                                            <option value='13 Year'> 13 Year</option>
                                            <option value='14 Year'> 14 Year</option>
                                            <option value='15 Year'> 15 Year</option>
                                            <option value='16 Year'> 16 Year</option>
                                            <option value='17 Year'> 17 Year</option>
                                            <option value='18 Year'> 18 Year</option>
                                            <option value='19 Year'> 19 Year</option>
                                            <option value='20 Year'> 20 Year</option>
                                            <option value='21 Year'> 21 Year</option>
                                            <option value='22 Year'> 22 Year</option>
                                            <option value='23 Year'> 23 Year</option>
                                            <option value='24 Year'> 24 Year</option>
                                            <option value='25 Year'> 25 Year</option>
                                            <option value='26 Year'> 26 Year</option>
                                            <option value='27 Year'> 27 Year</option>
                                            <option value='28 Year'> 28 Year</option>
                                            <option value='29 Year'> 29 Year</option>
                                            <option value='30 Year'> 30 Year</option>
                                            <option value='31 Year'> 31 Year</option>
                                            <option value='32 Year'> 32 Year</option>
                                        </select>
                                        <div className="invalid-feedback">Please select work experience</div>
                                    </div>
                                    {state.TOTAL_EXP_YEAR.value != 'Fresher' && <div className="col-lg-6">

                                        <label className="form-label">
                                            <span className='text-danger'>&nbsp;&nbsp;*</span>
                                        </label>
                                        <select
                                            type="text"
                                            className={state.TOTAL_EXP_MONTH.error.length > 0 && !state.TOTAL_EXP_MONTH.value ? "form-select is-invalid" : "form-select"}
                                            onChange={onChange}
                                            name={state.TOTAL_EXP_MONTH.name}
                                            value={state.TOTAL_EXP_MONTH.value}
                                        >

                                            <option value=''> Select Experience Month</option>
                                            <option value='0 Month'> 0 Month</option>
                                            <option value='1 Month'> 1 Month</option>
                                            <option value='2 Month'> 2 Month</option>
                                            <option value='3 Month'> 3 Month</option>
                                            <option value='4 Month'> 4 Month</option>
                                            <option value='5 Month'> 5 Month</option>
                                            <option value='6 Month'> 6 Month</option>
                                            <option value='7 Month'> 7 Month</option>
                                            <option value='8 Month'> 8 Month</option>
                                            <option value='9 Month'> 9 Month</option>
                                            <option value='10 Month'> 10 Month</option>
                                            <option value='11 Month'> 11 Month</option>
                                        </select>
                                        <div className="invalid-feedback">Please select work experience</div>
                                    </div>}
                                </div>

                            </div>

                            {state.TOTAL_EXP_YEAR.value === 'Fresher' &&
                                <React.Fragment>

                                    <div className="col-lg-6 mb-3">
                                        <div>
                                            <label htmlFor="companyname-field" className="form-label">
                                                College Name
                                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                                            </label>
                                            <input
                                                type="text"
                                                className={state.COLLEGE_NAME.error.length > 0 && !state.COLLEGE_NAME.value ? "form-control is-invalid" : "form-control"}
                                                onChange={onChange}
                                                placeholder="College Name"
                                                name={state.COLLEGE_NAME.name}
                                                value={state.COLLEGE_NAME.value}
                                            />
                                            <div className="invalid-feedback">Please enter college name</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div>
                                            <label htmlFor="companyname-field" className="form-label">
                                                Passing Year
                                                <span className='text-danger'>&nbsp;&nbsp;*</span>

                                            </label>
                                            <input
                                                type="month"
                                                className={state.PASS_OUT_YEAR.error.length > 0 && !state.PASS_OUT_YEAR.value ? "form-control is-invalid" : "form-control"}
                                                onChange={onChange}
                                                placeholder="Total Years of Experience"
                                                name={state.PASS_OUT_YEAR.name}
                                                value={state.PASS_OUT_YEAR.value}
                                            />

                                            <div className="invalid-feedback">Please enter work experience</div>
                                        </div>
                                    </div>
                                </React.Fragment>

                            }
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Highest Qualification
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <select
                                        className={state.HIGHEST_QUALIFICATION.error.length > 0 && !state.HIGHEST_QUALIFICATION.value ? "form-select is-invalid" : "form-select"}
                                        onChange={onChange}
                                        placeholder="Highest Qualification"
                                        name={state.HIGHEST_QUALIFICATION.name}
                                        value={state.HIGHEST_QUALIFICATION.value}
                                    >
                                        <option value=''> Select Highest Qualification</option>
                                        {state.HIGHEST_QUALIFICATION.options && state.HIGHEST_QUALIFICATION.options.map((item) => {
                                            return (
                                                <option value={item.QUALIFICATION_ID}> {item.QUALIFICATION_NAME}</option>

                                            )
                                        })}
                                    </select>
                                    <div className="invalid-feedback">Please select highest qualification</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Course
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <select
                                        className={state.COURSE.error.length > 0 && !state.COURSE.value ? "form-select is-invalid" : "form-select"}
                                        onChange={onChange}
                                        name={state.COURSE.name}
                                        value={state.COURSE.value}
                                    >
                                        <option value=''> Select Course</option>
                                        {
                                            state.COURSE.options && state.COURSE.options.map((item) => {
                                                return (<option
                                                    value={item.EDUCATION_QUALIFICATION_ID}

                                                >
                                                    {item.COURSE_STREAM}
                                                </option>)
                                            })

                                        }
                                    </select>
                                    <div className="invalid-feedback">Please select course</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Specialization

                                    </label>
                                    <select
                                        type="text"
                                        className={state.SPECIALIZATION.error.length > 0 && !state.SPECIALIZATION.value ? "form-select is-invalid" : "form-select"}
                                        onChange={onChange}
                                        placeholder="Specialization"
                                        name={state.SPECIALIZATION.name}
                                        value={state.SPECIALIZATION.value}
                                    >
                                        <option value=''> Select Specialization</option>
                                        {
                                            state.SPECIALIZATION.options && state.SPECIALIZATION.options.map((item) => {
                                                return (<option
                                                    value={item.SPECIALIZATION_ID}
                                                >
                                                    {item.SPECIALIZATION}
                                                </option>)
                                            })

                                        }
                                    </select>
                                    <div className="invalid-feedback">Please select specialization</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Notice Period
                                        {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                    </label>
                                    <select
                                        type="text"
                                        className={state.NOTICE_PERIOD.error.length > 0 && !state.NOTICE_PERIOD.value ? "form-select is-invalid" : "form-select"}
                                        onChange={onChange}
                                        name={state.NOTICE_PERIOD.name}
                                        value={state.NOTICE_PERIOD.value}
                                    >
                                        <option value=''> Select Notice Period</option>
                                        <option value='Immediate Joiner'> Immediate Joiner</option>
                                        <option value='15 Days'> 15 Days</option>
                                        <option value='1 Month'> 1 Month</option>
                                        <option value='2 Month'> 2 Month</option>
                                        <option value='3 Month'> 3 Month or more</option>

                                    </select>
                                    <div className="invalid-feedback">Please select notice period</div>
                                </div>
                                
                            </div>
                            <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Preferred Role
                                                        </label>
                                                        <select
                                                            className={state.ROLE_ID.error.length > 0 && !state.ROLE_ID.value ? "form-select is-invalid" : "form-select"}
                                                            onChange={onChange}
                                                            name={state.ROLE_ID.name}
                                                            value={state.ROLE_ID.value}
                                                        >
                                                            <option value=''> Select Preferred Role</option>
                                                            {state.ROLE_ID.options && state.ROLE_ID.options.map((item) => {
                                                                return (
                                                                    <option value={item.ROLE_ID}> {item.ROLE_NAME}</option>
                                                                )
                                                            })}

                                                        </select>
                                                        <div className="invalid-feedback">Please select notice period</div>
                                                    </div>
                                                </div>
                            <div className="col-lg-6 mb-3">
                                <div>
                                    <label className="form-label">
                                        Lead Source
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <select
                                        className={state.SOURCE.error.length > 0 && !state.SOURCE.value ? "form-select is-invalid" : "form-select"}
                                        onChange={onChange}
                                        name={state.SOURCE.name}
                                        value={state.SOURCE.value}
                                    >
                                        <option value=''> Select Lead Source</option>
                                        <option value='Found It / Monster'> Found It / Monster</option>
                                        <option value='Naukri'> Naukri</option>
                                        <option value='LinkedIn'> LinkedIn</option>
                                        <option value='Instagram'> Instagram</option>
                                        <option value='apna'> apna</option>
                                        <option value='Campus'> Campus</option>
                                        <option value='Other Portal'> Other Portal</option>
                                        <option value='Referral'> Referral</option>
                                        <option value='Social Media'> Social Media</option>
                                        <option value='Walk-In'> Walk-In</option>
                                    </select>
                                    <div className="invalid-feedback">Please select Lead Source</div>
                                </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                                <div>

                                    <label className="form-label">
                                        Status
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <select
                                        className={state.STATUS.error.length > 0 && !state.STATUS.value ? "form-select is-invalid" : "form-select"}
                                        onChange={onChange}
                                        name={state.STATUS.name}
                                        value={state.STATUS.value}
                                    >
                                        <option value={''}> Choose Status</option>
                                        {state.STATUS.options && state.STATUS.options.map((item) => {
                                            return (
                                                <option value={item.STATUS_ID}>{item.STATUS_NAME}</option>
                                            )
                                        })
                                        }
                                    </select>
                                    <div className="invalid-feedback">Please select status.</div>
                                </div>
                            </div>
                            {(state.STATUS.value == '3' || state.STATUS.value == '4') &&
                                <React.Fragment>
                                    <div className="col-lg-6 mb-3">
                                        <div>
                                            <label className="form-label">
                                                Shortlisted for
                                            </label>
                                            <select
                                                className={state.CLIENT.error.length > 0 && !state.CLIENT.value ? "form-select is-invalid" : "form-select"}
                                                onChange={onChange}
                                                name={state.CLIENT.name}
                                                value={state.CLIENT.value}
                                            >
                                                <option value=''> Select Company</option>
                                                {state.CLIENT.options && state.CLIENT.options.map((item) => {
                                                    debugger
                                                    return (
                                                        <option value={item.CLIENT_ID}> {item.COMPANY_NAME}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                            <div className="invalid-feedback">Please select Company</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div>

                                            <label className="form-label">
                                                Process

                                            </label>
                                            <select
                                                className={state.JOBS.error.length > 0 && !state.JOBS.value ? "form-select is-invalid" : "form-select"}
                                                onChange={onChange}
                                                name={state.JOBS.name}
                                                value={state.JOBS.value}
                                            >
                                                <option value={''}> Choose Job</option>
                                                {state.JOBS.options ? state.JOBS.options.map((item) => {
                                                    return (

                                                        <option value={item.JOB_ID}>{item.JOB_TITLE}</option>
                                                    )

                                                }) :

                                                    <option value=''>No Active Job Found</option>

                                                }

                                            </select>
                                            <div className="invalid-feedback">Please select process.</div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {(state.STATUS.value == '5' || state.STATUS.value == '6' || state.STATUS.value == '7') &&
                                <React.Fragment>
                                    <div className="col-lg-6 mb-3">
                                        <div>
                                            <label className="form-label">
                                                Lined up Date
                                            </label>
                                            <input
                                                type='date'
                                                className={state.LINED_UP_DATE.error.length > 0 && !state.LINED_UP_DATE.value ? "form-select is-invalid" : "form-select"}
                                                onChange={onChange}
                                                name={state.LINED_UP_DATE.name}
                                                value={state.LINED_UP_DATE.value}
                                            />

                                            <div className="invalid-feedback">Please select date</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div>

                                            <label className="form-label">
                                                Lined up Time

                                            </label>
                                            <input
                                                type='time'
                                                className={state.LINED_UP_TIME.error.length > 0 && !state.LINED_UP_TIME.value ? "form-select is-invalid" : "form-select"}
                                                onChange={onChange}
                                                name={state.LINED_UP_TIME.name}
                                                value={state.LINED_UP_TIME.value}
                                            />

                                            <div className="invalid-feedback">Please select time</div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }

                            <div className="col-lg-6 mb-3">
                                <div >
                                    <label className="form-label">
                                        Candidate Quality
                                        {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                    </label>
                                    <input
                                        type='text'
                                        min={0}
                                        className={state.RATING.error.length > 0 && !state.RATING.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        name={state.RATING.name}
                                        value={state.RATING.value}
                                        placeholder='Enter Quality Number'
                                    />

                                    {state.RATING.error && !state.RATING.value && <span className="text-danger">Please select Lead Source</span>}
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div >
                                    <label className="form-label">
                                        Lead Owner
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <select
                                        className={state.LEAD_OWNER.error && !state.LEAD_OWNER.value ? 'form-select is-invalid' : 'form-select'}
                                        onChange={onChange}
                                        value={state.LEAD_OWNER.value}
                                        name={state.LEAD_OWNER.name}
                                    >
                                        <option value=''>
                                            Select Lead Owner
                                        </option>
                                        {state.LEAD_OWNER.options && state.LEAD_OWNER.options.map((item) => {
                                            return (
                                                <option key={item.USER_ID} value={item.USER_ID}>
                                                    {item.FULL_NAME}
                                                </option>
                                            )
                                        })}
                                    </select>

                                    {state.LEAD_OWNER.error && !state.LEAD_OWNER.value && <span className="text-danger">Please select Lead Source</span>}
                                </div>
                            </div>
                            <div className="col-lg-12  mb-3">
                                <div>
                                    <label className="form-label">
                                        Resume
                                        {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                    </label>
                                    <input
                                        type="file"
                                        className={state.RESUME.error.length > 0 && !state.RESUME.value ? "form-control is-invalid" : "form-control"}
                                        onChange={(e) => {
                                            setState(prevState => ({
                                                ...prevState,
                                                ['RESUME']: {
                                                    ...prevState['RESUME'],
                                                    value: e.target.files[0]
                                                }
                                            }));
                                        }}
                                    />
                                    <div className="invalid-feedback">Please upload resume.</div>
                                </div>
                            </div>
                            <div className="col-lg-12  mb-3">
                                <div>
                                    <label className="form-label">
                                        Note
                                        {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                    </label>
                                    <textarea
                                        placeholder='Enter Note / Remarks'
                                        className={state.NOTE.error.length > 0 && !state.NOTE.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        name={state.NOTE.name}
                                        value={state.NOTE.value}
                                        rows={3}
                                    />
                                    <div className="invalid-feedback">Please upload resume.</div>
                                </div>
                            </div>
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={() => { props.onClose() }}>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-success" id="add-btn">
                                    Save
                                </button>
                                {/* <button type="button" class="btn btn-success" id="edit-btn">Update</button> */}
                            </div>

                        </div>
                    </form>
                </div>
            </div>



        </React.Fragment >
    )
}

export default Add