import React, { useEffect, useState } from 'react'
import List from '../../../../components/companyUser/administrator/CRMUser/list_CRMUser'
import { v3_user_list, v3_user_status_update } from '../../../../actions/v3/administrator'
const Listuser = () => {

    const [list, setList] = useState(null)
    const [count, setCount] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [searchUser, setSearchUser] = useState('')


    const onChangeStatus = (model) => {
        v3_user_status_update(model).then((res) => {
            if (res.status) {
                setList(list.map((item) => {
                    if (item.USER_ID === model.USER_ID) {
                        item.STATUS = model.STATUS
                    }
                    return item
                }))
            }
        })
    }
    useEffect(() => {
        v3_user_list(currentPage, searchUser).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.TOTAL)

            }
        })
    }, [currentPage, refresh, searchUser])

   

    return (
        <React.Fragment>
            <List
                list={list}
                count={count}
                refresh={refresh}
                setRefresh={setRefresh}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                onChangeStatus={onChangeStatus}
                setSearchUser={setSearchUser}
                searchUser={searchUser}

            />
        </React.Fragment>
    )
}

export default Listuser