
import axios from 'axios'
import { getSuperAdminAuthHeader } from '../../utils';


export const v1_crm_available_packages = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-available-packages`;
    return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v1_crm_invoice_generation_details = async (company_id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-invoice_generation_details/${company_id}`;
    return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v1_crm_invoice_generate = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-invoice_generate`;
    return axios.post(url, model, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v1_crm_invoice = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-invoice/${model.company_id}/${model.subscription_id}`;
    return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v1_send_crm_invoice = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/send-crm-client-invoice`;
    return axios.post(url, model, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}




