import React, { useEffect, useState } from 'react'
import MapperForm from '../../../components/CompanyAdmin/userManagement/teamMapping'
import { v2_user_team_mapping_list, v2_user_team_mapping_update } from '../../../actions/v2/user_management'
import { ToastContainer, toast } from 'react-toastify'
import constant from '../../../constant'


const TeamMapping = (props) => {
    const [allUsers, setAllUsers] = useState(null)
    const [mappedUsers, setMappedUsers] = useState(null)

    useEffect(() => {
        v2_user_team_mapping_list(props.match.params.user_id).then(res => {
            if (res.status) {
                setAllUsers(res.result.all_users)
                setMappedUsers(res.result.mapped_users)

            }
        })

    }, [])

    const onSubmit = (users) => {
        v2_user_team_mapping_update({ SELECTED_USER_ID: users }, props.match.params.user_id).then((res) => {
            if (res.status) {
                toast.success('Team Assigned Successfully')
                setTimeout(() => {
                    props.history.push(constant.comapnyAdminComponent.viewUsers.url)
                }, 3000)
            } else {
                toast.error(res.error)
            }
        })
    }
    return (
        <React.Fragment>
            {allUsers && mappedUsers && <MapperForm
                allUsers={allUsers}
                mappedUsers={mappedUsers}
                onSubmit={onSubmit}
            />}
            <ToastContainer />
        </React.Fragment>
    )
}

export default TeamMapping