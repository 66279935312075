import React, { useEffect, useState } from 'react'
import { v2_user_roles } from '../../../../actions/v2/user_management'
import { validateFunctionalForm } from '../../../../utils'
import { v3_user_roles } from '../../../../actions/v3/administrator'

const AddUser = (props) => {

    const [state, setState] = useState({
        FULL_NAME: { name: 'FULL_NAME', value: '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: true },
        PHONE: { name: 'PHONE', value: '', error: '', isRequired: true },
        USER_NAME: { name: 'USER_NAME', value: '', error: '', isRequired: true },
        ROLE: { name: 'ROLE', value: '', options: [], error: '', isRequired: true },
        // HAVING_TEAM_MEMBERS: { name: 'HAVING_TEAM_MEMBERS', value: '0', error: '', isRequired: true },
        // IS_ADMIN: { name: 'IS_ADMIN', value: '0', error: '', isRequired: true },


    })
    const clearForm = () => {
        setState({
            FULL_NAME: { name: 'FULL_NAME', value: '', error: '', isRequired: true },
            EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: true },
            PHONE: { name: 'PHONE', value: '', error: '', isRequired: true },
            USER_NAME: { name: 'USER_NAME', value: '', error: '', isRequired: true },
            ROLE: { name: 'ROLE', value: '', options: [], error: '', isRequired: true },
            // HAVING_TEAM_MEMBERS: { name: 'HAVING_TEAM_MEMBERS', value: '0', error: '', isRequired: true },
            // IS_ADMIN: { name: 'IS_ADMIN', value: '0', error: '', isRequired: true },

        });
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === state.PHONE.name) {
            const numericPhoneNumber = value.replace(/\D/g, '');
            setState(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: numericPhoneNumber
                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: value
                }
            }));
        }

    };

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    useEffect(() => {
        v3_user_roles().then(res => {
            if (res.status) {
                setOptions('ROLE', res.result)
            }
            else {
                console.error(res.error)
            }
        })
    }, [])
    const onSubmit = () => {

        if (validateFunctionalForm(state, setState)) {
            const matchingRole = state.ROLE.options.find(item => item.ROLE_ID == state.ROLE.value);

            let HAVING_TEAM_MEMBERS = 0;

            if (matchingRole) {
                HAVING_TEAM_MEMBERS = matchingRole['HAVING_TEAM_MEMBERS'];
            } else {
                console.error('No matching role found for the given ROLE_ID.');
            }

            const model = {
                FULL_NAME: state.FULL_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                ROLE_ID: state.ROLE.value,
                USER_NAME: state.USER_NAME.value,
                HAVING_TEAM_MEMBERS: HAVING_TEAM_MEMBERS,

                // HAVING_TEAM_MEMBERS: state.HAVING_TEAM_MEMBERS.value,
                // IS_ADMIN: state.IS_ADMIN.value,


            }
            props.onSubmit(model)
        }
    }
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Add User</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">User Management</a>
                                            </li>
                                            <li className="breadcrumb-item active">Add User</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">CRM USER</h4>

                                    </div>
                                    <div className="card-body">
                                        <div className="live-preview">
                                            <div className="row gy-4">
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Full Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.FULL_NAME.error.length > 0 && !state.FULL_NAME.value ? "form-control is-invalid" : "form-control "}
                                                            placeholder='Full Name'
                                                            name={state.FULL_NAME.name}
                                                            value={state.FULL_NAME.value}
                                                            onChange={onChange}

                                                        />
                                                        <div className="invalid-feedback">Please enter full name</div>

                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div >
                                                        <label className="form-label">
                                                            Email
                                                        </label>
                                                        <div className='form-icon'>
                                                            <input
                                                                type="email"
                                                                placeholder="Email"
                                                                className={state.EMAIL.error.length > 0 && !state.EMAIL.value ? "form-control form-control-icon is-invalid" : "form-control form-control-icon "}
                                                                name={state.EMAIL.name}
                                                                value={state.EMAIL.value}
                                                                onChange={onChange}
                                                            />
                                                            <i className='ri-mail-unread-line' />
                                                            <div className="invalid-feedback">Please enter email</div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Phone
                                                        </label>
                                                        <div className='form-icon'>
                                                            <input
                                                                type="tel"
                                                                className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control form-control-icon is-invalid" : "form-control form-control-icon "}
                                                                placeholder="Phone"
                                                                name={state.PHONE.name}
                                                                value={state.PHONE.value}
                                                                onChange={onChange}
                                                            />
                                                            <i className='ri-phone-line' />
                                                            <div className="invalid-feedback">Please enter email</div>


                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Username
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control is-invalid" : "form-control "}
                                                            placeholder="Username"
                                                            name={state.USER_NAME.name}
                                                            value={state.USER_NAME.value}
                                                            onChange={onChange}
                                                        />
                                                        <div className="invalid-feedback">Please enter username</div>

                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Role
                                                        </label>
                                                        <select
                                                            className={state.ROLE.error.length > 0 && !state.ROLE.value ? "form-select is-invalid" : "form-select "}
                                                            name={state.ROLE.name}
                                                            value={state.ROLE.value}
                                                            onChange={onChange}
                                                        >
                                                            <option value=''>Select Role</option>
                                                            {state.ROLE.options.map((item) => {
                                                                return (<option value={item.ROLE_ID}>{item.ROLE_NAME}</option>)
                                                            })}
                                                        </select>
                                                        <div className="invalid-feedback">Please select role</div>

                                                    </div>
                                                </div>

                                                {/* <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Having Team Members ?
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            name={state.HAVING_TEAM_MEMBERS.name}
                                                            value={state.HAVING_TEAM_MEMBERS.value}
                                                            onChange={onChange}
                                                        >
                                                            <option value='1'>Yes</option>
                                                            <option value='0'>No</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-3 col-md-6">
                                                    <div>
                                                        <label className="form-label">
                                                            Is Admin ?
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            name={state.IS_ADMIN.name}
                                                            value={state.IS_ADMIN.value}
                                                            onChange={onChange}
                                                        >
                                                            <option value='1'>Yes</option>
                                                            <option value='0'>No</option>
                                                        </select>
                                                    </div>
                                                </div> */}

                                                <div className="col-xxl-12 col-md-12">
                                                    <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">Add New</button>
                                                    <button type="button" onClick={() => { clearForm() }} class="btn btn-danger waves-effect waves-light mx-3">Clear Form</button>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}

export default AddUser