import axios from 'axios'
import { getCompanyUserAuthHeader } from '../../utils';

export const v3_dashboard_count_stats = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/dashboard/count-stats`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_dashboard_clients = async (PAGE) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/dashboard/clients/${PAGE}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_dashboard_job_status_wise_candidates = async (STATUS_ID, PAGE, QUERY) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/dashboard/job-status-wise/candidates/${STATUS_ID}/${PAGE}/${QUERY}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v3_dashboard_billing_cycle_candidates = async (PAGE) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/dashboard/billing-cycle/candidates/${PAGE}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}




export const v3_my_today_candidate_reminder = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/today-candidate-reminder`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_dashboard_application_statistics = async (DATE_RANGE) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/dashboard/application-statistics/${DATE_RANGE}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
