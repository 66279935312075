import React, { useEffect, useState } from 'react';
import { getStorage, validateFunctionalForm } from '../../../utils';
import { v3_call_directory_assignee } from '../../../actions/v3/candidates';
import constant from '../../../constant';

const Add = (props) => {
    const [state, setState] = useState({
        CALLING_ASSIGNEE_ID: { name: 'CALLING_ASSIGNEE_ID', value: props?.data?.CALLING_ASSIGNEE_ID ? props.data.CALLING_ASSIGNEE_ID : '', error: '', isRequired: true },
    });

    useEffect(() => {
        v3_call_directory_assignee(props.data.CANDIDATE_ID).then(res => {
            if (res.status) {
                setOptions('CALLING_ASSIGNEE_ID', res.result);
            }
        });
    }, []);

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const onSubmit = (e) => {
        if (validateFunctionalForm(state, setState)) {
            const { FULL_NAME } = getStorage(constant.keys.companyUserDetails)

            const model = {
                CALLING_ASSIGNEE_ID: state.CALLING_ASSIGNEE_ID.value,
                CANDIDATE_ID: props?.data?.CANDIDATE_ID ,
                FULL_NAME: props?.data?.FULL_NAME,
                RECRUITER_NAME: FULL_NAME,
            };
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }
            props.onSubmit(formData);
        }
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 mb-3">
                    <div>
                        <label className="form-label">
                            Assignee
                        </label>
                        <select
                            className={state.CALLING_ASSIGNEE_ID.error && !state.CALLING_ASSIGNEE_ID.value ? 'form-select is-invalid' : 'form-select'}
                            onChange={onChange}
                            value={state.CALLING_ASSIGNEE_ID.value}
                            name={state.CALLING_ASSIGNEE_ID.name}
                        >
                            <option value=''>
                                Select Assignee
                            </option>
                            {state.CALLING_ASSIGNEE_ID.options && state.CALLING_ASSIGNEE_ID.options.map((item) => {
                                return (
                                    <option key={item.USER_ID} value={item.USER_ID}>
                                        {item.FULL_NAME}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-sm-auto ms-auto">
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-success" onClick={() => { onSubmit() }}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Add;
