import React, { useState } from 'react'
import { validateFunctionalForm } from '../../../utils';

const DesignationForm = (props) => {

    const [state, setState] = useState({
        NAME: { name: 'NAME', value: props.data?.ROLE_NAME ? props.data.ROLE_NAME : '', error: '', isRequired: true },
        STATUS: { name: 'STATUS', value: props.data?.STATUS ? Boolean(props.data.STATUS) : true, error: '', isRequired: true },
        IS_ADMIN: { name: 'IS_ADMIN', value: props.data?.IS_ADMIN ? props.data.IS_ADMIN : false, error: '', isRequired: true },
        HAVING_TEAM_MEMBERS: { name: 'HAVING_TEAM_MEMBERS', value: props.data?.HAVING_TEAM_MEMBERS ? props.data.HAVING_TEAM_MEMBERS : false, error: '', isRequired: true },

    })
    const clearForm = () => {
        setState({
            NAME: { name: 'NAME', value: props.data?.ROLE_NAME ? props.data.ROLE_NAME : '', error: '', isRequired: true },
            STATUS: { name: 'STATUS', value: props.data?.STATUS ? Boolean(props.data.STATUS) : true, error: '', isRequired: true },
            IS_ADMIN: { name: 'IS_ADMIN', value: props.data?.IS_ADMIN ? props.data.IS_ADMIN : false, error: '', isRequired: true },
            HAVING_TEAM_MEMBERS: { name: 'HAVING_TEAM_MEMBERS', value: props.data?.HAVING_TEAM_MEMBERS ? props.data.HAVING_TEAM_MEMBERS : false, error: '', isRequired: true },
        });
    }


    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const onSubmit = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                ROLE_NAME: state.NAME.value,
                STATUS: state.STATUS.value,
                IS_ADMIN: state.IS_ADMIN.value,
                HAVING_TEAM_MEMBERS: state.HAVING_TEAM_MEMBERS.value,

            }
            props.onSubmit(model)
        }
    }
    return (
        <React.Fragment>

            <div className="row">
                <div className="col-lg-12">
                    <div className="live-preview">
                        <div className="row gy-4">
                            <div className="col-xxl-4 col-md-4">
                                <div>
                                    <label className="form-label">
                                        Role Name
                                    </label>
                                    <input
                                        type="text"
                                        className={state.NAME.error.length > 0 && !state.NAME.value ? "form-control is-invalid" : "form-control "}
                                        placeholder='Role Name'
                                        name={state.NAME.name}
                                        value={state.NAME.value}
                                        onChange={onChange}

                                    />
                                    <div className="invalid-feedback">Please enter role</div>

                                </div>
                            </div>
                            <div className="col-xxl-8 col-md-8">
                                <div className="row">
                                    <div className="col-xxl-3 col-md-3">

                                        <div>

                                            <label className="form-label">
                                                Team Options
                                            </label>
                                            <div class="form-check form-switch form-switch-lg">
                                                <input class="form-check-input code-switcher" type="checkbox"
                                                    checked={state.HAVING_TEAM_MEMBERS.value}
                                                    onClick={() => {
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            ['HAVING_TEAM_MEMBERS']: {
                                                                ...prevState['HAVING_TEAM_MEMBERS'],
                                                                value: !state.HAVING_TEAM_MEMBERS.value
                                                            }
                                                        }));
                                                    }}
                                                    id="icon-button" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-md-3">

                                        <div>

                                            <label className="form-label">
                                                Admin Options
                                            </label>
                                            <div class="form-check form-switch form-switch-lg">
                                                <input class="form-check-input code-switcher" type="checkbox"
                                                    checked={state.IS_ADMIN.value}
                                                    onClick={() => {
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            ['IS_ADMIN']: {
                                                                ...prevState['IS_ADMIN'],
                                                                value: !state.IS_ADMIN.value
                                                            }
                                                        }));
                                                    }}
                                                    id="icon-button" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-2 col-md-2">
                                        <div>
                                            <label className="form-label">
                                                Status
                                            </label>
                                            <div class="form-check form-switch form-switch-lg">
                                                <input class="form-check-input code-switcher" type="checkbox"
                                                    checked={state.STATUS.value}
                                                    onClick={() => {
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            ['STATUS']: {
                                                                ...prevState['STATUS'],
                                                                value: !state.STATUS.value
                                                            }
                                                        }));
                                                    }}
                                                    id="icon-button" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div className="col-xxl-12 col-md-12">
                                <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">{props.buttonName}</button>
                                <button type="button" onClick={() => { clearForm() }} class="btn btn-danger waves-effect waves-light mx-3">Clear Form</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default DesignationForm