import React, { useEffect, useState } from 'react'
import { v1_dashboard_count_stats } from '../../../actions/v1/dashboard'

const CountStats = () => {
    const [stats, setStats] = useState(null)
    useEffect(() => {
        v1_dashboard_count_stats().then((res) => {
            if (res.status) {
                setStats(res.result)
            }
        })
    }, [])
    return (
        <React.Fragment>
  <div className="d-flex flex-column h-100">

<div className="row">
  <div className="col-md-6">
    <div className="card card-animate">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div>
            <p className="fw-medium text-muted mb-0">Total Clients</p>
            <h2 className="mt-4 ff-secondary fw-semibold">
              <span className="counter-value" data-target="28.05">
                {stats?.total_clients}
              </span>
            </h2>
            <p className="mb-0 text-muted">
              <span className="badge bg-light text-success mb-0">
                <i className="ri-arrow-up-line align-middle" /> 16.24 %
              </span>
              vs. previous month
            </p>
          </div>
          <div>
            <div className="avatar-sm flex-shrink-0">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-md-6">
    <div className="card card-animate">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div>
            <p className="fw-medium text-muted mb-0">Acttive Clients</p>
            <h2 className="mt-4 ff-secondary fw-semibold">
              <span className="counter-value" data-target="97.66">
              {stats?.active_clients}
                
              </span>
            </h2>
            <p className="mb-0 text-muted">
              <span className="badge bg-light text-danger mb-0">
                <i className="ri-arrow-down-line align-middle" /> 3.96 %
              </span>
              vs. previous month
            </p>
          </div>
          <div>
            <div className="avatar-sm flex-shrink-0">
              <span className="avatar-title bg-info-subtle rounded-circle fs-2">

              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-md-6">
    <div className="card card-animate">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div>
            <p className="fw-medium text-muted mb-0">
             Inactive Clients
            </p>
            <h2 className="mt-4 ff-secondary fw-semibold">
              <span className="counter-value" data-target={3}>
              {stats?.inactive_clients}

              </span>
             
            </h2>
            <p className="mb-0 text-muted">
              <span className="badge bg-light text-danger mb-0">
                <i className="ri-arrow-down-line align-middle" /> 0.24 %
              </span>
              vs. previous month
            </p>
          </div>
          <div>
            <div className="avatar-sm flex-shrink-0">
              <span className="avatar-title bg-info-subtle rounded-circle fs-2">

              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-md-6">
    <div className="card card-animate">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div>
            <p className="fw-medium text-muted mb-0">Total Demo Enquiry</p>
            <h2 className="mt-4 ff-secondary fw-semibold">
              <span className="counter-value" data-target="33.48">
              
              {stats?.total_demo_enquiry_lead}

              </span>
            </h2>
            <p className="mb-0 text-muted">
              <span className="badge bg-light text-success mb-0">
                <i className="ri-arrow-up-line align-middle" /> 7.05 %
              </span>
              vs. previous month
            </p>
          </div>
          <div>
            <div className="avatar-sm flex-shrink-0">
              <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-external-link text-info"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                  <polyline points="15 3 21 3 21 9" />
                  <line x1={10} y1={14} x2={21} y2={3} />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
        </React.Fragment>
    )
}

export default CountStats