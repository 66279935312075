import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import constant from '../../../constant';


// Styled component for profile details
const ProfileDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

// Styled component for detail label
const DetailLabel = styled.span`
  font-weight: bold;
  min-width: 120px;
`;

// Styled component for detail value
const DetailValue = styled.span`
  margin-left: 10px;
`;


const ClientProfileComponent = ({ clientDetails }) => {
    console.log(clientDetails.LOGO ? `${process.env.REACT_APP_BASE_URL}/v1/crm-client-logo/${clientDetails.LOGO}` : "/assets/images/companies/img-2.png")
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body profileBodyStyleSection">
                        <div className="live-preview">
                            <div className="row gy-4">
                                <div className="profileTopImg">
                                    <ProfileDetail>
                                        <DetailValue>{
                                            <img style={{ border: '2px solid #e7e7e7' }}
                                                src={clientDetails.LOGO ? `${process.env.REACT_APP_BASE_URL}/v1/crm-client-logo/${clientDetails.LOGO}` : "/assets/images/companies/img-2.png"}
                                                alt=""
                                                className="avatar-lg rounded-circle"
                                            />
                                        }</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Company Name :</DetailLabel>
                                        <DetailValue>{clientDetails.COMPANY_NAME}</DetailValue>
                                    </ProfileDetail>
                                </div>

                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Admin Name :</DetailLabel>
                                        <DetailValue>{clientDetails.ADMIN_NAME}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Admin Designation :</DetailLabel>
                                        <DetailValue>{clientDetails.ADMIN_DESIGNATION}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Email :</DetailLabel>
                                        <DetailValue>{clientDetails.EMAIL}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Phone :</DetailLabel>
                                        <DetailValue>{clientDetails.MOBILE}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>GST No :</DetailLabel>
                                        <DetailValue>{clientDetails.GST_NUMBER ? clientDetails.GST_NUMBERS : 'NA'}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Status
                                            :</DetailLabel>
                                        <DetailValue>{clientDetails.STATUS === 'A' ? 'Active' : 'Inactive'}</DetailValue>
                                    </ProfileDetail>
                                </div>

                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Client Address :</DetailLabel>
                                        <DetailValue>{clientDetails.ADDRESS}, {clientDetails.CITY}, {clientDetails.STATE}, {clientDetails.COUNTRY}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Company Code :</DetailLabel>
                                        <DetailValue>{clientDetails.COMPANY_CODE}</DetailValue>
                                    </ProfileDetail>
                                </div>
                                <div className="col-lg-4 mb-0 profileInnerStyleBox">
                                    <ProfileDetail>
                                        <DetailLabel>Plan Details :</DetailLabel>
                                        <DetailValue>{clientDetails.PLAN_DETAILS ? clientDetails.PLAN_DETAILS.PACKAGE_NAME + ` (${clientDetails.PLAN_DETAILS.PAYMENT_STATUS === 'S' ? 'Paid' : 'Unpaid'})` : 'Not Available'} {clientDetails.PLAN_DETAILS ? < a href={constant.superAdminComponent.clientSubscriptionInvoice.url.replace(':company_id', clientDetails.PLAN_DETAILS.COMPANY_ID).replace(':subscription_id', clientDetails.PLAN_DETAILS.SUBSCRIPTION_ID)}> View Invoice</a> : ''}</DetailValue>
                                    </ProfileDetail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >



    );
};

export default ClientProfileComponent;
