import React, { useState } from 'react'
import constant from '../../../constant'
import { getStorage, removeAllLocalStorage } from '../../../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import NotificationBadge from './notificationBadge';


const Header = () => {
    const [ud, setUd] = useState(getStorage(constant.keys.companyUserDetails))
    const havingTeam = getStorage(constant.keys.companyUserHavingTeam);
    const isAdministrator = getStorage(constant.keys.companyUserHavingAdminRights);


    const getFullScreenElement = () => {
        return document.fullscreenElement
            || document.webkitFullscreenElement
            || document.mozFullScreenElement
            || document.msFullscreenElement;
    };
    
    const toggleFullScreen = () => {
        if (getFullScreenElement()) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            document.documentElement.requestFullscreen().catch((e) => {
                console.error(`Error attempting to enable full-screen mode: ${e.message} (${e.name})`);
            });
        }
    };
    
    return (
        <header id="page-topbar">
            <div className="layout-width">
                <div className="navbar-header">
                    <div className="d-flex">
                        <button
                            type="button"
                            className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                            id="topnav-hamburger-icon"
                        >
                            <span className="hamburger-icon">
                                <span />
                                <span />
                                <span />
                            </span>
                        </button>

                        <div>
                            {(havingTeam || isAdministrator) &&
                                <div className="app-search d-none d-md-block">
                                    <div>
                                        <Link className="reportHeaderButton form-control"
                                            to={constant.companyUserComponent.JobApplicationReport.url}>
                                            <i class="ri-file-list-fill"></i> Report
                                        </Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="dropdown d-md-none topbar-head-dropdown header-item">
                            <button
                                type="button"
                                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                id="page-header-search-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i className="bx bx-search fs-22" />
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown"
                            >
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search ..."
                                                aria-label="Recipient's username"
                                            />
                                            <button className="btn btn-primary" type="submit">
                                                <i className="mdi mdi-magnify" />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* <div className="dropdown topbar-head-dropdown ms-1 header-item">
                            <button
                                type="button"
                                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i className="bx bx-category-alt fs-22" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
                                <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h6 className="m-0 fw-semibold fs-15"> Web Apps </h6>
                                        </div>
                                        <div className="col-auto">
                                            <a href="javascript:void(0);" className="btn btn-sm btn-soft-info">
                                                {" "}
                                                View All Apps
                                                <i className="ri-arrow-right-s-line align-middle" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="row g-0">
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="javascript:void(0);">
                                                <img src="assets/images/brands/github.png" alt="Github" />
                                                <span>GitHub</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="javascript:void(0);">
                                                <img
                                                    src="assets/images/brands/bitbucket.png"
                                                    alt="bitbucket"
                                                />
                                                <span>Bitbucket</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="javascript:void(0);">
                                                <img
                                                    src="assets/images/brands/dribbble.png"
                                                    alt="dribbble"
                                                />
                                                <span>Dribbble</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row g-0">
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="javascript:void(0);">
                                                <img src="assets/images/brands/dropbox.png" alt="dropbox" />
                                                <span>Dropbox</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="javascript:void(0);">
                                                <img
                                                    src="assets/images/brands/mail_chimp.png"
                                                    alt="mail_chimp"
                                                />
                                                <span>Mail Chimp</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <a className="dropdown-icon-item" href="javascript:void(0);">
                                                <img src="assets/images/brands/slack.png" alt="slack" />
                                                <span>Slack</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="ms-1 header-item d-none d-sm-flex">
                            <button
                                type="button"
                                onClick={() => toggleFullScreen()}
                                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                data-toggle="fullscreen"
                            >
                                <i className="bx bx-fullscreen fs-22" />
                            </button>
                        </div>
                        <NotificationBadge />

                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <button
                                type="button"
                                className="btn"
                                id="page-header-user-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span className="d-flex align-items-center">
                                    <img
                                        className="rounded-circle header-profile-user"
                                        src="/assets/images/users/avatar-3.jpg"
                                        alt="Header Avatar"
                                    />
                                    <span className="text-start ms-xl-2" style={{ marginTop: '-4px' }}>
                                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                            {ud.FULL_NAME}
                                        </span>
                                        <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">
                                            {ud.USER_ROLE}
                                        </span>
                                    </span>
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end customDropdownHeader">
                                {/* item*/}
                                <h6 className="dropdown-header">Welcome {ud.FULL_NAME} !</h6>
                                <a className="dropdown-item" href={constant.companyUserComponent.profilePreview.url}>
                                    <i className="mdi mdi-account-circle fs-16 align-middle me-1" />{" "}
                                    <span className="align-middle">Profile</span>
                                </a>

                                <div className="dropdown-divider" />
                                <a className="dropdown-item" href={'javascript:void(0)'}
                                    onClick={() => {
                                        removeAllLocalStorage();
                                        window.location.href = constant.nonAuthedcomponent.login.url.replace(':user_type', 'user');
                                    }}>
                                    <i className="mdi mdi-logout fs-16 align-middle me-1" />{" "}
                                    <span className="align-middle" >
                                        Logout
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header >


    )
}

export default Header