
import axios from 'axios'
import { getCompanyUserAuthHeader } from '../../utils';

export const v3_add_candidate = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/add-candidate`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_qucik_add_candidate = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/quick-add-candidate`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_walk_in_qr_send_candidate_otp = async (model, AuthHeader = getCompanyUserAuthHeader()) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/walk-in-qr-send-candidate-otp`;
    return axios.post(url, model, AuthHeader).then((res) => {
        return res.data;
    })
}
export const v3_walk_in_qr_add_candidate = async (model, AuthHeader = getCompanyUserAuthHeader()) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/walk-in-qr-add-candidate`;
    return axios.post(url, model, AuthHeader).then((res) => {
        return res.data;
    })
}

export const v3_walk_in_qr_update_candidate = async (model, AuthHeader = getCompanyUserAuthHeader()) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/walk-in-qr-update-candidate`;
    return axios.put(url, model, AuthHeader).then((res) => {
        return res.data;
    })
}

export const v3_edit_candidate = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/edit-candidate`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_edit_candidate_lead_owner = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/edit-candidate-lead-owner`;
    return axios.patch(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v3_edit_candidate_ticket_status = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/edit-candidate-ticket-status`;
    return axios.patch(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_add_to_call_directory = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/call-directory`;
    return axios.patch(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_call_directory_assignee = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/call-directory-assignee?CANDIDATE_ID=${id}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_search_candidates = async (query) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/search-candidate?query=${query}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_candidates_calling_directory = async (query) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidate-calling-directory?query=${query}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}



export const v3_qr_walk_in_candidates = async (query) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/qr-walk-in-candidate?query=${query}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_search_job_status_wise_candidates = async (QUERY, PAGE) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidates/job-status-wise/candidates/${PAGE}/${QUERY}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_candidate_details = async (id, name, AuthHeader = getCompanyUserAuthHeader()) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidate/${id}-${name}`;
    return axios.get(url, AuthHeader).then((res) => {
        return res.data;
    })

}

export const v3_add_candidate_message_template = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidate-message-template`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v3_edit_candidate_message_template = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidate-message-template`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_candidate_message_template_list = async (page) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidate-message-template/${page}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_available_message_templates = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/available-message-template`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v3_lead_owners = async (AuthHeader = getCompanyUserAuthHeader()) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/lead-owners`;
    return axios.get(url, AuthHeader).then((res) => {
        return res.data;
    })

}
export const v3_role_wise_lead_owners = async (AuthHeader = getCompanyUserAuthHeader()) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/role-wise-lead-owners`;
    return axios.get(url, AuthHeader).then((res) => {
        return res.data;
    })

}


export const v3_add_candidate_reminder = async (body) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidate-reminder`;
    return axios.post(url, body, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_candidate_preferred_role_options = async (AuthHeader = getCompanyUserAuthHeader()) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidates-preferred-role-options`;
    return axios.get(url, AuthHeader).then((res) => {
        return res.data;
    })

}


export const v3_candidate_bulk_upload_duplicate_check = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidates-bulk-upload-duplicate-check`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_candidate_bulk_upload = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/candidates-bulk-upload`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}




export const v3_inactive_candidate_reminder = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/inactive-candidate-reminder`;
    return axios.patch(url, { id }, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


