import React, { useEffect, useState } from 'react'
import CountStats from '../../../components/CompanyAdmin/dashboard/count_stats'
import ApplicationStatistics from '../../../components/CompanyAdmin/dashboard/applicationStatistics'
import Clients from '../../../components/CompanyAdmin/dashboard/clients'
import { v2_dashboard_clients, v2_dashboard_count_stats } from '../../../actions/v2/dashboard';
// import Reminder from '../../../../components/companyUser/administrator/dashboard/Reminder';

const Index = () => {

  const [countStats, setCountStats] = useState(null);
  const [clientList, setClientList] = useState(null);
  const [clientCount, setClientCount] = useState(null);
  const [clientListCurrentPage, setClientListCurrentPage] = useState(1);


  useEffect(() => {
    v2_dashboard_count_stats().then((res) => {
      if (res.status) {
        setCountStats(res.result)
      }
    })

  }, [])


  useEffect(() => {

    v2_dashboard_clients(clientListCurrentPage).then((res) => {
      if (res.status) {
        setClientList(res.result.list)
        setClientCount(res.result.count)
      }
    })

  }, [clientListCurrentPage])

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">


            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Statistics</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Administrator</a>
                      </li>
                      <li className="breadcrumb-item active">Statistics</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <CountStats
                  countStats={countStats}
                />
              </div>
              {/*end col*/}
              <div className="col-xl-12">
                <Clients
                  clientList={clientList}
                  clientCount={clientCount}
                  setClientListCurrentPage={setClientListCurrentPage}
                  clientListCurrentPage={clientListCurrentPage}
                />
                {/* .card*/}
              </div>
              {/*end col*/}
            </div>
            <div className="row">
              <div className="col-xxl-8">
                <ApplicationStatistics/>
                {/* end card */}
              </div>
              {/* end col */}
              <div className="col-xxl-4">
                {/* <Reminder/> */}
                {/* end card */}
                <div className="card overflow-hidden shadow-none">
                  <div className="card-body bg-danger-subtle">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <div className="avatar-sm">
                          <div className="avatar-title bg-danger-subtle text-danger rounded-circle fs-17">
                            <i className="ri-gift-line" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-16">Invite your friends to Recruit JobzShala</h6>
                        <p className="text-muted mb-0">
                          Nor again is there anyone who loves or pursues or desires to
                          obtain pain of itself, because it is pain, but because
                          occasionally.
                        </p>
                      </div>
                    </div>
                    <div className="mt-3 text-end">
                      <a href="javascript:void(0)" className="btn btn-danger">
                        Invite Friends
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>

          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default Index