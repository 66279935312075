import React, { useEffect, useState } from 'react'
import { v2_user_roles } from '../../../actions/v2/user_management';
import { validateFunctionalForm } from '../../../utils';

const EditUserModel = (props) => {

    const [state, setState] = useState({
        FULL_NAME: { name: 'FULL_NAME', value: props.data ? props.data.FULL_NAME : '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: props.data ? props.data.EMAIL : '', error: '', isRequired: true },
        PHONE: { name: 'PHONE', value: props.data ? props.data.PHONE : '', error: '', isRequired: true },
        ROLE: { name: 'ROLE', value: props.data ? props.data.ROLE_ID : '', options: [], error: '', isRequired: true },
        // HAVING_TEAM_MEMBERS: { name: 'HAVING_TEAM_MEMBERS', value: props.data ? props.data.HAVING_TEAM_MEMBERS : '0', error: '', isRequired: true },
        // IS_ADMIN: { name: 'IS_ADMIN', value: props.data ? props.data.IS_ADMIN : '0', error: '', isRequired: true },

    })

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    useEffect(() => {
        v2_user_roles().then(res => {
            if (res.status) {
                setOptions('ROLE', res.result)
            }
            else {
                console.error(res.error)
            }
        })
    }, [])
    const onSubmit = () => {
        const matchingRole = state.ROLE.options.find(item => item.ROLE_ID == state.ROLE.value);

        let HAVING_TEAM_MEMBERS = 0;

        if (matchingRole) {
            HAVING_TEAM_MEMBERS = matchingRole['HAVING_TEAM_MEMBERS'];
        } else {
            console.error('No matching role found for the given ROLE_ID.');
        }
        if (validateFunctionalForm(state, setState)) {
            const model = {
                FULL_NAME: state.FULL_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                ROLE_ID: state.ROLE.value,
                HAVING_TEAM_MEMBERS: HAVING_TEAM_MEMBERS,
                
                // HAVING_TEAM_MEMBERS: state.HAVING_TEAM_MEMBERS.value,
                // IS_ADMIN: state.IS_ADMIN.value,
                USER_ID: props.data.USER_ID,

            }
            props.onSubmit(model)
        }
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">

                    <div className="live-preview">
                        <div className="row gy-4">
                            <div className="col-xxl-6 col-md-6">
                                <div>
                                    <label className="form-label">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        className={state.FULL_NAME.error.length > 0 && !state.FULL_NAME.value ? "form-control is-invalid" : "form-control "}
                                        placeholder='Full Name'
                                        name={state.FULL_NAME.name}
                                        value={state.FULL_NAME.value}
                                        onChange={onChange}

                                    />
                                    <div className="invalid-feedback">Please enter full name</div>

                                </div>
                            </div>
                            <div className="col-xxl-6 col-md-6">
                                <div >
                                    <label className="form-label">
                                        Email
                                    </label>
                                    <div className='form-icon'>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            className={state.EMAIL.error.length > 0 && !state.EMAIL.value ? "form-control form-control-icon is-invalid" : "form-control form-control-icon "}
                                            name={state.EMAIL.name}
                                            value={state.EMAIL.value}
                                            onChange={onChange}
                                        />
                                        <i className='ri-mail-unread-line' />
                                        <div className="invalid-feedback">Please enter email</div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-md-6">
                                <div>
                                    <label className="form-label">
                                        Phone
                                    </label>
                                    <div className='form-icon'>
                                        <input
                                            type="phone"
                                            className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control form-control-icon is-invalid" : "form-control form-control-icon "}
                                            placeholder="Phone"
                                            name={state.PHONE.name}
                                            value={state.PHONE.value}
                                            onChange={onChange}
                                        />
                                        <i className='ri-phone-line' />
                                        <div className="invalid-feedback">Please enter email</div>


                                    </div>
                                </div>
                            </div>


                            <div className="col-xxl-6 col-md-6">
                                <div>
                                    <label className="form-label">
                                        Role
                                    </label>
                                    <select
                                        className={state.ROLE.error.length > 0 && !state.ROLE.value ? "form-select is-invalid" : "form-select "}
                                        name={state.ROLE.name}
                                        value={state.ROLE.value}
                                        onChange={onChange}
                                    >
                                        <option value=''>Select Role</option>
                                        {state.ROLE.options.map((item) => {
                                            return (<option value={item.ROLE_ID}>{item.ROLE_NAME}</option>)
                                        })}
                                    </select>
                                    <div className="invalid-feedback">Please select role</div>

                                </div>
                            </div>

                            {/* <div className="col-xxl-6 col-md-6">
                                <div>
                                    <label className="form-label">
                                        Having Team Members ?
                                    </label>
                                    <select
                                        className="form-select"
                                        name={state.HAVING_TEAM_MEMBERS.name}
                                        value={state.HAVING_TEAM_MEMBERS.value}
                                        onChange={onChange}
                                    >
                                        <option value='1'>Yes</option>
                                        <option value='0'>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-md-6">
                                <div>
                                    <label className="form-label">
                                        Is Admin ?
                                    </label>
                                    <select
                                        className="form-select"
                                        name={state.IS_ADMIN.name}
                                        value={state.IS_ADMIN.value}
                                        onChange={onChange}
                                    >
                                        <option value='1'>Yes</option>
                                        <option value='0'>No</option>
                                    </select>
                                </div>
                            </div> */}


                            <div className="col-xxl-12 col-md-12">
                                <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">Save</button>
                                <button type="button" onClick={() => { props.onClose() }} class="btn btn-danger waves-effect waves-light mx-3">Cancel</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditUserModel