import React, { useEffect, useState } from 'react'
import { v3_user_roles } from '../../../../actions/v3/administrator';

const MasterDetails = (props) => {
  const { data } = props;
  const [state, setState] = useState({
    FULL_NAME: { name: 'FULL_NAME', value: data?.FULL_NAME ? data.FULL_NAME : '', error: '', isRequired: true },
    EMAIL: { name: 'EMAIL', value: data?.EMAIL ? data.EMAIL : '', error: '', isRequired: true },
    PHONE: { name: 'PHONE', value: data?.PHONE ? data.PHONE : '', error: '', isRequired: true },
    ROLE_ID: { name: 'ROLE_ID', value: data?.ROLE_ID ? data.ROLE_ID : '', options: [], error: '', isRequired: true },
    USER_NAME: { name: 'USER_NAME', value: data?.USER_NAME ? data.USER_NAME : '', error: '', isRequired: true },
  })

  const onChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value
      }
    }));
  }

  const setOptions = (name, options) => {
    setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        options: options
      }
    }));
  }

  useEffect(() => {
    v3_user_roles().then(res => {
      if (res.status) {
        setOptions('ROLE_ID', res.result)
      }
      else {
        console.error(res.error)
      }
    })
  }, [])

  const onSubmit = () => {
    const model = {
      FULL_NAME: state.FULL_NAME.value,
      EMAIL: state.EMAIL.value,
      PHONE: state.PHONE.value,
      ROLE_ID: state.ROLE_ID.value,

    }
    props.onSubmit(model)
  }
  return (
    <React.Fragment>
      <div className="tab-content pt-4 text-muted">
        <div
          className="tab-pane active"
          id="overview-tab"
          role="tabpanel"
        >
          <div className="row">
            <div className="col-xxl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className={state.FULL_NAME.error && !state.FULL_NAME.value ? "form-control is-invalid" : "form-control"}
                          name={state.FULL_NAME.name}
                          value={state.FULL_NAME.value}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="lastnameInput" className="form-label">
                          User Name
                        </label>
                        <input
                          type="text"
                          className={state.USER_NAME.error && !state.USER_NAME.value ? "form-control is-invalid" : "form-control"}
                          name={state.USER_NAME.name}
                          value={state.USER_NAME.value}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="lastnameInput" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className={state.EMAIL.error && !state.EMAIL.value ? "form-control is-invalid" : "form-control"}
                          name={state.EMAIL.name}
                          value={state.EMAIL.value}
                        />
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="phonenumberInput" className="form-label">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className={state.PHONE.error && !state.PHONE.value ? "form-control is-invalid" : "form-control"}
                          name={state.PHONE.name}
                          value={state.PHONE.value}

                        />
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Role
                        </label>
                        <select
                          className={state.ROLE_ID.error && !state.ROLE_ID.value ? "form-select is-invalid" : "form-select"}
                          name={state.ROLE_ID.name}
                          value={state.ROLE_ID.value}
                          onChange={onChange}
                        >
                          <option value=''> Select Role</option>
                          {state.ROLE_ID.options?.map((item) => {
                            return (<option value={item.ROLE_ID}> {item.ROLE_NAME}</option>)
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-md-12">
                      <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light">Save</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default MasterDetails