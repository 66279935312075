import React, { useState } from 'react'
import WalkinOnbaordingStepOne from '../../components/nonAuthed/walkinOnbaording/step_one'

import WalkinOnbaordingStepTwo from '../../components/nonAuthed/walkinOnbaording/step_two'
import WalkinOnbaordingCompleted from '../../components/nonAuthed/walkinOnbaording/step_three'

import { ToastContainer, toast } from 'react-toastify'
import { v3_candidate_details, v3_walk_in_qr_add_candidate, v3_walk_in_qr_send_candidate_otp, v3_walk_in_qr_update_candidate } from '../../actions/v3/candidates'
import Modal from '../../components/common/htmlModal/index'
import OtpInput from '../../components/common/otpInput'
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '../../utils'
import constant from '../../constant'
import Loader from '../../components/common/Loader/circleLoader'
const WalkinOnbaording = (props) => {
    const [decode_url, set_decode_url] = useState(JSON.parse(atob(props.match.params.encoded_url)))
    const [candidateDetails, setCandidateDetails] = useState(null)
    const [showOtpModal, setShowOtpModal] = useState(null);
    const [session, setSession] = useState(getSessionStorage(constant.keys.walkInRegistration))
    const [completed_session, setCompletedSession] = useState(getSessionStorage(constant.keys.walkInRegistrationCompleted))
    const [showLoader, setShowLoader] = useState(false)
    const onSubmitStepOne = (otp) => {
        let model = showOtpModal
        model.append('otp', otp)
        setShowLoader(true)
        v3_walk_in_qr_add_candidate(model, {
            headers: { Authorization: "Bearer " + decode_url.token },
            "Content-Type": "multipart/form-data",
        }).then((res) => {
            setShowLoader(false)
            if (res.status) {
                toast.success(res.result.message)
                setShowOtpModal(null);
                setSessionStorage(constant.keys.walkInRegistration, { id: res.result.id, ...candidateDetails })
                setSession({ id: res.result.id, ...candidateDetails, CANDIDATE_ID: res.result.id })
                v3_candidate_details(res.result.id, candidateDetails.FULL_NAME, {
                    headers: { Authorization: "Bearer " + decode_url.token },
                    "Content-Type": "multipart/form-data",
                }).then(res => {
                    if (res.status) {
                        setCandidateDetails(res.result)
                    }
                })
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const onSubmitStepTwo = (model) => {
        setShowLoader(true)

        v3_walk_in_qr_update_candidate(model, {
            headers: { Authorization: "Bearer " + decode_url.token },
            "Content-Type": "multipart/form-data",
        }).then((res) => {
            setShowLoader(false)

            if (res.status) {
                toast.success(res.result.message)
                removeSessionStorage(constant.keys.walkInRegistration)
                setSessionStorage(constant.keys.walkInRegistrationCompleted, true)
                setCompletedSession(true)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const onSendOtp = (model) => {
        model.append('recruiter_mobile', decode_url.mobile)
        model.append('recruiter_email', decode_url.email ? decode_url.email : 'hr@mindclick.co.in')

        setShowLoader(true)

        v3_walk_in_qr_send_candidate_otp(model, {
            headers: { Authorization: "Bearer " + decode_url.token },
            "Content-Type": "multipart/form-data",
        }).then((res) => {
            setShowLoader(false)
            if (res.status) {
                toast.success('Otp Sent Successfully. Please Ask to Desk Recruiter for OTP')
                setShowOtpModal(model)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    useState(() => {
        if (session) {
            v3_candidate_details(session.id, session.FULL_NAME, {
                headers: { Authorization: "Bearer " + decode_url.token },
                "Content-Type": "multipart/form-data",
            }).then(res => {
                if (res.status) {
                    setCandidateDetails(res.result)
                }
            })
        }
    }, [session])

    return (
        <React.Fragment>
            {showLoader && <Loader />}
            <ToastContainer />
            <Modal
                isOpen={showOtpModal}
                header={'Registration OTP'}
                onClose={() => { setShowOtpModal(false) }}
            >
                <OtpInput
                    onSubmit={onSubmitStepOne}
                />
            </Modal>
            {!session && !completed_session && <WalkinOnbaordingStepOne
                onSendOtp={onSendOtp}
                token={decode_url.token}
                mobile={decode_url.mobile}
                setCandidateDetails={setCandidateDetails}



            />}
            {session && candidateDetails && !completed_session &&
                <WalkinOnbaordingStepTwo
                    candidateDetails={candidateDetails}
                    token={decode_url.token}
                    onSubmit={onSubmitStepTwo}
                />}
            {completed_session &&
                <WalkinOnbaordingCompleted
                />}
        </React.Fragment >
    )
}

export default WalkinOnbaording