import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Add from '../../../components/companyUser/candidates/bulkAdd'

const BulkAdd = () => {
    return (
        <React.Fragment>
            <ToastContainer />
            <Add />
        </React.Fragment>
    )
}

export default BulkAdd