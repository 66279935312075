import React from 'react'
import ProflePreview from './ProfilePreview'
import { ToastContainer } from 'react-toastify'
const ClientDetail = ({ clientDetail }) => {
    return (
        <React.Fragment>

            <ToastContainer />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Client Profile</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Client</a>
                                            </li>
                                            <li className="breadcrumb-item active">Client Profile</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {clientDetail && <ProflePreview
                            clientDetails={clientDetail}
                        />}

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default ClientDetail