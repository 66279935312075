import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    height: 100%;
    margin: 0;
    padding: 0;
    widht:100%;
`;

const Title = styled.h1`
    text-align: center;
    color: green;
`;

const SubTitle = styled.h3`
    text-align: center;
`;

const PDFObject = styled.object`
    width: 100%; /* Adjust width as needed */
    height: 1000px; /* Adjust height as needed */
`;

const PDFEmbed = ({ file, title, subTitle }) => {
    return (
        <Container>
            <Title>{title}</Title>
            <SubTitle>{subTitle}</SubTitle>
            <PDFObject
                data={file}
                width="100%"
                height="600px"
            />
        </Container>
    );
}

export default PDFEmbed;
