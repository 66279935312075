import React, { useEffect, useState } from 'react'
import MultiSelect from '../../common/Multiselect'

const TeamMapping = (props) => {
    const { mappedUsers, allUsers } = props;
    const [selectedUsers, setSelectedUsers] = useState(mappedUsers?.map((item)=>{
        return item.USER_ID
    }));


    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Add User</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">User Management</a>
                                            </li>
                                            <li className="breadcrumb-item active">Add User</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">CRM USER</h4>

                                    </div>
                                    <div className="card-body">
                                        <div className="live-preview">
                                            <div className="row gy-4">
                                                <div className="col-xxl-5 col-md-6">
                                                    <div>
                                                        <h5 className="fs-13 mb-1">Assign Team Members</h5>
                                                        <p className="text-muted">Move Selected Team Members from left box to right box </p>
                                                        {allUsers && mappedUsers && <MultiSelect
                                                            allUsers={allUsers}
                                                            selectedUserIds={selectedUsers}
                                                            setSelectedUserIds={setSelectedUsers}
                                                        />}
                                                    </div>

                                                </div>


                                                <div className="col-xxl-12 col-md-12">
                                                    <button type="button" onClick={() => { props.onSubmit(selectedUsers) }} class="btn btn-primary waves-effect waves-light">Save</button>
                                                    <button type="button" class="btn btn-danger waves-effect waves-light mx-3">Skip</button>

                                                </div>

                                            </div>
                                            {/*end row*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*end col*/}
                        </div>
                    </div>{" "}
                </div>

            </div>
        </React.Fragment>
    )
}

export default TeamMapping