
import axios from 'axios'
import { getCompanyUserAuthHeader } from '../../utils';

export const v3_add_step_two_job = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-step-two`;
    return axios.post(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_update_step_three_job = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-step-three`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v3_post_job = async (job_id) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-post`;
    return axios.patch(url, { JOB_ID: job_id }, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_update_step_two_job = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-step-two`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v3_update_step_one_job = async (model) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-step-one`;
    return axios.put(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v3_edit_form_details = async (job_id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/job/${job_id}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}



export const v3_non_posted_jobs = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/jobs/non-posted`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}



export const v3_live_jobs = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/jobs/live?keyword=${model.keyword}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_expired_jobs = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/jobs/expired`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_client_wise_active_jobs = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/client/active-jobs/${id}`;
    return axios.get(url, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_update_job_visibility = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/job-visibility`;
    return axios.patch(url, model, getCompanyUserAuthHeader()).then((res) => {
        return res.data;
    })
}

