import axios from 'axios'
import { getSuperAdminAuthHeader } from '../../utils';

export const v1_create_offer_letter = async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/offer-letter`;
    return axios.post(url, payload, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v1_offer_letters = async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/offer-letter/${btoa(JSON.stringify(payload))}`;
    return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}
