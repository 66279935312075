import React, { useEffect, useState } from 'react'

const MasterDetails = (props) => {
    const { data } = props;
    const [state, setState] = useState({
        AADHAAR_NUMBER: { name: 'AADHAAR_NUMBER', value: data?.AADHAAR_NUMBER ? data.AADHAAR_NUMBER : '', error: '', isRequired: true },
        PAN_NUMBER: { name: 'PAN_NUMBER', value: data?.PAN_NUMBER ? data.PAN_NUMBER : '', error: '', isRequired: false },
    })

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }


  


    const onSubmit = () => {
        const model = {
            AADHAAR_NUMBER: state.AADHAAR_NUMBER.value,
            PAN_NUMBER: state.PAN_NUMBER.value,
        }
        props.onSubmit(model)
    }
    return (
        <React.Fragment>
            <div className="tab-content pt-4 text-muted">
                <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                >
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="card p-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Aadhaar Number
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.AADHAAR_NUMBER.error && !state.AADHAAR_NUMBER.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.AADHAAR_NUMBER.name}
                                                    value={state.AADHAAR_NUMBER.value}
                                                    onChange={onChange}
                                                    placeholder='Aadhaar Number'

                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                <label htmlFor="lastnameInput" className="form-label">
                                                    Pan Number
                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.PAN_NUMBER.error && !state.PAN_NUMBER.value ? "form-control is-invalid" : "form-control"}
                                                    name={state.PAN_NUMBER.name}
                                                    value={state.PAN_NUMBER.value}
                                                    onChange={onChange}
                                                    placeholder='Pan Number'

                                                />
                                            </div>
                                        </div>
                                        <div className="col-xxl-12 col-md-12">
                                            <button type="button" onClick={() => { onSubmit() }} class="btn btn-primary waves-effect waves-light profileSubmitSaveButton">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default MasterDetails