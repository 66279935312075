import React, { useEffect, useState } from 'react'
import Invoice from '../../../components/SuperAdmin/manage_subscription/invoicePreview'
import { v1_crm_invoice } from '../../../actions/v1/subscription_management'
import { ToastContainer } from 'react-toastify'
const PriviewInvoice = (props) => {

    const [invoiceDetails, setInvoiceDetails] = useState(null)

    useEffect(() => {
        v1_crm_invoice(props.match.params).then((res) => {
            if (res.status) {
                setInvoiceDetails(res.result)
            }
        })
    }, [])

    return (
        <React.Fragment>
            <ToastContainer/>
            {invoiceDetails && <Invoice
                invoiceDetails={invoiceDetails}
            />}
        </React.Fragment>
    )
}

export default PriviewInvoice