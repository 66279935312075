import React, { useState } from 'react'
import { v1_post_demo_request } from '../../actions/v1/crm_client'
import { ToastContainer, toast } from 'react-toastify'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { validateFunctionalForm } from '../../utils'
import constant from '../../constant'
const RequestDemoForm = (props) => {
    const [state, setState] = useState({
        NAME: { name: 'NAME', value: '', error: '', isRequired: true },
        BUSINESS_EMAIL: { name: 'BUSINESS_EMAIL', value: '', error: '', isRequired: true },
        COMPANY_NAME: { name: 'COMPANY_NAME', value: '', error: '', isRequired: true },
        RECRUITERS_SIZE: { name: 'RECRUITERS_SIZE', value: '', error: '', isRequired: true },
        PHONE: { name: 'PHONE', value: '', error: '', isRequired: true },
        LOCATION: { name: 'LOCATION', value: '', error: '', isRequired: true },
        REQUIREMENT_DETAILS: { name: 'REQUIREMENT_DETAILS', value: '', error: '', isRequired: false },

    })

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === state.PHONE.name) {
            const numericPhoneNumber = value.replace(/\D/g, '');
            setState(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: numericPhoneNumber
                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: value
                }
            }));
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (validateFunctionalForm(state, setState)) {
            const model = {
                NAME: state.NAME.value,
                BUSINESS_EMAIL: state.BUSINESS_EMAIL.value,
                COMPANY_NAME: state.COMPANY_NAME.value,
                RECRUITERS_SIZE: state.RECRUITERS_SIZE.value,
                PHONE: state.PHONE.value,
                LOCATION: state.LOCATION.value,
                REQUIREMENT_DETAILS: state.REQUIREMENT_DETAILS.value
            }
            v1_post_demo_request(model).then((res) => {
                if (res.status) {
                    props.history.push(constant.nonAuthedcomponent.requestDemoStatus.url.replace(":id", res.result))
                }
                else {
                    toast(res.error)
                }
            })
        }

    }
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="card demoFormCustomCard">
                <div className="card-header">
                    <h1 className='text-center'>Schedule Your <span style={{ color: '#fdcc01' }}>Free Demo</span></h1>
                </div>
                <div className="card-body">
                    <div className="tab-content">
                        <form onSubmit={(e) => { onSubmit(e) }} >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="firstnameInput" className="form-label">
                                            Name
                                            <span className='text-danger'> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={state.NAME.error && !state.NAME.value ? "form-control is-invalid" : "form-control"}
                                            id="firstnameInput"
                                            placeholder="Full Name"
                                            name={state.NAME.name}
                                            value={state.NAME.value}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                {/*end col*/}
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="lastnameInput" className="form-label">
                                            Business Email
                                        </label>
                                        <input
                                            type="email"
                                            className={state.BUSINESS_EMAIL.error && !state.BUSINESS_EMAIL.value ? "form-control is-invalid" : "form-control"}

                                            id="lastnameInput"
                                            placeholder="Business Email address"
                                            name={state.BUSINESS_EMAIL.name}
                                            value={state.BUSINESS_EMAIL.value}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                {/*end col*/}
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="phonenumberInput" className="form-label">
                                            Company
                                        </label>
                                        <input
                                            type="text"
                                            className={state.COMPANY_NAME.error && !state.COMPANY_NAME.value ? "form-control is-invalid" : "form-control"}

                                            id="phonenumberInput"
                                            placeholder="Company Name"
                                            name={state.COMPANY_NAME.name}
                                            value={state.COMPANY_NAME.value}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                {/*end col*/}
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="emailInput" className="form-label">
                                            How many recruiters work in your organization? *

                                        </label>
                                        <select
                                            type="email"
                                            className={state.RECRUITERS_SIZE.error && !state.RECRUITERS_SIZE.value ? "form-select is-invalid" : "form-select"}
                                            id="emailInput"

                                            name={state.RECRUITERS_SIZE.name}
                                            value={state.RECRUITERS_SIZE.value}
                                            onChange={onChange}
                                        >
                                            <option value=''>
                                                Select
                                            </option>
                                            <option value='1'>1</option>
                                            <option value='2-3'>2-3</option>
                                            <option value='4-7'>4-7</option>
                                            <option value='8-10'>8-10</option>
                                            <option value='11-20'>11-20</option>
                                            <option value='20-50'>20-50</option>
                                            <option value='50+'>50+</option>
                                        </select>
                                    </div>
                                </div>
                                {/*end col*/}
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="JoiningdatInput" className="form-label">
                                            Phone
                                        </label>
                                        <input
                                            type="tel"
                                            className={state.PHONE.error && !state.PHONE.value ? "form-control is-invalid" : "form-control"}
                                            id="JoiningdatInput"
                                            placeholder='Phone'
                                            name={state.PHONE.name}
                                            value={state.PHONE.value}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                {/*end col*/}
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="skillsInput" className="form-label">
                                            Location
                                        </label>
                                        <input
                                            className={state.LOCATION.error && !state.LOCATION.value ? "form-control is-invalid" : "form-control"}
                                            id="skillsInput"
                                            placeholder='Phone'
                                            name={state.LOCATION.name}
                                            value={state.LOCATION.value}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                {/*end col*/}
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="designationInput" className="form-label">
                                            Tell us more about your requirements
                                        </label>
                                        <textarea
                                            rows={5}
                                            type="text"
                                            className={state.REQUIREMENT_DETAILS.error && !state.REQUIREMENT_DETAILS.value ? "form-control is-invalid" : "form-control"}
                                            id="designationInput"
                                            placeholder='Write about your requirements'
                                            name={state.REQUIREMENT_DETAILS.name}
                                            value={state.REQUIREMENT_DETAILS.value}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                {/*end col*/}

                                {/*end col*/}
                                <div className="col-lg-12">
                                    <div className="demoButtonWapper">
                                        <button type="submit" className="btn btn-primary">
                                            Request Demo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default withRouter(RequestDemoForm)