import React, { useEffect, useState } from 'react'
import ListComponent from '../../../components/SuperAdmin/client/list'
import { v1_clients } from '../../../actions/v1/crm_client'
const List = () => {
    const [list, setList] = useState(null)
    const [count, setCount] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)


    useEffect(() => {
        v1_clients({ page: currentPage }).then((res) => {
            if (res.status) {
                setCount(res.result.count)
                setList(res.result.list)


            }

        })

    }, [currentPage])
    return (
        <React.Fragment>
            <ListComponent
                currentPage={currentPage}
                list={list}
                count={count}
            />
        </React.Fragment>
    )
}

export default List