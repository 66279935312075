import React, { useState } from 'react'
import constant from '../../../constant'
import { getStorage, removeAllLocalStorage } from '../../../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const Header = () => {
    const [ud, setUd] = useState(getStorage(constant.keys.companyAdminDetails))

    return (
        <header id="page-topbar">
            <div className="layout-width">
                <div className="navbar-header">
                    <div className="d-flex">
                        <button
                            type="button"
                            className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                            id="topnav-hamburger-icon"
                        >
                            <span className="hamburger-icon">
                                <span />
                                <span />
                                <span />
                            </span>
                        </button>
                        <div className="app-search d-none d-md-block">
                            <div className="position-relative">
                                <Link
                                to={constant.companyUserComponent.JobApplicationReport.url}
                                    className="form-control"
                                >
                                    Report
                                </Link>
                                <span className="mdi mdi-file search-widget-icon" />

                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="dropdown d-md-none topbar-head-dropdown header-item">
                            <button
                                type="button"
                                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                id="page-header-search-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i className="bx bx-search fs-22" />
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown"
                            >
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search ..."
                                                aria-label="Recipient's username"
                                            />
                                            <button className="btn btn-primary" type="submit">
                                                <i className="mdi mdi-magnify" />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <button
                                type="button"
                                className="btn"
                                id="page-header-user-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span className="d-flex align-items-center">
                                    <img
                                        className="rounded-circle header-profile-user"
                                        src="/assets/images/users/avatar-3.jpg"
                                        alt="Header Avatar"
                                    />
                                    <span className="text-start ms-xl-2" style={{ marginTop: '-4px' }}>
                                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                            {ud.ADMIN_NAME}
                                        </span>
                                        <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">
                                            {ud.ADMIN_DESIGNATION}
                                        </span>
                                    </span>
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end customDropdownHeader">
                                {/* item*/}
                                <h6 className="dropdown-header">Welcome {ud.ADMIN_NAME} !</h6>
                                <a className="dropdown-item" href={constant.comapnyAdminComponent.profilePreview.url}>
                                    <i className="mdi mdi-account-circle fs-16 align-middle me-1" />{" "}
                                    <span className="align-middle">Profile</span>
                                </a>

                                <div className="dropdown-divider" />
                                <a className="dropdown-item" href={'javascript:void(0)'}
                                    onClick={() => {
                                        removeAllLocalStorage();
                                        window.location.href = constant.nonAuthedcomponent.login.url.replace(':user_type', 'company');
                                    }}>
                                    <i className="mdi mdi-logout fs-16 align-middle me-1" />{" "}
                                    <span className="align-middle" >
                                        Logout
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header >


    )
}

export default Header