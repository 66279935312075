import React, { useEffect, useState } from 'react'
import { v3_dashboard_clients, v3_dashboard_count_stats } from '../../../actions/v3/dashboard'
import CountStats from '../../../components/companyUser/dashboard/count_stats'
import Clients from '../../../components/companyUser/dashboard/clients'
import Reminder from '../../../components/companyUser/dashboard/Reminder'

import ApplicationStatistics from '../../../components/companyUser/dashboard/applicationStatistics'
import { getGreeting, getStorage } from '../../../utils'
import constant from '../../../constant'
const Index = () => {

  const [countStats, setCountStats] = useState(null);
  const [clientList, setClientList] = useState(null);
  const [clientCount, setClientCount] = useState(null);
  const [clientListCurrentPage, setClientListCurrentPage] = useState(1);
  const [ud, setUd] = useState(getStorage(constant.keys.companyUserDetails))

  useEffect(() => {
    v3_dashboard_count_stats().then((res) => {
      if (res.status) {
        setCountStats(res.result)
      }
    })

  }, [])





  useEffect(() => {

    v3_dashboard_clients(clientListCurrentPage).then((res) => {
      if (res.status) {
        setClientList(res.result.list)
        setClientCount(res.result.count)
      }
    })

  }, [clientListCurrentPage])

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  {/* <h3 className="mb-sm-0">Dashboard</h3>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Dashboards</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div> */}
                   <div className="col-xl-12 ms-2">
                <h2>
                  {getGreeting()}, <span style={{color: '#028cd0'}}>{ud.FULL_NAME}!!</span>
                </h2>
              </div>
                </div>
              </div>
            </div>
            <div className="row">
             

              <div className="col-xl-12">
                <CountStats
                  countStats={countStats}
                />
              </div>
              {/*end col*/}
              <div className="col-xl-12 clientTableSection">
                <Clients
                  clientList={clientList}
                  clientCount={clientCount}
                  setClientListCurrentPage={setClientListCurrentPage}
                  clientListCurrentPage={clientListCurrentPage}
                />
                {/* .card*/}
              </div>
              {/*end col*/}
            </div>
            <div className="clientTableSection">
              <div class="col-md-12 clientAppStaticSection">
                <div className="row">
                  <div className="col-xxl-8">
                    <ApplicationStatistics />
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xxl-4">
                    <Reminder />
                    {/* end card */}

                  </div>
                  {/* end col */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default Index