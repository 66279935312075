import React, { useState } from 'react'
import { validateFunctionalForm } from '../../../utils';

const Index = (props) => {

  const [state, setState] = useState({
    TO: { name: 'TO', value: props.TO ? props.TO : '', disable: props.TO ? true : false, error: '', isRequired: true },
    CC: { name: 'CC', value: props.CC ? props.CC : '', error: '', isRequired: false },
    SUBJECT: { name: 'SUBJECT', value: props.SUBJECT ? props.SUBJECT : '', error: '', isRequired: true },
    BODY: { name: 'BODY', value: props.BODY ? props.BODY : '', error: '', isRequired: true },

  })

  const onChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value
      }
    }));
  }


  const onSubmit = () => {
    if (validateFunctionalForm(state, setState)) {
      const model = {
        to: state.TO.value,
        cc: state.CC.value,
        subject: state.SUBJECT.value,
        mail_body: state.BODY.value
      }
      props.onSendEmailMEssage(model)
    }
  }


  return (
    <React.Fragment>
      <div>
        <div className="mb-3 position-relative">
          <input
            type="text"
            className={state.TO.error && !state.TO.value ? "form-control email-compose-input is-invalid" : "form-control email-compose-input"}
            placeholder="To"
            name={state.TO.name}
            disabled={state.TO.disable}
            value={state.TO.value}
            onChange={onChange}

          />
          <div className="position-absolute top-0 end-0">
            <div className="d-flex">
              <button
                className="btn btn-link text-reset fw-semibold px-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#CcRecipientsCollapse"
                aria-expanded="true"
                aria-controls="CcRecipientsCollapse"
              >
                Cc
              </button>

            </div>
          </div>
        </div>
        <div className="collapse" id="CcRecipientsCollapse" >
          <div className="mb-3">
            <label>Cc:</label>
            <input
              type="text"
              className={state.CC.error && !state.CC.value ? "form-control is-invalid" : "form-control"}
              placeholder="Cc recipients"
              name={state.CC.name}
              value={state.CC.value}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="mb-3">
          <input type="text"
            className={state.SUBJECT.error && !state.SUBJECT.value ? "form-control is-invalid" : "form-control"}
            name={state.SUBJECT.name}
            value={state.SUBJECT.value}
            onChange={onChange}
            placeholder="Subject" />
        </div>
        <div className="ck-editor-reverse">
          <div className="mb-3">
            <textarea
              type="text"
              rows={10}
              className={state.BODY.error && !state.BODY.value ? "form-control is-invalid" : "form-control"}
              name={state.BODY.name}
              value={state.BODY.value}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-ghost-danger"
          data-bs-dismiss="modal"
        >
          Discard
        </button>
        <div className="btn-group">
          <button type="button" className="btn btn-success" onClick={() => { onSubmit() }}>
            Send
          </button>
          <button
            type="button"
            className="btn btn-success dropdown-toggle dropdown-toggle-split show"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul
            className="dropdown-menu dropdown-menu-end"
            data-popper-placement="top-end"
            style={{
              position: "absolute",
              inset: "auto 0px 0px auto",
              margin: 0,
              transform: "translate3d(0px, -39.2px, 0px)"
            }}
          >
            <li>
              <a className="dropdown-item" href="#">
                <i className="ri-timer-line text-muted me-1 align-bottom" /> Schedule
                Send
              </a>
            </li>
          </ul>
        </div>
      </div>


    </React.Fragment>
  )
}

export default Index