import axios from 'axios'
import { getSuperAdminAuthHeader } from '../../utils';

export const v1_onboard_client = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-client`;
    return axios.post(url, model, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v1_clients = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-client?page=${model.page}`;
    return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

export const v1_client = async (CLIENT_ID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-client/${CLIENT_ID}`;
    return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}



export const v1_post_demo_request = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-demo-enquiry`;
    return axios.post(url, model, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}


export const v1_demo_requests = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-demo-enquiry?query=${model}`;
    return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}
export const v1_change_demo_enquiry_status = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-demo-enquiry-status`;
    return axios.patch(url, model, getSuperAdminAuthHeader()).then((res) => {
        return res.data;
    })

}

// export const v1_crm_available_packages = async () => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-available-packages`;
//     return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
//         return res.data;
//     })

// }


// export const v1_crm_invoice_generation_details = async (company_id) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/crm-invoice_generation_details/${company_id}`;
//     return axios.get(url, getSuperAdminAuthHeader()).then((res) => {
//         return res.data;
//     })

// }   