import React from 'react'

const ReminderModal = ({ data }) => {
    return (
        <React.Fragment>
            <div class="col-md-12 remainderStylTableViewStyle">
                <div className='row'>
                    <div className='col-md-6'>
                        <h6>Candidate Name</h6>
                        <span>{data.CANDIDATE_NAME}</span>
                    </div>

                    <div className='col-md-6 p-0'>
                        <h6>Date Time</h6>
                        <span>{data.DATE} at {data.TIME}</span>
                    </div>
                    <div className='col-md-12 mt-3'>
                        <h6>Reminder Note</h6> 
                        <span>{data.REMINDER_NOTE}</span>
                    </div>
                    {data.REMARK && 
                    <div className='col-md-12 mt-3'>
                        <h6>Remark</h6>
                        <span>{data.REMARK}</span>
                    </div>
                    }
                    <div className='col-md-2 mt-3'>
                        <h6>Status</h6>
                        <span>{data.STATUS === 'A'?'Active':'Closed'}</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReminderModal