import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import constant from '../../../constant';
import { v3_add_to_call_directory, v3_edit_candidate, v3_edit_candidate_lead_owner, v3_edit_candidate_ticket_status } from '../../../actions/v3/candidates';
import Modal from '../../common/htmlModal';
import { ToastContainer, toast } from "react-toastify";
import Edit from "./editModal";
import EditLeadOwner from "./updateLeadOwnerModal";
import EditLeadTicketStatus from "./updateLeadTicketStatus"
import AssignCallDirectory from "./AssignCallDirectory";



// Styled component for lead profile


// Styled component for profile details
const ProfileDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

// Styled component for detail label
const DetailLabel = styled.span`
  font-weight: bold;
  min-width: 120px;
`;

// Styled component for detail value
const DetailValue = styled.span`
  margin-left: 10px;
`;

const LeadProfileComponent = ({ leadDetails, history, setRefresh, refresh }) => {
  const [showEditModal, setShowEditModal] = useState(null);
  const [showCallDirectoryAssigneeModal, setShowCallDirectoryAssigneeModal] = useState(false);
  const [showEditLeadOwnerModel, setShowEditLeadOwnerModel] = useState(null)
  const [showEditTickeStatusModal, setShowEditTickeStatusModal] = useState(false)

  const onEdit = (model) => {
    v3_edit_candidate(model).then((res) => {
      if (res.status) {
        setShowEditModal(false)
        toast.success('Candidate Lead Saved')
        setRefresh(!refresh)
      }
      else {
        toast.error(res.error)
      }
    })
  }

  const onUpdateLeadOwner = (model) => {
    v3_edit_candidate_lead_owner(model).then((res) => {
      if (res.status) {
        setShowEditLeadOwnerModel(null)
        toast.success('Lead Owner Updated Successfully')
        setRefresh(!refresh)
      }
      else {
        toast.error(res.error)
      }
    })
  }

  const onAddToCallDirectory = (model) => {
    model.CANDIDATE_ID = leadDetails.CANDIDATE_ID
    v3_add_to_call_directory(model).then((res) => {
      if (res.status) {
        setShowCallDirectoryAssigneeModal(false)
        toast.success('Lead Assigned Successfully');
      }
      else {
        toast.error(res.error)
      }
    })
  }

  const onUpdateTicketStatus = (model) => {
    v3_edit_candidate_ticket_status(model).then((res) => {
      if (res.status) {
        setShowEditTickeStatusModal(false)
        toast.success('Lead Ticket Status Updated Successfully')
        setRefresh(!refresh)
      }
      else {
        toast.error(res.error)
      }
    })
  }
  return (

    <div className="row">
      <ToastContainer />
      <Modal isOpen={showEditModal} header={'Edit Candidate Lead Details'}
      onClose={() => { setShowEditModal(null) }}
      >
        <Edit
          data={showEditModal}
          onClose={() => { setShowEditModal(null) }}
          onSubmit={(model) => { onEdit(model) }}
        />
      </Modal>

      <Modal isOpen={showEditLeadOwnerModel} header={'Change Lead Owner'}>
        <EditLeadOwner
          data={showEditLeadOwnerModel}
          onClose={() => { setShowEditLeadOwnerModel(null) }}
          onSubmit={(model) => { onUpdateLeadOwner(model) }}
        />
      </Modal>
      <Modal isOpen={showEditTickeStatusModal} header={'Change Ticket Status'}
        onClose={() => { setShowEditTickeStatusModal(false) }}
      >
        <EditLeadTicketStatus
          data={leadDetails}
          onClose={() => { setShowEditTickeStatusModal(false) }}
          onSubmit={(model) => { onUpdateTicketStatus(model) }}
        />
      </Modal>

      <Modal isOpen={showCallDirectoryAssigneeModal} header={'Assign to HR'
      }
        onClose={() => { setShowCallDirectoryAssigneeModal(null) }}

      >
        <AssignCallDirectory
          data={leadDetails}
          onSubmit={(model) => { onAddToCallDirectory(model) }}
        />
      </Modal>



      <div className="col-lg-12">
        <div className="card">
          <div className="card-header align-items-center d-flex">
            <div className="hstack gap-2 justify-content-end">
              <button type="submit" className="btn btn-success" onClick={() => { history.push(constant.companyUserComponent.addCandidate.url) }}>
                Add Next Lead
              </button>
              <button className="btn btn-primary" onClick={() => { setShowEditModal(leadDetails) }}>
                Edit Lead
              </button>

              <button className="btn btn-primary" onClick={() => { setShowCallDirectoryAssigneeModal(true) }}>
                Assign Lead
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="live-preview">
              <div className="row gy-4">

                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Full Name :</DetailLabel>
                    <DetailValue>{leadDetails.FULL_NAME}</DetailValue>
                  </ProfileDetail>
                </div>

                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Email :</DetailLabel>
                    <DetailValue>{leadDetails.EMAIL}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Phone :</DetailLabel>
                    <DetailValue>{leadDetails.PHONE}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Work Location :</DetailLabel>
                    <DetailValue>{leadDetails.WORKING_LOCATION}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Residential Location :</DetailLabel>
                    <DetailValue>{leadDetails.RESIDENTIAL_LOCATION}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Current Company :</DetailLabel>
                    <DetailValue>{leadDetails.CURRENT_COMPANY}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Current CTC :</DetailLabel>
                    <DetailValue>{leadDetails.CURRENT_CTC}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Preferred Role :</DetailLabel>
                    <DetailValue>{leadDetails.PREFERRED_ROLE_NAME ? leadDetails.PREFERRED_ROLE_NAME : "Not Available"}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Expected CTC :</DetailLabel>
                    <DetailValue>{leadDetails.EXPECTED_CTC}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Skills :</DetailLabel>
                    <DetailValue>{leadDetails.SKILLS}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Total Experience :</DetailLabel>
                    <DetailValue>{leadDetails.TOTAL_EXP_YEARS} {leadDetails.TOTAL_EXP_MONTHS}</DetailValue>
                  </ProfileDetail>
                </div>
                {leadDetails.TOTAL_EXP_YEARS === 'Fresher' &&
                  <React.Fragment>
                    <div className="col-lg-4 mb-3" >
                      <ProfileDetail>
                        <DetailLabel>College Name :</DetailLabel>
                        <DetailValue>{leadDetails.COLLEGE_NAME}</DetailValue>
                      </ProfileDetail>
                    </div>
                    <div className="col-lg-4 mb-3" >
                      <ProfileDetail>
                        <DetailLabel>Passout Year :</DetailLabel>
                        <DetailValue>{moment(leadDetails.PASS_OUT_YEAR).format('MMM-YYYY')}</DetailValue>
                      </ProfileDetail>
                    </div>
                  </React.Fragment>
                }
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Highest Qualification :</DetailLabel>
                    <DetailValue>{leadDetails.HIGHEST_QUALIFICATION}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Highest Education Qualification :</DetailLabel>
                    <DetailValue>{leadDetails.HIGHEST_EDUCATION_QUALIFICATION}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Course Specialization :</DetailLabel>
                    <DetailValue>{leadDetails.COURSE_SPECIALIZATION}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Notice Period :</DetailLabel>
                    <DetailValue>{leadDetails.NOTICE_PERIOD}</DetailValue>
                  </ProfileDetail>
                </div>


                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Source :</DetailLabel>
                    <DetailValue>{leadDetails.SOURCE}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Rating :</DetailLabel>
                    <DetailValue>{leadDetails.RATING}</DetailValue>
                  </ProfileDetail>
                </div>

                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Lead Owner :</DetailLabel>
                    <DetailValue>{leadDetails.LEAD_OWNER_NAME} &nbsp;
                      <a className="edit-item-btn" href="javascript:void(0)" onClick={() => { setShowEditLeadOwnerModel(leadDetails) }}>
                        <i className="ri-pencil-fill align-bottom me-2 text-muted" />
                      </a>
                    </DetailValue>
                  </ProfileDetail>
                </div>

                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Note :</DetailLabel>
                    <DetailValue>{leadDetails.NOTE}</DetailValue>
                  </ProfileDetail>
                </div>
                <div className="col-lg-4 mb-3" >
                  <ProfileDetail>
                    <DetailLabel>Ticket Status :</DetailLabel>
                    <DetailValue>{leadDetails.TICKET_STATUS} &nbsp;
                      <a className="edit-item-btn" href="javascript:void(0)" onClick={() => { setShowEditTickeStatusModal(true) }}>
                        <i className="ri-pencil-fill align-bottom me-2 text-muted" />
                      </a>
                    </DetailValue>
                  </ProfileDetail>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>



  );
};

export default withRouter(LeadProfileComponent);
