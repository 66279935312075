import React, { useState } from 'react'
import constant from '../../../constant'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getStorage } from '../../../utils';

const Sidebar = () => {
  const havingTeam = getStorage(constant.keys.companyUserHavingTeam);
  const isAdministrator = getStorage(constant.keys.companyUserHavingAdminRights);


  return (
    <div className="app-menu navbar-menu sidebar-closed customSiderbarDashboard">

      <div className="navbar-brand-box">
        <Link to={constant.companyUserComponent.dashBoard.url} className="logo logo-dark">

          <span className="logo-sm">
            <img src="/images/logo/recruit-logo.png" alt="" height={47} />
          </span>
          <span className="logo-lg">
            <img src="/images/logo/recruit-logo.png" alt="" height={47} />
          </span>
        </Link>

        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line" />
        </button>
      </div>
      <div id="scrollbar">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
            {/* <li className="menu-title">
              <hr />
            </li> */}
            <li className="nav-item">
              <Link
                className="nav-link"
                to={constant.companyUserComponent.dashBoard.url}
              >
                <i className="ri-dashboard-2-line" />
                <span data-key="t-dashboards">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={constant.companyUserComponent.orgChart.url}
                className="nav-link"
              >

                <i className="bx bx-network-chart" />
                <span >Organization Chart</span>

              </Link>
            </li>
            {/* end Dashboard Menu */}
            <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#sidebarApps"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarApps"
              >
                <i className="ri-apps-2-line" /> <span data-key="t-user-managemt">Job Management</span>
              </Link>
              <div className="collapse menu-dropdown" id="sidebarApps">
                <ul className="nav nav-sm flex-column">
                  {havingTeam && <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.postJob.url}
                      className="nav-link"
                      data-key="t-starter"
                    >
                      Post Job
                    </Link>
                  </li>}
                  <li className="nav-item">
                    <Link
                      to="#sidebarSignIn"
                      className="nav-link"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarSignIn"
                      data-key="t-signin"
                    >

                      View Jobs
                    </Link>
                    <div className="collapse menu-dropdown" id="sidebarSignIn">
                      <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                          <Link
                            to={constant.companyUserComponent.postedJobs.url}
                            className="nav-link"
                            data-key="t-basic"
                          >

                            Posted Jobs
                          </Link>
                          <Link
                            to={constant.companyUserComponent.expiredJobs.url}
                            className="nav-link"
                            data-key="t-basic"
                          >

                            Expired Jobs
                          </Link>
                        </li>
                        {havingTeam && <li className="nav-item">
                          <Link
                            to={constant.companyUserComponent.nonPostedJobs.url}
                            className="nav-link"
                          >

                            Non Posted Jobs
                          </Link>
                        </li>}
                      </ul>
                    </div>
                  </li>

                </ul>
              </div>
            </li>


            <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#sidebarPages"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="ri-pages-line" />
                <span data-key="t-pages">Candidate&nbsp;Management</span>
              </Link>
              <div className="collapse menu-dropdown" id="sidebarPages">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.addCandidate.url}
                      className="nav-link"
                    >
                      Add Candidate
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.addBulkCandidate.url}
                      className="nav-link"
                    >
                      Bulk Upload
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.messageTemplates.url}
                      className="nav-link"
                    >
                      Message Templates
                    </Link>

                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="pages-team.html"
                      className="nav-link"
                      data-key="t-team"
                    >

                      Resume Parsing
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.listCandidate.url}
                      className="nav-link"
                      data-key="t-timeline"
                    >

                      Candidate Search
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.CandidateHiringStatus.url}
                      className="nav-link"
                      data-key="t-timeline"
                    >
                      Hiring Status
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.callingDirectory.url}
                      className="nav-link"
                      data-key="t-timeline"
                    >

                      Assigned Leads
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.QrWalkInCandidates.url}
                      className="nav-link"
                      data-key="t-timeline"
                    >

                      Walk-in (QR)
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="pages-faqs.html"
                      className="nav-link"
                      data-key="t-faqs"
                    >

                      Add Notes
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="pages-pricing.html"
                      className="nav-link"
                      data-key="t-pricing"
                    >

                      Candidates Follow Ups
                    </Link>
                  </li> */}

                </ul>
              </div>
            </li>

            {havingTeam && <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#team"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="bx bx-aperture" />
                <span >Team</span>
              </Link>
              <div className="collapse menu-dropdown" id="team">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={constant.companyUserComponent.teamStats.url}
                    >
                      <span data-key="t-team-insights">Team Dashboard</span>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={constant.companyUserComponent.teamInsights.url}
                    >
                      <span data-key="t-team-insights">Team Insights</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>}

            {isAdministrator && <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#administrator"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPages"
              >
                <i className="bx bx-aperture" />
                <span data-key="t-pages">Administrator</span>
              </Link>
              <div className="collapse menu-dropdown" id="administrator">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.overAllStats.url}
                      className="nav-link"
                    >
                      Admin Dashboard
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.viewUsers.url}
                      className="nav-link"
                    >
                      CRM User
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to={constant.companyUserComponent.viewClients.url}
                      className="nav-link"
                    >
                      Clients
                    </Link>
                  </li>
                </ul>

              </div>
            </li>}
          </ul>
        </div>
        {/* Sidebar */}
      </div>
      {/* <div className="sidebar-background" /> */}
    </div >


  )
}

export default Sidebar