import React from 'react';
import styles from './style.module.css';

const Detail = ({ label, value }) => {
    return (
        <div className={styles.detail}>
            <span className={styles.label}>{label} : </span>
            <span className={styles.value}>{value}</span>
        </div>
    );
};

const CanididateDetailDrawerModal = (props) => {
    const { clientDetails } = props;
    return (
        <div>
            <h2>Client Details</h2>
            <div className={styles['details-container']}>
                <Detail label="Client ID" value={'RJZSHCC' + clientDetails.CLIENT_ID} />
                <Detail label="Company Name" value={clientDetails.COMPANY_NAME} />
                <Detail label="SPOC Name" value={clientDetails.CLIENT_NAME} />
                <Detail label="Email" value={clientDetails.EMAIL} />
                <Detail label="Phone" value={clientDetails.PHONE} />
                <Detail label="GST No" value={clientDetails.GST_NO} />

                <Detail label="Agreement Start Date" value={clientDetails.AGREEMENT_START_DATE} />
                <Detail label="Agreement End Date" value={clientDetails.AGREEMENT_END_DATE} />
                <Detail label="Client Document" value={clientDetails.CLIENT_DOC} />
                <Detail label="Service Agreement Document" value={clientDetails.SERVICE_AGREEMENT_DOC} />
                <Detail label="Rate Card Document" value={clientDetails.RATE_CARD} />
                <Detail label="Client Detail" value={clientDetails.CLIENT_DETAILS} />
                <Detail label="Client Address" value={clientDetails.CLIENT_ADDRESS} />

            </div>
        </div>
    );
};

export default CanididateDetailDrawerModal;
