import React, { useState } from 'react'
import moment from 'moment'
import constant from '../../../../constant'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getStorage } from '../../../../utils'

const NonPosted = (props) => {
    const isAdministrator = getStorage(constant.keys.companyUserHavingAdminRights);
    const { searchKeyword, setSearchKeyword } = props
    const onSearch = () => {
        props.onSearch(searchKeyword)
    }

    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">{props.Title}</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">View Jobs</a>
                                            </li>
                                            <li className="breadcrumb-item active">Posted Jobs</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end page title */}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form>
                                            <div className="row g-3">
                                                <div className="col-xxl-4 col-sm-12">
                                                    <div className="search-box">
                                                        <input
                                                            type="text"
                                                            className="form-control search bg-light border-light"
                                                            placeholder="Search for jobs or companies..."
                                                            value={searchKeyword}
                                                            onChange={(e) => { setSearchKeyword(e.target.value) }}
                                                        />
                                                        <i className="ri-search-line search-icon" />
                                                    </div>
                                                </div>
                                                <div className="col-xxl-1 col-sm-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100"
                                                        onClick={() => { onSearch() }}
                                                    >
                                                        <i className="ri-equalizer-fill align-bottom" />{" "}
                                                        Filters
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                        </div>
                        <div className="row" id="job-list">
                            <div className="col-12">
                                <div className="list-group">
                                    {props.jobList && props.jobList.length > 0 ? props.jobList.map((item) => {
                                        return (<div
                                            className="list-group-item list-group-item-action"
                                        >
                                            {props.CLOSE_JOB_BUTTON && <div className="float-end"><span class="badge badge-label bg-success"><i class="mdi mdi-circle-medium"></i> &nbsp;Live&nbsp;&nbsp;</span>
                                                <div className="alert border-0 alert-danger mt-2" onClick={() => { props.updateJobVisibility(item) }}>
                                                    Close Job
                                                </div>

                                            </div>}
                                            {props.LIVE_JOB_BUTTON && <div className="float-end"><span class="badge badge-label bg-danger"><i class="mdi mdi-circle-medium"></i> &nbsp;Closed&nbsp;&nbsp;</span>
                                                <div className="alert border-0 alert-success mt-2" onClick={() => { props.updateJobVisibility(item) }}>
                                                    Live Job
                                                </div>

                                            </div>}


                                            <div className="d-flex mb-2 align-items-center"
                                                // to={constant.companyUserComponent.jobApplications.url.replace(':job_id', item.JOB_ID).replace(':page_number', 1)}

                                            >
                                                <div className="flex-shrink-0">

                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h5 className="list-title fs-15 mb-1">
                                                        <Link
                                                to={constant.companyUserComponent.jobApplications.url.replace(':job_id', item.JOB_ID).replace(':page_number', 1)}
                                                        
                                                        >
                                        
                                                        {item.JOB_TITLE}
                                                        </Link>

                                                        {isAdministrator &&
                                                            <a href={constant.companyUserComponent.updateJob.url.replace(':step', item.COMPLETED_STEPS).replace(':job_id', item.JOB_ID)}>
                                                                <span class="badge badge-label bg-light text-dark"><i class="ri-edit-2-line" /> &nbsp;&nbsp;Edit Job&nbsp;&nbsp; </span>
                                                            </a>
                                                        }
                                                    </h5>

                                                    <h6 className="list-title fs-12 mb-1">{item.COMPANY_NAME}</h6>

                                                    <p className="list-text mb-3 fs-12"><strong>Posted </strong> {moment(item.MODIFIED_ON).fromNow()}</p>
                                                    <p className="list-text mb-0 fs-12"><strong>Work Experience :</strong> {item.WORK_EXP_MIN + ' Year - ' + item.WORK_EXP_MAX + ' Year'}, <strong>Salary Range :</strong> {item.SALARY_MIN + ' Lakh - ' + item.SALARY_MAX + ' Lakh'}</p>
                                                    <p className="list-text mb-3 fs-12"></p>

                                                </div>
                                            </div>

                                        </div>)
                                    }) :
                                        <div className="list-group-item noresult" >
                                            <div className="text-center">
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/msoeawqm.json"
                                                    trigger="loop"
                                                    colors="primary:#121331,secondary:#08a88a"
                                                    style={{ width: 75, height: 75 }}
                                                />
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">
                                                    We've searched more than 150+ leads We did not find any
                                                    leads for you search.
                                                </p>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>


                        </div>
                        {/* <div className="row g-0 justify-content-end mb-4" id="pagination-element">
                            <div className="col-sm-6">
                                <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                                    <div className="page-item">
                                        <a
                                            href="javascript:void(0);"
                                            className="page-link"
                                            id="page-prev"
                                        >
                                            Previous
                                        </a>
                                    </div>
                                    <span id="page-num" className="pagination" />
                                    <div className="page-item">
                                        <a
                                            href="javascript:void(0);"
                                            className="page-link"
                                            id="page-next"
                                        >
                                            Next
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}

export default NonPosted