import React from 'react'
import AddClient from '../../../components/CompanyAdmin/clientManagement/add'
import { v2_add_client } from '../../../actions/v2/client_management'
import { ToastContainer, toast } from 'react-toastify';

const Add = (props) => {
    const onSubmit = (model) => {
        v2_add_client(model).then((res) => {
            if (res.status) {
                toast.success('Client Added Successfully')
            }
            else {
                toast.error(res.error)

            }
        }).catch((err) => {
            toast.error(err)

        })
    }
    return (
        <React.Fragment>

            <AddClient
                onSubmit={onSubmit}
            />
            <ToastContainer />
        </React.Fragment>
    )
}

export default Add