import axios from 'axios'

export const v1_login = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/login`;
    return axios.post(url,model).then((res) => {
        return res.data;
    })

}



export const v1_is_valid_company = async (company_code) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/is-valid/${company_code}`;
    return axios.get(url).then((res) => {
        return res.data;
    })

}

