import React from 'react'

const FourZeroFour = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default FourZeroFour